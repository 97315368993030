import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import ApiClient from "../../methods/api/apiClient";
import SelectDropdown from "../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import moment from "moment";
import CountdownTimer from "./countdoenTimer";
import loader from "../../methods/loader";
import { Col, Tooltip } from "antd";
import { IoHammerOutline } from "react-icons/io5";
import { LuCircleDotDashed } from "react-icons/lu";
import { LuCircleDot } from "react-icons/lu";
import dateFormat from "dateformat";
import { FaAward } from "react-icons/fa6";
import pipeModel from "../../models/pipeModel";

const Html = ({
  view,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  GetLoadsFromEmail,
  reset,
  CheckParamsPath,
  sorting,
  add,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  filters,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  timeRemaining,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  Handlefilter,
  getExpiredData,
  TAB,
  SETTAB,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [Min_rate, setMin_rate] = useState("");
  const [Max_rate, setMax_rate] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [BoardFilter, setBoardFilter] = useState([]);
  const [boards, setBoards] = useState([]);
  const latestSliderValue = React.useRef([0, 0]);
  const [AppliedBid, setAppliedBids] = useState([]);
  const [BidDetial, setBidDetails] = useState({});
  const [tab, settab] = useState("best");
  const [bigLoader, setBidLoader] = useState(false);
  const Columns = JSON.parse(localStorage.getItem("LoadColumn")) || [];
  const url = window.location.pathname;
  const StartTimer = (data) => {
    let Created = new Date(data);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    // setHour(hours);
  
    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    // setTimeRemaining2(`${hours}h ${minutes}m ${seconds}s`);
    // setHour(hours);

    return `${hours}h ${minutes}m ${seconds}s`;
  };
  useEffect(() => {
    setInterval(() => {
      // console.log(StartTimer());
    }, 1000);
  }, []);
  const columns = [
    // "distance",
    "origin_city",
    "origin_state",
    "origin_zip",
    "destination_city",
    "destination_state",
    "destination_zip",
    "load_start_date",
    "load_end_date",
    "avg_rating",
    "total_reviews",
    // "Posted At",
    // "min_rate",
    // "max_rate",
  ];

  const [visibleColumns, setVisibleColumns] = useState(Columns);

  useEffect(() => {
    localStorage.setItem("LoadColumn", JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  const GetBidsUser = (id) => {
    setBidLoader(true);
    ApiClient.get("bids-on-load", { id }).then((res) => {
      if (res.success) {
        setAppliedBids(res?.data?.bids_detail);
        setBidDetails(res?.data);
      }
      setBidLoader(false);
    });
  };
  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);

    {
      TAB == "list"
        ? getData({ min_rate: min, max_rate: max })
        : getExpiredData({ min_rate: min, max_rate: max });
    }
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  useEffect(() => {
    ApiClient.get("boards").then((res) => {
      // setBoards(res?.data?.data);
      let arr = res?.data?.data?.map((itm) => {
        return {
          name: `${methodModel.capitalizeFirstLetter(itm?.name)} | expires  ${itm?.expiration_type == "hours"
              ? moment()
                .endOf("hours")
                .add(itm?.expiration_value, "hours")
                .fromNow()
              : itm?.expiration_type == "minutes"
                ? moment()
                  .endOf("minutes")
                  .add(itm?.expiration_value, "minutes")
                  .fromNow()
                : moment()
                  .endOf("days")
                  .add(itm?.expiration_value, "days")
                  .fromNow()
            }`,
          id: itm?.id,
        };
      });
      setBoards(arr);
    });
  }, []);

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }

  const ConvertToSortingKeys = (str) => {
    switch (str) {
      case "origin_city":
        return "origin_location_city";
        break;

      case "origin_state":
        return "origin_location_state";
        break;

      case "origin_zip":
        return "origin_location_postal_code";
        break;

      case "destination_city":
        return "destination_location_city";
        break;

      case "destination_state":
        return "destination_location_state";
        break;

      case "destination_zip":
        return "destination_location_postal_code";
        break;

      case "Posted At":
        return "createdAt";
        break;

      default:
        return str;
    }
  };

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "origin_city":
        return itm?.origin_location_city || "--";
        break;
      case "origin_state":
        return itm?.origin_location_state || "--";
        break;
      case "origin_zip":
        return itm?.origin_location_postal_code || "--";
        break;
      case "destination_city":
        return (
          ConvertStringToAbbrivate(itm?.destination_location_city).replace(
            ",",
            " "
          ) || "--"
        );
        break;
      case "destination_state":
        return (
          ConvertStringToAbbrivate(itm?.destination_location_state).replace(
            ", ",
            " "
          ) || "--"
        );
        break;
      case "destination_zip":
        return itm?.destination_location_postal_code || "--";
        break;
      case "load_start_date":
        return moment(value).format("DD-MM-YYYY HH:MM A");
        break;
      case "load_end_date":
        return moment(value).format("DD-MM-YYYY HH:MM A");
        break;
      case "Posted At":
        return datepipeModel.date(itm?.createdAt);
        break;
      // case "distance":
      //   return itm?.total_distance
      // case "min_rate":
      //   return `$${itm?.min_rate}`;
      //   break;
      // case "max_rate":
      //   return `$${itm?.max_rate}`;
      //   break;
      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return pipeModel.number(value) == NaN ? (value || '') : pipeModel.number(value);
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }
  useEffect(() => {
    let JoindID = BoardFilter.join(",");
    {
      TAB == "list"
        ? getData({ ...filters, board_id: JoindID })
        : getExpiredData({ ...filters, board_id: JoindID });
    }
  }, [BoardFilter]);

  const completeBid = (id) => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=accepted`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // setShowAcceptModal("none")
        document.getElementById("CloseBidViewModel").click();
        {
          TAB == "list"
            ? getData({ search: "", page: 1 })
            : getExpiredData({ search: "", page: 1 });
        }
      }
      loader(false);
    });

    // }
  };

  const ConvertStringToAbbrivate = (str) => {

    let key = str?.includes(" ");
    if (key) {
 
      let extractletter = str
        ?.split(" ")
        ?.map((word) => methodModel.capitalizeFirstLetter(word[0]));

      return extractletter?.join("");
    } else {
      return str || "--";
    }
  };

  const rejectBid = (id) => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=rejected`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // setShowRejectModal("none")
        document.getElementById("CloseBidViewModel").click();
        {
          TAB == "list"
            ? getData({ search: "", page: 1 })
            : getExpiredData({ search: "", page: 1 });
        }
      }
      loader(false);
    });
    // }
  };


  const [ratingForm, setRatingForm] = useState({ rating: '', review: '', load_id: '' })

  const openReview = (id) => {
    setRatingForm({
      rating: '', review: '', load_id: id
    })
    let el = document.getElementById("openRatingModal")
    el?.click()

  }

  const reviewSubmit = () => {
    loader(true)
    ApiClient.post('reviews', ratingForm).then(res => {
      if (res.success) {
        toast.success(res.message)
        let el = document.getElementById("openRatingModal")
        el?.click()
        if (TAB == 'archive') {
          getExpiredData()
        } else {
          getData()
        }

      }
      loader(false)
    })
  }


  return (
    <>
      <Layout>
        <CommonDeleteModal
          show={ShowDeleteModal}
          setShow={setShowDeleteModal}
          confirm={Delete}
        />
        <CommonActiveModal
          show={ShowActiveModal}
          setShow={setShowActiveModal}
          confirm={StatusCh}
          status={StatusData.status}
        />
        <div className="common-head-tbl ">
          <h3 className="hedding">{CheckParamsPath()?.key}</h3>
          <div className="row mt-md-3 mt-2">
            {/* <div className="  col-md-3">
            <div className="tbl-shadow">
              <label className="mb-0 price-range-text">Price Range:</label>
              <div className=" price-range-label mt-3 mb-2">
                <RangeSlider
                  min={0}
                  step={5}
                  value={[Min_rate, Max_rate]}
                  max={10000}
                  onInput={handleSliderChange}
                />
                <div className="d-flex justify-content-between mt-2">
                  <p className="mb-0 range_price ">${Min_rate}</p>
                  <p className="mb-0 range_price ">${Max_rate}</p>
                </div>
              </div>
            </div>
          </div> */}
            <div className="col-md-12 ">
              <article className="row filterFlex phView mb-2 justify-content-between">
                <div className="col-xl-6 col-lg-6 mb-sm-2 mb-md-1">
                  <div className="dropdown addDropdown chnagesg  d-flex align-items-center equal-width-50 w-100 mt-0">
                    {/* <button
                    className="btn blck-border-btn dropdown-toggle removeBg"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {filters.status
                      ? filters.status == "deactive"
                        ? "Inactive"
                        : filters.status
                      : "All Status"}
                  </button>

                  <div
                    className="dropdown-menu shadow bg_hover w-100"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className={
                        filters.status == ""
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      onClick={() => ChangeStatus("")}
                    >
                      All Status
                    </a>
                    <a
                      className={
                        filters.status == "active"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      onClick={() => ChangeStatus("active")}
                    >
                      Active
                    </a>
                    <a
                      className={
                        filters.status == "Inactive"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      onClick={() => ChangeStatus("deactive")}
                    >
                      Inactive
                    </a> */}
                    {/* </div> */}
                    <input
                      className="form-control"
                      placeholder="Search..."
                      type="search"
                      value={filters.search}
                      name="search"
                      onChange={(e) => Handlefilter(e)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 ">
                  <div className="d-flex gap-2 fiftyflex">
                    <div className="w-50">
                      {" "}
                      <div className="dropdown addDropdown   chnagesg  d-flex align-items-center equal-width-50 w-100 ">
                        <button
                          className="btn blck-border-btn dropdown-toggle mb-0 "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Add Columns
                        </button>
                        <div
                          className="dropdown-menu w-100"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {findUniqueElements(visibleColumns, columns).map(
                            (itm) => {
                              return (
                                <a
                                  className={"dropdown-item"}
                                  onClick={() => handleColumnToggle(itm)}
                                >
                                  {replaceUnderscoresWithSpace(itm)}
                                </a>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      {" "}
                      <div className="">
                        {/* <button
                        className="btn btn-primary w-100 mb-0"
                        onClick={() => {
                          Navigate.push("/loads/add");
                        }}
                      >
                        Add Load
                      </button> */}
                        <button
                          className="btn btn-primary w-100 mb-0 "
                          onClick={() => {
                            GetLoadsFromEmail();
                          }}
                        >
                          Get Load
                        </button>
                        <div
                          className={`equal-width-10  w-100 add-load ${filters.status || filters.role ? "addLoadNew" : ""
                            }`}
                        >
                          {filters.status || filters.role ? (
                            <>
                              <a
                                className="btn btn-primary text-white ms-2"
                                onClick={(e) => reset()}
                              >
                                Reset
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="row">
                <div className="col-xl-6 col-lg-6 ">
                  <div className="multiselect-custom">
                    <MultiSelectDropdown
                      id="statusDropdown"
                      className="role-color"
                      displayValue="name"
                      placeholder="Select Load Type"
                      intialValue={BoardFilter}
                      result={(e) => {
            
                        setBoardFilter(e.value);
                      }}
                      options={boards}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6  pt-1">
                  <ul class="nav nav-tabs portal-tabs portal-tabs-view ">
                    <li class="nav-item">
                      <a
                        class={`nav-link ${TAB == "list" ? "active bg-primary" : ""
                          }`}
                        aria-current="page"
                        onClick={() => {
                          SETTAB("list");
                          getData();
                          // setFilter({ ...filters, isDeleted: false });

                          // getData({ isDeleted: false });
                        }}
                      >
                        <span className="me-2">Active</span>
                        Loads
                      </a>
                    </li>
                    <li class={`nav-item ${TAB == "archive" ? "hello" : ""}`}>
                      <a
                        class={`nav-link ${TAB == "archive" || filters?.isExpired
                            ? "active bg-danger"
                            : ""
                          }`}
                        onClick={() => {
                          SETTAB("archive");
                          getExpiredData();
                          // setFilter({ ...filters, isDeleted: true });
                          // getData({ isDeleted: true });
                        }}
                      >
                        <span class="me-2" aria-hidden="true">
                          Expired
                        </span>
                        Loads
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="table-responsive table_section mt-4">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row">
                  {TAB == "list" ? (
                    <th
                      scope="col"
                      className="table_data pointer"
                      onClick={() => {
                        sorting(
                          "expiration_date",
                          filters?.sorder == "asc" ? "desc" : "asc"
                        );
                      }}
                    >
                      {" "}
                      <span className="d-flex align-items-center">
                        {" "}
                        Time left{" "}
                        {filters?.sortBy === "expiration_date asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                  ) : (
                    ""
                  )}
                  <th
                    onClick={() => {
                      sorting(
                        "load_id",
                        filters?.sorder == "asc" ? "desc" : "asc"
                      );
                    }}
                    scope="col"
                    className="table_data pointer"
                  >
                    {" "}
                    <span className="d-flex align-items-center">
                      {" "}
                      Load ID/Lane ID{" "}
                      {filters?.sortBy === "load_id asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">north</span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">south</span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      sorting(
                        "total_distance",
                        filters?.sorder == "asc" ? "desc" : "asc"
                      );
                    }}
                    scope="col"
                    className="table_data pointer"
                  >
                    {" "}
                    <span className="d-flex align-items-center">
                      {" "}
                      Distance (Mi){" "}
                      {filters?.sortBy === "total_distance asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">north</span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">south</span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      sorting(
                        "bid_count",
                        filters?.sorder == "asc" ? "desc" : "asc"
                      );
                    }}
                    scope="col"
                    className="table_data pointer"
                  >
                    {" "}
                    <span className="d-flex align-items-center">
                      {" "}
                      Total Bids{" "}
                      {filters?.sortBy === "bid_count asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">north</span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">south</span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="table_data pointer"
                    onClick={() =>
                      sorting("stops", filters?.sorder == "asc" ? "desc" : "asc")
                    }
                  >
                    {" "}
                    <span className="d-flex align-items-center">
                      Stop{" "}
                      {filters?.sortBy === "bid_count asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">north</span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">south</span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  {/* <th scope="col" className="table_data pointer">
                  Posted At
                </th> */}

                  {visibleColumns.map((item) => (
                    <th
                      onClick={() => {
                        sorting(
                          ConvertToSortingKeys(item),
                          filters?.sorder == "asc" ? "desc" : "asc"
                        );
                      }}
                      className="text-capitalize table_data "
                    >
                      <span className="d-flex align-items-center">
                        {replaceUnderscoresWithSpace(item)}
                        {item == "origin_zip" ||
                          item == "destination_zip" ||
                          item == "load_start_date" ||
                          item == "load_end_date" ? (
                          <i className="fa fa-sort"></i>
                        ) : (
                          <i aria-hidden="true">
                            {filters?.sorder === "asc" ? (
                              <div class="d-flex  zfonts align-items-center">
                                <div className="d-flex flex-column">
                                  <span className="zfont">A </span>{" "}
                                  <span className="afont">Z</span>
                                </div>{" "}
                                <span>
                                  <span class="material-icons arrfont">
                                    north
                                  </span>
                                </span>
                              </div>
                            ) : (
                              <div class="d-flex zfonts align-items-center">
                                <div className="d-flex flex-column">
                                  <span className="zfont">Z </span>{" "}
                                  <span className="afont">A</span>
                                </div>
                                <span>
                                  <span class="material-icons arrfont">
                                    south
                                  </span>
                                </span>
                              </div>
                            )}
                          </i>
                        )}{" "}
                        <i
                          className="fa fa-times"
                          onClick={(e) => handleColumnToggle(item)}
                        ></i>{" "}
                      </span>
                    </th>
                  ))}
                  {url == "/delivered-loads1" ? (
                    <th className="table_data">Pickup Time</th>
                  ) : (
                    ""
                  )}

                  {url == "/delivered-loads1" ? (
                    <th className="table_data">Delivery Time</th>
                  ) : (
                    ""
                  )}
                  {/* <th scope="col" className="table_data ">
                  Status
                </th> */}
                  <th scope="col" className="table_data">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  ListingData &&
                  ListingData.map((itm, i) => {
                    return (
                      <tr className="data_row">
                        {TAB == "list" ? (
                          <td className="table_dats">
                            <div className="timer d-flex align-items-center">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 512 512"
                                class="me-2"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                              </svg>
                              <CountdownTimer
                                expirationDate={itm?.expiration_date}
                              />
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        <td
                          className="table_dats"
                          onClick={() => {
                            if (Permission?.load_get) {
                              view(itm?.id);
                            }
                          }}
                        >
                          {itm.load_id || itm.lane_id || "--"}
                        </td>
                        <td className="table_dats">{itm.total_distance}</td>
                        <td
                          className="table_dats pointer"
                          onClick={() => {
                            if(user?.role=="admin")
                            {
                              if (itm?.bid_count != 0) {
                                document.getElementById("ViewBidsUser").click();
                                GetBidsUser(itm?.id);
                                settab("best");
                              }
                            }else
                            {
                              if (itm?.bid_count != 0 && Permission?.load_get) {
                                document.getElementById("ViewBidsUser").click();
                                GetBidsUser(itm?.id);
                                settab("best");
                              }
                            }
                            
                          }}
                        >
                          <Tooltip title="View Bid" placement="top">
                            <span className="bid-count">{itm?.bid_count}</span>
                          </Tooltip>
                        </td>
                        <td className="table_dats">
                          <div
                            onClick={() => view(itm?.id)}
                            className="badge badge-success common-badge"
                          >
                            All Stops
                          </div>
                          <div className="ellipses">{itm.stops[0]?.address}</div>
                          <div className="ellipses">{itm.stops[1]?.address} </div>
                        </td>
                        {/* <td className="table_dats" >
                        {moment(itm?.createdAt).format('DD-MMM-YYYY')}
                      </td> */}

                        {visibleColumns.map((item, index) => (
                          <td className="text-capitalize">
                            {ColumnReturner(item, itm[[item]], itm)}
                          </td>
                        ))}

                        {/* <td className="table_dats">
                        {" "}
                        <div className={` ${itm.status}`}>
                          <span className="custom-toggle-btn">
                            {itm.status == "deactive" ? (
                              <Switch
                                onChange={(e) => {
                                  if (
                                    Permission?.carrier_edit ||
                                    user?.role == "admin"
                                  ) {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  } else {
                                    toast.warn(
                                      "You do not have valid permission"
                                    );
                                  }
                                }}
                                checked={false}
                              />
                            ) : (
                              <Switch
                                onChange={(e) => {
                                  if (
                                    Permission?.carrier_edit ||
                                    user?.role == "admin"
                                  ) {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  } else {
                                    toast.warn(
                                      "You do not have valid permission"
                                    );
                                  }
                                }}
                                checked={true}
                              />
                            )}
                          </span>
                        </div>
                      </td> */}
                        {url == "/delivered-loads1" ? (
                          <td>
                            {moment(itm?.pickedup_at).format(
                              "DD-MM-YYYY h:m A"
                            ) || "--"}
                          </td>
                        ) : null}

                        {url == "/delivered-loads1" ? (
                          <td>
                            {moment(itm?.delivered_at).format(
                              "DD-MM-YYYY h:m A"
                            ) || "--"}
                          </td>
                        ) : null}
                        {/* dropdown */}
                        <td className="table_dats">
                          <div className="action_icons">
                            {Permission?.load_delete || user?.role == "admin" ? (
                              <>
                                <Tooltip placement="top" title="Delete">
                                  <span
                                    className="edit_icon"
                                    title="Delete"
                                    onClick={() => {
                                      if (
                                        Permission?.load_delete ||
                                        user?.role == "admin"
                                      ) {
                                        setDeleteId(itm?.id);
                                        setShowDeleteModal("block");
                                        // deleteLoad(itm?.id)
                                      } else {
                                        toast.warn(
                                          "You do not have valid permission"
                                        );
                                      }
                                    }}
                                  >
                                    <i
                                      class="material-icons delete"
                                      title="Delete"
                                    >
                                      {" "}
                                      delete
                                    </i>
                                  </span>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}

                            {Permission?.load_edit || user?.role == "admin" ? (
                              <Tooltip placement="top" title="View">
                                <span
                                  className="edit_icon"
                                  onClick={() => {
                                    if (
                                      Permission?.load_edit ||
                                      user?.role == "admin"
                                    ) {
                                      Navigate.push(`loads/view/${itm.id}`);
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Tooltip>
                            ) : null}

                            {url == "/delivered-loads1" && !itm?.is_submit_review && TAB == 'archive' ? <>
                              {/* <button type="button" onClick={()=>openReview(itm?.id)} className="btn btn-sm btn-primary">Add Review</button> */}
                              <Tooltip placement="top" title="Add Review"> <span class="material-icons reviewIcon" onClick={() => openReview(itm?.id)}>add_comment</span> </Tooltip>
                            </> : <>
                              <div></div>
                            </>}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>

        {total == 0 ? (
          <div className=" no-data">
            <img src="assets/img/no-data.png" />
            No Data
          </div>
        ) : (
          <></>
        )}

        {!loaging && total > 0 ? (
          <div className="paginationWrapper">
            <div className="d-flex align-items-center">
              <div className="me-2 user-name-color">Show</div>
              {total > 0 && (
                <div className="dropdown addDropdown chnagesname ">
                  <button
                    className="btn btn-primary dropdown-toggle removeBg"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {filters?.count}
                  </button>
                  <div
                    className="dropdown-menu shadow bg_hover"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 5 });
                      }}
                    >
                      5
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 10 });
                      }}
                    >
                      10
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 20 });
                      }}
                    >
                      20
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 30 });
                      }}
                    >
                      30
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 40 });
                      }}
                    >
                      40
                    </a>
                  </div>
                </div>
              )}{" "}
              <div className="ms-2 user-name-color">from {total} Loads</div>
            </div>

            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}

        {loaging ? (
          <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div>
        ) : (
          <></>
        )}

        <button
          style={{ display: "none" }}
          type="button"
          id="ViewBidsUser"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal9"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade bid-modal"
          id="exampleModal9"
          tabindex="-1"
          aria-labelledby="exampleModalLabel9"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div style={{ width: "230%" }} class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel9">
                  Bids Details
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body col-md-12">
                <div
                  className={`${AppliedBid?.length > 6 ? "bid-scroll" : "bid_scrolll_remove"
                    }`}
                >
                  <ul
                    class="nav nav-tabs portal-tabs bid-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        className={`nav-link ${tab == "best" ? "active" : ""}`}
                        onClick={() => settab("best")}
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <LuCircleDotDashed className="me-2" />
                        Best Bids
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        className={`nav-link ${tab == "other" ? "active" : ""}`}
                        onClick={() => settab("other")}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        {" "}
                        <LuCircleDot className="me-2" />
                        Bids
                      </button>
                    </li>
                  </ul>
                  {bigLoader ? (
                    <>
                      <div className="text-center p-3">Loading...</div>
                    </>
                  ) : (
                    <>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class={`tab-pane fade ${tab == "best" ? "active show" : ""
                            }`}
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          {AppliedBid.filter((itm) => itm.isBest).length ? (
                            <>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Carrier Name</th>
                                    <th>Origin City</th>
                                    <th>Destination City</th>
                                    <th>Truck Location</th>
                                    <th>Pickup Date</th>
                                    <th>Expiration Date</th>
                                    <th>ETA</th>
                                    {/* <th>Carrier Name</th> */}

                                    <th>Agreed Rate</th>

                                    <th>Actions</th>
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {AppliedBid?.map((itm) =>
                                    itm?.isBest ? (
                                      <tr
                                        key={itm.id}
                                        className={`data_row ${itm.overtime ? "overtime" : ""
                                          }`}
                                      >
                                        <td className="">
                                          <Tooltip
                                            title="Best Bid"
                                            placement="top"
                                          >
                                            {methodModel.capitalizeFirstLetter(
                                              itm?.carrier_name
                                            )}
                                          </Tooltip>
                                        </td>
                                        <td>{BidDetial?.origin_location_city}</td>
                                        <td>
                                          {BidDetial?.destination_location_city}
                                        </td>
                                        <Tooltip
                                          title={itm?.truck_location}
                                          placement="top"
                                        >
                                          <td>
                                            {itm?.truck_location?.substr(0, 18)}
                                          </td>
                                        </Tooltip>
                                        <td>
                                          {datepipeModel.date(
                                            itm?.load_info?.load_start_date
                                          )}
                                        </td>
                                        <td>
                                          {datepipeModel.date(
                                            BidDetial?.expiration_date
                                          )}
                                        </td>
                                        <td>
                                          {datepipeModel.date(itm?.bid_time)}{" "}
                                          {datepipeModel.isotime(itm?.bid_time)}
                                        </td>
                                        <td>${itm?.carrier_info?.agreed_rate}</td>

                                        <td className="text-capitalize">
                                          {itm.status == "rejected" ||
                                            itm.status == "accepted" ? (
                                            <>
                                              <span
                                                className={`badge ${itm.status == "accepted"
                                                    ? "badge-success"
                                                    : "badge-danger"
                                                  }`}
                                              >
                                                {itm.status}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span
                                                className="badge badge-success pointer "
                                                onClick={() => {
                                                  completeBid(itm?.id);
                                                }}
                                              >
                                                Accept
                                              </span>
                                              <span
                                                className="badge badge-danger pointer ms-2"
                                                onClick={() => {
                                                  rejectBid(itm?.id);
                                                }}
                                              >
                                                {" "}
                                                Reject
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <>
                              <div className="text-center p-3">No Data</div>
                            </>
                          )}
                        </div>
                        <div
                          class={`tab-pane fade ${tab == "other" ? "active show" : ""
                            }`}
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          {AppliedBid.filter((itm) => !itm.isBest).length ? (
                            <>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Carrier Name</th>
                                    <th>Origin City</th>
                                    <th>Destination City</th>
                                    <th>Truck Location</th>
                                    <th>Pickup Date</th>
                                    <th>ETA</th>
                                    <th>Expiration Date</th>
                                    <th>Agreed Rate</th>

                                    <th>Actions</th>
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {AppliedBid?.map((itm) => (
                                    <>
                                      {!itm?.isBest && (
                                        <tr
                                          key={itm.id}
                                          className={`data_row ${itm.overtime ? "overtime" : ""
                                            }`}
                                        >
                                          <td className="pointer">
                                            {methodModel.capitalizeFirstLetter(
                                              itm?.carrier_name
                                            )}
                                          </td>
                                          <td>
                                            {BidDetial?.origin_location_city}
                                          </td>
                                          <td>
                                            {BidDetial?.destination_location_city}
                                          </td>
                                          <Tooltip
                                            title={itm?.truck_location}
                                            placement="top"
                                          >
                                            <td>
                                              {itm?.truck_location?.substr(0, 18)}
                                            </td>
                                          </Tooltip>
                                          <td>
                                            {datepipeModel.date(
                                              itm?.load_info?.load_start_date
                                            )}
                                          </td>
                                          <td>
                                            {itm?.eta_value} {itm?.eta_type}
                                          </td>
                                          <td>
                                            {" "}
                                            {datepipeModel.date(
                                              itm?.bid_time
                                            )}{" "}
                                            {datepipeModel.isotime(itm?.bid_time)}
                                          </td>

                                          <td>
                                            ${itm?.carrier_info?.agreed_rate}
                                          </td>

                                          <td className="text-capitalize">
                                            {itm.status == "rejected" ||
                                              itm.status == "accepted" ? (
                                              <>
                                                <span
                                                  className={`badge ${itm.status == "accepted"
                                                      ? "badge-success"
                                                      : "badge-danger"
                                                    }`}
                                                >
                                                  {itm.status}
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  className="badge badge-success "
                                                  onClick={() => {
                                                    completeBid(itm?.id);
                                                  }}
                                                >
                                                  Accept
                                                </span>
                                                <span
                                                  className="badge badge-danger ms-2"
                                                  onClick={() => {
                                                    rejectBid(itm?.id);
                                                  }}
                                                >
                                                  {" "}
                                                  Reject
                                                </span>
                                              </>
                                            )}


                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <>
                              <div className="text-center p-3">No Data</div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  id="CloseBidViewModel"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>


      </Layout>



      <button type="button" class="btn btn-primary d-none" id="openRatingModal" data-bs-toggle="modal" data-bs-target="#ratingModal">
        Launch demo modal
      </button>

      <div class="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Rating</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={(e) => { e.preventDefault(); reviewSubmit() }}>
              <div class="modal-body">
                <label>Rating</label>
                <div>
                  <div class="rate-area clearfix rate-div">
                    <input type="radio" id="5-star" name="crating" value="5"
                      checked={ratingForm.rating == 5 ? true : false}
                      onChange={e => {
                        setRatingForm({ ...ratingForm, rating: e.target.value })
                      }}
                    />
                    <label for="5-star" title="Amazing">5 stars</label>
                    <input type="radio" id="4-star" name="crating" value="4"
                      checked={ratingForm.rating == 4 ? true : false}
                      onChange={e => {
                        setRatingForm({ ...ratingForm, rating: e.target.value })
                      }}
                    />
                    <label for="4-star" title="Good">4 stars</label>
                    <input type="radio" id="3-star" name="crating" value="3"
                      checked={ratingForm.rating == 3 ? true : false}
                      onChange={e => {
                        setRatingForm({ ...ratingForm, rating: e.target.value })
                      }}
                    />
                    <label for="3-star" title="Average">3 stars</label>
                    <input type="radio" id="2-star" name="crating" value="2"
                      checked={ratingForm.rating == 2 ? true : false}
                      onChange={e => {
                        setRatingForm({ ...ratingForm, rating: e.target.value })
                      }}
                    />
                    <label for="2-star" title="Not Good">2 stars</label>
                    <input type="radio" id="1-star" required=""
                      name="crating" value="1" aria-required="true"
                      checked={ratingForm.rating == 1 ? true : false}
                      onChange={e => {
                        setRatingForm({ ...ratingForm, rating: e.target.value })
                      }}
                    />
                    <label for="1-star" title="Bad">1 star</label>
                  </div>
                </div>
                <div>
                  <label>Review</label>
                  <textarea
                    className="form-control"
                    value={ratingForm.review}
                    onChange={e => {
                      setRatingForm({ ...ratingForm, review: e.target.value })
                    }}
                    required
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" id="closeRatingModal" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default Html;
