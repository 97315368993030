import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const CMSView = () => {
  const [addFaq, setAddFaq] = useState({
    question: "",
    answer: "",
  });
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`content?id=${id}`).then((res) => {
        const newdata = res.data;
        setAddFaq(res?.data);
        loader(false);
      });
    }
  }, [id]);

  useEffect(() => {
    // if (!Permission?.faq_get) {
    //   history.push("/dashboard");
    // }
  }, []);

  return (
    <Layout>
      <div className="pprofile1 edit-page-common p-0">
        <div className="row">
          <div className="col-lg-12">
            <div className=" title-head">
              <h3 className="ViewUser mb-0 user-back">
                <Link to="/content" className="">
                  <i
                    className="fa fa-arrow-left ml-1 "
                    title="Back"
                    aria-hidden="true"
                  ></i>
                </Link>
                {/* {form && form.id ? "Edit" : "Add"} Board */}
                Content View
              </h3>
            </div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-12">
            <div className="white-bg-main mb-4">
              <div className=" white-head mb-3">
                <h5 class="profilelist">Content Details</h5>
              </div>
              <div className="row ">
                <div className="col-md-12 mb-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addFaq.title || "--"}
                    name="question"
                    disabled
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label>Meta Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addFaq.meta_title || "--"}
                    name="question"
                    disabled
                  />
                </div>{" "}
                <div className="col-md-12 mb-3">
                  <label>Meta Key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={addFaq.meta_key || "--"}
                    name="question"
                    disabled
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label>Description</label>
                  <div
                    disabled
                    className="border desp"
                    dangerouslySetInnerHTML={{ __html: addFaq?.description }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CMSView;
