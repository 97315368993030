import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { Tooltip } from "antd";
const Html = ({
  view,
  edit,
  user,
  ChangeFilter,
  reset,
  RollBack,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  addCol,
  deleteItem,
  exportCsv,
  uTableCols,

  removeCol,
  filters,
  ChangeDocumentStatus,
  tableCols,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {
  const Navigate = useHistory();

  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };
  const [TAB, SETTAB] = useState("list");
  const [StatusData, setStatusData] = useState({});
  const latestSliderValue = React.useRef([0, 0]);
  const columns = ["email", "createdAt", "updatedAt"];
  const [visibleColumns, setVisibleColumns] = useState([
    "email",
    "createdAt",
    "updatedAt",
  ]);

  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "name":
        return value;
        break;
      case "email":
        return value;
        break;
      case "createdAt":
        return datepipeModel.date(value);
        break;
      case "updatedAt":
        return datepipeModel.date(value);
        break;

      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return value;
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }

  const StatusCh = () => {
    statusChange(StatusData);
  };

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between  flex-wrap">
        <h3 className="hedding">{role ? rolesModel.name(role) : "Approved Sub-Carriers"}</h3>
        <div className="d-flex gap-3 align-items-center ">
          <article className="d-flex filterFlex phView wrapit">

            <div className="dropdown addDropdown chnagesg d-flex align-items-center equal-width-50 w-100 mb-2 mt-0">
              <button
                className="btn dropdown-toggle removeBg blck-border-btn mb-0 "
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {filters.status
                  ? filters.status == "deactive"
                    ? "Inactive"
                    : "Active"
                  : "All Status"}
              </button>
              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={
                    filters.status == ""
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("")}
                >
                  All Status
                </a>
                <a
                  className={
                    filters.status == "active"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("active")}
                >
                  Active
                </a>
                <a
                  className={
                    filters.status == "Inactive"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  onClick={() => ChangeStatus("deactive")}
                >
                  Inactive
                </a>
              </div>
            </div>
            <div className="dropdown addDropdown ml-2 equal-width-50 w-100 mt-0">
              <button
                className="btn blck-border-btn dropdown-toggle mb-0"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Add Columns
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {findUniqueElements(visibleColumns, columns).map((itm) => {
                  return (
                    <a
                      className={"dropdown-item"}
                      onClick={() => handleColumnToggle(itm)}
                    >
                      {replaceUnderscoresWithSpace(itm)}
                    </a>
                  );
                })}
              </div>
            </div>
            {/* {Permission?.carrier_add || user?.role == "admin" ? (
              <>
                <button className="btn btn-primary mt-2 mt-md-0 mb-0" onClick={(e) => add()}>
                  Add {role ? rolesModel.name(role) : "Staff"}
                </button>
              </>
            ) : (
              <></>
            )} */}

            {/* <button onClick={exportfun} className="btn btn-primary">
                        Export
                    </button> */}

            {/* <div className="dropdown addDropdown chnagesg mr-2">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.isVerifiedDocument ? filters.isVerifiedDocument == "pending" ? "Pending" : filters.isVerifiedDocument : 'All '}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.isVerifiedDocument == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("")}>All Status</a>
                            <a className={filters.isVerifiedDocument == 'pending' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("pending")} >Pending</a>
                            <a className={filters.isVerifiedDocument == 'accepted' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("accepted")} >Accepted</a>
                            <a className={filters.isVerifiedDocument == 'rejected' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeDocumentStatus("rejected")} >Rejected</a>
                        </div>
                    </div> */}

            {filters.status || filters.role ? (
              <>
                <a
                  className="btn btn-primary text-white ms-2"
                  onClick={(e) => reset()}
                >
                  Reset
                </a>
              </>
            ) : (
              <></>
            )}

            {/* <div className="ms-md-2 mt-2 mt-md-0">
              <ul class="nav nav-tabs portal-tabs mt-0">
                <li class="nav-item">
                  <a
                    class={`nav-link ${TAB == "list" ? "active" : ""}`}
                    aria-current="page"
                    title="List"
                    onClick={() => {
                      SETTAB("list");
                      setFilter({ ...filters, isDeleted: false });
                      getData({ isDeleted: false });
                    }}
                  >
                    <i className="fa fa-list me-2"></i>
                    List
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    title="Archive"
                    class={`nav-link ${TAB == "archive" ? "active" : ""}`}
                    onClick={() => {
                      SETTAB("archive");
                      setFilter({ ...filters, isDeleted: true });

                      getData({ isDeleted: true });
                    }}
                  >
                    <i class="fa fa-archive me-2" aria-hidden="true"></i>
                    Archive
                  </a>
                </li>
              </ul>
            </div> */}

            {/* 
                    <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>

                        </ul>
                    </div> */}

            {/* <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Add Columns
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            {uTableCols().map(itm => {
                                return <a className="dropdown-item" onClick={() => addCol(itm)} key={itm.value}>{itm.value}</a>
                            })}
                        </div>
                    </div> */}
            {/* <button onClick={exportCsv} className="btn btn-primary" type="button">
                        Export
                    </button> */}
          </article>
        </div>
      </div>

      {tab == "grid" ? (
        <>
          <div className="cardList">
            <div className="row">
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <div className="new_cards">
                        <div className="user_card">
                          <div
                            className="user_detail"
                            onClick={(e) => view(itm.id)}
                          >
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />

                            <div className="user_name">
                              <h4 className="user">{itm.fullName}</h4>
                              <p className="user_info">{itm.email}</p>
                            </div>
                          </div>

                          <div
                            className={`user_hours ${itm.status}`}
                            onClick={() => statusChange(itm)}
                          >
                            <span className="contract">{itm.status}</span>
                          </div>
                        </div>

                        <div className="user_proff user_proff1">
                          <div className="id_name">
                            <ul className="user_list">
                              <li className="list_name">
                                <a className="id">Role</a>
                              </li>
                              <li className="list_name">
                                <a className="id">Phone number</a>
                              </li>
                            </ul>
                          </div>
                          <div className="detail_list">
                            <ul className="user_list">
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  {itm.role?.name}
                                </a>
                              </li>
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <span className="call_icon"></span>
                                  {itm.mobileNo ? (
                                    <>
                                      <i
                                        class="fa fa-phone"
                                        aria-hidden="true"
                                      ></i>
                                      {itm.dialCode} {itm.mobileNo}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table-responsive table_section mt-4">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row">
                  <th
                    scope="col"
                    className="table_data pointer"
                    onClick={(e) =>
                      filters?.sortBy == "fullName asc"
                        ? sorting("fullName desc", "desc")
                        : sorting("fullName asc", "asc")
                    }
                  >
                    <span className="d-flex align-items-center">

                      Name

                      {filters?.sortBy === "fullName asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">
                              north
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">
                              south
                            </span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="table_data pointer"
                    onClick={(e) =>
                      filters?.sortBy == "fullName asc"
                        ? sorting("fullName desc", "desc")
                        : sorting("fullName asc", "asc")
                    }
                  >

                    <span className="d-flex align-items-center">

                      Carrier Name

                      {filters?.sortBy === "fullName asc" ? (
                        <div class="d-flex  zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">A </span>{" "}
                            <span className="afont">Z</span>
                          </div>{" "}
                          <span>
                            <span class="material-icons arrfont">
                              north
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div class="d-flex zfonts align-items-center">
                          <div className="d-flex flex-column">
                            <span className="zfont">Z </span>{" "}
                            <span className="afont">A</span>
                          </div>
                          <span>
                            <span class="material-icons arrfont">
                              south
                            </span>
                          </span>
                        </div>
                      )}
                    </span>
                  </th>
                  {visibleColumns.map((item) => (
                    <th className="text-capitalize table_data">
                      {replaceUnderscoresWithSpace(item)}{" "}
                      <i
                        className="fa fa-times"
                        onClick={(e) => handleColumnToggle(item)}
                      ></i>{" "}
                    </th>
                  ))}
                  {/* <th scope="col" className='table_data'>Last Login</th> */}
                  <th scope="col" className="table_data ">
                    Status
                  </th>

                  <th scope="col" className="table_data">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  ListingData &&
                  ListingData.map((itm, i) => {
                    return (
                      <tr className="data_row">
                        <td
                          className="table_dats"
                          onClick={(e) => {
                            if (
                              Permission?.carrier_get ||
                              user?.role == "admin"
                            ) {
                              view(itm.id);
                            } else {
                              toast.warn("You do not have valid permission");
                            }
                          }}
                        >
                          <div className="user_detail">
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />
                            {/* <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle mr-1 user_imgs" /> */}

                            <div className="user_name">
                              <h4 className="user">
                                {methodModel.capitalizeFirstLetter(
                                  itm.fullName
                                )}
                              </h4>
                              {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                            </div>
                          </div>
                        </td>
                        <td className="table_dats">{methodModel.capitalizeFirstLetter(itm?.addedBy_name) || "--"}</td>

                        {visibleColumns.map((item, index) => (
                          <td className="">
                            {ColumnReturner(item, itm[[item]], itm)}
                          </td>
                        ))}
                        <td className="table_dats">
                          {" "}
                          <div className={` ${itm.status}`}>
                            <span className="custom-toggle-btn">
                              {itm.status == "deactive" ? (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={false}
                                />
                              ) : (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={true}
                                />
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td className='table_dats'> <span className='call_icon'></span>
                                        {itm.mobileNo ?
                                            <>
                                                <i class="fa fa-phone" aria-hidden="true"></i>
                                                {itm.dialCode} {itm.mobileNo}
                                            </>
                                            :
                                            ''
                                        }
                                    </td> */}

                        {/* dropdown */}
                        {TAB == "list" && (
                          <td className="table_dats">
                            <div className="action_icons">
                              {Permission?.invite_user_get ||
                                user?.role == "admin" ? (
                                <>
                                  <Tooltip placement="top" title="Edit">
                                    <a
                                      className="edit_icon"
                                      title="Edit"
                                      onClick={
                                        user?.role == "admin" ||
                                          itm?.role == "carrier" ||
                                          Permission?.invite_user_get
                                          ? (e) => {
                                            if (
                                              Permission?.invite_user_get ||
                                              user?.role == "admin"
                                            ) {
                                              edit(itm.id);
                                            } else {
                                              toast.warn(
                                                "You do not have valid permission"
                                              );
                                            }
                                          }
                                          : (e) =>
                                            toast.warn(
                                              "You do not have valid permission"
                                            )
                                      }
                                    >
                                      <i
                                        class="material-icons edit"
                                        title="Edit"
                                      >
                                        edit
                                      </i>
                                    </a>
                                  </Tooltip>
                                </>
                              ) : (
                                <></>
                              )}

                              {Permission?.carrier_delete ||
                                user?.role == "admin" ? (
                                <>
                                  <Tooltip placement="top" title="Delete">
                                    <span
                                      className="edit_icon"
                                      
   
                                      
                                      onClick={() => {
                                        if (
                                          Permission?.carrier_delete ||
                                          user?.role == "admin"
                                        ) {
                                          setDeleteId(itm.id);
                                          setShowDeleteModal("block");
                                        } else {
                                          toast.warn(
                                            "You do not have valid permission"
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        class="material-icons delete"
                                        title="Delete"
                                      >
                                        {" "}
                                        delete
                                      </i>
                                    </span>
                                  </Tooltip>
                                </>
                              ) : (
                                <></>
                              )}
                              {user?.role == "admin" ||
                                Permission?.invite_user_get ? (
                                <Tooltip placement="top" title="View">
                                  <span
                                    className="edit_icon"
                                    title="View"
                                    onClick={() => {
                                      if (
                                        Permission?.invite_user_get ||
                                        user?.role == "admin"
                                      ) {
                                        Navigate.push(`userdetail/${itm.id}`);
                                      } else {
                                        toast.warn(
                                          "You do not have valid permission"
                                        );
                                      }
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </span>
                                </Tooltip>
                              ) : null}
                            </div>
                          </td>
                        )}
                        {TAB == "archive" && (
                          <td className="table_dats">
                            <div className="action_icons">
                              <>
                                <a
                                  className="edit_icon"
                                  title="back"
                                  onClick={() => {
                                    RollBack(itm?.id);
                                  }}
                                >
                                  <i
                                    class="fa fa-undo"
                                    title="Roll Back"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {(!loaging && total == 0) || data?.length < 1 ? (
        <div className=" no-data">
          <img src="assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {!loaging && total > 0 ? (
        <div className="paginationWrapper">
          <div className="d-flex align-items-center">
            <div className="me-2 user-name-color">Show</div>
            {total > 0 && (
              <div className="dropdown addDropdown chnagesname ">
                <button
                  className="btn btn-primary dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters?.count}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 5 });
                    }}
                  >
                    5
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 10 });
                    }}
                  >
                    10
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 20 });
                    }}
                  >
                    20
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 30 });
                    }}
                  >
                    30
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 40 });
                    }}
                  >
                    40
                  </a>
                </div>
              </div>
            )}{" "}
            <div className="ms-2 user-name-color">from {total} Users</div>
          </div>

          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
