import React, { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useHistory, useParams } from "react-router-dom";
import environment from "../../../environment";
import { toast } from "react-toastify";
import addressModel from "../../../models/address.model";
import moment from "moment";

const AddEditLoad = () => {
  const { role, id } = useParams();
  const [images, setImages] = useState({ image: "", logo: "" });
  const defaultvalue = userType;
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const [form, setform] = useState({
    customer_name: "",
    total_weight: null,
    // total_distance: null,
    load_start_date: "",
    load_end_date: "",
    // total_volume: null,
    // total_pallets: null,
    // total_pieces: null,
    auction_type: "",
    origin_location_street: "",
    origin_location_city: "",
    origin_location_state: "",
    origin_location_country: "",
    origin_location_postal_code: "",
    destination_location_street: "",
    destination_location_city: "",
    destination_location_state: "",
    destination_location_country: "",
    destination_location_postal_code: "",
    board_id: "",
    stops: [],
  });
  // console.log(form, "Form");
  const [set, setState] = useState();
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [Stops, SetStops] = useState([{ address: "" }]);
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const user = useSelector((state) => state.user);
  const SearchParam = new URLSearchParams(window.location.search);

  const formValidation = [
    { key: "mobileNo", minLength: 10 },
    { key: "role", required: true },
    { key: "ic_number", minLength: 6 },
    { key: "password", minLength: 8 },
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "password"],
    },
  ];

  useEffect(() => {
    let key = SearchParam.get("board");
    if (key == "rfps") {
      setform({ ...form, board_id: environment.rfpsId });
    }
    if (key == "prebook") {
      setform({ ...form, board_id: environment.prebooksId });
    }

    // if (!Permission?.load_add || !Permission?.load_edit) {
    //   history.push("/dashboard");
    // }
  }, []);

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    setSubmitted(true);

    const LoadID = Math.floor(Math.random() * 1000000000);
    let method = "post";
    let url = "load";
    let value = {};
    if (form?.board_id == environment.rfpsId) {
      value = {
        customer_name: form?.customer_name,
        board_id: form?.board_id,
        total_weight: form?.total_weight,
        load_start_date: moment(form?.load_start_date).format(
          "YYYY-MM-DDTHH:mm"
        ),
        // load_end_date: form?.load_end_date,

        origin_location_street: form?.origin_location_street,
        origin_location_city: form?.origin_location_city,
        origin_location_state: form?.origin_location_state,
        origin_location_country: form?.origin_location_country,
        origin_location_postal_code: form?.origin_location_postal_code,

        destination_location_city: form?.destination_location_city,
        destination_location_state: form?.destination_location_state,
        destination_location_country: form?.destination_location_country,
        destination_location_postal_code:
          form?.destination_location_postal_code,
        destination_location_street: form?.destination_location_street,
        // load_id: parseInt(form?.load_id),

        est_volume: form?.est_volume,
        capacity: form?.capacity,
        dispatch_days: form?.dispatch_days,
        lane_id: form?.lane_id,
        lane_duration: form?.lane_duration,
        all_in_rate: form?.all_in_rate,
        frequency: form?.frequency,
        origin_address: form?.origin_address,
        destination_address: form?.destination_address,
        stops: form?.stops,
      };
    }
    if (form?.board_id == environment.prebooksId) {
      value = {
        total_distance: form?.total_distance,
        pu_date_time: moment(form?.pu_date_time).format("YYYY-MM-DDTHH:mm")+":00.000Z",

        board_id: form?.board_id,
        notes: form?.notes,
        pu_rate: form?.pu_rate,
        bid_rate: form?.bid_rate,
     
        origin_location_street: form?.origin_location_street,
        origin_location_city: form?.origin_location_city,
        origin_location_state: form?.origin_location_state,
        origin_location_country: form?.origin_location_country,
        origin_location_postal_code: form?.origin_location_postal_code,

        destination_location_city: form?.destination_location_city,
        destination_location_state: form?.destination_location_state,
        destination_location_country: form?.destination_location_country,
        destination_location_postal_code:
          form?.destination_location_postal_code,
        destination_location_street: form?.destination_location_street,
        load_id: parseInt(form?.load_id),
        total_distance: form?.total_distance,

        origin_address: form?.origin_address,
        destination_address: form?.destination_address,
        stops: form?.stops,
      };
    }
    if (value.id) {
      // value.fullName=value.firstName+" "+value.lastName
      method = "put";
      url = "load";
      value = {
        customer_name: form?.customer_name,
        total_weight: form?.total_weight,
        comments:form.comments||'',
        notes:form.notes||'',
        // total_distance: form?.total_distance,
        load_start_date: form?.load_start_date,
        load_end_date: form?.load_end_date,
        // total_volume: form?.total_volume,
        // total_pallets: form?.total_pallets,
        // total_pieces: form?.total_pieces,
        // auction_type: form?.auction_type,
        origin_location_street: form?.origin_location_street,
        origin_location_city: form?.origin_location_city,
        origin_location_state: form?.origin_location_state,
        origin_location_country: form?.origin_location_country,
        origin_location_postal_code: form?.origin_location_postal_code,
        id: form?.id,
        customer_id: form?.customer_id,
        division_code: form?.division_code,
        tm_equipment_type: form?.tm_equipment_type,
        customer_id: form?.customer_id,
        // division_code: "TESTINGID",
        destination_location_city: form?.destination_location_city,
        destination_location_state: form?.destination_location_state,
        destination_location_country: form?.destination_location_country,
        destination_location_postal_code:
          form?.destination_location_postal_code,
        destination_location_street: form?.destination_location_street,
        load_id: parseInt(form?.load_id),
        // max_rate: Number(form?.max_rate),
        // min_rate: Number(form?.min_rate),
        est_volume: form?.est_volume,
        capacity: form?.capacity,
        dispatch_days: form?.dispatch_days,
        lane_id: form?.lane_id,
        lane_duration: form?.lane_duration,
        all_in_rate: form?.all_in_rate,
        origin_address: form?.origin_address,
        destination_address: form?.destination_address,
        stops: form?.stops,
      };
    } else {
      delete value.id;
    }

    if (form.dialCode != "") {
      loader(true);
      ApiClient.allApi(url, value, method).then((res) => {
        if (res.success) {
          toast.success(res.message);
          let url = "/loads1";
          if (role) url = "/users/" + role;
          history.push(url);
        }
        loader(false);
      });
    }
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);

  };
  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      origin_address: e.value,
      origin_location_country: address.country || "",
      origin_location_city: address.city || "",
      origin_location_state: address.state || "",
      origin_location_postal_code: address.zipcode || "",
      origin_lat: `${address.lat}` || "",
      origin_lng: `${address.lng}` || "",
    });
  };

  const back = () => {
    history.goBack();
  };

  const getRoles = () => {
    ApiClient.get("roles/listing", { status: "active" }).then((res) => {
      if (res.success) {
        let newarray = [];
        res.data &&
          res.data.map((item, index) => {
            if (item.id != environment.adminRoleId) {
              newarray.push(item);
            }
          });
        setRoles(newarray);
      }
    });
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const GetLoadDetails = () => {
    ApiClient.get("load", { id }).then((res) => {
      if (res.success) {
        setform(res?.data);
        let stops = res?.data?.stops || [];
        if (!stops.length) stops = [{ address: "" }];
        SetStops(stops);
      }
    });
  };
  useEffect(() => {
    setSubmitted(false);
    // setState()
    if (id) {
      GetLoadDetails();
    }
    // if (id) {
    //     loader(true)
    //     ApiClient.get("user/profile", { id }).then(res => {
    //         if (res.success) {
    //             let value=res.data
    //             setDetail(value)
    //             let payload = defaultvalue
    //             let oarr = Object.keys(defaultvalue)
    //             oarr.map(itm => {
    //                 payload[itm] = value[itm] || ''
    //             })
    //             payload.dialCode=value.dialCode
    //             payload.role=value.role._id
    //             payload.merchant_fee=value.merchant_fee
    //             payload.status=value.status
    //             payload.firstName=value.firstName
    //             payload.lastName=value.lastName
    //             payload.companyName=value.companyName
    //             setform({ ...payload, })
    //             images.image = payload?.image
    //             images.logo = payload?.logo
    //             setImages(images)
    //         }
    //         loader(false)
    //     })
    // }
    // getRoles()
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        roles={roles}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        Stops={Stops}
        SetStops={SetStops}
        // DestinationAddress={DestinationAddress}
      />
    </>
  );
};

export default AddEditLoad;
