import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import moment from "moment";
import ReadMore from "../../../components/common/ReadMore";
import datepipeModel from "../../../models/datepipemodel";
import { Steps } from "rsuite";
import "rsuite/Steps/styles/index.css";
import environment from "../../../environment";
import { toast } from "react-toastify";
import CommonAcceptModal from "../../CommonDelete&ActiveModal/CommonAccecptModel";
import CommonRejectModal from "../../CommonDelete&ActiveModal/CommonRejectModel";
import CommonAwardModal from "../../CommonDelete&ActiveModal/CommonAwardModal";
import { FaAward } from "react-icons/fa";
import { Tooltip } from "antd";
import Layout2 from "../../../components/global/layout2";
const BidDetails2 = (p) => {

  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId, pageName } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const [timeRemaining, setTimeRemaining] = useState("");
  const [ExpiredDate, setExpiredDate] = useState(0);
  const [ShowAwardModal, setshowAwardModal] = useState("none");
  const Permissions = JSON.parse(localStorage.getItem("permission"));
  const [ShowRejectModal, setShowRejectModal] = useState("none");
  const [LeftDays, setLeftDays] = useState(0);
  const [Hour, setHour] = useState(0);
  const [createdDate, setCreated] = useState(0);
  const [showItem, setShowItem] = useState({});
  const [ActiveStop, setActiveStop] = useState(0);
  const [ShowActiveModal, setShowActiveModal] = useState("none");
  const [Stops, setStops] = useState([]);
  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  const styles = {
    width: "200px",
    display: "inline-table",
    verticalAlign: "top",
  };
  const getDetail = (id) => {
    loader(true);
    ApiClient.get(`bid`, { id: id }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res.data);
        setCreated(res?.data?.createdAt);
        setExpiredDate(res?.data?.expiration_date);
        if (res?.data?.load_details?.shipment_status == "delivered") {
          setActiveStop(5);
        } else {
          setActiveStop(
            res?.data?.stops_details?.filter((itm) => itm?.checkin)?.length
          );
        }
        setExpiredDate(res?.data?.expiration_date);
        let arry = [];
        Stops.push(
          {
            address: res?.data?.load_details?.origin_address,
          },
          {
            destination_address: res?.data?.load_info?.destination_address,
            date: res?.data?.load_details?.delivered_at,
          }
        );
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const Timeleft = () => {
    let Created = new Date(ExpiredDate);

    let expired = new Date();

    let Total = Created.getTime() - expired.getTime();
    let LeftDay = Math.round(Total / (1000 * 3600 * 24));
    setLeftDays(LeftDay);
  };
  const StartTimer = () => {
    let Created = new Date(ExpiredDate);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();
    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);
    const hours = Math.floor(Total / (1000 * 60 * 60));
    setHour(hours);
    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    setHour(hours);
  };
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      StartTimer();
      Timeleft();
    }, 1000);
    return () => clearInterval(interval);
  }, [timeRemaining]);

  useEffect(() => {
    Timeleft();
    let UpdatedDate = new Date(ExpiredDate);
  }, [data]);

  const DownloadPdf = (url) => {
    let a = document.createElement("a");
    a.href = `${environment.api}documents/${url}`;
    document.body.appendChild(a);
    a.click();
  };

  const completeBid = () => {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=accepted`).then((res) => {
      if (res.success) {
        setShowActiveModal("none");
        toast.success(res.message);
        getDetail(id);
      }
      loader(false);
    });
  };

  const rejectBid = () => {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=rejected`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setShowRejectModal("none");
        getDetail(id);
      }
      loader(false);
    });
  };
  const AwardBid = () => {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=awarded`).then((res) => {
      if (res.success) {
        setshowAwardModal("none");
        setShowActiveModal("none");
        toast.success("Bid awarded successfully");
        getDetail(id);
        loader(false);
      }
      setshowAwardModal("none");

      loader(false);
    });
  };

  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      return methodModel.capitalizeFirstLetter(str.split("_").join(" "));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const downloadFile = async (pdfUrl) => {
    try {
      loader(true);

      const response = await fetch(`${environment.api}documents/${pdfUrl}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.pdf");
      document.body.appendChild(link);
      link.click();
      loader(false);

      link.parentNode.removeChild(link);
    } catch (error) {
      loader(false);

      console.error("Error downloading PDF:", error);
    }
  };

  const downloadImage = async (fileUrl, format = "hievc") => {
    try {
      loader(true);
      let UploadIamge = await fetch(
        `${environment.api}images/users/${fileUrl}`
      );
      let blob = await UploadIamge.blob();

      let url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `document.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      loader(false);
    } catch (error) {
      loader(false);

      console.error("err", error);
    }
  };
  return (
    <Layout2 title={pageName}>
      <CommonAcceptModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={completeBid}
        status={"Accept"}
      />
      <CommonAwardModal
        show={ShowAwardModal}
        setShow={setshowAwardModal}
        confirm={AwardBid}
        status={"Award"}
      />
      <CommonRejectModal
        show={ShowRejectModal}
        setShow={setShowRejectModal}
        confirm={rejectBid}
        status={"Reject"}
      />
      <div className="mainareas">
        <div className="edit-page-common p-0 edit-page-commons">
          <div className="row">
            <div className="col-lg-12">
              <div className="  title-head">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="ViewUser mb-3 user-back">
                    {" "}
                    <a to="/bids" onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left me-2  "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    {pageName} Detail
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3 d-flex justify-content-between">
                  <h5 class="profilelist">Basic Information</h5>
                  <div className="d-flex">
                    {data?.status == "pending" ? (
                      <>
                        <div className="edit-bg">
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setShowActiveModal("flex");
                              // setAcceptID(data?.id);
                            }}
                            class="material-icons edit-bg2"
                            title="Accept"
                          >
                            check
                          </i>
                        </div>
                        <div className="edit-bg ml-3">
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setShowRejectModal("flex");
                            }}
                            class="material-icons edit-bg2"
                            title="Reject"
                          >
                            close
                          </i>
                        </div>
                        <div className="edit-bg">
                          {data?.status == "accepted" &&
                            data?.status != "awarded" && (
                              <>
                                <Tooltip title={"Award this"}>
                                  <span
                                    className="edit_icon "
                                    onClick={() => {
                                      setshowAwardModal("flex");
                                      // setDeleteId(itm?.id);
                                    }}
                                  >
                                    <FaAward />
                                  </span>
                                </Tooltip>
                              </>
                            )}
                        </div>
                      </>
                    ) : null}
                    {data?.status == "accepted" &&
                      data?.load_details?.shipment_status != "delivered" && (
                        <div className="edit-bg">
                          {data?.status == "accepted" && (
                            <>
                              <Tooltip title={"Award this"}>
                                <span
                                  className="edit_icon pointer "
                                  onClick={() => {
                                    setshowAwardModal("flex");
                                    // setDeleteId(itm?.id);
                                  }}
                                >
                                  <FaAward />
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Bid Status :</label>
                    <div className={` ${data?.status}`}>
                      <span>{data && methodModel.capitalizeFirstLetter(data?.status)}</span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Bid At :</label>
                    <div className="profiledetailscls">
                      {/* {data &&
                        moment(data?.createdAt).format("DD-MM-YYYY h:mm A")} */}
                        {datepipeModel.isotodate(data?.createdAt)}
                    </div>
                  </div>
                  {data?.load_info?.total_distance != null ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Total Distance in Mi :
                      </label>
                      <div className="profiledetailscls">
                        {data?.load_info?.total_distance}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Bid ETA :</label>
                    <div className="profiledetailscls">
                      {datepipeModel.date(data?.bid_time)},{" "}
                      {datepipeModel.isotime(data?.bid_time)}
                    </div>
                  </div>
                  {data?.load_details?.minutes != 0 ||
                  data?.load_details?.hours != 0 ||
                  data?.load_details?.days != 0 ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls"> Total ETA :</label>
                      <div className="profiledetailscls">
                        {data?.load_details?.minutes != 0
                          ? data?.load_details?.minutes
                          : null}{" "}
                        {data?.load_details?.minutes > 9 ? "Minutes" : "Minute"}
                        {data?.load_details?.hours != 0
                          ? data?.load_details?.hours
                          : null}{" "}
                        {data?.load_details?.hours < 0 && "Hours"}
                        {data?.load_details?.days != 0
                          ? data?.load_details?.days
                          : null}{" "}
                        {data?.load_details?.days < 0 && "Days"}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      USPS Amount ($) :
                    </label>
                    <div className="profiledetailscls">{data?.usps_amount}</div>
                  </div>
                </div>
              </div>
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Load Information</h5>
                </div>
                <div className="row">
                  {data?.load_info?.load_id || data?.load_info?.load_id ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        {data?.load_info?.load_id ? "Load ID" : "Lane ID"}
                      </label>
                      <div className="profiledetailscls">
                        {data?.load_info?.load_id
                          ? data?.load_info?.load_id
                          : data?.load_info?.lane_id}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      Dispatcher Name :
                    </label>
                    <div className="profiledetailscls">
                      {data &&
                        methodModel.capitalizeFirstLetter(
                          data?.carrier_id?.fullName
                        )}
                    </div>
                  </div>
                  {data?.driver_detail &&
                    data?.driver_detail?.map((itm) => {
                      return (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Driver Name :
                          </label>
                          <div className="profiledetailscls">
                            {data &&
                              methodModel.capitalizeFirstLetter(
                                itm?.fullName || itm?.firstName
                              )}
                          </div>
                        </div>
                      );
                    })}
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Company Name :</label>
                    <div className="profiledetailscls">
                      {data &&
                        methodModel.capitalizeFirstLetter(
                          data?.carrier_id?.company_name
                        )}
                    </div>
                  </div>
                  {Hour > -1 && (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">End Time :</label>
                      <div className="profiledetailscls">
                        {data && timeRemaining}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Truck Number</label>
                    <div className="profiledetailscls">
                      {data && data?.truck_number}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Estimate Time :</label>
                    <div className="profiledetailscls">
                      {data && data?.eta_value} {data && data?.eta_type}
                    </div>
                  </div>

                  {/* {data?.load_info?.load_id ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Load ID :</label>
                      <div className="profiledetailscls">
                        {data?.load_info?.load_id}
                      </div>
                    </div>
                  ) : null} */}
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      Submitted Rate :
                    </label>
                    <div className="profiledetailscls">
                      ${data?.carrier_info?.agreed_rate}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Load Start Date :</label>
                    <div className="profiledetailscls">
                      {datepipeModel.isotodate(data?.load_info?.load_start_date)}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Load End Date :</label>
                    <div className="profiledetailscls">
                      {datepipeModel.isotodate(data?.load_info?.load_end_date)}
                    </div>
                  </div>
                  {data?.load_info?.pickedup_at?<div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      pickedUp Date :
                    </label>
                    <div className="profiledetailscls">
                      {datepipeModel.isotodate(data?.load_info?.pickedup_at)}
                    </div>
                  </div>:<></>}
                  
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      Expiration Date :
                    </label>
                    <div className="profiledetailscls">
                      {datepipeModel.isotodate(data?.load_info?.expiration_date)}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 view-flex ">
                    <label className="profileheddingcls">
                      Truck Location :
                    </label>
                    <div
                      
                        className="profiledetailscls read-more-new"
                      >
                        <p>
                          {methodModel.capitalizeFirstLetter(
                          data?.truck_location
                          ) || "--"}
                        
                        </p>
                      </div>
                  </div>
                </div>
              </div>

              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Address</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12 mb-3 view-flex ">
                      <label className="profileheddingcls">
                        Origin Address :
                      </label>
                      <div className="d-flex">
                        <div className="profiledetailscls">
                          <p>
                            {methodModel.capitalizeFirstLetter(
                            data?.load_info?.origin_address
                            ) || "--"}

                           
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">City :</label>
                      <div className="profiledetailscls">
                        {data && data?.load_info.origin_location_city}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">State :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                          data?.load_info?.origin_location_state
                        ) || "--"}
                      </div>
                    </div>
                    {/* <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Street Address :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.load_info?.origin_location_street
                            ) || "--"}
                          </div>
                        </div> */}
                    {data?.load_info?.origin_location_postal_code?  <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">Postal Code :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                          data?.load_info?.origin_location_postal_code
                        ) || "--"}
                      </div>
                    </div>:<></>}
                  {data?.load_info?.origin_location_country? <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">Country :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                          data?.load_info?.origin_location_country
                        ) || "--"}
                      </div>
                    </div>:<></>}
                   
                  </div>

                  <div className="col-md-6">
                    <div className="col-md-12 mb-3 view-flex ">
                      <label className="profileheddingcls  ">
                        Destination Address :
                      </label>
                      <div className="d-flex">
                        <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                              data?.load_info?.destination_address
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">City :</label>
                      <div className="profiledetailscls">
                        {data && data?.load_info?.destination_location_city}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">State :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                          data?.load_info?.destination_location_state
                        ) || "--"}
                      </div>
                    </div>
                    {/* <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Street Address :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.load_info?.destination_location_street
                            ) || "--"}
                          </div>
                        </div> */}
                        {
                          data?.load_info?.destination_location_postal_code?  <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">Postal Code :</label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.load_info?.destination_location_postal_code
                            ) || "--"}
                          </div>
                        </div>:<></>
                        }
                  {data?.load_info?.destination_location_country?<div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">Country :</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(
                          data?.load_info?.destination_location_country
                        ) || "--"}
                      </div>
                    </div>:<></>}
                    
                  </div>
                </div>
              </div>
              {data?.status == "rejected" ||
              data?.status == "pending" ? null : (
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 class="profilelist">Track Details</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Shipment Status :
                      </label>
                      <div className="profiledetailscls statusdivs">
                      <span className={`${ data?.load_details?.shipment_status}`}> {ReplaceUnderScoreWithSpace(
                          data?.load_details?.shipment_status
                        ) || "--"}</span>
                      </div>
                    </div>
                    {data?.load_details?.pickedup_at != null ? (
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Pickup Date :
                        </label>
                        <div className="profiledetailscls">
                          {/* {datepipeModel.isotodate(data?.load_details?.pickedup_at)} */}
                          {moment(data?.load_details?.pickedup_at).format(
                          "DD-MMM-YYYY, h:mm a"
                        )}
                        </div>
                      </div>
                    ) : null}
                     {data?.load_details?.delivered_at != null ? (
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Delivered Date :
                        </label>
                        <div className="profiledetailscls">
                        {/* {datepipeModel.isotodate(data?.load_details?.delivered_at)} */}
                        {moment(data?.load_details?.delivered_at).format(
                          "DD-MMM-YYYY, h:mm a"
                        )}
                        </div>
                      </div>
                    ) : null}
                    {data?.status == "accepted" && (
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">
                          {data?.load_details?.delivered_at != null
                            ? " Delivered At :"
                            : data?.load_info?.transit_at && "Transit At"}
                        </label>
                        <div className="profiledetailscls">
                          {data?.load_details?.shipment_status == "delivered"
                            ? moment(data?.load_details?.delivered_at).format(
                                "DD-MMM-YYYY h:mm A"
                              )
                            : data?.load_info?.transit_at &&
                              moment(data?.load_info?.transit_at).format(
                                "DD-MMM-YYYY h:mm A"
                              )}
                        </div>
                      </div>
                    )}
                     {data?.load_details?.pod_doc?.length != 0 &&
                    data?.status == "awarded" ? (
                    <div className="col-md-6 mb-3 ">
                      <label className="profileheddingcls">
                        POD (Proof of Delivery) :
                      </label>
                      <div className=" download-doc">
                        {data?.load_details?.pod_doc?.map((itm) => {
                  
                          return (
                            <div className="position-relative">
                              <img
                                src="/assets/img/pdf.png"
                                className="pdfimg"
                              />
                              <i
                                title="Download"
                                onClick={() => {
                                  if (itm?.includes(".pdf")) {
                                    downloadFile(itm);
                                  }
                                  if (itm?.includes("png")) {
                                    downloadImage(itm, "png");
                                  }
                                  if (itm?.includes(".jpeg")) {
                                    downloadImage(itm, "jpeg");
                                  }
                                  if (itm?.includes(".jpg")) {
                                    downloadImage(itm, "jpg");
                                  }
                                  // downloadImage(itm);
                                }}
                                class="fa fa-download "
                              ></i>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {data?.load_details?.bol_doc?.length != 0 &&
                  data?.status == "awarded" ? (
                    <div className="col-md-6 mb-3 ">
                      <label className="profileheddingcls">
                        BOL (Bill of Landing) :
                      </label>
                      <div className="download-doc">
                        {data?.load_details?.bol_doc?.map((itm) => {
                          return (
                            <div className="position-relative">
                              <img
                                src="/assets/img/pdf.png"
                                className="pdfimg"
                              />
                              <i
                                title="Download"
                                onClick={() => {
                                  if (itm?.includes(".pdf")) {
                                    downloadFile(itm);
                                  }

                                  if (itm?.includes("png")) {
                                    downloadImage(itm, "png");
                                  }
                                  if (itm?.includes(".jpeg")) {
                                    downloadImage(itm, "jpeg");
                                  }
                                  if (itm?.includes(".jpg")) {
                                    downloadImage(itm, "jpg");
                                  }
                                  // downloadImage(itm);
                                }}
                                class="fa fa-download "
                              ></i>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                    {data?.status == "awarded" && (
                      <div className="col-lg-12">
                         <label className="profileheddingcls">
                      Stop Details :
                      </label>
                    <div className="steps-main steps-scroll">
                      {data?.stops_details?.length > 1 ? (
                        <Steps vertical style={styles} current={ActiveStop}>
                          <Steps.Item
                            title={
                              data?.stops_details[0]?.checkin
                                ? "Picked Up"
                                : "Pickup Pending"
                            }
                            description={data?.load_details?.origin_address}
                          />
                          {data?.stops_details?.map((itm, index) => {
                            return (
                              <>
                                <Steps.Item
                                  title={`${
                                    // index > 0 &&
                                    index < data?.stops_details?.length - 1
                                      ? "In Transit"
                                      : ""
                                  } ${itm?.address}`}
                                  description={
                                    itm.checkin &&
                                    index == data?.stops_details?.length - 1
                                      ? itm?.checkin &&
                                        `Delivered at ${moment(data?.load_details?.delivered_at).format(
                                          "DD-MMM-YYYY h:mm A"
                                        )} ${
                                          itm?.address
                                        } `
                                      : itm?.checkin &&
                                        `Checkedin ${moment(
                                          itm?.checkin
                                        ).fromNow()}  ${
                                          itm?.checkout == null
                                            ? ""
                                            : `|| checkedOut ${moment(
                                                itm?.checkout
                                              ).fromNow()}`
                                        }`
                                  }
                                />
                              </>
                            );
                          })}
                        </Steps>
                      ) : (
                        <>
                          <Steps
                            current={
                              Stops[1]?.date || Stops[1]?.delivered_at ? 2 : 0
                            }
                            vertical
                            style={styles}
                          >
                            {Stops?.map((itm, index) => {
                              return (
                                <>
                                  <Steps.Item
                                    title={
                                      itm?.address || itm?.destination_address
                                    }
                                    description={
                                      Stops[1]?.date ?  itm?.address?"Picked up":"Delivered" :  itm?.address?"Picked up":"No status"
                                    }
                                  />
                                </>
                              );
                            })}
                          </Steps>
                        </>
                      )}
                    </div>
                    </div>
                  )}
                  </div>
                
                 
                </div>
              )}
                       
             
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default BidDetails2;
