import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import methodModel from "../../../methods/methods";
import rolesModel from "../../../models/roles.model";
import environment from "../../../environment";
import parse from "html-react-parser";
import moment from "moment";

import { toast } from "react-toastify";
import Layout2 from "../../../components/global/layout2";

const BoardDetails2 = (p) => {
  // const [form,setform]=useState({resume:"",id:"",resumeDocument:""})
  // const [Comission,setComission]=useState("")
  // const [RejectionReason,setRejectionReason]=useState("");
  // const [Resume,setResume]=useState("")
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const getDetail = () => {
    loader(true);
    ApiClient.get(`board`, { id: id }).then((res) => {
      setData(res?.data);

      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  return (
    <Layout2  title="Load Classification">

      <div className="mainareas">
      <div className="pprofile1 edit-page-common p-0 edit-page-commons">
        <div className="row">
          <div className="col-lg-12">
            <div className=" title-head">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="ViewUser mb-3 user-back">
                  {" "}
                  <a onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left ml-1 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>
             Load Classification Detail


                </h3>
              </div>
            </div>
          </div>
        </div>
 
          <div className="row">
            <div className="col-lg-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 className="profilelist">
                    Board information
                  </h5>
                </div>

                <div className="row">
                  
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">Name :</label>
                        <div className="profiledetailscls">
                        {data && methodModel.capitalizeFirstLetter(data.name)}
                        </div>
                      </div>

                    
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                          Expiration Time:
                          </label>
                          <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(data?.expiration_type == "hours"
                            ? moment()
                                .endOf("hours")
                                .add(data?.expiration_value, "hours")
                                .fromNow()
                            : data?.expiration_type == "minutes"
                            ? moment()
                                .endOf("minutes")
                                .add(data?.expiration_value, "minutes")
                                .fromNow()
                            : moment()
                                .endOf("days")
                                .add(data?.expiration_value, "days")
                                .fromNow())}
                          {/* {data &&
                            moment(data?.expiration_time).format("DD-MMM-YYYY")} */}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
   
          </div>
      </div>
      </div>
    </Layout2>
  );
};

export default BoardDetails2;
