import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import moment from "moment";

function ViewStopsNotes() {
  const { id } = useParams();
  const history = useHistory();
  const [showItem, setShowItem] = useState({});
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const [data, setData] = useState({});
  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  const GetNote = () => {
    loader(true);
    ApiClient.get("carrier-note", { id }).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetNote();
    // if (!Permission?.notes_get) {
    //   history.push("/dashboard");
    // }
  }, []);

  return (
    <>
      <Layout>
        <div className="pprofile1 edit-page-common">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-right title-head">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="ViewUser mb-0 user-back">
                    {" "}
                    <a
                      onClick={() => {
                        history.push("/shipment-notes");
                      }}
                    >
                      {" "}
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    View Shipment Note
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="common-padding">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className=" white-bg-main mb-4">
                      <div className=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Dispatcher Name:
                          </label>
                          <div className="profiledetailscls">
                            {(data &&
                              methodModel.capitalizeFirstLetter(
                                data?.carrier_details?.fullName
                              )) ||
                              "--"}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Title:</label>
                          <p className=" profiledetailscls mb-0">
                            {data?.title}
                          </p>
                        </div>

                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">New ETA:</label>
                          <div className="profiledetailscls">
                            {data &&
                              moment(data?.new_eta).format("DD-MM-YYYY h:m A")}
                          </div>
                        </div>
                        {data?.load_details?.load_id||data?.load_details?.lane_id
                        ?
                        <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">{data?.load_details?.load_id?"Load ID:":"Lane ID:"}</label>
                        <p
                          className=" profiledetailscls mb-0 pointer"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            history.push(`/loads1/view/${data?.load_id}`);
                          }}
                        >
                          {data?.load_details?.load_id?data?.load_details?.load_id:data?.load_details?.lane_id}
                        </p>
                         </div>
                        :
                        <></>
                        }
                       
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Stop Address:
                          </label>
                          <p className=" profiledetailscls mb-0">
                            {methodModel.capitalizeFirstLetter(
                              data?.stop_details?.address?.substr(
                                0,
                                showItem?.origin_address ? 200 : 14
                              )
                            ) || "--"}

                            {data?.stop_details?.address?.length > 14 && (
                              <span
                                className="see-more"
                                onClick={() => {
                                  toggleShowItem("origin_address");
                                }}
                              >
                                {" "}
                                {showItem?.origin_address
                                  ? "see less"
                                  : "...see more"}
                              </span>
                            )}
                          </p>
                        </div>
                        {data?.load_details?.shipment_status ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Load Status:
                            </label>
                            <div className="profiledetailscls">
                              {data &&
                                methodModel.capitalizeFirstLetter(
                                  data?.load_details?.shipment_status
                                )}
                            </div>
                          </div>
                        ) : null}
                        {data?.carrier_details?.company_name ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Company Name:
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.company_name}
                            </div>
                          </div>
                        ) : null}
                        {data?.carrier_details?.mc_number ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              MC Number:
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.mc_number}
                            </div>
                          </div>
                        ) : null}
                        {data?.carrier_details?.dot_number ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              DOT Number:
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.dot_number}
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Description:
                          </label>
                          <p className=" profiledetailscls mb-0">
                            {methodModel.capitalizeFirstLetter(
                              data?.description?.substr(
                                0,
                                showItem?.description
                                  ? 100000000000000000000000000000
                                  : 14
                              )
                            ) || "--"}

                            {data?.description?.length > 14 && (
                              <span
                                className="see-more"
                                onClick={() => {
                                  toggleShowItem("description");
                                }}
                              >
                                {" "}
                                {showItem?.description
                                  ? "see less"
                                  : "...see more"}
                              </span>
                            )}
                          </p>
                        </div>

                        {data?.load_details?.pickedup_at ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Pickup Date:
                            </label>
                            <div className="profiledetailscls">
                              {moment(
                                data && data?.load_details?.pickedup_at
                              ).format("DD-MMM-YYYY h:m A")}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ViewStopsNotes;
