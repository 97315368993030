import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { WebsiteDetail } from "../../actions/WebsiteDetail";

const UpdateSettingsForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: "",
    logo: "",
    fav_icon: "",
    website_email: "",
    company_name: "",
    company_email: "",
    company_address: "",
    company_country_code: "",
    company_dial_code: "",
    company_mobile_no: "",
    support_email: "",
    google_analytics_script: "",
    merchant_fee: "",
    footer_info: "",
  });

  const GetSettingDetails = () => {
    loader(true);
    ApiClient.get(`setting`).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setFormData({
          id: newdata.id,
          logo: newdata.logo,
          fav_icon: newdata.fav_icon,
          website_email: newdata.website_email,
          company_name: newdata.company_name,
          company_email: newdata.company_email,
          company_address: newdata.company_address,
          company_country_code: newdata.company_country_code,
          company_dial_code: newdata.company_dial_code,
          company_mobile_no: newdata.company_mobile_no,
          support_email: newdata.support_email,
          google_analytics_script: newdata.google_analytics_script,
          merchant_fee: newdata.merchant_fee,
          footer_info: newdata.footer_info,
        });
      }
      loader(false);
    });
  };
  useEffect(() => {
    // GetSettingDetails()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.logo == "") {
      toast.error("Logo is required");
      return false;
    }
    if (formData.fav_icon == "") {
      toast.error("FavIcon is required");
      return false;
    }
    loader(true);
    ApiClient.post(`setting`, formData).then((res) => {
      if (res.success) {
        dispatch(WebsiteDetail());
        toast.success("Settings updated successfully");
        // GetSettingDetails()
      }
      loader(false);
    });
    
  };

  const ImageUpload = (e, name) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image", { file: file }).then((res) => {
      if (res.fileName) {
        let image = res.fileName;
        setFormData({ ...formData, [name]: image });
      } else {
      }
      loader(false);
    });
  };
  return (
    <Layout>
      <div className="container">
        <h1> Settings</h1>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-md-6 mb-3 mt-3">
            <label className="form-label">
              Logo<span className="text-danger">*</span>
            </label>
            {formData.logo == "" ? (
              <input
                type="file"
                name="logo"
                onChange={(e) => ImageUpload(e, "logo")}
              />
            ) : (
              <div>
                <img
                  src={`${methodModel.userImg(formData.logo)}`}
                  width={100}
                  height={100}
                />
                <i
                  className="fa fa-trash text-danger ms-2"
                  onClick={(e) => setFormData({ ...formData, logo: "" })}
                ></i>
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3 mt-3">
            <label className="form-label">
              FavIcon<span className="text-danger">*</span>
            </label>
            {formData.fav_icon == "" ? (
              <input
                type="file"
                name="fav_icon"
                onChange={(e) => ImageUpload(e, "fav_icon")}
              />
            ) : (
              <div>
                <img
                  src={`${methodModel.userImg(formData.fav_icon)}`}
                  width={100}
                  height={100}
                />
                <i
                  className="fa fa-trash text-danger ms-2"
                  onClick={(e) => setFormData({ ...formData, fav_icon: "" })}
                ></i>
              </div>
            )}
          </div>
          {/* <div className='col-md-6 mb-3'>
          <label className='form-label'>Merchant Fee(%)</label>
          <input type="number"  value={formData.merchant_fee} className='form-control' name="merchant_fee" onChange={handleChange} />
        </div> */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Website Email</label>
            <input
              type="email"
              value={formData.website_email}
              className="form-control"
              name="website_email"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Company Name</label>
            <input
              type="text"
              className="form-control"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Company Email</label>
            <input
              type="email"
              className="form-control"
              name="company_email"
              onChange={handleChange}
              value={formData.company_email}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Company Address</label>
            <input
              type="text"
              className="form-control"
              name="company_address"
              onChange={handleChange}
              value={formData.company_address}
            />
          </div>

          <div className="col-md-6 mb-3">
            {/* <label className='form-label'>Company Dial Code:</label> */}
            <label className="form-label">Company Mobile No</label>
            <PhoneInput
              value={
                formData.company_dial_code + "" + formData.company_mobile_no
              }
              countryCodeEditable={false}
              enableSearch={true}
              placeholder=""
              country="us"
              onChange={(phone, country) => {
                let phonenumber = phone.replace(country.dialCode, "");
                // if(phonenumber!=form.mobileNo){
                // setform({ ...form, mobileNo: phonenumber })
                // }
                setFormData({
                  ...formData,
                  company_dial_code: country.dialCode,
                  company_mobile_no: phonenumber,
                  company_country_code: country.countryCode,
                });
              }}
              required
            />
            {/* <input type="text" className='form-control' name="company_dial_code" value={formData.company_dial_code}  onChange={handleChange} /> */}
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label">Company Country Code</label>
            <input
              type="text"
              className="form-control"
              name="company_country_code"
              value={formData.company_country_code}
              onChange={handleChange}
            />
          </div>
          {/* <div className='col-md-6 mb-3'  */}
          {/* <input type="text" className='form-control' name="company_mobile_no" value={formData.company_mobile_no} onChange={handleChange} /> */}
          {/* </div> */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Support Email</label>
            <input
              type="email"
              className="form-control"
              name="support_email"
              value={formData.support_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">Google Analytics Script</label>
            <textarea
              className="form-control"
              value={formData.google_analytics_script}
              name="google_analytics_script"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label>
              Footer <span className="text-danger"></span>
            </label>

            <Editor
              textareaName="content"
              value={formData.footer_info ? formData.footer_info : ""}
              className="tuncketcls"
              onEditorChange={(newValue, editor) => {
                setFormData({ ...formData, footer_info: newValue });
              }}
              required
            />
          </div>
          <div className="col-md-12 mt-3 text-center">
            <button
              style={{ float: "right" }}
              type="submit"
              className="btn btn-primary mr-5"
            >
              Update Settings
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default UpdateSettingsForm;
