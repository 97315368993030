import React, { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./AddEdit";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import addressModel from "../../../models/address.model";

const AddEditBoards = () => {
  const { role, id } = useParams();
  const [images, setImages] = useState({ image: "", logo: "" });
  const defaultvalue = userType;
  const [form, setform] = useState({
    name: "",
    expiration_value: "",
    expiration_type: "",
  });

  const [set, setState] = useState();
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "mobileNo", minLength: 10 },
    { key: "role", required: true },
    { key: "ic_number", minLength: 6 },
    { key: "password", minLength: 8 },
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "password"],
    },
  ];

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form?.name || !form?.expiration_type || !form?.expiration_value)
      return false;
    setSubmitted(true);
    loader(true);
    let url = "board";
    let method = "post";
    let value = {
      name: form?.name,
      expiration_type: form?.expiration_type,
      expiration_value: parseInt(form?.expiration_value),
    };
    if (id) {
      url = "board";
      method = "put";
      value = {
        id: form?.id,
        name: form?.name,
        expiration_type: form?.expiration_type,
        expiration_value: parseInt(form?.expiration_value),
      };
    }
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
        loader(false);
      }
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);

  };
  const addressResult = async (e) => {
  
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      origin_address: e.value,
      origin_location_country: address.country || "",
      origin_location_city: address.city || "",
      origin_location_state: address.state || "",
      origin_location_postal_code: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  
  };

  const back = () => {
    history.goBack();
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const GeBoardDetails = () => {
    ApiClient.get("board", { id }).then((res) => {
      setform(res?.data);
    });
  };

  useEffect(() => {
    setSubmitted(false);
    if (id) {
      GeBoardDetails();
    }
  }, [id]);

  return (
    <>
      <Html
        form={form}
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role}
        setform={setform}
        roles={roles}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        getError={getError}
        // DestinationAddress={DestinationAddress}
      />
    </>
  );
};

export default AddEditBoards;
