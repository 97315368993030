import React from 'react'
import Layout from '../../components/global/layout'
import Pagination from "react-pagination-js";
import Layout2 from '../../components/global/layout2';


function Html({
  loaderr,  filters, setfilters, getdata, data, history, sorting, user, loading, setloading, methodModel, total, moment, PageChange, ChangeFilter
}) {
    return (
        <>
            <Layout2 title="News Letters"  Sidetitle='News Letters'>
               <div className='mainareas'>
               <div className="d-flex justify-content-between align-items-center">
                <div class="bids-top ">
                        <h4>
                          Results<span>{total}</span>
                        </h4>
                      </div>
                   
                </div>
                <div className="table-responsive table_section new-table">
                    <table class="table">
                        <thead className="table_head">
                            <tr className="heading_row">
                                <th scope="col" className="table_data pointer" onClick={() => {
                                    filters?.sortBy == 'email asc' ? sorting("email desc", 'desc') : sorting('email asc', 'asc')
                                }}>
                                    {/* Email<i
                                        class="fa fa-sort"
                                        aria-hidden="true"
                                    ></i> */}
                                        <span className="d-flex align-items-center">    Email{" "}
                                            {filters?.sortBy === "email asc"
                                                ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                                                : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>}
                                        </span>
                                    </th>
                                    <th onClick={() => {
                                        filters?.sortBy == 'createdAt asc' ? sorting("createdAt desc", 'desc') : sorting('createdAt asc', 'asc')
                                    }} scope="col" className="table_data pointer">
                                        Subscribed At<i
                                            class="fa fa-sort"
                                            aria-hidden="true"
                                        ></i>
                                    </th>


                                    {/* <th scope="col" className="table_data">
                                    Actions
                                </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {!loading &&
                                    data &&
                                    data?.map((itm, i) => {
                                        return (
                                            <tr className="data_row">
                                                <td className="table_dats">
                                                    <div className="user_detail">

                                                        {/* <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle mr-1 user_imgs" /> */}

                                                        <div className="user_name">
                                                            <h4 className="user">
                                                                {itm.email}
                                                            </h4>
                                                            {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{moment(itm?.createdAt).format('DD-MM-YYYY')}</td>



                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {(!loading && total == 0 && !loaderr)  ? (
                        <div className=" no-data">
                            <img src="assets/img/no-data.png" />
                            No Data
                        </div>
                    ) : (
                        <></>
                    )}
                    {!loading && total > 0 ? (
                        <div className="paginationWrapper">
                            <div className="d-flex align-items-center">
                                <div className="me-2 user-name-color">Show</div>
                                {total > 0 && (
                                    <div className="dropdown addDropdown chnagesname ">
                                        <button
                                            className="btn btn-primary dropdown-toggle removeBg"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {filters?.count}
                                        </button>
                                        <div
                                            className="dropdown-menu shadow bg_hover"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                    ChangeFilter({ ...filters, count: 5 });
                                                }}
                                            >
                                                5
                                            </a>
                                            <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                    ChangeFilter({ ...filters, count: 10 });
                                                }}
                                            >
                                                10
                                            </a>
                                            <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                    ChangeFilter({ ...filters, count: 20 });
                                                }}
                                            >
                                                20
                                            </a>
                                            <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                    ChangeFilter({ ...filters, count: 30 });
                                                }}
                                            >
                                                30
                                            </a>
                                            <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                    ChangeFilter({ ...filters, count: 40 });
                                                }}
                                            >
                                                40
                                            </a>
                                        </div>
                                    </div>
                                )}{" "}
                                <div className="ms-2 user-name-color">from {total} NewsLetters</div>
                            </div>

                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={PageChange}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {loading ? (
                        <div className="text-center py-4">
                            <img src="/assets/img/loader.gif" className="pageLoader" />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Layout2>

        </>
    )
}

export default Html
