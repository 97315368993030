import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import methodModel from "../../../methods/methods";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ReadMore from "../../../components/common/ReadMore";

import { useSelector } from "react-redux";
import datepipeModel from "../../../models/datepipemodel";
import environment from "../../../environment";
import Layout2 from "../../../components/global/layout2";

const CustomerDetail2 = (p) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId, statusName } = useParams();
  const [Permission, SetPermission] = useState({});
  const [notes, setNote] = useState("");
  const [filters, setfilter] = useState({});
  const [data, setData] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");
  const [board, setBoard] = useState("");
  const [ListingData, setListingData] = useState([]);


  const Permissions = JSON.parse(localStorage.getItem("permission"));

  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`user/detail`, { id: id }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res?.data);
        if (res?.data?.role == "staff") {
          ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
            SetPermission(res?.data);
          });
        }
      }
      loader(false);
    });
  };

  useEffect(() => {
    // if (user?.role == 'admin' && data?.role == 'carrier' && !Permissions?.carrier_get && data?.request_status != 'pending') {
    //   history.push("/dashboard");
    // }
  }, [data]);

  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      let splitedStr = str?.replace("_", " ");
      return methodModel.capitalizeFirstLetter(splitedStr);
    }
    return methodModel.capitalizeFirstLetter(str);
  };

  const GetTrucks = (p = {}) => {
    let filter = { ...p, addedBy: id };
    ApiClient.get("trucks", filter).then((res) => {
      if (res.success) {
        setListingData(res?.data?.data);
      }
    });
  };

  const HandleSearch = (e) => {
    e.preventDefault();
    GetTrucks({ search: filters?.search });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
    GetTrucks({});
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };

  const [images, setimages] = useState([]);
  const [AccecptID, setAcceptID] = useState("");
  const [ProtofolioError, setProtofolioError] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState([]);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);
  const [boards, setBoards] = useState([]);
  const [enableNote, setEnableNote] = useState(false);

  const getboards = () => {
    ApiClient.get("boards", { status: "active" }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.data);
      }
    });
  };

  const handleNotes = () => {
    setEnableNote(true);
  };

  useEffect(() => {
    getboards();
  }, []);

  const AssignBoard = (carrier_id, board_id, note) => {
    loader(true);
    note;
    ApiClient.put("assign/board", {
      carrier_id: carrier_id,
      board_id: board_id,
    }).then((res) => {
      if (res.success) {
        AcceptCarrier(carrier_id);
        document.getElementById("CloseAlphaModel").click();
        getDetail();
        if (note?.length > 0) {
          ApiClient.post("note", {
            user_id: carrier_id,
            description: note,
          }).then((res) => {
            if (res?.success) {
              toast.success("Board assigned successfully");
            }
          });
        } else {
          toast.success("Board assigned successfully");
        }
      }
      loader(false);
    });
  };

  const AcceptCarrier = (id) => {
    loader(true);
    ApiClient.put("carrier-request", { id: id, status: "accepted" }).then(
      (res) => {
        if (res.success) {
          getDetail({ search: "", page: 1 });
        }
        loader(false);
      }
    );
  };
  const RejecetCarrier = (id) => {
    loader(true);
    ApiClient.put("carrier-request", { id: id, status: "rejected" }).then(
      (res) => {
        if (res.success) {
          toast.success(res.message);
          getDetail({ search: "", page: 1 });
        }
        loader(false);
      }
    );
  };
  return (
    <Layout2 title={statusName}>
      <div className="mainareas">
        <div className=" edit-page-common edit-page-commons approved-user">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="ViewUser mb-3 user-back">
                    {" "}
                    <a onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    {methodModel.capitalizeFirstLetter(statusName)} Details
                  </h3>
                  {/* <div className="d-flex edit_bg_gap">
                  {data?.request_status == "pending" ? (
                    <>
                      {user?.role == 'admin' || Permissions?.carrier_edit ? <div className="edit-bg">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            document.getElementById("modelBoard").click();

                            setAcceptID(data?.id);
                          }}
                          class="material-icons edit"
                          title="Accept"
                        >
                          check
                        </i>
                      </div> : null}
                      {user?.role == 'admin' || Permissions?.carrier_edit ? <div className="edit-bg">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={(e) => RejecetCarrier(data?.id)}
                          class="material-icons edit"
                          title="Reject"
                        >
                          close
                        </i>
                      </div> : null}
                    </>
                  ) : null}
                  {
                    data?.request_status == 'pending' || data?.role == 'user' ? null : <div className="edit-bg">
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (data?.role == "carrier") {
                            history.push(`/carriers/edit/${id}`);
                          } else if (data?.role == "staff") {
                            history.push(`/staff/edit/${id}`);
                          }
                          if (data?.role == "user") {
                            history.push("/carrier2/user-edit/" + id);
                          }
                        }}
                        class="material-icons edit"
                        title="Edit"
                      >
                        edit
                      </i>
                    </div>
                  }
                </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={`${data?.role == "staff" ? "col-lg-6 height-set staff-role" : "col-lg-12"}`}
            >
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 className="profilelist">
                    {methodModel.capitalizeFirstLetter(data?.role)} information
                  </h5>
                </div>

                <div className="row">
                  <div
                    className={`${data?.role == "staff" ? "col-xl-4 col-lg-5" : "col-xl-3 col-lg-4"
                      } imagesRow  mb-3`}
                  >
                    <div className="image-centered">
                      <div className="imagethumbWrapper w-100">
                        <img
                          src={methodModel.userImg(data && data.image)}
                          className="uploadimage  d-block"
                        />
                      </div>
                      <div className=" view-flex">
                        <div className="profiledetailscls">
                          {data &&
                            methodModel.capitalizeFirstLetter(data.fullName)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${data?.role == "staff" ? "col-xl-8 col-lg-7" : "col-xl-9 col-lg-8"
                      } imagesRow  mb-3`}
                  >
                    <div className="row">
                      {/* <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">Name :</label>
                        <div className="profiledetailscls">
                          {data &&
                            methodModel.capitalizeFirstLetter(data.fullName)}
                        </div>
                      </div> */}

                      {data?.position && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Position :
                          </label>
                          <div className="profiledetailscls">
                            {ReplaceUnderScoreWithSpace(data?.position)}
                          </div>
                        </div>
                      )}
                      {/* {
                            data?.role=='carrier'&&
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">Last Login</label>
                            <div className="profiledetailscls">
                              {moment(data?.last_login).endOf("minute").fromNow()}
                            </div>
                          </div>
                                } */}
                      {data?.role == "carrier" && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Latest Login IP :
                          </label>
                          <div className="profiledetailscls">
                            {data?.ip_address || "--"}
                          </div>
                        </div>
                      )}
                      {/* {data?.role == "carrier" && (
                                <div className="col-md-6 mb-3 view-flex">
                                  <label className="profileheddingcls">
                                    Identification Number :
                                  </label>
                                  <div className="profiledetailscls">
                                    {data && data?.identification_number}
                                  </div>
                                </div>
                              )} */}
                      {data?.dialCode && data?.mobileNo && (
                        <div
                          className={`${data?.role == "staff" ? "col-md-12" : "col-md-6"
                            } mb-3 view-flex`}
                        >
                          <label className="profileheddingcls">
                            Mobile No :
                          </label>
                          <div className="profiledetailscls">
                            {/* {data?.dialCode?.split("+")?.length < 1
                                      ? null
                                      : "+"}{" "} */}
                            {data && data?.dialCode.includes("+")?data?.dialCode:`+${data?.dialCode}`} {data?.mobileNo || "--"}
                          </div>
                        </div>
                      )}
                      {data?.telephoneExt && data?.telephoneNo && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Telephone :
                          </label>
                          <div className="profiledetailscls">
                            {/* {data?.telephoneExt?.split("+")?.length < 1
                                      ? null
                                      : "+"}{" "} */}
                            {data && data?.telephoneExt.includes("+")?data?.telephoneExt:`+${data?.telephoneExt}`}{" "}
                            {data?.telephoneNo || "--"}
                          </div>
                        </div>
                      )}
                      {data &&
                        data.role &&
                        data.role._id == "6540cfa35a7513892be61415" ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label>Merchant Fee (%)</label>
                          <div className="profiledetailscls">
                            {data && data.merchant_fee}
                          </div>
                        </div>
                      ) : null}

                      {data?.company_name ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Company Name :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.company_name
                            ) || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.fax_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Fax Number :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.fax_number
                            ) || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.tax_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Tax Number :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.tax_number
                            ) || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.mc_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Mc#</label>
                          <div className="profiledetailscls">
                            {data?.mc_number || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.dot_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Dot#</label>
                          <div className="profiledetailscls">
                            {data?.dot_number || "--"}
                          </div>
                        </div>
                      ) : null}
                      <div
                        className={`${data?.role == "staff" ? "col-md-12" : "col-md-12"
                          } mb-3 view-flex`}
                      >
                        <label className="profileheddingcls">Email :</label>
                        <div className="profiledetailscls">
                          {data && data.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${data?.role == "staff" ? "col-lg-6 height-set" : "col-lg-12 address_new"
                }`}
            >
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 className="profilelist">Address</h5>
                </div>
                <div className="row">
                  <div
                    className={`${data?.role == "staff" ? "col-md-12" : "col-md-6 "
                      } mb-3 view-flex`}
                  >
                    <label className="profileheddingcls">Address :</label>
                    <div className="profiledetailscls read-more-new">
                      <div className="">
                        {methodModel.capitalizeFirstLetter(
                          data?.address
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${data?.role == "staff" ? "col-md-12" : "col-md-6 "
                      } mb-3 view-flex`}
                  >
                    <label className="profileheddingcls">City :</label>
                    <div className="profiledetailscls">
                      {methodModel.capitalizeFirstLetter(data?.city)}
                    </div>
                  </div>
                  <div
                    className={`${data?.role == "staff" ? "col-md-12" : "col-md-6 "
                      } mb-3 view-flex`}
                  >
                    <label className="profileheddingcls">State :</label>
                    <div className="profiledetailscls">
                      {methodModel.capitalizeFirstLetter(data?.state) || "--"}
                    </div>
                  </div>
                  <div
                    className={`${data?.role == "staff" ? "col-md-12" : "col-md-6 "
                      } mb-3 view-flex`}
                  >
                    <label className="profileheddingcls">Country :</label>
                    <div className="profiledetailscls">
                      {methodModel.capitalizeFirstLetter(data?.country) || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {data?.role == "carrier" && (
              <> */}
                <div className="col-lg-12">
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head mb-3">
                      <h5 className="profilelist">Truck Details</h5>
                    </div>
                    <div className="row">
                      {data?.truck_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Truck Number :
                          </label>
                          <div className="profiledetailscls">
                            {data?.truck_number || "--"}
                          </div>
                        </div>
                      ) : null}

                      {/* {data?.mc_description && (
                              <div className="col-md-6 mb-3 view-flex">
                                <label className="profileheddingcls">
                                  DOT :
                                </label>
                                <div className="profiledetailscls">
                                  {data?.mc_description || "--"}
                                </div>
                              </div>
                            )} */}
                      {data?.team_truck ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Team Truck :
                          </label>
                          <div className="profiledetailscls">
                            {data?.team_truck || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.solo_truck ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Solo Truck :
                          </label>
                          <div className="profiledetailscls">
                            {data?.solo_truck || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.total_trucks ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Total Truck :
                          </label>
                          <div className="profiledetailscls">
                            {data?.total_trucks || "--"}
                          </div>
                        </div>
                      ) : null}

                      {data?.trailers_number ? (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Total Trailer :
                          </label>
                          <div className="profiledetailscls">
                            {data?.trailers_number || "--"}
                          </div>
                        </div>
                      ) : null}
                      {data?.trailer_type && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Trailer Type :
                          </label>
                          <div className="profiledetailscls  ">
                            {data?.trailer_type?.map((itm, i) => {
                              return (

                                <span className="me-2"> {itm == "dry_van" ? "Dry Van" : "Reefer"} {i < data?.trailer_type.length - 1
                                  ? " , "
                                  : ""}</span>

                              );
                            })}
                          </div>
                        </div>
                      )}
                      {data?.team_number && (
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Team Number :
                          </label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.team_number
                            ) || "--"}
                          </div>
                        </div>
                      )}
                      {data?.board_id.length > 0 && (
                        <div className="col-md-12 mb-3 view-flex w-100">
                          <label className="profileheddingcls">
                            Board Name :
                          </label>
                          <div className="d-flex align-items-center w-100">
                            {data && data?.boardDetails.map((board, i) => {
                              return (
                                <>
                                  <div className="profiledetailscls  board-name me-2">
                                    {methodModel.capitalizeFirstLetter(
                                      board?.name
                                    ) || "--"}
                                    {i < data?.boardDetails.length - 1
                                      ? " , "
                                      : ""}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head d-flex justify-content-between align-items-center">
                      <h5 className="profilelist">Trucks List</h5>
                      <form onSubmit={HandleSearch}>
                        <input
                          className="form-control "
                          type="text"
                          value={filters?.search}
                          placeholder="Search Here.."
                          onChange={(e) => {
                            if (e.target.value == "") {
                              GetTrucks({ search: "" });
                            }
                            setfilter({
                              ...filters,
                              search: e.target.value,
                            });
                          }}
                        />
                      </form>
                      {/* {
                                filters?.search && <button className="btn btn-primary" onClick={() => {
                                  setfilter({
                                    ...filters,
                                    search: '',
                                  });
                                  GetTrucks({ search: '' })
                                }}>Reset</button>
                              } */}
                    </div>
                    <div className="row">
                      <div className="table-responsive table_section mt-0 p-3">
                        <table class="table border ">
                          <thead className="table_head ">
                            <tr className="heading_row">
                              <th scope="col" className="table_data pointer">
                                Truck Number
                              </th>
                              <th scope="col" className="table_data pointer">
                                VIN Number
                              </th>

                              <th scope="col" className="table_data">
                                CreatedAt
                              </th>
                              <th scope="col" className="table_data">
                                UpdatedAt
                              </th>
                              {/* <th scope="col" className="table_data">
                                        Status
                                      </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {ListingData &&
                              ListingData.map((itm, i) => {
                                return (
                                  <tr className="data_row">
                                    <td className="table_dats">
                                      <div className="user_detail">
                                        {itm?.truck_number}
                                      </div>
                                    </td>
                                    <td className="table_dats">
                                      {itm?.vin_number}
                                    </td>
                                    <td className="table_dats">
                                      {datepipeModel.date(itm?.createdAt)}
                                    </td>
                                    <td className="table_dats">
                                      {datepipeModel.date(itm?.updatedAt)}
                                    </td>
                                    {/* <td className={`table_dats ${itm?.status=='active'?'acceptedbid':'pendingbid'}`}>
                                              {methodModel.capitalizeFirstLetter(
                                                itm?.status
                                              )}
                                            </td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {ListingData?.length < 1 ? (
                          <div className=" no-data">
                            <img src="/assets/img/no-data.png" />
                            No Data
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              {/* </>
            )} */}

            {data?.Driver_information?.length ? (
              <>
                <div className="col-lg-12">
                  <div className=" white-bg-main mb-4">
                    <div className=" white-head d-flex justify-content-between align-items-center">
                      <h5 className="profilelist">Drivers</h5>
                      {/* <form onSubmit={HandleSearch}>
                                <input
                                  className="form-control "
                                  type="text"
                                  value={filters?.search}
                                  placeholder="Search Here.."
                                  onChange={(e) => {
                                    if (e.target.value == '') {
                                      GetTrucks({ search: '' })
                                    }
                                    setfilter({
                                      ...filters,
                                      search: e.target.value,
                                    });
                                  }}
                                />
                              </form> */}
                      {/* {
                                filters?.search && <button className="btn btn-primary" onClick={() => {
                                  setfilter({
                                    ...filters,
                                    search: '',
                                  });
                                  GetTrucks({ search: '' })
                                }}>Reset</button>
                              } */}
                    </div>
                    <div className="row">
                      <div className="table-responsive table_section mt-0 p-3">
                        <table class="table border ">
                          <thead className="table_head ">
                            <tr className="heading_row">
                              <th scope="col" className="table_data pointer">
                                Driver Name
                              </th>
                              <th scope="col" className="table_data pointer">
                                Licence Number
                              </th>
                              <th scope="col" className="table_data pointer">
                                Review
                              </th>
                              <th scope="col" className="table_data pointer">
                                Address
                              </th>

                              <th scope="col" className="table_data">
                                CreatedAt
                              </th>
                              <th scope="col" className="table_data">
                                UpdatedAt
                              </th>
                              {/* <th scope="col" className="table_data">
                                        Status
                                      </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.Driver_information.map((itm, i) => {
                              return (
                                <tr className="data_row">
                                  <td className="table_dats">
                                    <div className="user_detail">
                                      {methodModel.capitalizeFirstLetter(itm?.fullName)}
                                    </div>
                                  </td>
                                  <td className="table_dats">
                                    {itm.license_image ? (
                                      <>
                                        <a
                                          target="_blank"
                                          href={`${environment.api}documents/${itm.license_image}`}
                                        >
                                          {itm?.licence_number}
                                        </a>
                                      </>
                                    ) : (
                                      <>{itm.licence_number}</>
                                    )}
                                  </td>
                                  <td className="table_dats">
                                    <i className="fa fa-star"></i>{" "}
                                    {itm.avg_rating?.toFixed(1)} (
                                    {itm.total_reviews})
                                  </td>
                                  <td className="table_dats">{itm?.address}</td>

                                  <td className="table_dats">
                                    {datepipeModel.date(itm?.createdAt)}
                                  </td>
                                  <td className="table_dats">
                                    {datepipeModel.date(itm?.updatedAt)}
                                  </td>
                                  {/* <td className={`table_dats ${itm?.status=='active'?'acceptedbid':'pendingbid'}`}>
                                              {methodModel.capitalizeFirstLetter(
                                                itm?.status
                                              )}
                                            </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {data?.role == "staff" && (
              <div className="col-lg-12">
                <div className=" ">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">Permissions</h5>
                  </div>

                  {Permission && (
                    <>
                      <div class="table-responsive ">
                        <div class="table_section tablepadding table-bordered mt-0">
                          <table class="table mb-0">
                            <thead class="table_head roleTable">
                              <tr class="heading_row">
                                <th scope="col" class="table_data"></th>
                                {/* <th scope="col" class="table_data">
                                      
                                      All
                                    </th> */}
                                <th scope="col" class="table_data">
                                  Read
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  Add
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  Edit
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  Delete
                                </th>

                                {/* <th scope="col" class="table_data"> <input
                            type="checkbox"  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                              </tr>
                            </thead>
                            <tbody className="roleTable">
                              <tr>
                                <td>
                                  <b>Dashboard</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.dashboard_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Statistics</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.statistics_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Performance Matrix	</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.performance_matrix_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Carrier</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.carrier_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.carrier_add ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.carrier_edit
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.carrier_delete
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>

                                {/* <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.carrier_request_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_request_get",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td> */}
                              </tr>
                              <tr>
                                <td>
                                  <b>Loads</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.load_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.load_add ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.load_edit ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.load_delete ? "done" : "clear"}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Bids</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.bid_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.bid_add ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.bid_edit ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.bid_delete ? "done" : "clear"}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Boards</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.board_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.board_add ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.board_edit ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.board_delete
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>FAQ</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.faq_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.faq_add ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.faq_edit ? "done" : "clear"}
                                  </span>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.faq_delete ? "done" : "clear"}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Content Management	</b>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.content_management_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.content_management_add
                                      ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.content_management_edit ? "done" : "clear"}
                                  </span>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.content_management_delete ? "done" : "clear"}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Invited Users</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.invite_user_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Settings</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.settings_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                 
                                </td>
                                <td>
                                <span class="material-icons rights-ic ">
                                    {Permission?.settings_edit ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Shipment Notes	</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.shipment_notes_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                 
                                </td>
                                <td>
                                
                                </td>
                                <td>
                                  
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Notification</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.notifications_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                 
                                </td>
                                <td>
                                
                                </td>
                                <td>
                                  
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Carrier Request</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.carrier_request_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Query</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.query_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Notes</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.notes_get ? "done" : "clear"}
                                  </span>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>News Letter</b>
                                </td>

                                <td>
                                  <span class="material-icons rights-ic ">
                                    {Permission?.news_letter_get
                                      ? "done"
                                      : "clear"}
                                  </span>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <table className="table custom-table border mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col"> Carrier Add</th>
                                    <th scope="col"> Carrier Edit</th>
                                    <th scope="col"> Carrier Read</th>
                                    <th scope="col">Carrier Delete</th>
                                    <th scope="col">Carrier Request</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.carrier_add
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.carrier_edit
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.carrier_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.carrier_delete
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.carrier_request_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}

                      {/* <table className="table custom-table border mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col"> Loads Add</th>
                                    <th scope="col"> Loads Edit</th>
                                    <th scope="col"> Loads Read</th>
                                    <th scope="col">Loads Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.load_add
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.load_edit
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.load_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.load_delete
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}

                      {/* <table className="table custom-table border mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col"> Board Add</th>
                                    <th scope="col"> Board Edit</th>
                                    <th scope="col"> Board Read</th>
                                    <th scope="col">Board Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.board_add
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.board_edit
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.board_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.board_delete
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}

                      {/* <table className="table custom-table border mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col"> FAQ Add</th>
                                    <th scope="col"> FAQ Edit</th>
                                    <th scope="col"> FAQ Read</th>
                                    <th scope="col">FAQ Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.faq_add
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.faq_edit
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.faq_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.faq_delete
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}

                      {/* <table className="table custom-table border mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col"> Invited User Read</th>
                                    <th scope="col"> Shipment Notes Read</th>
                                    <th scope="col"> Notes Read</th>
                                    <th scope="col">Query Read</th>
                                    <th scope="col">News Letter Read</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.invite_user_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.shipment_note_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.notes_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.query_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                    <td>
                                      <span class="material-icons rights-ic ">
                                        {Permission?.news_letter_get
                                          ? "done"
                                          : "clear"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table> */}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <button
          style={{ display: "none" }}
          id="modelBoard"
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-target="#exampleModal"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Select Board
                </h5>
                <button
                  type="button"
                  class="close"
                  id="CloseAlphaModel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!enableNote && (
                <>
                  <div
                    style={{ justifyContent: "space-around" }}
                    class="modal-body "
                  >
                    <div
                      className={`${boards?.length > 6 ? "scroll-height" : ""
                        } boards-details`}
                    >
                      {boards?.map((itm) => {
                        return (
                          <div
                            className="d-flex justify-content-between my-1 board-view"
                            key={itm.id}
                          >
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedBoards((prevSelectedBoards) => [
                                      ...prevSelectedBoards,
                                      itm.id,
                                    ]);
                                  } else {
                                    setSelectedBoards((prevSelectedBoards) =>
                                      prevSelectedBoards.filter(
                                        (id) => id !== itm.id
                                      )
                                    );
                                  }
                                }}
                                checked={selectedBoards.includes(itm.id)}
                              />
                              {methodModel.capitalizeFirstLetter(itm?.name)}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 mb-3 mt-4">
                      <label className="profileheddingcls">
                        {" "}
                        Note <span className="add-notes">(Private Notes)</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={notes}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      id="CloseBoardModelAlpha"
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => {
                        if (selectedBoards?.length > 0) {
                          AssignBoard(AccecptID, selectedBoards, notes);
                        } else {
                          toast.error("Select minimum one board");
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default CustomerDetail2;
