import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import methodModel from "../../../methods/methods";
import rolesModel from "../../../models/roles.model";
import environment from "../../../environment";
import parse from "html-react-parser";
import moment from "moment";
import ReadMore from "../../../components/common/ReadMore";
import { toast } from "react-toastify";
import CountdownTimer from "../countdoenTimer";
import { Steps } from "rsuite";
import datepipeModel from "../../../models/datepipemodel";
import Layout2 from "../../../components/global/layout2";

const LoadDetails2 = (p) => {
  const [Stops, setStops] = useState([]);
  const [ActiveStop, setActiveStop] = useState(0);

  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id, userId, statusData } = useParams();
  const [Permission, SetPermission] = useState({});
  const [data, setData] = useState();
  const [setboard, setNewBoard] = useState('Loads');
  const [showItem, setShowItem] = useState({});
  const Permissions = JSON.parse(localStorage.getItem("permission"));

  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };

  useEffect(() => {
    if (id == environment?.prebooksId) {
      setNewBoard('Weekly Bids');
    } else if (id == environment?.rfpsId) {
      setNewBoard('Rfps');
    } else if (id == environment?.uspsId) {
      setNewBoard('Usps');
    } else {
      setNewBoard('Loads');
    }
  }, [id]);

  const getDetail = (did) => {
    loader(true);
    ApiClient.get(`load`, { id: did }).then((res) => {
      if (res.success) {
        const newdata = res.data;
        setData(res.data);
        if (res?.data?.shipment_status == "delivered") {
          setActiveStop(5);
        } else {
          setActiveStop(
            res?.data?.stops?.filter((itm) => itm?.checkin)?.length
          );
        }
        Stops.push(
          {
            address: res?.data?.origin_address,
          },
          {
            destination_address: res?.data?.destination_address,
            date: res?.data?.delivered_at,
          }
        );
        // if (res?.data?.role == "staff") {
        //   getPermission();
        // }
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history.push(url);
  };

  useEffect(() => {
    getDetail(userId ? userId : id);
  }, [id, userId]);

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then((res) => {
      if (res.success) {
        setEducationData(res.data);
      }
    });
  };
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then((res) => {
      if (res.success) {
        setEmoloyeementData(res.data);
      }
    });
  };

  const [images, setimages] = useState([]);

  const [ProtofolioError, setProtofolioError] = useState(false);

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);

  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = [];
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            setResume(path);
            setform({ ...form, resumeDocument: path });
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        SetPermission({
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });

        // setform({...form,permissions:res?.data})
      }
    });
  };

  const downloadFile = async (pdfUrl) => {
    try {
      const response = await fetch(`${environment.api}documents/${pdfUrl}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const styles = {
    width: "200px",
    display: "inline-table",
    verticalAlign: "top",
  };

  const downloadImage = async (fileUrl, format) => {
    try {
      const response = await fetch(`${environment.api}images/users/${fileUrl}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `document.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      return methodModel.capitalizeFirstLetter(str.split("_").join(" "));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  const pathname = window.location.pathname;
  const StartdateTime = data?.load_start_date?.split("T")?.join()?.split(".")?.[0]
  const EnddateTime = data?.load_end_date?.split("T")?.join()?.split(".")?.[0]
  return (
    <Layout2 title={setboard} Sidetitle="Loads">
      <div className="mainareas">
        <div className="edit-page-common edit-page-commons">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head">
                <div className="d-flex align-items-center ">
                  <h3 className="ViewUser mb-3 user-back">
                    {" "}
                    <a to="/users2" onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    {statusData} Detail
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Basic Information</h5>
                </div>
                <div className="row">
                  {data?.load_id ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Load ID:</label>
                      <div className="profiledetailscls">{data?.load_id}</div>
                    </div>
                  ) : null}
                  {data?.lane_id ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Lane ID:</label>
                      <div className="profiledetailscls">{data?.lane_id}</div>
                    </div>
                  ) : null}
                  {data?.customer_name ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Customer's Name:
                      </label>
                      <div className="profiledetailscls">
                        {data &&
                          methodModel.capitalizeFirstLetter(
                            data?.customer_name
                          )}
                      </div>
                    </div>
                  ) : null}
                  {data?.total_distance ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Total Distance (Mi):
                      </label>
                      <div className="profiledetailscls">
                        {data?.total_distance}
                      </div>
                    </div>
                  ) : null}
                  {data?.pu_rate ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">PU Rate:</label>
                      <div className="profiledetailscls">{data?.pu_rate}</div>
                    </div>
                  ) : null}
                  {data?.bid_rate ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Bid Price:</label>
                      <div className="profiledetailscls">{data?.bid_rate}</div>
                    </div>
                  ) : null}

                  {data?.all_in_rate ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">All in Rate:</label>
                      <div className="profiledetailscls">
                        {data?.all_in_rate}
                      </div>
                    </div>
                  ) : null}
                  {data?.capacity ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Capacity:</label>
                      <div className="profiledetailscls">{data?.capacity}</div>
                    </div>
                  ) : null}
                  {data?.comments ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Cooment:</label>
                      <div className="profiledetailscls">{data?.comments}</div>
                    </div>
                  ) : null}
                  {data?.dispatch_days ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Dispatch Days:
                      </label>
                      <div className="profiledetailscls">
                        {data?.dispatch_days}
                      </div>
                    </div>
                  ) : null}
                  {data?.est_volume ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">EST Volume:</label>
                      <div className="profiledetailscls">
                        {data?.est_volume}
                      </div>
                    </div>
                  ) : null}
                  {data?.frequency ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Frequency:</label>
                      <div className="profiledetailscls">{data?.frequency}</div>
                    </div>
                  ) : null}

                  {data?.load_start_date != null && (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Load Start DateTime:
                      </label>
                      <div className="profiledetailscls">
                        {/* {datepipeModel.datetime()} */}
                        {moment(StartdateTime).format(
                          "DD-MMM-YYYY, h:mm a"
                        )}
                      </div>
                    </div>
                  )}

                  {data?.load_end_date && (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        Load End DateTime:
                      </label>
                      <div className="profiledetailscls">
                      {moment(EnddateTime).format(
                          "DD-MMM-YYYY, h:mm a"
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Address</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls origin-address">
                          Origin Address
                        </label>

                        <div className="profiledetailscls read-more-new">
                        {methodModel.capitalizeFirstLetter(
                              data?.origin_address
                            )}
                        </div>
                      </div>
                      {/* {data?.board_id?.name == "usps" ? (
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls origin-address">
                            Street Address
                          </label>

                          <div className="profiledetailscls read-more-new">
                          {methodModel.capitalizeFirstLetter(
                                data?.origin_location_street
                              )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}

                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">City</label>
                        <div className="profiledetailscls">
                          {data && data.origin_location_city}
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">State</label>
                        <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(
                            data?.origin_location_state
                          ) || "--"}
                        </div>
                      </div>
                      {data?.origin_location_postal_code?<>
                        <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">Postal Code</label>
                        <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(
                            data?.origin_location_postal_code
                          ) || "--"}
                        </div>
                      </div>
                      </>:<></>}
                     
                      {data?.origin_location_country?<>
                        <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">Country</label>
                        <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(
                            data?.origin_location_country
                          ) || "--"}
                        </div>
                      </div>
                      </>:<></>}
                     
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls origin-address">
                          Destination Address
                        </label>

                        <div className="profiledetailscls read-more-new">
                        {methodModel.capitalizeFirstLetter(
                              data?.destination_address
                            )}
                        </div>
                      </div>
                      {/* {data?.board_id?.name == "usps" ? (
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls origin-address">
                            Street Address
                          </label>

                          <div className="profiledetailscls read-more-new">
                          {methodModel.capitalizeFirstLetter(
                                // data?.stops[data?.stops?.length - 1]
                                //   ?.address ||
                                //   data?.destination_location_street
                                data?.destination_location_street || "--"
                              )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )} */}

                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">City</label>
                        <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(
                            data?.destination_location_city
                          ) }
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">State</label>
                        <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(data?.destination_location_state )}
                        </div>
                      </div>

                      {data?.destination_location_postal_code ? <>
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">Postal Code</label>
                          <div className="profiledetailscls">
                            {data?.destination_location_postal_code || "--"}
                          </div>
                        </div>
                      </> : <></>}

                      {data?.destination_location_country ? <>
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">Country</label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(
                              data?.destination_location_country
                            ) || "--"}
                          </div>
                        </div>
                      </> : <></>}
                      
                      
                    </div>
                  </div>
                </div>
              </div>

              {data?.stops?.length > 0 ? (
                <div className="white-bg-main mb-4">
                  <div className=" white-head d-flex justify-content-between align-items-center">
                    <h5 class="profilelist">Stop Details</h5>
                  </div>{" "}
                  <div className="stops-address border-0">
                    <div className="row padrow">
                      <div className="table-responsive table_section mt-0 p-3">
                     
                        <table className="table">
                          <thead>
                            <th>Stop No.</th>
                            {data?.board_id?.name == "usps" || data?.board_id?.name=="prebooks" ||data?.board_id?.name=="rfps" ? (
                              <> {data?.stops?.find((item, index)=>item?.location_id)?<th>Location Id</th>:<></>}</>
                              
                            ) : null}
                            <th>Address</th>
                          </thead>
                          <tbody>
                            {data?.stops?.map((itm, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  {(data?.board_id?.name == "usps" || data?.board_id?.name=="prebooks" || data?.board_id?.name=="rfps" ) && itm?.location_id ? (
                                    <td>{itm?.location_id || "--"}</td>
                                  ) : null}
                                  <td>{itm?.address}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Track Details</h5>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">
                      Shipment Status :
                    </label>
                    <div className="profiledetailscls statusdivs">
                      <span className={`${data?.shipment_status}`}>{ReplaceUnderScoreWithSpace(data?.shipment_status) ||
                        "--"}</span>
                  
                    </div>
                  </div>
                  
                  {data?.pickedup_at != null ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Pickup Date :</label>
                      <div className="profiledetailscls">
                      {/* {datepipeModel.date(data?.pickedup_at)}{" "}
                      {datepipeModel.isotime(data?.pickedup_at) || "--"} */}
                        {moment(data?.pickedup_at).format("DD-MM-YYYY h:mm A")}
                      </div>
                    </div>
                  ) : null}
                   {data?.delivered_at != null ? (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">Delivered Date :</label>
                      <div className="profiledetailscls">
                      {/* {datepipeModel.date(data?.pickedup_at)}{" "}
                      {datepipeModel.isotime(data?.pickedup_at) || "--"} */}
                        {moment(data?.delivered_at).format("DD-MM-YYYY h:mm A")}
                      </div>
                    </div>
                  ) : null}
                  {data?.status == "accepted" && (
                    <div className="col-md-6 mb-3 view-flex">
                      <label className="profileheddingcls">
                        {data?.delivered_at != null
                          ? " Delivered At :"
                          : data?.transit_at && "Transit At"}
                      </label>
                      <div className="profiledetailscls">
                        {data?.shipment_status == "delivered"
                          ? moment(data?.delivered_at).format(
                              "DD-MMM-YYYY h:m A"
                            )
                          : data?.transit_at &&
                            moment(data?.transit_at).format(
                              "DD-MMM-YYYY h:m A"
                            )}
                      </div>
                    </div>
                  )}
                </div>
                {data?.status == "awarded" && (
                  <div className="p-3">
                    {data?.stops?.length > 1 ? (
                      <Steps vertical style={styles} current={ActiveStop}>
                        <Steps.Item
                          title={
                            data?.stops[0]?.checkin
                              ? "Picked Up"
                              : "Pickup Pending"
                          }
                          description={data?.origin_address}
                        />
                        {data?.stops?.map((itm, index) => {
                          return (
                            <>
                              <Steps.Item
                                title={`${
                                  index < data?.stops?.length - 1
                                    ? "In Transit -"
                                    : ""
                                } ${itm?.address}`}
                                description={
                                  itm.checkin &&
                                  index == data?.stops?.length - 1
                                    ? itm?.checkin &&
                                      `Delivered at ${moment(
                                        itm?.checkin
                                      ).format("DD-MM-YYYY- HH:MM A")} ${
                                        itm?.address
                                      } `
                                    : itm?.checkin &&
                                      `Checkedin ${moment(
                                        itm?.checkin
                                      ).fromNow()}  ${
                                        itm?.checkout == null
                                          ? ""
                                          : `|| checkedOut ${moment(
                                              itm?.checkout
                                            ).fromNow()}`
                                      }`
                                }
                              />
                            </>
                          );
                        })}
                      </Steps>
                    ) : (
                      <>
                        <Steps
                          current={
                            Stops[1]?.date || Stops[1]?.delivered_at ? 2 : 0
                          }
                          vertical
                          style={styles}
                        >
                          {Stops?.map((itm, index) => {
                            return (
                              <>
                                <Steps.Item
                                  title={
                                    itm?.address || itm?.destination_address
                                  }
                                  description={
                                    Stops[1]?.date ? "Dilevered" : "No status"
                                  }
                                />
                              </>
                            );
                          })}
                        </Steps>
                      </>
                    )}
                  </div>
                )}
                {data?.pod_doc?.length != 0 && data?.status == "awarded" ? (
                  <div className="col-md-12 mb-3 ">
                    <label className="profileheddingcls">
                      POD (Proof of Delivery) :
                    </label>
                    <div className="prodflex">
                      {data?.pod_doc?.map((itm) => {
                        return (
                          <div className="profiledetailscls position-relative">
                            {/* <iframe
                                src={`${environment.api}documents/${itm}`}
                                width="200"
                                height="200"
                              />{" "} */}
                            <img src="/assets/img/pdf.png" className="pdfimg" />

                            <i
                              title="Download"
                              onClick={() => {
                                if (itm?.includes(".pdf")) {
                                  downloadFile(itm);
                                }

                                if (itm?.includes("png")) {
                                  downloadImage(itm, "png");
                                }
                                if (itm?.includes(".jpeg")) {
                                  downloadImage(itm, "jpeg");
                                }
                                if (itm?.includes(".jpg")) {
                                  downloadImage(itm, "jpg");
                                }
                                // downloadImage(itm);
                              }}
                              class="fa fa-download docdown"
                            ></i>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {data?.bol_doc?.length != 0 && data?.status == "awarded" ? (
                  <div className="col-md-12 mb-3 mt-4">
                    <label className="profileheddingcls">
                      BOL (Bill of Landing) :
                    </label>
                    <div className="prodflex">
                      {data?.bol_doc?.map((itm) => {
                        return (
                          <div className="profiledetailscls position-relative">
                            {/* <iframe
                                src={`${environment.api}documents/${itm}`}
                                width="200"
                                height="200"
                              />{" "} */}
                            <img src="/assets/img/pdf.png" className="pdfimg" />
                            <i
                              title="Download"
                              onClick={() => {
                                if (itm?.includes(".pdf")) {
                                  downloadFile(itm);
                                }

                                if (itm?.includes("png")) {
                                  downloadImage(itm, "png");
                                }
                                if (itm?.includes(".jpeg")) {
                                  downloadImage(itm, "jpeg");
                                }
                                if (itm?.includes(".jpg")) {
                                  downloadImage(itm, "jpg");
                                }
                                // downloadImage(itm);
                              }}
                              class="fa fa-download docdown"
                            ></i>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {data?.comments ? (
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Comments:</label>
                    <div className="profiledetailscls">
                      {" "}
                      {methodModel.capitalizeFirstLetter(
                          data?.comments
                        )}
                    </div>
                  </div>
                ) : null}
                {data?.notes ? (
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Note:</label>
                    <div className="profiledetailscls">
                      {" "}
                      {methodModel.capitalizeFirstLetter(data?.notes)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {data?.role == "staff" && (
              <div className="col-md-12 mb-3">
                <label className="profileheddingcls">Permissions</label>
                {Permission && (
                  <table className="table custom-table border">
                    <thead>
                      <tr>
                        <th scope="col"> User Add</th>
                        <th scope="col"> User Edit</th>
                        <th scope="col"> User Read</th>
                        <th scope="col">User Delete</th>
                        {/* <th scope="col"> User Complete Access </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_add ? "done" : "clear"}
                          </span>
                        </td>
                        <td>
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_edit ? "done" : "clear"}
                          </span>
                        </td>
                        <td>
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_get ? "done" : "clear"}
                          </span>
                        </td>
                        <td>
                          <span class="material-icons rights-ic ">
                            {Permission?.carrier_delete ? "done" : "clear"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default LoadDetails2;
