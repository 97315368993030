import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import { Tooltip } from "antd";
const Html = ({
  view,
  edit,
  user,
  ChangeFilter,
  reset,
  sorting,
  add,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  total = { total },
}) => {
  const Navigate = useHistory();
  const columns = ["updatedAt", "createdAt"];
  const [visibleColumns, setVisibleColumns] = useState([]);

  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "carrier_name":
        return value;
        break;
      case "updatedAt":
        return datepipeModel.date(value);
        break;
      case "createdAt":
        return datepipeModel.date(value);
        break;
      case "origin_location_city":
        return itm?.load_info?.origin_location_city;
        break;
      case "destination_location_city":
        return itm?.load_info?.destination_location_city;
        break;
      case "createdAt":
        return datepipeModel.date(value);
        break;
      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return value;
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">
          {role ? rolesModel.name(role) : "Load Classification"}
        </h3>

        <article className="d-flex filterFlex phView reversearticle reversearticleagin">
          <div className="dropdown addDropdown chnagesg  d-flex align-items-center equal-width-50 mt-0">
            <button
              className="btn blck-border-btn dropdown-toggle removeBg mb-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters.status
                ? filters.status == "deactive"
                  ? "Inactive"
                  : "Active"
                : "All Status"}
            </button>
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a>
              <a
                className={
                  filters.status == "active"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("active")}
              >
                Active
              </a>
              <a
                className={
                  filters.status == "Inactive"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("deactive")}
              >
                Inactive
              </a>
            </div>
          </div>
          <div className="dropdown addDropdown mr-2 ms-2 equal-width-50 mt-0">
            <button
              className="btn blck-border-btn dropdown-toggle mb-0"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Add Columns
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              {findUniqueElements(visibleColumns, columns).map((itm) => {
                return (
                  <a
                    className={"dropdown-item"}
                    onClick={() => handleColumnToggle(itm)}
                  >
                    {replaceUnderscoresWithSpace(itm)}
                  </a>
                );
              })}
            </div>
          </div>
          {Permission?.board_add || user?.role == "admin" ? (
            <>
              <a className="btn btn-primary mr-2" onClick={(e) => add()}>
                Add {role ? rolesModel.name(role) : "Board"}
              </a>
            </>
          ) : (
            <></>
          )}

          {filters.status || filters.role ? (
            <>
              <a
                className="btn btn-primary text-white mr-2"
                onClick={(e) => reset()}
              >
                Reset
              </a>
            </>
          ) : (
            <></>
          )}
        </article>
      </div>

      <>
        <div className="table-responsive table_section mt-4">
          <table class="table">
            <thead className="table_head">
              <tr className="heading_row">
                <th
                  scope="col"
                  className="table_data pointer"
                  onClick={(e) =>
                    filters?.sortBy == "name asc"
                      ? sorting("name desc", "desc")
                      : sorting("name asc", "asc")
                  }
                >
                  {/* Board Name {filters?.sortBy === "name asc" ? "↑ A-Z" : "↓ Z-A"} */}
                  <span className="d-flex align-items-center">    Board Name{" "}
                    {filters?.sortBy === "name asc"
                      ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                      : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>}
                  </span>
                </th>
                {/* <th scope="col" className="table_data pointer">
                  Expiration Type
                </th> */}
                <th scope="col" className="table_data pointer">
                  Expiration Time
                </th>

                {visibleColumns.map((item) => (
                  <th className="text-capitalize table_data">
                    {replaceUnderscoresWithSpace(item)}{" "}
                    <i
                      className="fa fa-times"
                      onClick={(e) => handleColumnToggle(item)}
                    ></i>{" "}
                  </th>
                ))}
                <th scope="col" className="table_data ">
                  Status
                </th>
                <th scope="col" className="table_data">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                ListingData &&
                ListingData.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        className="table_dats"
                        onClick={(e) => {
                          if (
                            Permission?.carrier_get ||
                            user?.role == "admin"
                          ) {
                            view(itm.id);
                          } else {
                            toast.warn("You do not have valid permission");
                          }
                        }}
                      >
                        <div className="user_detail">
                          <div className="user_name">
                            <h4 className="user">
                              {methodModel.capitalizeFirstLetter(itm?.name)}
                            </h4>
                            {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        {methodModel.capitalizeFirstLetter(
                          itm?.expiration_type
                        )}
                      </td> */}
                      <td>
                        {itm?.expiration_type == "hours"
                          ? moment()
                            .endOf("hours")
                            .add(itm?.expiration_value, "hours")
                            .fromNow()
                          : itm?.expiration_type == "minutes"
                            ? moment()
                              .endOf("minutes")
                              .add(itm?.expiration_value, "minutes")
                              .fromNow()
                            : moment()
                              .endOf("days")
                              .add(itm?.expiration_value, "days")
                              .fromNow()}
                      </td>

                      {visibleColumns.map((item, index) => (
                        <td className="text-capitalize">
                          {ColumnReturner(item, itm[[item]], itm)}
                        </td>
                      ))}
                      <td className="table_dats">
                        {" "}
                        <div className={` ${itm.status}`}>
                          <span className="custom-toggle-btn">
                            {itm.status == "deactive" ? (
                              <Switch
                                onChange={(e) => {
                                  if (
                                    Permission?.board_edit ||
                                    user?.role == "admin"
                                  ) {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  } else {
                                    toast.warn(
                                      "You do not have valid permission"
                                    );
                                  }
                                }}
                                checked={false}
                              />
                            ) : (
                              <Switch
                                onChange={(e) => {
                                  if (
                                    Permission?.board_edit ||
                                    user?.role == "admin"
                                  ) {
                                    setStatusData(itm);
                                    setShowActiveModal("block");
                                  } else {
                                    toast.warn(
                                      "You do not have valid permission"
                                    );
                                  }
                                }}
                                checked={true}
                              />
                            )}
                          </span>
                        </div>
                      </td>

                      {/* dropdown */}
                      <td className="table_dats">
                        <div className="action_icons">
                          {Permission?.board_edit || user?.role == "admin" ? (
                            <>
                              <Tooltip placement="top" title="Edit">
                                <a
                                  className="edit_icon"
                                  title="Edit"
                                  onClick={
                                    user?.role == "admin" ||
                                      Permission?.board_edit
                                      ? (e) => {
                                        if (
                                          Permission?.board_edit ||
                                          user?.role == "admin"
                                        ) {
                                          edit(itm.id);
                                        } else {
                                          toast.warn(
                                            "You do not have valid permission"
                                          );
                                        }
                                      }
                                      : (e) =>
                                        toast.warn(
                                          "You do not have valid permission"
                                        )
                                  }
                                >
                                  <i class="material-icons edit" title="Edit">
                                    edit
                                  </i>
                                </a>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}

                          {Permission?.board_delete ||
                            user?.role == "admin" ? (
                            <>
                              <Tooltip placement="top" title="delete">
                                <span
                                  className="edit_icon"
                                  title="Delete"
                                  onClick={() => {
                                    if (
                                      Permission?.board_delete ||
                                      user?.role == "admin"
                                    ) {
                                      setDeleteId(itm?.id);
                                      setShowDeleteModal("block");
                                      // deleteLoad(itm?.id)
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                >
                                  <i
                                    class="material-icons delete"
                                    title="Delete"
                                  >
                                    {" "}
                                    delete
                                  </i>
                                </span>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>

      {(!loaging && total == 0) || data?.length < 1 ? (
        <div className=" no-data">
          <img src="assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {!loaging && total > 0 ? (
        <div className="paginationWrapper">
          <div className="d-flex align-items-center">
            <div className="me-2 user-name-color">Show</div>
            {total > 0 && (
              <div className="dropdown addDropdown chnagesname ">
                <button
                  className="btn btn-primary dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters?.count}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 5 });
                    }}
                  >
                    5
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 10 });
                    }}
                  >
                    10
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 20 });
                    }}
                  >
                    20
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 30 });
                    }}
                  >
                    30
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 40 });
                    }}
                  >
                    40
                  </a>
                </div>
              </div>
            )}{" "}
            <div className="ms-2 user-name-color">from {total} Boards</div>
          </div>

          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
