import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { roleType } from "../../../models/type.model";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";
import statusModel from "../../../models/status.model";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import environment from "../../../environment";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../../components/common/ImageUpload";
import addressModel from "../../../models/address.model";

const AddEditCarrierUsers = () => {
  const { id, CarrierID } = useParams();
  const [PermissionId, setPermissionId] = useState("");
  const [form, setform] = useState(roleType);
  const history = useHistory();
  const [permission, setPermission] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "" });
  const [role, setRole] = useState([]);
  const [emailErr, setEmailErr] = useState("");
  const [Boards, setBoards] = useState([]);
  const [editCheck, setEditTrue] = useState(true);
  const [emailLoader, setEmailLoader] = useState(false);
  const [documents, setdocument] = useState({
    documentType: "Registration",
    documentNumber: "",
    issueDate: "",
    expirationDate: "",
    documents: "",
  });
  const [truck_info, setinfo] = useState({
    truck_id: null,
    brand: "",
    model: "",
    year: null,
  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");

  const defaultvalue = () => {
    let keys = { ...roleType }; sssss
    Object.keys(roleType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    Object.keys(roleType.permissions).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = "active";
    return keys;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      !form?.firstName ||
      !form?.email ||
      !form?.address ||
      !form?.city ||
      !form?.country ||
      !form?.dialCode ||
      !form?.email ||
      !form?.mobileNo ||
      !form?.pincode
    ) {
      return false;
    }
    loader(true);

    let url = "admin/add-user";
    let method = "post";
    let value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      image: form?.image,
      address: form?.address,
      city: form?.city,
      country: form?.country,
      role: "user",

      state: form?.state,
      pincode: form?.pincode,
      carrier_id: CarrierID,
      mobileNo: form?.mobileNo,
      email: form?.email,
      dialCode: form?.dialCode,
    };
    if (id) {
      method = "put";
      url = "admin/edit-user";
      value = {
        id: id,
        firstName: form?.firstName,
        lastName: form?.lastName,
        image: form?.image,
        address: form?.address,
        city: form?.city,
        country: form?.country,
        // role: "user",

        state: form?.state,
        pincode: form?.pincode,
        carrier_id: form?.carrier_id,
        mobileNo: form?.mobileNo,
        email: form?.email,
        dialCode: form?.dialCode,
      };
    }
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
      }
      loader(false);
    });
  };
  const GetBoards = () => {
    ApiClient.get("boards").then((res) => {
      if (res.success) {
        setBoards(res?.data);
      }
    });
  };
  const setpermission = (key, value) => {
    if (id) {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          id: PermissionId,
          [key]: value,
        },
      });
    } else {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          [key]: value,
        },
      });
      setPermission({
        ...permission,
        [key]: value,
      });
    }
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        setPermission({
          id: res?.data?.id,
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });
        setPermissionId(res?.data?.id);
        // setform({...form,permissions:res?.data})
      }
    });
  };

  //   useEffect(() => {
  //     if (id) {
  //       setform({ ...form, permissions: permission });
  //     }
  //   }, [permission]);

  const GetUser = () => {
    setform({});
    loader(true);
    ApiClient.get("user/detail", { id: id }).then((res) => {
      if (res.success) {
        let value = res?.data;

        if (res?.data?.carrier_id) {
          ApiClient.get("user/detail", { id: res?.data?.carrier_id }).then(
            (res2) => {
     
              setform({
                ...form,
                firstName: res?.data?.firstName,
                lastName: res?.data?.lastName,
                mobileNo: res?.data?.mobileNo,
                dialCode: res?.data?.dialCode,
                address: res?.data?.address,

                email: res?.data?.email,
                carrier_id: CarrierID,
                carrierName: res2?.data?.fullName,
                city: res?.data?.city,
                state: res?.data?.state,
                country: res?.data?.country,
                pincode: res?.data?.pincode,
              });
            }
          );
        }
        if (res?.data?.addedBy != null) {
          setEditTrue(true);
        }

        let payload = {
          id: "",
          name: "",
          status: "active",
          permissions: {
            //  Done
            carrier_delete: false,
            carrier_get: false,
            carrier_complete_access: false,
            carrier_edit: false,
            carrier_add: false,
          },
        };

        Object.keys(payload).map((itm) => {
          payload[itm] = value[itm];
        });
        payload.loginPortal = value.loginPortal;
        if (value.permissions) {
          payload.permissions = value.permissions;
          // payload.permissions={ ...payload.permissions,...value.permissions}
        }
    

        // setform({
        //   ...payload,
        // });
      }
      loader(false);
    });
  };
  useEffect(() => {
    if (id) {
      GetUser();
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  const handleAdminUser = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        carrier_edit: value,
        carrier_get: value,
        carrier_delete: value,
        carrier_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };
  useEffect(() => { }, []);
  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = roleType.permissions;
    Object.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({ ...form, permissions: permissions });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = {
      carrier_add: form.permissions?.carrier_add,
      carrier_delete: form?.permissions?.carrier_delete,
      carrier_edit: form?.permissions?.carrier_edit,
      carrier_get: form?.permissions?.carrier_get,
    };
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    });
    return value;
  };

  const handlecompleteAccess = () => {
    let result = false;
    if (
      form?.permissions?.carrier_add &&
      form?.permissions?.carrier_delete &&
      form?.permissions?.carrier_edit &&
      form?.permissions?.carrier_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };
  const arry = [
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
    1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
    2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
  ];
  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_get: value },
    });
    isAllChecked();
  };
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_add: value },
    });
    isAllChecked();
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_edit: value },
    });
    isAllChecked();
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_delete: value },
    });
    isAllChecked();
  };

  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
 
  };

  useEffect(() => {
    if (id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (!id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    } else if (!id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    }
  }, [param1]);

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setform({ ...form, image: image, baseImg: "" });
        } else {
          setform({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const imageResult = (e, key) => {
  
    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: images?.image });
 
  };

  const Carrier = () => {

    ApiClient.get("user/detail", { id: CarrierID }).then((res) => {
      if (res.success) {
        setform({ ...form, carrierName: res?.data?.fullName });
      }
    });
  };
  useEffect(() => {
  
    if (CarrierID) {
      Carrier();
    }
  }, []);

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <h3
                    className="ViewUser mb-0 user-back"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <i
                      className="fa fa-arrow-left ml-1 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                    {id ? "Edit" : "Add"} Carrier's User
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="common-padding">
                  <div className="row">
                    <div className="col-md-8  mb-3 mx-auto">
                      {/* <label className="lablefontcls">Image</label> */}
                      {/* <div className="imagethumbWrapper w-100">
                        {!form?.image && (
                          <img
                            src="/assets/img/person.jpg"
                            className="uploadimage mx-auto d-block"
                          />
                        )}
                      </div> */}
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className="imagesRow d-flex"
                          style={{
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div className="imagethumbWrapper">
                            <img
                              src={
                                form?.image
                                  ? methodModel.userImg(form && form.image)
                                  : "/assets/img/person.jpg"
                              }
                              className="thumbnail"
                            />
                            {/* <i className="fa fa-times" title="Remove"></i> */}
                          </div>
                          {form?.image ? (
                            <>
                              <label
                                className={`btn btn-primary mt-2 `}
                                onClick={() => {
                                  setform({ ...form, image: "" });
                                }}
                              >
                                Remove Image
                              </label>{" "}
                            </>
                          ) : (
                            <label className={`btn btn-primary mt-2 `}>
                              <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                              Upload Image
                            </label>
                          )}
                          {/* <div className="imagethumbWrapper">
                            <img
                              src={
                                "http://localhost:3000/static/media/imgpsh_fullsize_anim%20(1).18777176cee1ccf356ed.jpeg"
                              }
                              className="thumbnail"
                            />
                            <i className="fa fa-times" title="Remove"></i>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <h5 class="profilelist">User information</h5>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Carrier Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={methodModel.capitalizeFirstLetter(
                          form?.carrierName
                        )}
                        disabled
                      // required
                      />
                      {/* {submitted && !form.firstName ? (
                        <div className="invalid-feedback d-block">
                          First Name is Required
                        </div>
                      ) : (
                        <></>
                      )} */}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.firstName}
                        onChange={(e) =>
                          setform({ ...form, firstName: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.firstName ? (
                        <div className="invalid-feedback d-block">
                          First Name is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.lastName}
                        onChange={(e) =>
                          setform({ ...form, lastName: e.target.value })
                        }
                      // required
                      />
                    </div>

                    {/* <div className="col-md-6 mb-3">
                      <label>
                        Gender <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <select
                          value={form?.gender}
                          className="form-control col-md-12 select-new"
                          onChange={(e) => {
                            setform({ ...form, gender: e.target.value });
                          }}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>;
                          <option value="female">Female</option>;
                          <option value="other">Other</option>;
                        </select>
                      </div>
                      {submitted && !form?.gender ? (
                        <div className="invalid-feedback d-block">
                          Gender is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div> */}
                    <div className="col-md-12 mb-3">
                      <label>
                        Address<span className="text-danger">*</span>
                      </label>
                      <GooglePlaceAutoComplete
                        value={form.address}
                        result={addressResult}
                        id="address"
                        placeholder=""
                      />
                      {submitted && !form.address ? (
                        <div className="invalid-feedback d-block">
                          Address is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.city}
                        onChange={(e) =>
                          setform({ ...form, city: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.city ? (
                        <div className="invalid-feedback d-block">
                          City is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        state<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.state}
                        onChange={(e) =>
                          setform({ ...form, state: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.state ? (
                        <div className="invalid-feedback d-block">
                          State is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Country<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.country}
                        onChange={(e) =>
                          setform({ ...form, country: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.country ? (
                        <div className="invalid-feedback d-block">
                          Country is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Zipcode<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        pattern="^[a-zA-Z0-9]+$"
                        onKeyPress={(e) => {
                          var regex = new RegExp("^[a-zA-Z0-9]+$");
                          var key = String.fromCharCode(
                            !e.charCode ? e.which : e.charCode
                          );
                          if (!regex.test(key)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        className="form-control"
                        value={form.pincode}
                        onChange={(e) =>
                          setform({ ...form, pincode: e.target.value })
                        }
                      // required
                      />
                      {submitted && !form.pincode ? (
                        <div className="invalid-feedback d-block">
                          Zipcode is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3 ">
                      <label className=" d-block">
                        Mobile No<span className="star">*</span>
                      </label>

                      <div className="w-100  d-inline-flex">
                        <PhoneInput
                          value={form.dialCode + "" + form.mobileNo}
                          countryCodeEditable={true}
                          enableSearch={true}
                          placeholder=""
                          country="us"
                          onChange={(phone, country) => {
                            let phonenumber = phone.replace(
                              country.dialCode,
                              ""
                            );
                            // if(phonenumber!=form.mobileNo){
                            // setform({ ...form, mobileNo: phonenumber })
                            // }
                            setform({
                              ...form,
                              dialCode: country.dialCode,
                              mobileNo: phonenumber,
                            });
                          }}
                          required
                        />
                      </div>
                      {submitted && !form.mobileNo ? (
                        <div className="invalid-feedback d-block">
                          Mobile Number is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={form.email}
                        // disabled={id ? true : false}
                        onChange={(e) => {
                          setform({ ...form, email: e.target.value });
                          // emailCheck(e.target.value);
                        }}
                      // required
                      />
                      {submitted && !form.email ? (
                        <div className="invalid-feedback d-block">
                          Email is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label>
                        Board Status <span className="text-danger">*</span>
                      </label>
                      <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }} className="d-flex">
                        <div className="d-flex" style={{ flexDirection: 'column' }}>

                          <label htmlFor="">USPS</label>
                          <Switch
                            checked={form?.USPS}
                            onChange={(e) => {
                              if (e) {
                                setform({ ...form, USPS: true })
                              } else {
                                setform({ ...form, USPS: false })

                              }
                          
                            }}
                          />
                        </div>
                        <div className="d-flex" style={{ flexDirection: 'column' }}>

                          <label htmlFor="">RFPs</label>
                          <Switch
                            checked={form?.RPF}
                            onChange={(e) => {
                              if (e) {
                                setform({ ...form, RPF: true })
                              } else {
                                setform({ ...form, RPF: false })

                              }
                   
                            }}
                          />
                        </div>
                        <div className="d-flex" style={{ flexDirection: 'column' }}>

                          <label >GENERAL LOBES</label>
                          <Switch
                            checked={form?.general_lobes}
                            onChange={(e) => {
                              if (e) {
                                setform({ ...form, general_lobes: true })
                              } else {
                                setform({ ...form, general_lobes: false })

                              }
                             
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                    {!id ? (
                      <>
                        {/* <div className="col-md-6 mb-3">
                          <label>
                            Password<span className="star">*</span>
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={eyes?.password ? "text" : "password"}
                              className="form-control"
                              value={form?.password}
                              onChange={(e) =>
                                setform({ ...form, password: e.target.value })
                              }
                            />
                            <i
                              className={
                                eyes?.password
                                  ? "fa fa-eye fa-set"
                                  : "fa fa-eye-slash fa-set"
                              }
                              onClick={() =>
                                setEyes({ ...eyes, password: !eyes?.password })
                              }
                            ></i>
                          </div>
                          {submitted &&
                            form?.password &&
                            form?.password?.length < 8 ? (
                            <div className="invalid-feedback d-block">
                              Password minimum length should be 8
                            </div>
                          ) : (
                            <></>
                          )}
                          {submitted && !form?.password ? (
                            <div className="invalid-feedback d-block">
                              Password is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div> */}
                        {/* <div className="col-md-6 mb-3">
                          <label>
                            Confirm Password <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={eyes?.confirmPassword ? "text" : "password"}
                              className="form-control"
                              value={form?.confirmPassword}
                              onChange={(e) =>
                                setform({
                                  ...form,
                                  confirmPassword: e.target.value,
                                })
                              }
                            // required={form?.password ? true : false}
                            />
                            <i
                              className={
                                eyes?.confirmPassword
                                  ? "fa fa-eye fa-set"
                                  : "fa fa-eye-slash fa-set"
                              }
                              onClick={() =>
                                setEyes({
                                  ...eyes,
                                  confirmPassword: !eyes.confirmPassword,
                                })
                              }
                            ></i>
                          </div>
                          {submitted &&
                            form?.password?.length > 0 &&
                            form?.confirmPassword !== form?.password &&
                            form?.confirmPassword?.length > 0 ? (
                            <div className="invalid-feedback d-block">
                              Comfirm Password is not matched with Password
                            </div>
                          ) : (
                            <></>
                          )}
                          {submitted && form?.confirmPassword?.length == 0 ? (
                            <div className="invalid-feedback d-block">
                              Confirm Password is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div> */}
                      </>
                    ) : null}
                    {form?.role == "carrier" && editCheck && (
                      <>
                        {/* <div>
                      <p className="accopunt text-center">
                        Truck Information
                      </p>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>
                        Truck ID <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <input
                          type={'text'}
                          className="form-control"
                          value={truck_info?.truck_id}
                          onChange={(e) =>
                            setinfo({
                              ...truck_info,
                              truck_id: e.target.value,
                            })
                          }

                        />

                      </div>
                      {submitted &&
                        !truck_info?.truck_id ? (
                        <div className="invalid-feedback d-block">
                          Truck ID  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                         Brand <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <input
                          type={'text'}
                          className="form-control"
                          value={truck_info?.brand}
                          onChange={(e) =>
                            setinfo({
                              ...truck_info,
                              brand: e.target.value,
                            })
                          }

                        />

                      </div>
                      {submitted &&
                        !truck_info?.brand ? (
                        <div className="invalid-feedback d-block">
                          Truck Brand  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                         Year <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                      <select
                                className="form-control col-md-12 select-new"
                                onChange={(e) => {
                                  setinfo({
                                    ...truck_info,
                                    year: parseInt(e.target.value),
                                  });
                                
                                }}
                              >
                                <option value=''>Select Year</option>
                                {arry?.map((itm) => {
                                  return <option value={itm}>{itm}</option>;
                                })}
                              </select>

                      </div>
                      {submitted &&
                        !truck_info?.year ? (
                        <div className="invalid-feedback d-block">
                          Year  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>
                    <div>
                      <p className="accopunt text-center">
                        Truck Documentation
                      </p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Document Number <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <input
                          type='text'
                          className="form-control"
                          pattern="^[a-zA-Z0-9]+$"
                          onKeyPress={(e)=> {
                            var regex = new RegExp("^[a-zA-Z0-9]+$");
                            var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                            if (!regex.test(key)) {
                               e.preventDefault();
                               return false;
                            }}}
                          value={documents?.documentNumber}
                          onChange={(e) =>
                            setdocument({
                              ...documents,
                              documentNumber: e.target.value,
                            })
                          }

                        />

                      </div>
                      {submitted &&
                        !documents?.documentNumber ? (
                        <div className="invalid-feedback d-block">
                          Document Number  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                       Issue Date <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <input
                          type='date'
                          className="form-control"
                          value={documents?.issueDate}
                          onChange={(e) =>
                            setdocument({
                              ...documents,
                              issueDate: e.target.value,
                            })
                          }

                        />

                      </div>
                      {submitted &&
                        !documents?.issueDate ? (
                        <div className="invalid-feedback d-block">
                          Issue Date  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                       Expiry Date <span className="star">*</span>{" "}
                      </label>
                      <div className="inputWrapper quick-ic">
                        <input
                          type='date'
                          className="form-control"
                          value={documents?.expirationDate}
                          onChange={(e) =>
                            setdocument({
                              ...documents,
                              expirationDate: e.target.value,
                            })
                          }

                        />

                      </div>
                      {submitted &&
                        !documents?.expirationDate ? (
                        <div className="invalid-feedback d-block">
                          Expiry Date  is Required
                        </div>
                      ) : (
                        <></>
                      )}

                    </div> */}
                        {/* <div className="col-md-12 p-0 mt-3 inputWrapper">
                              <label className="form-label ml-2">
                                Document
                                <span className="text-danger">*</span>
                              </label>
                              {
                                documents?.documents ? <>

                                  <embed style={{ borderRadius: '12px' }} src={`http://74.208.206.18:4020/documents/${documents?.documents}`} width="390" height="200"></embed>
                                  <button
                                    type="button"
                                    className="btn btn-secondary discard ml-3"
                                    onClick={() => {
                                      loader(true)
                                      ApiClient.delete(`delete/document?fileName=${documents?.documents}`).then((res) => {
                                        if (res.success) {
                                          setdocument({...documents,documents:''})
loader(false)
                                        }
                                      })
                                    }}
                                  >
                                    Remove
                                  </button>
                                </> :

                                  <input
                                    className="form-control mb-0 bginput chnages"
                                    type="file"
                                    required
                                    name=""
                                    onChange={async (e) => {
                                      loader(true);

                                      console.log(
                                        e.target.files[0]?.name,
                                        "==File"
                                      );

                                      const Form = new FormData();
                                      Form.append("file", e.target.files.item(0));

                                      const Upload = await fetch(
                                        "http://74.208.206.18:4020/upload/document",
                                        {
                                          method: "POST",
                                          body: Form,
                                        }
                                      );

                                      const res = await Upload.json();
                              
                                      loader(false);
                                      setdocument({
                                        ...documents,
                                        documents: res?.data?.imagePath,
                                      });
                                    }}
                                    placeholder="select your documents"
                                  // onBlur={handleBlur}
                                  />}
                            </div> */}
                      </>
                    )}
                    {form?.role == "staff" && (
                      <div className="col-md-12 mb-3">
                        <h5 className="mb-0 mt-4">
                          <b>Permissions</b>
                        </h5>
                        <div class="table-responsive ">
                          <div class="table_section tablepadding table-bordered">
                            <table class="table">
                              <thead class="table_head roleTable">
                                <tr class="heading_row">
                                  <th scope="col" class="table_data"></th>
                                  <th scope="col" class="table_data">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        HandleAll(e.target.checked)
                                      }
                                      checked={isAllChecked()}
                                    />
                                    All
                                  </th>
                                  <th scope="col" class="table_data">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        HandleAllRead(e.target.checked)
                                      }
                                      checked={form.permissions.carrier_get}
                                    />
                                    Read
                                  </th>
                                  <th scope="col" class="table_data">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        HandleAllAdd(e.target.checked)
                                      }
                                      checked={form.permissions.carrier_add}
                                    />
                                    Add
                                  </th>
                                  <th scope="col" class="table_data">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        HandleallEdit(e.target.checked)
                                      }
                                      checked={form.permissions.carrier_edit}
                                    />
                                    Edit
                                  </th>
                                  <th scope="col" class="table_data">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        HandleAllDelete(e.target.checked)
                                      }
                                      checked={form.permissions.carrier_delete}
                                    />
                                    Delete
                                  </th>
                                  {/* <th scope="col" class="table_data"> <input
                            type="checkbox"  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                                </tr>
                              </thead>
                              <tbody className="roleTable">
                                <tr>
                                  <td>
                                    <b>Users</b>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        handleAdminUser(e.target.checked);
                                        //   if(e.target?.checked){
                                        //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                        //   }else
                                      }}
                                      checked={handlecompleteAccess()}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={form?.permissions?.carrier_get}
                                      onChange={(e) =>
                                        setpermission(
                                          "carrier_get",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={form?.permissions?.carrier_add}
                                      onChange={(e) =>
                                        setpermission(
                                          "carrier_add",
                                          e.target.checked
                                        )
                                      }
                                    />{" "}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={form?.permissions?.carrier_edit}
                                      onChange={(e) =>
                                        setpermission(
                                          "carrier_edit",
                                          e.target.checked
                                        )
                                      }
                                    />{" "}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        form?.permissions?.carrier_delete
                                      }
                                      onChange={(e) =>
                                        setpermission(
                                          "carrier_delete",
                                          e.target.checked
                                        )
                                      }
                                    />{" "}
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={() => {
                          setSubmitted(true);
                        }}
                      >
                        Save
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditCarrierUsers;
