
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import environment from '../../environment';
import moment from 'moment';
import Layout2 from '../../components/global/layout2';

export default function Notification2() {
  const user = useSelector((state) => state.user);
  const pathname = location.pathname;
  const history = useHistory();
  // const [List, setList] = useState([]);
  const [pickedup, setpickedup] = useState([]);
  const [inTransit, setinTransit] = useState([]);
  const [delivered, setdelivered] = useState([]);
  const [total, settotal] = useState(0);
  const [TruckTransit, setTruckTransit] = useState([]);
  const [DriverTransit, setDriverTransit] = useState([]);
  const GetNotification = () => {
    loader(true);
    ApiClient.get('notification/all', {
      send_to: user?.id,
      status: 'unread',
    }).then((res) => {
      if (res.success) {
        settotal(res?.data?.total_count);
        setpickedup(res?.data?.data.filter((itm) => itm?.load_status == "pickedup"))
        setinTransit(res?.data?.data.filter((itm) => itm?.load_status == "in_transit"))
        setdelivered(res?.data?.data.filter((itm) => itm?.load_status == "delivered"))
        setDriverTransit(res?.data?.data.filter((itm) => itm?.type == "driver"))
        setTruckTransit(res?.data?.data.filter((itm) => itm?.type == "truck"))

      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user && user?.id) GetNotification();
  }, [user]);

  const UpdateNotificationStatus = (id, status) => {
    loader(true);
    ApiClient.put('notification/change-status', {
      id: id,
      status: status,
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };

  const UpdateNotificationStatusAll = (id, status) => {
    loader(true);
    ApiClient.put('notification/change-status-all', {
      status: 'read',
    }).then((res) => {
      if (res.success) {
        // toast.success(res?.message);
        loader(false);
        GetNotification();
      }
      loader(false);
      GetNotification();
    });
  };
  const websitedetails = useSelector((state) => state.WebsiteDetails);

  return (
    <>
      <Layout2 title='Notifications'>
        <div className="mainareas">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-md-9 mx-auto">
              <div className="notification-main  ">
                <div className="notify-upper">
                  <div className="notify-img">
                    <img src="assets/img/notify.png" />

                  </div>
                </div>
                <div className="notify-bottom">
                  
                  {pickedup?.length == 0 && inTransit?.length == 0 && delivered?.length == 0 && TruckTransit?.length == 0 && TruckTransit?.length == 0 
                  ?
                  <div className='text-center notify-no-data'>No Notifications</div>
                  :
                  <>  {pickedup?.length > 0 &&
                    <div class="notifications-wrapper">
                      <h4>Picked Up</h4>

                      {pickedup?.map((itm, index) => {
                        return (
                          <a class="content">
                            <div
                              class="notification-item"
                              onClick={() => {

                                UpdateNotificationStatus(itm?.id, 'read');
                                history.push(`/loads/view/${itm?.load_management_id}`);
                              }}
                            >
                              <div className="d-flex align-items-center gap-2 justify-content-between">
                                {' '}
                                <div className='d-flex align-items-center notify-left'>
                                  <img
                                    className="noteimg me-2"
                                    src={itm?.addedBy_image ? `${environment.api}images/users/${itm?.addedBy_image}` : '/assets/img/person.jpg'}
                                    alt=""
                                  />

                                  <p class="m-0 messagep pl-1">

                                    {itm?.message}{' '}
                                  </p>
                                </div>
                                <p className="text-end m-0 nottime">
                                  {moment(itm?.createdAt).format(
                                    'DD MMM YYYY h:mm A'
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        );
                      })}

                    </div>
                  }
                  {inTransit?.length > 0 &&
                    <div class="notifications-wrapper">
                      <h4>In Transit</h4>

                      {inTransit?.map((itm, index) => {
                        return (
                          <a class="content">
                            <div
                              class="notification-item"
                              onClick={() => {
                                UpdateNotificationStatus(itm?.id, 'read');
                                history.push(`/loads/view/${itm?.load_management_id}`);
                              }}
                            >
                              <div className="d-flex align-items-center gap-2 justify-content-between">

                                <div className='d-flex align-items-center notify-left'>
                                  <img
                                    className="noteimg me-2"
                                    src={itm?.addedBy_image ? `${environment.api}images/users/${itm?.addedBy_image}` : '/assets/img/person.jpg'}
                                    alt=""
                                  />
                                  {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                  <p class="m-0 messagep pl-1">

                                    {itm?.message}{' '}
                                  </p>
                                </div>
                                <p className="text-end m-0 nottime">
                                  {moment(itm?.createdAt).format(
                                    'DD MMM YYYY h:mm A'
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        );
                      })
                      }
                    </div>
                  }
                  {delivered?.length > 0 &&
                    <div class="notifications-wrapper">
                      <h4>Delivered</h4>

                      {delivered?.map((itm, index) => {
                        return (
                          <a class="content">
                            <div
                              class="notification-item"
                              onClick={() => {
                                UpdateNotificationStatus(itm?.id, 'read');
                                history.push(`/loads/view/${itm?.load_management_id}`);
                              }}
                            >
                              <div className="d-flex align-items-center gap-2 justify-content-between">
                                {' '}
                                <div className='d-flex align-items-center notify-left'>
                                  <img
                                    className="noteimg me-2"
                                    src={itm?.addedBy_image ? `${environment.api}images/users/${itm?.addedBy_image}` : '/assets/img/person.jpg'}
                                    alt=""
                                  />
                                  {/* <p class="item-info d-flex m-0 align-items-center"><span class="item-title">{itm?.title} </span> <span className="notmess">{itm?.message}</span> </p> */}
                                  <p class="m-0 messagep pl-1">

                                    {itm?.message}{' '}
                                  </p>
                                </div>
                                <p className="text-end m-0 nottime">
                                  {moment(itm?.createdAt).format(
                                    'DD MMM YYYY h:mm A'
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        );
                      })
                      }
                    </div>
                  }
                  {DriverTransit?.length > 0 &&
                    <div class="notifications-wrapper">
                      <h4 style={{ width: '70px', overflow: 'hidden' }}>Driver</h4>
                      {
                        DriverTransit?.map((itm, index) => {
                          return (
                            <a class="content">
                              <div
                                class="notification-item"
                                onClick={() => {
                                  UpdateNotificationStatus(itm?.id, 'read');
                                  history.push(`/userDetail/Carriers/${itm?.addedBy}`);
                                }}
                              >
                                <div className="d-flex align-items-center gap-2 justify-content-between">
                                  {' '}
                                  <div className='d-flex align-items-center notify-left'>
                                    <img
                                      className="noteimg me-2"
                                      src={itm?.addedBy_image ? `${environment.api}images/users/${itm?.addedBy_image}` : '/assets/img/person.jpg'}
                                      alt=""
                                    />

                                    <p class="m-0 messagep pl-1">
                                    {`This driver with this license number  ${itm?.licence_number} been added by company`}<span className='text_underline'>{` ${itm?.company_name}.`}</span>{' '}
                                    </p>
                                  </div>
                                  <p className="text-end m-0 nottime">
                                    {moment(itm?.createdAt).format(
                                      'DD MMM YYYY h:mm A'
                                    )}
                                  </p>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      }
                    </div>
                  }

                  {TruckTransit?.length > 0 &&
                    <div class="notifications-wrapper">
                      <h4 style={{ width: '70px', overflow: 'hidden' }}>Truck</h4>
                      {
                        TruckTransit?.map((itm, index) => {
                          return (
                            <a class="content">
                              <div
                                class="notification-item"
                                onClick={() => {
                                  UpdateNotificationStatus(itm?.id, 'read');
                                  history.push(`/userDetail/Carriers/${itm?.addedBy}`);
                                }}
                              >
                                <div className="d-flex align-items-center gap-2 justify-content-between">
                                  {' '}
                                  <div className='d-flex align-items-center notify-left'>
                                    <img
                                      className="noteimg me-2"
                                      src={itm?.addedBy_image ? `${environment.api}images/users/${itm?.addedBy_image}` : '/assets/img/person.jpg'}
                                      alt=""
                                    />
                                    {itm?.vin_number && itm?.truck_number? <p class="m-0 messagep  pl-1">                               
                                      {`Duplicate vin number ${itm?.vin_number} and truck number ${itm?.truck_number} been added by the company`}<span className='text_underline'>{` ${itm?.company_name}.`}</span>{' '}
                                    </p>:itm?.truck_number?<p class="m-0 messagep pl-1">                               
                                    {`Duplicate truck number ${itm?.truck_number} been added by the company`}<span className='text_underline'>{` ${itm?.company_name}.`}</span>{' '}
                                    </p>:<p class="m-0 messagep pl-1">                               
                                    {`Duplicate vin number ${itm?.vin_number} been added by the company`}<span className='text_underline'>{` ${itm?.company_name}.`}</span>{' '}
                                    </p>}
                                 
                                  </div> 
                                  <p className="text-end m-0 nottime">
                                    {moment(itm?.createdAt).format(
                                      'DD MMM YYYY h:mm A'
                                    )}
                                  </p>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      }
                    </div>
                  }</>
                  }
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
}
