import React, { useState, useRef, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import environment from "../../../environment";

const GooglePlacesAutocomplete = ({ placeholder, result, id, value, key,required=false }) => {
  const [searchText, setSearchText] = useState(value);
  const autocompleteRef = useRef(null);
  const debounceTimeout = useRef(null);
  useEffect(() => {
    setSearchText(value);
  }, [value]);
  const handlePlaceSelect = (place) => {
    // onSelect(place);
    placeChange(place);
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      if (inputText.trim() !== "") {
        // handleAutocompleteRequest(inputText);
      }
    }, 1500); // Adjust debounce delay as needed
  };

  const handleAutocompleteRequest = (inputText) => {
    if (!autocompleteRef.current) return;
    autocompleteRef.current.setOptions({ input: inputText });
  };
  const placeChange = (place) => {
    setSearchText(place.formatted_address);
    result({
      event: "placeChange",
      value: place.formatted_address,
      place,
    });
  };
  return (
    <Autocomplete
      key={key}
      options={{ types: "establishment", debounce: 1000 }}
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={() =>
        handlePlaceSelect(autocompleteRef.current.getPlace())
      }
    >
      <input
        key={key}
        type="text"
        placeholder="Enter a location"
        value={searchText}
        required={required}
        className="form-control"
        onChange={handleInputChange}
      />
    </Autocomplete>
  );
};

export default GooglePlacesAutocomplete;
