import React from "react";
import { Link } from "react-router-dom";
import methodModel from "../../../methods/methods";
import Sidebar from "../sidebar";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import Img from "../../../assets/imgpsh_fullsize_anim (1).jpeg";
import { LuLogOut } from "react-icons/lu";
import { LuLock } from "react-icons/lu";
import { RiUser6Line } from "react-icons/ri";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  isOpen1,
  searchChange,
  clear,
  Logout,
}) => {
  const pathname = location.pathname;
  const { id } = useParams();
  const history = useHistory();
  const websitedetails = useSelector((state) => state.WebsiteDetails);
  return (
    <nav
      component="header"
      className={
        isOpen
          ? "navbar navbar-expand-lg main-navbar min-sidebar nowrapflex navfix"
          : "navbar navbar-expand-lg main-navbar nowrapflex navfix"
      }
    >
      {" "}
      <div className="logo-width pointer">
        <a className="navbar-brand">
          <img
            onClick={() => {
              history.push("/dashboard1");
            }}
            src="/assets/img/imgpsh_fullsize_anim (1).jpeg"
            alt=""
            className="logo-hidden"
          />
          <img
            onClick={() => {
              history.push("/dashboard1");
            }}
            src="assets/img/logo-small.jpeg"
            alt=""
            className="mobile-logo"
          />
        </a>
      </div>
      <button
        className="navbar-toggler hidebt"
        type="button"
        data-bs-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <a
        onClick={toggle}
        className={
          isOpen
            ? "btn barlink text-primary active threebars"
            : "btn barlink  text-primary"
        }
      >
        <i className="fas fa-bars" />
      </a>
      <div className="d-flex justify-content-end w-100 align-items-center main-head main-headwidth">
        {pathname == "/faq1/add" ||
        pathname == `/faq1/edit/${id}` ||
        pathname == "/categorylist" ||
        pathname == "/projects" ||
        pathname == "/technologies" ||
        pathname == "/speciality" ||
        pathname == "/edit-setting1s" ||
        pathname == "/settings1" ||
        pathname == "/dashboard1" ||
        pathname == "/profile1" ||
        pathname == `/shipment-note1/${id}` ||
        pathname == `/notes1/${id}` ||
        pathname == `/bids1/view/${id}` ||
        pathname == "/staff1/add" ||
        pathname == `/staff1/edit/${id}` ||
        pathname == `/queries1/view/${id}` ||
        pathname == "/carrier1/users" ||
        pathname.includes("/Reqrooter") ||
        pathname.includes("/content1") ||
        pathname.includes("/performance/carriers1") ||
        pathname.includes(`/loads/view/${id}`) ||
        pathname.includes(`/loads/add`) ||
        pathname.includes(`/boards1/edit/${id}`) ||
        pathname.includes("/boards1/add") ? null : (
          <form className="headerSearch mr-2" onSubmit={searchHandle}>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => searchChange(e.target.value)}
              className="Searchbar"
            ></input>
            <i
              className="fa fa-search"
              onClick={searchHandle}
              aria-hidden="true"
            ></i>
            {search ? (
              <i className="fa fa-times" onClick={clear} aria-hidden="true"></i>
            ) : (
              <></>
            )}
          </form>
        )}
        <div className="dropdown profile-dropDOwn">
          <div>
            <a
              data-bs-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center"
            >
              <div className="me-1 username-head ">
                <b>{methodModel.capitalizeFirstLetter(user.fullName)}</b>
                <p className=" mb-0 text-capitalize descmaxw">{user.role}</p>
              </div>
              <img
                alt="image"
                src={methodModel.userImg(user.image)}
                className="rounded-circle"
              />
            </a>
            <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover">
              <Link to="/profile1" className="dropdown-item has-icon">
                <RiUser6Line className="me-2" />
                Profile
              </Link>
              <Link
                to="/profile1?id=change-pass"
                className="dropdown-item has-icon"
              >
                <LuLock className="me-2" />
                Change Password
              </Link>

              <a
                id="handleLogout"
                onClick={() => Logout()}
                className="dropdown-item has-icon"
              >
                <LuLogOut className="me-2" />
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
      {isOpen1 ? (
        <div className="w-100 mobi-dropdown">
          <Sidebar />
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Html;
