import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";

const initialState = {
  logo: "/assets/img/Logo_new.png",
  fav_icon: "/assets/img/Logo_new.png",
};

const WebsiteDetails = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WEBSITE_DETAIL":
      state = action.data;
      return action.data;
    case "POST_WEBSITE_DETAIL":
      return state;
    case "WEBSITE_DETAIL_ERROR":
      return state;
    default:
      return state;
  }
};
export default WebsiteDetails;
