import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import ApiClient from "../../methods/api/apiClient";
import CommonAcceptModal from "../CommonDelete&ActiveModal/CommonAccecptModel";
import CommonRejectModal from "../CommonDelete&ActiveModal/CommonRejectModel";
import { Tooltip } from "antd";
import moment from "moment";
import { IoListCircleOutline } from "react-icons/io5";
import { MdOutlinePending } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { FaAward } from "react-icons/fa";

import { BsCheck2 } from "react-icons/bs";
import CommonAwardModal from "../CommonDelete&ActiveModal/CommonAwardModal";
import CommonUpdateKeyModal from "../CommonDelete&ActiveModal/CommomUpdateKey";
import loader from "../../methods/loader";
import { BsInfoCircleFill } from "react-icons/bs";
import Layout2 from "../../components/global/layout2";
const Stops = ({ stop, data }) => {
  const [stops, setstops] = useState(true);
  return (
    <>
      {stops ? (
        <>
          {stop?.map((itm, index) => {
            return (
              <>
                {index < 2 ? (
                  <>
                    <div className="d-flex gap-1 ">
                      {" "}
                      <span className="destspan">
                        {itm?.address?.substr(0, 10)}...
                      </span>
                      {data?.status == "awarded" ? (
                        <>
                          {!itm?.checkout && !itm?.checkin && (
                            <span className="tricon">
                              {" "}
                              <i
                                class="fa fa-truck"
                                title="In-Transit"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                          {itm?.checkin || itm?.checkout ? (
                            <Tooltip
                              title={`Reached ${itm?.address} at  ${moment(
                                itm?.checkin
                              ).format("DD-MMM-YYYY h:m A")} Destination-: ${itm?.load_info?.destination_address
                                }`}
                            >
                              <span className="tableficon">
                                <i
                                  class="fa fa-map-marker text-success"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </>
      ) : (
        <></>
      )}
      {stop?.length > 2 ? (
        <span className="info_i">
          <Tooltip
            title={
              <>
                {stop?.map((itm) => {
                  return (
                    <div className="text-white">
                      <ul>
                        <li> {itm?.address}</li>
                      </ul>
                    </div>
                  );
                })}
              </>
            }
          >
            <BsInfoCircleFill />
          </Tooltip>
        </span>
      ) : (
        <></>
      )}
    </>
  );
};
const Html = ({
  view,
  Min_rate,
  Max_rate,
  setMax_rate,
  setMin_rate,
  addressResult,
  edit,
  user,
  ChangeFilter,
  deleteLoad,
  AwardBid,
  reset,
  sorting,
  add,
  colClick,
  tab,
  tabChange,
  tabs,
  setTabs,
  ChangeRole,
  ChangeStatus,
  openModal,
  statusChange,
  pageChange,
  ChangeShipmentStatus,
  completeBid,
  deleteItem,
  exportCsv,
  uTableCols,
  removeCol,
  filters,
  ShowRejectModal,
  setShowRejectModal,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowAcceptModal,
  isAllow,
  setshowAwardModal,
  ShowAwardModal,
  rejectBid, loaderr,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [BidID, setID] = useState("");
  const Columns = JSON.parse(localStorage.getItem("BidColumn")) || [];
  const [DeleteId, setDeleteId] = useState("");
  const history = useHistory();
  const latestSliderValue = React.useRef([0, 0]);
  const [Notes, SetNotes] = useState({});
  const [UpdateModal, setUpdateModal] = useState("none");
  const [setting, setsetting] = useState({});
  const applyBid = () => {
    completeBid(DeleteId);
  };

  const AwardBidFunc = () => {
    AwardBid(DeleteId);
  };
  const columns = ["Bid ETA", "Bid At"];
  const [visibleColumns, setVisibleColumns] = useState(Columns);
  useEffect(() => {
    localStorage.setItem("BidColumn", JSON.stringify(visibleColumns));
  }, [visibleColumns]);



  useEffect(() => {
    GetSetting();
  }, []);

  const GetSetting = () => {
    ApiClient.get("settings").then((res) => {
      if (res.success) {
        setsetting(res?.data);
      }
    });
  };

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);

    getData({ min_price: min, max_price: max });
  }, 500);
  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };
  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };
  const ReplaceUnderScoreWithSpace = (str) => {
    if (str?.includes("_")) {
      return methodModel.capitalizeFirstLetter(str.split("_").join(" "));
    } else {
      return methodModel.capitalizeFirstLetter(str);
    }
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "carrier_name":
        return value;
        break;
      case "updatedAt":
        return datepipeModel.date(value) || "--";
        break;
      case "Bid At":
        return datepipeModel.date(itm?.createdAt) || "--";
        break;

      case "Origin City":
        return itm?.load_info?.origin_location_city || "--";
        break;
      case "Origin State":
        return itm?.load_info?.origin_location_state || "--";
        break;
      case "Destination City":
        return itm?.load_info?.destination_location_city || "--";
        break;
      case "Destination State":
        return itm?.load_info?.destination_location_state || "--";
        break;
      case "createdAt":
        return datepipeModel.date(value) || "--";
        break;
      case "Bid Rate":
        return `$${itm?.agreed_rate}` || "--";
        break;
      case " Load Expiration Date":
        return (
          datepipeModel.isotodate(itm?.expiration_date) || '--'
        );
        break;
      case "Bid ETA":
        return (
          <>
            {datepipeModel.date(itm?.bid_time)}{" "}
            {datepipeModel.isotime(itm?.bid_time) || "--"}
          </>
        );
        break;
      case "Pickup Date":
        datepipeModel.isotodate(itm?.itm?.load_info?.load_start_date) || "--";
        break;
      default:
        return value;
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }
  const rejectedBid = () => {
    rejectBid(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  const ConvertStringToAbbrivate = (str) => {
    let key = str?.includes(" ");
    if (key) {
      let extractletter = str
        ?.split(" ")
        ?.map((word) => methodModel.capitalizeFirstLetter(word[0]));
      return extractletter?.join("");
    } else {
      return str;
    }
  };

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }

  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };

  const UpdateMargin = (price) => {
    loader(true);
    ApiClient.put("change-rate", { id: BidID?.id, usps_amount: price }).then(
      (res) => {
        if (res.success) {
          toast.success(res?.message);
          loader(false);

          setUpdateModal("none");
          getData();
        }
        loader(false);
      }
    );
  };
  const [expandedRow, setExpandedRow] = useState(-1);
  const toggle = (index) => {
    if (expandedRow === index) {
      setExpandedRow(-1);
    } else {
      setExpandedRow(index);
    }
  };
  const pathname = window.location.pathname;
  useEffect(() => {
    setExpandedRow(-1)
  }, [pathname]);
  return (
    <Layout2
      title={
        pathname == "/pending-bids"
          ? "Pending Bids"
          : pathname == "/accepted-bids"
            ? "Accepted Bids"
            : pathname == "/awarded-bids"
              ? "Awarded Bids"
              : pathname == "/intransit-bids"
                ? " Intransit Bids"
                : pathname == "/delivered-bids"
                  ? " Delivered Bids"
                  : pathname == "/rejected-bids"
                    ? "Rejected Bids"
                    : "Bids"
      }
      Sidetitle="Bids Management"
      searchShow="true"
    >
      <div className="mainareas new-table-set">
        <CommonAcceptModal
          show={ShowActiveModal}
          setShow={setShowActiveModal}
          confirm={applyBid}
          status={StatusData.status}
        />
        <CommonAwardModal
          show={ShowAwardModal}
          setShow={setshowAwardModal}
          confirm={AwardBidFunc}
          status={"Award"}
        />
        <CommonUpdateKeyModal
          show={UpdateModal}
          setShow={setUpdateModal}
          confirm={UpdateMargin}
          status={"Award"}
        />
        <CommonRejectModal
          show={ShowRejectModal}
          setShow={setShowRejectModal}
          confirm={rejectedBid}
          status={StatusData.status}
        />
        <div className="row ">
          <div className=" col-md-12 ">
            <article className="row filterFlex phView mb-2 justify-content-between">
              <div className="col-xl-6 col-lg-6 mb-sm-2 mb-md-1">
                <div class="bids-top ">
                  <h4>
                    Results<span>{total}</span>
                  </h4>
                </div>
              </div>
              {/* <div className="dropdown addDropdown chnagesg mr-2 d-flex align-items-center equal-width-50 w-100">
            <button
              className="btn blck-border-btn dropdown-toggle removeBg"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters.status
                ? filters.status == "deactive"
                  ? "Inactive"
                  : methodModel.capitalizeFirstLetter(filters.status)
                : "All Status"}
            </button>
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a>
              <a
                className={
                  filters.status == "Accepted"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("accepted")}
              >
                Accepted
              </a>
              <a
                className={
                  filters.status == "Accepted"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("pending")}
              >
                Pending
              </a>
              <a
                className={
                  filters.status == "rejected"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("rejected")}
              >
                Rejected
              </a>
            </div>
          </div> */}
              <div className="col-xl-6 col-lg-6 mb-sm-2 mb-md-1">
                <div className="d-flex gap-2 fiftyflex justify-content-end">
                  <div className="tbl-shadow mb-lg-0 mb-2 ml-2 new-price-range">
                    <label className="mb-0 price-range-text">Price Range:</label>
                    <div className=" price-range-label ">
                      <RangeSlider
                        min={0}
                        step={5}
                        value={[Min_rate, Max_rate]}
                        max={10000}
                        onInput={handleSliderChange}
                      />
                      <div className="d-flex justify-content-between mt-1">
                        <p className="mb-0 range_price ">${Min_rate}</p>
                        <p className="mb-0 range_price ">${Max_rate}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="dropdown addDropdown   chnagesg  d-flex align-items-center equal-width-50 w-100 ">
                      <button
                        className="btn blck-border-btn dropdown-toggle mb-0 "
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Add Columns
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {findUniqueElements(visibleColumns, columns).map((itm) => {
                          return (
                            <a
                              className={"dropdown-item"}
                              onClick={() => handleColumnToggle(itm)}
                            >
                              {replaceUnderscoresWithSpace(itm)}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="col-sm-6 sm-md-0 px-0">
                <input
                  className="form-control "
                  placeholder="Serach..."
                  type="search"
                  value={filters.search}
                  name="search"
                  onChange={e => Handlefilter(e)}
                />
              </div> */}

              {/* {filters.status || filters.role ? (
              <>
                <a
                  className="btn btn-primary text-white mr-2"
                  onClick={(e) => reset()}
                >
                  Reset
                </a>
              </>
            ) : (
              <></>
            )} */}

              {/* <ul class="nav nav-tabs rangetabs mt-2 ml-2">
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'Active' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('Active')
                  ChangeStatus('')
                  history.push('/bids2')

                  ChangeShipmentStatus('')
                }} href="#"><span className="d-flex align-items-center"> <IoListCircleOutline /> <span>All</span></span> </a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'pending' ? 'active ' : ''}`} aria-current="page" onClick={() => {
                  setTabs('pending')
                  history.push('/pending-bids2')


                  ChangeStatus('pending')
                }} href="#"><span className="d-flex align-items-center"> <MdOutlinePending /> <span>Pending</span></span> </a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'accepted' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('accepted')
                  ChangeStatus('accepted')
                  history.push('/accepted-bids2')

                  // ChangeShipmentStatus('accepted')
                }} href="#"><span className="d-flex align-items-center"> <IoListCircleOutline /> <span>Accepted</span></span> </a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'awarded' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('awarded')
                  ChangeStatus('awarded')
                  history.push('/awarded-bids2')
                  // ChangeShipmentStatus('')

                }} href="#"><span className="d-flex align-items-center"><FaAward />
                    <span>Awarded</span></span></a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'rejected' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('rejected')
                  history.push('/rejected-bids')

                  ChangeStatus('rejected')
                  // ChangeShipmentStatus('accepted')
                }} href="#"><span className="d-flex align-items-center"> <IoListCircleOutline /> <span>Rejected</span></span> </a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'in_transit' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('in_transit')
                  // ChangeStatus('')
                  ChangeShipmentStatus('in_transit')
                  history.push('/intransit-bids2')


                }} href="#"><span className="d-flex align-items-center"> <BiTransfer /> <span>In Transit</span></span></a>
              </li>
              <li class="nav-item ">
                <a class={`nav-link ${tabs == 'delivered' ? 'active' : ''}`} aria-current="page" onClick={() => {
                  setTabs('delivered')
                  // ChangeStatus('')
                  history.push('/delivered-bids2')

                  ChangeShipmentStatus('delivered')

                }} href="#"><span className="d-flex align-items-center"><BsCheck2 /> <span>Delivered</span></span></a>
              </li>

            </ul> */}
            </article>
          </div>
        </div>

        {tab == "grid" ? (
          <>
            <div className="cardList">
              <div className="row">
                {!loaging &&
                  data &&
                  data.map((itm, i) => {
                    return (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div className="new_cards">
                          <div className="user_card">
                            <div
                              className="user_detail"
                              onClick={(e) => view(itm.id)}
                            >
                              <img
                                src={methodModel.userImg(itm.image)}
                                className="user_imgs"
                              />

                              <div className="user_name">
                                <h4 className="user">{itm.fullName || "--"}</h4>
                                <p className="user_info">{itm.email || "--"}</p>
                              </div>
                            </div>

                            <div
                              className={`user_hours ${itm.status}`}
                              onClick={() => statusChange(itm)}
                            >
                              <span className="contract">
                                {itm.status || "--"}
                              </span>
                            </div>
                          </div>

                          <div className="user_proff user_proff1">
                            <div className="id_name">
                              <ul className="user_list">
                                <li className="list_name">
                                  <a className="id">Role</a>
                                </li>
                                <li className="list_name">
                                  <a className="id">Phone number</a>
                                </li>
                              </ul>
                            </div>
                            <div className="detail_list">
                              <ul className="user_list">
                                <li className="list_names">
                                  <a
                                    className="id_name"
                                    onClick={(e) => edit(itm.id)}
                                  >
                                    {itm.role?.name}
                                  </a>
                                </li>
                                <li className="list_names">
                                  <a
                                    className="id_name"
                                    onClick={(e) => edit(itm.id)}
                                  >
                                    <span className="call_icon"></span>
                                    {itm.mobileNo ? (
                                      <>
                                        <i
                                          class="fa fa-phone"
                                          aria-hidden="true"
                                        ></i>
                                        {itm.dialCode} {itm.mobileNo}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive table_section new-table">
              <table class="table">
                <thead className="table_head">
                  <tr className="heading_row">
                    <th
                      scope="col"
                      className="table_data"
                      onClick={(e) =>
                        filters?.sortBy == "load_id asc"
                          ? sorting("load_id desc", "desc")
                          : sorting("load_id asc", "asc")
                      }
                    >
                      <span className="d-flex align-items-center">
                        {" "}
                        Load ID /Lane ID
                        {filters?.sortBy === "load_id asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="table_data pointer"
                      onClick={(e) =>
                        filters?.sortBy == "carrier_name asc"
                          ? sorting("carrier_name desc", "desc")
                          : sorting("carrier_name asc", "asc")
                      }
                    >
                      {/* Bidder Name{" "}
                    <i
                      class="fa fa-sort"
                      aria-hidden="true"
                    ></i> */}
                      <span className="d-flex align-items-center">
                        {" "}
                        Bidder Name{" "}
                        {filters?.sortBy === "carrier_name asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="table_data"
                      onClick={(e) =>
                        filters?.sortBy == "agreed_rate asc"
                          ? sorting("agreed_rate desc", "desc")
                          : sorting("agreed_rate asc", "asc")
                      }
                    >
                      <span className="d-flex align-items-center">
                        {" "}
                        Bid Price ($){" "}
                        {filters?.sortBy === "agreed_rate asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    {tabs == "awarded" || "Active" ? (
                      <th scope="col" className="table_data ">
                        USPS Margin
                      </th>
                    ) : null}

                    <th
                      scope="col"
                      className="table_data "
                      onClick={(e) =>
                        filters?.sortBy == "expiration_date asc"
                          ? sorting("expiration_date desc", "desc")
                          : sorting("expiration_date asc", "asc")
                      }
                    >
                      Expiration Date{" "}
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th scope="col" className="table_data ">
                      Stops
                    </th>
                    {visibleColumns.map((item) => (
                      <th className="text-capitalize table_data">
                        {replaceUnderscoresWithSpace(item)}{" "}
                        <i
                          className="fa fa-times"
                          onClick={(e) => handleColumnToggle(item)}
                        ></i>{" "}
                      </th>
                    ))}

                    {tabs == "delivered" ||
                      tabs == "in_transit" ||
                      tabs == "rejected" ? null : (
                      <th scope="col" className="table_data ">
                        Delivery Status
                      </th>
                    )}
                    {tabs == "Active" && (
                      <th scope="col" className="table_data ">
                        Bid Result
                      </th>
                    )}
                    <th scope="col" className="table_data">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loaging &&
                    ListingData &&
                    ListingData.map((itm, i) => {
                      return (
                        <>
                          <tr
                            className={`data_row ${itm.overtime ? "overtime" : ""
                              }`}
                          >
                            <td className="table_dats">
                              <span onClick={() => {
                                if(Permission?.load_get || user?.role=="admin")
                                {
                                  Navigate.push(
                                    "loads/view/" + itm?.load_id
                                  );
                                }
                              
                              }

                              }>{itm?.load_info?.load_id ||
                                itm?.load_info?.lane_id}</span>

                            </td>
                            <td
                              className="table_dats pointer"
                              onClick={() => {
                                history.push(
                                  `/bids/view/${itm?.id}/${pathname == "/pending-bids2"
                                    ? "Pending Bids"
                                    : pathname == "/accepted-bids"
                                      ? "Accepted Bids"
                                      : pathname == "/awarded-bids"
                                        ? "Awarded Bids"
                                        : pathname == "/intransit-bids"
                                          ? " Intransit Bids"
                                          : pathname == "/delivered-bids"
                                            ? " Delivered Bids"
                                            : pathname == "/rejected-bids"
                                              ? "Rejected Bids"
                                              : "Bids"
                                  } `
                                );
                              }}
                            >
                              {methodModel.capitalizeFirstLetter(
                                itm?.carrier_name
                              ) || "--"}
                            </td>
                            <td className="table_dats">${itm?.agreed_rate}</td>
                            {tabs == "awarded" || "Active" ? (
                              <td className="table_dats">
                                <span className="d-flex gap-2 align-items-center">
                                  {" "}
                                  <span className="amospan">
                                    {" "}
                                    {itm?.usps_amount || "--"}
                                  </span>
                                  <a class="edit_icon" title="Edit">
                                    {itm?.status == "pending" ||
                                      itm?.status == "accepted" ||
                                      (itm?.status == "awarded" &&
                                        itm?.shipment_status !== "delivered") ? (
                                      <i
                                        onClick={() => {
                                          setID(itm);
                                          setUpdateModal("flex");
                                        }}
                                        class="material-icons edit onedit"
                                        title="Edit"
                                      >
                                        edit
                                      </i>
                                    ) : null}
                                  </a>
                                </span>
                              </td>
                            ) : null}
                            {/* <td className="table_dats">
                              {ConvertStringToAbbrivate(
                                itm?.load_info?.origin_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {ConvertStringToAbbrivate(
                                itm?.load_info?.origin_location_state
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.origin_location_street?.substr(
                                  0,
                                  20
                                )
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {ConvertStringToAbbrivate(
                                itm?.load_info?.destination_location_city
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {ConvertStringToAbbrivate(
                                itm?.load_info?.destination_location_state
                              ) || "--"}
                            </td>
                            <td className="table_dats">
                              {methodModel.capitalizeFirstLetter(
                                itm?.load_info?.destination_location_street?.substr(
                                  0,
                                  20
                                )
                              ) || "--"}
                            </td> */}

                            <td className="table_dats">
                              {/* {datepipeModel.isotodate(itm?.expiration_date)} */}
                              {moment(itm?.expiration_date).format(
                                "DD-MMM-YYYY, h:mm a"
                              )}
                            </td>
                            <td className="table_dats">
                              <div
                                className="stops-down-arrow"
                                onClick={() => toggle(i)}
                              >
                                All Stops
                                <i class="fas fa-arrow-circle-down ms-2"></i>
                              </div>
                              {/* <Stops stop={itm?.stops_details} data={itm} /> */}

                            </td>
                            {visibleColumns.map((item, index) => (
                              <td className="text-capitalize">
                                {ColumnReturner(item, itm[[item]], itm)}
                              </td>
                            ))}


                            {tabs == "delivered" ||
                              tabs == "in_transit" ||
                              tabs == "rejected" ? null : (
                              <td className="table_dats statusdivs">
                                <span className={`${itm?.shipment_status}`}>
                                  {ReplaceUnderScoreWithSpace(
                                    itm?.shipment_status
                                  )}
                                </span>
                              </td>
                            )}
                            {tabs == "Active" && (
                              <td className="table_dats">
                                {" "}
                                <div className={` ${itm.status}`}>
                                  <span className="">
                                    {methodModel.capitalizeFirstLetter(
                                      itm.status
                                    ) || "--"}
                                  </span>
                                </div>
                              </td>
                            )}
                            {/* dropdown */}
                            <td className="table_dats">
                              <div className="action_icons action-icons-main">
                                {itm?.status == "pending" && (
                                  <>
                                    {user?.role == "admin" ||
                                      Permission?.bid_edit ? (
                                      <Tooltip placement="top" title="Accept">
                                        <span
                                          className="edit_icon"


                                          onClick={() => {
                                            setDeleteId(itm?.id);
                                            setShowActiveModal("block");

                                            // deleteLoad(itm?.id)
                                          }}
                                        >
                                          <i
                                            class="fa fa-check color-green"
                                            title="Accept"
                                          ></i>
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                    {user?.role == "admin" ||
                                      Permission?.bid_edit ? (
                                      <Tooltip placement="top" title="Reject">
                                        <span
                                          className="edit_icon"

                                          onClick={() => {
                                            setDeleteId(itm?.id);
                                            setShowRejectModal("block");
                                          }}
                                        >
                                          <i
                                            color="red"
                                            class="fa fa-times colorred "
                                            title="Reject"
                                          ></i>
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </>
                                )}
                                {itm?.status == "accepted" &&
                                  itm?.status != "awarded" &&
                                  itm?.shipment_status == "pending" && (
                                    <>
                                      {user?.role == "admin" ||
                                        Permission?.bid_edit ? (
                                        <>
                                          <Tooltip title={"Award this"}>
                                            <span
                                              className="edit_icon awardic"
                                              onClick={() => {
                                                setshowAwardModal("flex");
                                                setDeleteId(itm?.id);
                                              }}
                                            >
                                              <FaAward />
                                            </span>
                                          </Tooltip>

                                          <Tooltip title={"Reject"}>
                                            <span
                                              className="edit_icon awardic"
                                              onClick={() => {
                                                setDeleteId(itm?.id);
                                                setShowRejectModal("block");
                                              }}
                                            >
                                              <i
                                                color="red"
                                                class="fa fa-times colorred"
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                {/* {user?.role == "admin" ||
                              Permission?.carrier_get ? ( */}
                                {user?.role == "admin" ||
                                  Permission?.bid_get ? (
                                  <Tooltip placement="top" title="View">
                                    <span
                                      className="edit_icon "

                                      onClick={() => {
                                        // if (
                                        //   Permission?.carrier_get ||
                                        //   user?.role == "admin"
                                        // ) {
                                        Navigate.push(
                                          `bids/view/${itm.id}/${pathname == "/pending-bids"
                                            ? "Pending Bids"
                                            : pathname == "/accepted-bids"
                                              ? "Accepted Bids"
                                              : pathname == "/awarded-bids"
                                                ? "Awarded Bids"
                                                : pathname == "/intransit-bids"
                                                  ? " Intransit Bids"
                                                  : pathname == "/delivered-bids"
                                                    ? " Delivered Bids"
                                                    : pathname == "/rejected-bids"
                                                      ? "Rejected Bids"
                                                      : "Bids"
                                          }`
                                        );
                                        // } else {
                                        //   toast.warn(
                                        //     "You do not have valid permission"
                                        //   );
                                        // }
                                      }}
                                    >
                                      <i className="fa fa-eye"> </i>
                                    </span>
                                  </Tooltip>
                                ) : null}
                                {/* ) : null} */}
                              </div>
                            </td>
                          </tr>
                          <React.Fragment key={i}>
                            {expandedRow === i && (
                              <tr>
                                <td colSpan="17" className="bg-white">
                                  <div className="stops-inn">
                                    <div className="address-fields">
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="origin address">
                                            <h2>Origin Address</h2>
                                            <ul>
                                              <li>
                                                <p>Origin City</p>
                                                <h5>  {ConvertStringToAbbrivate(
                                                  itm?.load_info?.origin_location_city
                                                ) || "--"}</h5>
                                              </li>
                                              <li>
                                                <p>Origin State</p>
                                                <h5> {ConvertStringToAbbrivate(
                                                  itm?.load_info?.origin_location_state
                                                ) || "--"}</h5>
                                              </li>
                                              {/* <li>
                                                <p>Origin Street</p>
                                                <h5> {methodModel.capitalizeFirstLetter(
                                                  itm?.load_info?.origin_location_street?.substr(
                                                    0,
                                                    20
                                                  )
                                                ) || "--"}</h5>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="origin address">
                                            <h2>Destination Address</h2>
                                            <ul>
                                              <li>
                                                <p>Destination City</p>
                                                <h5>    {ConvertStringToAbbrivate(
                                                  itm?.load_info?.destination_location_city
                                                ) || "--"}</h5>
                                              </li>
                                              <li>
                                                <p>Destination State</p>
                                                <h5> {ConvertStringToAbbrivate(
                                                  itm?.load_info?.destination_location_state
                                                ) || "--"}</h5>
                                              </li>
                                              {/* <li>
                                                <p>Destination Street</p>
                                                <h5> {methodModel.capitalizeFirstLetter(
                                                  itm?.load_info?.destination_location_street?.substr(
                                                    0,
                                                    20
                                                  )
                                                ) || "--"}</h5>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="table_dats">

                                      {/* <div onClick={() => view(itm?.id)} className="badge badge-success common-badge">
                                        All Stops
                                      </div> */}

                                      {/* <div className="ellipses">{itm.stops[0]?.address}</div>
                                      <div className="ellipses">{itm.stops[1]?.address}</div> */}
                                      <table className="table">
                                        <thead>
                                          <th>Stop No.</th>
                                          <th>Address</th>
                                          {itm?.status == "awarded" ? <><th>Delivery Status</th>
                                            <th>Reached Time</th></> : <></>}

                                        </thead>

                                        <tbody>
                                          {itm?.stops_details?.map(
                                            (item, index) => {
                                              return (
                                                <tr>
                                                  <td>{index + 1}</td>

                                                  <td>{item?.address}

                                                  </td>
                                                  {itm?.status == "awarded" ? (<td className="statusdivs">
                                                    <>
                                                      {itm?.shipment_status == "in-transit" && (
                                                        <span className="tricon">
                                                          {" "}
                                                          <i
                                                            class="fa fa-truck me-2"
                                                            // title="In-Transit"
                                                            aria-hidden="true"
                                                          ></i>
                                                          <span className="in-transit"> In-Transit</span>

                                                        </span>
                                                      )}
                                                      {itm?.shipment_status == "delivered" ? (

                                                        <span className="tableficon icon-set">
                                                          <i
                                                            class="fa fa-map-marker text-success me-2"
                                                            aria-hidden="true"
                                                          ></i>
                                                          <span className="delivered"> Delivered</span>

                                                        </span>

                                                      ) : (
                                                        "--"
                                                      )}
                                                    </>
                                                  </td>) : null}
                                                  {itm?.status == "awarded" ? (<td>
                                                    <>
                                                      {item?.checkin || item?.checkout ? (
                                                        <span className="tableficon">
                                                          {item?.checkin ? moment(
                                                            item?.checkin
                                                          ).format("DD-MMM-YYYY h:m A") : "--"}
                                                        </span>

                                                      ) : (
                                                        "--"
                                                      )}
                                                    </>
                                                  </td>) : null}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}

        {(!loaging && total == 0 && !loaderr) ? (
          <div className=" no-data">
            <img src="assets/img/no-data.png" />
            No Data
          </div>
        ) : (
          <></>
        )}

        {!loaging && total > 0 ? (
          <div className="paginationWrapper">
            <div className="d-flex align-items-center">
              <div className="me-2 user-name-color">Show</div>
              {total > 0 && (
                <div className="dropdown addDropdown chnagesname ">
                  <button
                    className="btn btn-primary dropdown-toggle removeBg"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {filters?.count}
                  </button>
                  <div
                    className="dropdown-menu shadow bg_hover"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 5 });
                      }}
                    >
                      5
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 10 });
                      }}
                    >
                      10
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 20 });
                      }}
                    >
                      20
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 30 });
                      }}
                    >
                      30
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 40 });
                      }}
                    >
                      40
                    </a>
                  </div>
                </div>
              )}{" "}
              <div className="ms-2 user-name-color">from {total} Bids</div>
            </div>

            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
        {/* <button type="button" id="OpenNotesModal" class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#NoteModal">
        Launch demo modal
      </button> */}
        {/* <div class="modal fade" id="NoteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Shipment Notes</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={(e) => {

              // HandleNoteSubmit(e)
            }}>
              <div class="modal-body documentation_modal">

                <div className="col-md-6 mb-3">
                  <label >Title <label className="text-danger">*</label></label>
                  <input placeholder="Enter Title" value={Notes?.title} type="text" required onChange={(e) => {
                    SetNotes({ ...Notes, title: e.target.value })
                  }} className="form-control mb-2" />
                </div>
                <div className="col-md-6 mb-3 mt-3 ">
                  <label htmlFor="">Description <label className="text-danger">*</label></label>
                  <textarea value={Notes?.description} cols={10} type="text" placeholder="Enter Description" required onChange={(e) => {
                    SetNotes({ ...Notes, description: e.target.value })
                  }} className="form-control mb-2" />
                </div>
                <div className="col-md-6 mb-3 mt-3">
                  <label htmlFor="">New ETA <label className="text-danger">*</label></label>
                  <input type="datetime-local" className="form-control" min={Date.now()} onChange={(e) => {
                    SetNotes({ ...Notes, new_eta: e.target.value })
                  }} />

                </div>

              </div>
              <div class="modal-footer">
                <button type="button" id="CloseNotesModal" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>

          </div>
        </div>
      </div> */}
        {loaging ? (
          <div className="text-center py-4">
            <img src="/assets/img/loader.gif" className="pageLoader" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout2>
  );
};

export default Html;
