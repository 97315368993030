import React, { useEffect, useState } from "react";
import Html from "./html";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import moment from "moment";

const NewsLetter2 = (p) => {
  const [data, setdata] = useState([]);
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const user = useSelector((state) => state.user);
  const [total, setotal] = useState(0);
  const [loaderr ,setLoaderr]=useState(false);
  const [filters, setfilters] = useState({
    page: 1,
    count: 10,
    sorder: "",
  });
  const getdata = (p = {}) => {
    setloading(true);
    setLoaderr(true)
    let filter = { ...filters, ...p };
    ApiClient.get("getAllNewsLetter", filter).then((res) => {
      if (res.success) {
        setloading(false);

        setotal(res?.data?.total);
        setdata(res?.data?.data);
        setLoaderr(false)
      }
      false;
    });
  };

  useEffect(() => {
    getdata();
  }, []);

  const ChangeFilter = (p) => {
    setfilters(p);
    getdata(p);
  };
  const PageChange = (e) => {
    setfilters({ ...filters, page: e });
    getdata({ page: e });
  };
  // const sorting = (key, order) => {
  //   setfilters({ ...filters, sortBy: `${key} ${order}`, sorder: order })
  //   getdata({ sortBy: `${key} ${order}`, sorder: order });
  // };
  const filter = (p = {}) => {
    setfilters({ ...filters, ...p });
    getdata({ ...p, page: filters?.page });
  };
  const sorting = (key, i) => {
    // getData({sortBy})
    filter({ sortBy: key, sorder: i });
  };
  return (
    <Html
      data={data}
      setfilters={setfilters}
      filters={filters}
      sorting={sorting}
      getdata={getdata}
      PageChange={PageChange}
      history={history}
      user={user}
      setloading={setloading}
      loading={loading}
      methodModel={methodModel}
      total={total}
      moment={moment}
      ChangeFilter={ChangeFilter}
      loaderr={loaderr}
    />
  );
};

export default NewsLetter2;
