import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { roleType } from "../../models/type.model";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import environment from "../../environment";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
// import ImageUpload from "../../components/common/ImageUpload";
import addressModel from "../../models/address.model";
import ImageUpload from "../../components/common/ImageUpload";
// import addressModel from "../../models/address.model";
import { IoIosCamera } from "react-icons/io";
import { requestForToken } from "../../firebase/configuration";
import Layout2 from "../../components/global/layout2";

const AddEditRole2 = () => {
  const { id, pageName } = useParams();
  const [PermissionId, setPermissionId] = useState("");
  const [form, setform] = useState(roleType);
  const history = useHistory();
  const [Address, setAddress] = useState("");
  const [permission, setPermission] = useState({});
  const [DeviceToken, setDeviceToken] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "" });
  const [role, setRole] = useState([]);
  const [emailErr, setEmailErr] = useState("");
  const [Boards, setBoards] = useState([]);
  const [editCheck, setEditTrue] = useState(true);
  const [emailLoader, setEmailLoader] = useState(false);
  const [documents, setdocument] = useState({
    documentType: "Registration",
    documentNumber: "",
    issueDate: "",
    expirationDate: "",
    documents: "",
  });
  const [truck_info, setinfo] = useState({
    truck_id: null,
    brand: "",
    model: "",
    year: null,
  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const requestPermission = async () => {
    await Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });
        } else if (permission == "denied") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });

          // alert("You denied Notification permission.");
        }
      })
      .catch((error) => {
        console.error("Error while requesting notification permission:", error);
      });
  };


  useEffect(() => {
    if (form?.trailer_type) {
      setSelectedTypes(form.trailer_type);
    }
  }, [form?.trailer_type]);

  const handleTrailertypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedTypes([...selectedTypes, value]);
    } else {
      setSelectedTypes(selectedTypes.filter((type) => type !== value));
    }
  };

  const defaultvalue = () => {
    let keys = { ...roleType };
    Object.keys(roleType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    Object.keys(roleType.permissions).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = "active";
    return keys;
  };

  const emailvalidation=()=>
  { 
    if(form?.email)
    {
     let splitEmail=form?.email?.split("@")[1]
     if(splitEmail && (splitEmail.includes("yahoo.com")||splitEmail.includes("gmail.com")||splitEmail.includes("outlook.com")||splitEmail.includes("hotmail.com")))
      {
        return false
      }
      else
      {
        return true
      }
    }
    
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);   
    if (!id) {
      if (
        
        !emailvalidation()||
        !form?.address ||
        !form?.team_truck ||
        !form?.solo_truck ||
        !form?.trailer_type ||
        !form?.email ||
        !form?.role ||
        selectedTypes?.length == 0 ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        // !form?.identification_number ||
        // !form?.total_trucks ||
        !form?.trailers_number ||
        !form?.company_name ||
        // form?.board_id?.length <= 0 ||
        !form?.tax_number ||
        !form?.mc_number ||
        !form?.dot_number ||
        !form?.position
      ) {
        return;
      }
    } else {
      if (
    
        !form?.address ||
        !form?.email ||
        selectedTypes?.length == 0 ||
        !form?.team_truck ||
        !form?.solo_truck ||
        // !form?.trailer_type ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        !form?.country ||
        // !form?.identification_number ||
        // !form?.total_trucks ||
        !form?.trailers_number ||
        !form?.company_name ||
        !form?.board_id ||
        !form?.tax_number ||
        !form?.mc_number ||
        !form?.dot_number ||
        !form.position
      ) {
        return;
      }
    }

    let method = "post";
    let url = "admin/add-user";
    let value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      address: form?.address,
      position: form?.position,
      telephoneNo: form?.telephoneNo,
      telephoneExt: form?.telephoneExt,
      image: form?.image,
      email: form?.email,
      role: form?.role,
      country: form?.country,
      city: form?.city,
      state: form?.state,
      pincode: form?.pincode,
      // identification_number: form?.identification_number,
      company_name: form?.company_name,
      mc_number: form?.mc_number,
      dot_number: form?.dot_number,
      tax_number: form?.tax_number,
      fax_number: form?.fax_number,
      board_id: form?.board_id,
      device_token: DeviceToken,
      // total_trucks:
      //   Number(form.team_truck) +
      //   Number(form?.solo_truck) +
      //   Number(form?.trailers_number),
      trailer_type: selectedTypes,
      team_truck: form?.team_truck,
      solo_truck: form?.solo_truck,
      trailers_number: form?.trailers_number,
      // mc_description: form?.mc_description,
      // dot_description: form?.dot_description,
    };
    if (form?.role == "staff") {
      // if(!form?.permissions?.carrier_add || !form?.permissions?.carrier_delete || !form?.permissions?.carrier_edit || !form?.permissions?.carrier_get){
      //   setform({...form,permissions:{...form?.permissions,carrier_complete_access:false}})
      // }
      setform({
        ...form,
        permissions: {
          ...form?.permissions,
          carrier_complete_access: handlecompleteAccess(),
        },
      });

      value = {
        firstName: form?.firstName,
        lastName: form?.lastName,
        address: form?.address,
        position: form?.position,
        telephoneNo: form?.telephoneNo,
        telephoneExt: form?.telephoneExt,
        image: form?.image,
        email: form?.email,
        country: form?.country,
        city: form?.city,
        state: form?.state,
        role: form?.role,
        pincode: form?.pincode,
        permissions: form?.permissions,
      };
      value = {
        ...value,
        permissions: {
          ...value?.permissions,
          carrier_complete_access: handlecompleteAccess(),
        },
      };
    }

    if (id && form?.role == "staff") {
      method = "put";
      url = "admin/edit-user";
      value = {
        firstName: form?.firstName,
        lastName: form?.lastName,
        address: form?.address,
        position: form?.position,
        telephoneNo: form?.telephoneNo,
        telephoneExt: form?.telephoneExt,
        image: form?.image,
        permissions: form?.permissions,
        id: form?.id,
        country: form?.country,
        city: form?.city,
        state: form?.state,
        pincode: form?.pincode,
        company_name: form?.company_name,
        tax_number: form?.tax_number,
        mc_number: form?.mc_number,
        // dot_number: form?.dot_number,
        //   fax_number: form?.fax_number,
        board_id: form?.board_id,
        // total_trucks:
        //   Number(form.team_truck) +
        //   Number(form?.solo_truck) +
        //   Number(form?.trailers_number),
        trailer_type: selectedTypes,
        trailers_number: form?.trailers_number,
        team_truck: form?.team_truck,
        solo_truck: form?.solo_truck,
        // mc_description: form?.mc_description,
        // dot_description: form?.dot_description,
        // gender: form?.gender,
      };
      value = {
        ...value,
        permissions: {
          ...value?.permissions,
          carrier_complete_access: handlecompleteAccess(),
        },
      };
    } else if (id && form?.role == "carrier") {
      method = "put";
      url = "admin/edit-user";
      value = {
        firstName: form?.firstName,
        lastName: form?.lastName,
        address: form?.address,
        position: form?.position,
        telephoneNo: form?.telephoneNo,
        telephoneExt: form?.telephoneExt,
        image: form?.image,
        id: id,
        country: form?.country,
        // identification_number: form?.identification_number,
        city: form?.city,
        state: form?.state,
        pincode: form?.pincode,
        fax_number: form?.fax_number,
        tax_number: form?.tax_number,
        company_name: form?.company_name,
        board_id: form?.board_id,
        trailer_type: selectedTypes,
        team_truck: form?.team_truck,
        solo_truck: form?.solo_truck,
        mc_number: form?.mc_number,
        dot_number: form?.dot_number,
        // total_trucks:
        //   Number(form.team_truck) +
        //   Number(form?.solo_truck) +
        //   Number(form?.trailers_number),
        trailers_number: form?.trailers_number,
        // mc_description: form?.mc_description,
        // dot_description: form?.dot_description,
        // gender: form?.gender,
      };
    } else {
      delete value.id;
    }
    delete value?.confirmPassword;
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
      }
      loader(false);
    });
  };

  const handleCheckboxChange = (e, id) => {
    const checked = e.target.checked;
    if (checked) {
      setform((prevState) => ({
        ...prevState,
        board_id: prevState.board_id?.length?[...prevState.board_id, id]:[id],
      }));
    } else {
      setform((prevState) => ({
        ...prevState,
        board_id: prevState.board_id.filter((boardId) => boardId !== id), // Remove id from array
      }));
    }
  };

  const GetBoards = () => {
    ApiClient.get("boards", { status: "active" }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    requestPermission();
    GetBoards();
  }, []);
  const setpermission = (key, value) => {
    if (id) {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          id: PermissionId,
          [key]: value,
        },
      });
    } else {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          [key]: value,
        },
      });
      setPermission({
        ...permission,
        [key]: value,
      });
    }
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        setPermission({
          id: res?.data?.id,
          carrier_add: res?.data?.carrier_add,
          carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
        });
        setPermissionId(res?.data?.id);
        // setform({...form,permissions:res?.data})
      }
    });
  };

  useEffect(() => {
    if (id) {
      setform({ ...form, permissions: permission });
    }
  }, [permission]);

  useEffect(() => {
    if (id) {
      setform({});
      loader(true);
      ApiClient.get("user/detail", { id }).then((res) => {
        if (res.success) {
          let value = res?.data;
     
          setAddress(res?.data?.address);
          setform({
            ...form,
            id: res?.data?.id,
            firstName: res?.data.firstName,
            lastName: res?.data?.lastName,
            position: res?.data?.position,
            telephoneNo: res?.data?.telephoneNo,
            telephoneExt: res?.data?.telephoneExt,
            address: res?.data?.address,
            email: res?.data?.email,
            image: res?.data?.image,
            role: res?.data?.role,
            gender: res?.data?.gender,
            state: res?.data?.state,
            pincode: res?.data?.pincode,
            country: res?.data?.country,
            city: res?.data?.city,
            identification_number: res?.data?.identification_number,
            tax_number: res?.data?.tax_number,
            fax_number: res?.data?.fax_number,
            mc_number: res?.data?.mc_number,
            dot_number: res?.data?.dot_number,
            board_id: res?.data?.board_id,
            total_trucks: res?.data?.total_trucks,
            trailer_type: res?.data?.trailer_type,
            trailers_number: res?.data?.trailers_number,
            team_truck: res?.data?.team_truck,
            solo_truck: res?.data?.solo_truck,
            company_name: res?.data?.company_name,
            // mc_description: res?.data?.mc_description,
            // dot_description: res?.data?.dot_description,

            telephoneNo: res?.data?.telephoneNo,
            telephoneExt: res?.data?.telephoneExt,
          });
          setinfo(res?.data?.truck_information);
          setdocument(res?.data?.vehicle_documents);
          if (res?.data?.addedBy != null) {
            setEditTrue(true);
          }
          if (res?.data?.role == "staff") {
            getPermission();
          }
          let payload = {
            id: "",
            name: "",
            status: "active",
            permissions: {
              //  Done
              carrier_delete: false,
              carrier_get: false,
              carrier_complete_access: false,
              carrier_edit: false,
              carrier_add: false,
            },
          };

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });
          payload.loginPortal = value.loginPortal;
          if (value.permissions) {
            payload.permissions = value.permissions;
            // payload.permissions={ ...payload.permissions,...value.permissions}
          }
          

          // setform({
          //   ...payload,
          // });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  const handleAdminUser = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        carrier_edit: value,
        carrier_get: value,
        carrier_delete: value,
        carrier_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };
  useEffect(() => {}, []);
  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = roleType.permissions;
    Object.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({ ...form, permissions: permissions });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = {
      carrier_add: form.permissions?.carrier_add,
      carrier_delete: form?.permissions?.carrier_delete,
      carrier_edit: form?.permissions?.carrier_edit,
      carrier_get: form?.permissions?.carrier_get,
    };
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    });
    return value;
  };

  const handlecompleteAccess = () => {
    let result = false;
    if (
      form?.permissions?.carrier_add &&
      form?.permissions?.carrier_delete &&
      form?.permissions?.carrier_edit &&
      form?.permissions?.carrier_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };
  const arry = [
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
    1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
    2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
  ];
  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_get: value },
    });
    isAllChecked();
  };
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_add: value },
    });
    isAllChecked();
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_edit: value },
    });
    isAllChecked();
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: { ...form.permissions, carrier_delete: value },
    });
    isAllChecked();
  };

  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }
    setAddress(e.value);
    setform({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  useEffect(() => {
    if (id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (!id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    } else if (!id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    }
  }, [param1]);

  // Function to convert file data to a Blob and display it as an image
  const ConvertFileDataToBlobURL = (data) => {
    // Create a new FileReader object
    const reader = new FileReader();

    // When the FileReader has loaded the file as a data URL
    reader.onload = (event) => {
      // Create a new image element
      const imageElement = document.createElement("img");

      // Set the data URL as the source of the image element
      imageElement.src = event.target.result;

      // Append the image element to the document body or any other element to display it
      document.body.appendChild(imageElement);
    };

    // Read the file data as a data URL
    reader.readAsDataURL(data);
  };

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    // ConvertFileDataToBlobURL(file);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setform({ ...form, image: image, baseImg: "" });
        } else {
          setform({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const imageResult = (e, key) => {

    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: images?.image });
    
  };

  useEffect(() => {
   
  }, [form]);

  const setvalue = (e) => {
    var regex = new RegExp("^[a-zA-Z0-9]*$");
    var key = e.target.value;
    if (!regex.test(key.replaceAll(" ", ""))) {
      e.preventDefault();
      return false;
    } else {
      setform({
        ...form,
        company_name: e.target.value,
      });
    }
  };

  return (
    <>
      <Layout2 title={pageName} title2="Staff">
        <div className="mainareas">
          <form onSubmit={handleSubmit}>
            <div className=" edit-page-common new-edit edit-page-commons">
              <div className="row">
                <div className="col-lg-12">
                  <div class=" title-head">
                    <h3
                      className="ViewUseraa mb-3 user-back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left me-2 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                      {id ? "Edit" : "Add"} Carriers
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-3 height-set">
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">Profile</h5>
                  </div>
                  <div class=" white-bg-main mb-4 profile-sect-n">
                    <div className="imagesRow  mb-3">
                      <div className="imagethumbWrapper">
                        <img
                          src={
                            form?.image
                              ? `${environment.api}images/users/${form?.image}`
                              : "/assets/img/person.jpg"
                          }
                          className="thumbnail"
                        />
                        <div className="upload-img-icon">
                          {form?.image ? (
                            <>
                              <label
                                className={`btn  `}
                                onClick={() => {
                                  setform({ ...form, image: "" });
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </label>{" "}
                            </>
                          ) : (
                            <label className={`btn  `}>
                              <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                              <IoIosCamera />
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9">
                  <div className="row">
                    <div className="col-lg-6 height-set">
                      <div class=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div class=" white-bg-main mb-4">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label>
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.firstName}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    firstName: e.target.value,
                                  })
                                }
                                // required
                              />
                            </div>
                            {submitted && !form.firstName ? (
                              <div className="invalid-feedback d-block">
                                First Name is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>Last Name</label>

                            <div className="input-new-design">
                              <div className="input-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.lastName}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    lastName: e.target.value,
                                  })
                                }
                                // required
                              />
                            </div>
                          </div>

                          <div class="col-lg-12 ">
                            <div className="row">
                              <div className="col-md-6 mb-3 ">
                                <label> Position</label>

                                <div className="input-new-design">
                                  <div className="input-icon">
                                    <i class="fas fa-hand-point-down"></i>
                                  </div>
                                  <select
                                    name=""
                                    className="form-control"
                                    id=""
                                    value={form?.position}
                                    onChange={(e) => {
                                      setform({
                                        ...form,
                                        position: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="">Select Position</option>
                                    <option value="owner">Owner</option>
                                    <option value="manager">Manager</option>
                                    <option value="accounting_manager">
                                      Account Manager
                                    </option>
                                    <option value="dispatcher">
                                      Dispatcher
                                    </option>
                                  </select>
                                </div>

                                {submitted && !form.position ? (
                                  <div className="invalid-feedback d-block">
                                    Position is Required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label className=" d-block">
                                  Mobile No
                                </label>

                                <div className="w-100  d-inline-flex">
                                  <PhoneInput
                                    value={
                                      form.telephoneExt + "" + form.telephoneNo
                                    }
                                    countryCodeEditable={true}
                                    enableSearch={true}
                                    placeholder=""
                                    country="us"
                                    onChange={(phone, country) => {
                                      let phonenumber = phone.replace(
                                        country.dialCode,
                                        ""
                                      );
                                      // if(phonenumber!=form.mobileNo){
                                      // setform({ ...form, mobileNo: phonenumber })
                                      // }
                                      setform({
                                        ...form,
                                        telephoneExt: country.dialCode,
                                        telephoneNo: phonenumber,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                                {/* {submitted && !form.mobileNo ? (
                        <div className="invalid-feedback d-block">
                          Mobile Number is Required
                        </div>
                      ) : (
                        <></>
                      )} */}
                              </div>

                              {/* <div className="col-md-12 mb-3">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <div className="input-new-design">
                                  <div className="input-icon">
                                    <i class="fas fa-envelope"></i>
                                  </div>
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={form.email}
                                    disabled={id}
                                    onChange={(e) => {
                                      setform({
                                        ...form,
                                        email: e.target.value,
                                      });
                                    }}
                                
                                  />
                                </div>

                                {submitted && !form.email ? (
                                  <div className="invalid-feedback d-block">
                                    Email is Required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div> */}
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="">
                              <label>
                                Email <span className="text-danger">*</span>
                              </label>
                              <div className="input-new-design">
                                <div className="input-icon">
                                  <i class="fas fa-envelope"></i>
                                </div>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={form.email}
                                  disabled={id}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      email: e.target.value,
                                    });
                                  }}
                                  // required
                                />
                              </div>

                              {submitted ?  (
                                <>{!form.email ?<div className="invalid-feedback d-block">
                                  Email is Required
                                </div>:!emailvalidation()?<div className="invalid-feedback d-block">
                                This email domain not allowed. Please use a different email address.
                                </div>:<></>}</>                             
                              ) : (
                                <></>
                              )}
                            </div>

                            {/* {submitted && !form.firstName ? (
                        <div className="invalid-feedback d-block">
                          First Name is Required
                        </div>
                      ) : (
                        <></>
                      )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 height-set">
                      <div class=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div class=" white-bg-main mb-4">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label>
                              Company Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.company_name}
                                // pattern="^[a-zA-Z0-9\\s]+$"
                                // onKeyPress={(e) => {
                                //   var regex = new RegExp("^[a-zA-Z0-9\\s]+$");
                                //   var key = String.fromCharCode(
                                //     !e.charCode ? e.which : e.charCode
                                //   );
                                //   if (!regex.test(key)) {
                                //     e.preventDefault();
                                //     return false;
                                //   }
                                // }}
                                onChange={(e) => setvalue(e)}

                                // required
                              />
                            
                            </div>
                            {submitted && !form.company_name ? (
                                <div className="invalid-feedback d-block">
                                  Company Name is Required
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>Fax Number</label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fa fa-fax"></i>
                              </div>
                              <input
                                type="text"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                className="form-control"
                                value={form.fax_number}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    fax_number: e.target.value,
                                  })
                                }
                                // required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>
                              Tax Number{" "}
                              <label className="text-danger mb-0">*</label>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fa fa-fax"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                value={form.tax_number}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    tax_number: e.target.value,
                                  })
                                }
                                // required
                              />
                            
                            </div>
                            {submitted && !form.tax_number ? (
                                <div className="invalid-feedback d-block">
                                  Tax Number is Required
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>
                              MC# <label className="text-danger mb-0">*</label>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fa fa-hashtag"></i>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                value={form?.mc_number}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    mc_number: e.target.value,
                                  })
                                }
                                // required
                              />
                            
                            </div>
                            {submitted && !form.mc_number ? (
                                <div className="invalid-feedback d-block">
                                  MC# is Required
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>
                              DOT# <label className="text-danger mb-0">*</label>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fa fa-hashtag"></i>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                value={form?.dot_number}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    dot_number: e.target.value,
                                  })
                                }
                                // required
                              />
                            
                            </div>
                            {submitted && !form.dot_number ? (
                                <div className="invalid-feedback d-block">
                                  DOT# is Required
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 height-set">
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">Address</h5>
                  </div>
                  <div class=" white-bg-main mb-4">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>
                          Address<span className="text-danger">*</span>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fas fa-address-book"></i>
                          </div>
                          <GooglePlaceAutoComplete
                            value={form.address}
                            result={addressResult}
                            id="address"
                            placeholder=""
                          />
                        </div>

                        {submitted && !form.address ? (
                          <div className="invalid-feedback d-block">
                            Address is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>
                          City<span className="text-danger">*</span>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fas fa-building"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={form.city}
                            onChange={(e) =>
                              setform({ ...form, city: e.target.value })
                            }
                            // required
                          />
                        </div>

                        {submitted && !form.city ? (
                          <div className="invalid-feedback d-block">
                            City is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>
                          State<span className="text-danger">*</span>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fas fa-building"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={form.state}
                            onChange={(e) =>
                              setform({ ...form, state: e.target.value })
                            }
                            // required
                          />
                        </div>

                        {submitted && !form.state ? (
                          <div className="invalid-feedback d-block">
                            State is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>
                          Country<span className="text-danger">*</span>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fas fa-globe-asia"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={form.country}
                            onChange={(e) =>
                              setform({ ...form, country: e.target.value })
                            }
                            // required
                          />
                        </div>

                        {submitted && !form.country ? (
                          <div className="invalid-feedback d-block">
                            Country is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>
                          Zipcode<span className="text-danger">*</span>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fas fa-map-pin"></i>
                          </div>
                          <input
                            type="numeric"
                            pattern="^[a-zA-Z0-9]+$"
                            onKeyPress={(e) => {
                              var regex = new RegExp("^[a-zA-Z0-9]+$");
                              var key = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                              );
                              if (!regex.test(key)) {
                                e.preventDefault();
                                return false;
                              }
                            }}
                            className="form-control"
                            value={form.pincode}
                            onChange={(e) =>
                              setform({ ...form, pincode: e.target.value })
                            }
                            // required
                          />
                        </div>

                        {submitted && !form.pincode ? (
                          <div className="invalid-feedback d-block">
                            Zipcode is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 height-set">
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">TRUCK DETAIL</h5>
                  </div>
                  <div class=" white-bg-main mb-4">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>
                          Total Trailer <label className="text-danger">*</label>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fa fa-truck"></i>
                          </div>
                          <input
                            type="number"
                            min={0}
                            // pattern="^[a-zA-Z0-9]+$"
                            // onKeyPress={(e) => {
                            //   var regex = new RegExp("^[a-zA-Z0-9]+$");
                            //   var key = String.fromCharCode(
                            //     !e.charCode ? e.which : e.charCode
                            //   );
                            //   if (!regex.test(key)) {
                            //     e.preventDefault();
                            //     return false;
                            //   }
                            // }}
                            className="form-control"
                            value={form.trailers_number}
                            onChange={(e) =>
                              setform({
                                ...form,
                                trailers_number: e.target.value,
                              })
                            }
                            // required
                          />
                        
                        </div>
                        {submitted && !form.trailers_number ? (
                            <div className="invalid-feedback d-block">
                              Trailer Number is Required
                            </div>
                          ) : (
                            <></>
                          )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>
                          Solo Truck <label className="text-danger">*</label>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fa fa-truck"></i>
                          </div>
                          <input
                            min={0}
                            type="NUMBER"
                            className="form-control"
                            value={form.solo_truck}
                            onChange={(e) =>
                              setform({ ...form, solo_truck: e.target.value })
                            }
                            // required
                          />
                         
                        </div>
                        {submitted && !form.solo_truck ? (
                            <div className="invalid-feedback d-block">
                              Solo Truck is Required
                            </div>
                          ) : (
                            <></>
                          )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>
                          Team Truck <label className="text-danger">*</label>
                        </label>
                        <div className="input-new-design">
                          <div className="input-icon">
                            <i class="fa fa-truck"></i>
                          </div>
                          <input
                            min={0}
                            type="number"
                            className="form-control"
                            value={form.team_truck}
                            onChange={(e) =>
                              setform({ ...form, team_truck: e.target.value })
                            }
                          />
                          
                        </div>
                        {submitted && !form.team_truck ? (
                            <div className="invalid-feedback d-block">
                              Team Truck is Required
                            </div>
                          ) : (
                            <></>
                          )}
                      </div>
                      <div className="col-xl-6 mb-3">
                        <label>
                          Trailer Type <label className="text-danger">*</label>
                        </label>
                        <div className="d-flex trailer-bx">
                          <div className="width-49">
                            <label>
                              <input
                                type="checkbox"
                                value="dry_van"
                                checked={selectedTypes.includes("dry_van")}
                                onChange={handleTrailertypeChange}
                              />
                              Dry Van
                            </label>
                          </div>

                          <div className="ms-4">
                            <label>
                              <input
                                type="checkbox"
                                value="reefer"
                                checked={selectedTypes.includes("reefer")}
                                onChange={handleTrailertypeChange}
                              />
                              Reefer
                            </label>
                          </div>
                          {/* Add more checkboxes for other types if needed */}
                        </div>
                        {submitted && selectedTypes?.length == 0 ? (
                          <div className="invalid-feedback d-block">
                            Trailer Type is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-xl-12 mb-3">
                        <label>
                          Select Board
                        </label>
                        <div class="select-board ">
                          <div className="d-flex">
                            {Boards?.map((itm) => {
                              return (
                                <div key={itm.id} className="me-4">
                                  <input
                                    type="checkbox"
                                    id={itm.id}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, itm.id)
                                    }
                                    checked={form?.board_id?.includes(itm.id)}
                                  />
                                  <label htmlFor={itm.id} className="mb-0">
                                    {methodModel.capitalizeFirstLetter(
                                      itm?.name
                                    )}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* {submitted && form.board_id?.length <= 0 ? (
                          <div className="invalid-feedback d-block">
                            Board is Required
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right">
                {
                  <button
                    type="submit"
                    className="btn btn-primary text-end d-block btn-end-save ml-auto"
                    onClick={() => {
                      setSubmitted(true);
                    }}
                  >
                    Save
                  </button>
                }
              </div>
            </div>
          </form>
        </div>
      </Layout2>
    </>
  );
};

export default AddEditRole2;
