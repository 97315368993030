import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Chart from "react-google-charts";
import pipeModel from "../../models/pipeModel";
import Layout2 from "../../components/global/layout2";
import { useSelector } from "react-redux";
function CarrierPerformance2() {
  const [CarriersData, setCarrierData] = useState([]);
  const [CarriersGraphData, setCarrierGraphData] = useState([]);
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [filters, setfilters] = useState({ search: "" });
  const [DelayedData, setDelayedData] = useState([]);
  const [View, setView] = useState("Table");
  const [carrierPerformace, setCarrierPerformance] = useState([]);
  const [carrierPerformaceGraph, setCarrierPerformanceGraph] = useState([]);
  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      Getdata({ search: searchState.data });
      getCarrierPerformace({ search: searchState.data });
    }
  }, [searchState]);
  const Getdata = (p = {}) => {
    loader(true);
    let f = { ...filters, ...p };
    ApiClient.get("performance/carrier/all-awarded", f).then((res) => {
      if (res.success) {
        setCarrierData(res?.data);
        setCarrierGraphData(
          res?.data.length > 0
            ? res?.data
            : [
                {
                  carrier_name: "",
                  totalBids: 0,
                  awardedBids: 0,
                  percentage: 0,
                },
              ]
        );
        loader(false);
      }
      loader(false);
    });
  };

  const GetDelayedData = () => {
    loader(true);
    ApiClient.get("performance/carrier/delay-in-delivered").then((res) => {
      if (res.success) {
        setDelayedData(res?.data);
      }
    });
  };

  const CarrierGraphData = [
    ["Carrier", "Total Bids", "Awarded Bids", "Percentage"],
    // ...((CarriersData?.length > 0 &&
    ...CarriersGraphData?.map((item) => {
      return [
        `${methodModel.capitalizeFirstLetter(item?.carrier_name)}`,
        item?.totalBids,
        item?.awardedBids,
        item?.percentage,
      ];
    }),
    // ) || [
    //   ["Element", "Density", { role: "style" }],
    //   ["Copper", 8.94, "#b87333"], // RGB value
    //   ["Silver", 10.49, "silver"], // English color name
    //   ["Gold", 19.3, "gold"],
    //   ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    // ]),
  ];
  const DelayedTimeGraphData = [
    [
      "Carrier",
      // "Delayed Time",
      "Total Delivered Loads",
      "Delayed Loads",
      "Percentage",
    ],
    // ...((carrierPerformace?.length > 0 &&
    ...carrierPerformaceGraph?.map((item) => {
      return [
        `${methodModel.capitalizeFirstLetter(item?.carrier_name)}`,
        // item?.total_delay_time,
        Number(item?.total_delivered_loads || 0),
        Number(item?.total_delayed_loads || 0),
        Number(item?.on_time_delivery_rate || 0),
      ];
    }),
    // ) || [
    //   ["Element", "Density", { role: "style" }],
    //   ["Copper", 8.94, "#b87333"], // RGB value
    //   ["Silver", 10.49, "silver"], // English color name
    //   ["Gold", 19.3, "gold"],
    //   ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    // ]),
  ];

  const getCarrierPerformace = (p = {}) => {
    let f = { ...filters, ...p };
    ApiClient.get("performance/carrier", f).then((res) => {
      if (res.success) {
        let data = res.data;
        setCarrierPerformance(data);
        setCarrierPerformanceGraph(
          data.length > 0
            ? data
            : [
                {
                  carrier_name: "",
                  total_delivered_loads: 0,
                  total_delayed_loads: 0,
                  on_time_delivery_rate: 0,
                },
              ]
        );
      }
    });
  };

  useEffect(() => {
    Getdata();
    GetDelayedData();
    getCarrierPerformace();
  }, []);
  return (
    <>
      <Layout2
        title="Carrier Performance"
        Sidetitle="Performance Matrix"
        searchShow="true"
      >
        <div className="mainareas stats-page">
          <div className="row d-flex align-items-center">
            <div className="d-flex justify-content-between">
              {/* <h3 className="hedding">Carrier Performance</h3> */}

              <div className="d-flex justify-content-end  w-100">
                <div className="dropdown addDropdown mt-0">
                  <button
                    className="btn btn-primary dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    View: {View}
                  </button>
                  <div
                    className="dropdown-menu shadow bg_hover"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className={` ${
                        View == "Table"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }  `}
                      onClick={() => {
                        setView("Table");
                      }}
                    >
                      Table
                    </a>

                    <a
                      className={` ${
                        View == "Graph"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }  `}
                      onClick={() => {
                        setView("Graph");
                      }}
                    >
                      Graph
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-9 head_cls">
            <h3 className="hedding">Carrier Performance</h3>
            <p className="paraCls"></p>
          </div>
          <div className="d-flex gap-2 ml-auto justify-content-end flex-wrap align-items-center">
            <div className="dropdown addDropdown mt-0">
              <button
                className="btn btn-primary dropdown-toggle "
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                View: {View}
              </button>
              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={` ${
                    View == "Table" ? "dropdown-item active" : "dropdown-item"
                  }  `}
                  onClick={() => {
                    setView("Table");
                  }}
                >
                  Table
                </a>

                <a
                  className={` ${
                    View == "Graph" ? "dropdown-item active" : "dropdown-item"
                  }  `}
                  onClick={() => {
                    setView("Graph");
                  }}
                >
                  Graph
                </a>
              </div>
            </div>
          </div> */}
            {View == "Table" ? (
              <div className=" mt-4">
                <h5 className="mb-3">Awarded Percentage</h5>
                <div className="table-responsive postings_table">
                  {" "}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Carrier</th>
                        <th>Total Bids</th>
                        <th>Awarded Bids</th>
                        <th>Load Acceptance Rate</th>

                        <th>Awarded Percentage</th>
                        <th>Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CarriersData.length > 0 ? (
                        CarriersData?.map((itm, i) => {
                          return (
                            <tr>
                              <td>
                                {methodModel.capitalizeFirstLetter(
                                  itm?.carrier_name
                                )}
                              </td>
                              <td>{itm?.totalBids}</td>
                              <td>{itm?.awardedBids}</td>
                              <td>{itm?.averageBidRate?.toFixed()}</td>

                              <td>{itm?.percentage?.toFixed()}%</td>
                              <td>
                                <i className="fa fa-star"></i>{" "}
                                {itm.avg_rating?.toFixed(1)} (
                                {itm.total_reviews})
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                        <td colSpan="6" className="p-0">
                        <div className=" no-data">
                          <img src="assets/img/no-data.png" />
                          No Data
                        </div>
                        </td>
                        </tr>
                      )}

                      {/* <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="col-xl-6 mt-2">
                <div className="graph_section">
                  <div className="graph_range mb-3">
                    <div>
                      <h3 className="Amount_sales">Awarded Percentage</h3>
                    </div>
                  </div>

                  <div>
                    {CarrierGraphData?.length?<>
                      <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="350px"
                      data={CarrierGraphData}
                      options={{
                        colors: ["#494f9f", "#4bc0bf"],
                        annotations: {
                          alwaysOutside: true,
                        },
                        legend: { position: "top" },
                        vAxis: {
                          format: "0", // Display integers only on the vertical axis
                        },
                      }} //
                    />
                    </>:<></>}
                   
                  </div>
                </div>
              </div>
            )}

            {/* {View == "Table" ? (
            <div className="container mt-4">
              <h5>Delayed And Delivered</h5>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Carrier</th>
                      <th>Total Delivered Loads</th>
                      <th>Delayed Loads</th>
                      <th>Delayed Time (Hours)</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DelayedData?.map((itm, i) => {
                      return (
                        <tr>
                          <td>
                            {methodModel.capitalizeFirstLetter(
                              itm?.carrier_name
                            )}
                          </td>
                          <td>{itm?.total_loads}</td>
                          <td>{itm?.delayed_loads}</td>
                          <td>{itm?.total_actual_delivery_time?.toFixed(1)}</td>

                          <td>{itm?.average_percentage_delay?.toFixed()}%</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-12 mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Delayed Loads</h3>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={DelayedTimeGraphData}
                    options={{
                      colors: ["#fc466b", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
          )} */}

            {View == "Table" ? (
              <div className="  mt-4">
                <h5 className="my-3">On-Time Delivery and Delays</h5>
                <div className="table-responsive postings_table">
                  {" "}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Carrier</th>
                        <th>Total Delivered Loads</th>
                        <th>Delayed Loads</th>
                        <th>Delayed Time</th>
                        <th>On Time Delivery</th>
                      </tr>
                    </thead>
                    <tbody>
                      {carrierPerformace.length > 0 ? (
                        carrierPerformace?.map((itm, i) => {
                          return (
                            <tr>
                              <td>
                                {methodModel.capitalizeFirstLetter(
                                  itm?.carrier_name
                                )}
                              </td>
                              <td>{itm?.total_delivered_loads}</td>
                              <td>{itm?.total_delayed_loads}</td>
                              <td>
                                {itm?.total_delay_time?.toFixed(1)}{" "}
                                {itm.isMinutes ? "minutes" : ""}{" "}
                                {itm.isHours ? "hours" : ""}
                              </td>
                              <td>{itm?.on_time_delivery_rate?.toFixed()}%</td>
                            </tr>
                          );
                        })
                      ) : (
                       <tr>
                          <td colSpan="6" className="p-0">
                          <div className=" no-data">
                            <img src="assets/img/no-data.png" />
                            No Data
                          </div>
                          </td>
                       </tr>
                      )}

                      {/* <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="col-xl-6 mt-2">
                <div className="graph_section">
                  <div className="graph_range mb-3">
                    <div>
                      <h3 className="Amount_sales">
                        On-Time Delivery and Delays
                      </h3>
                    </div>
                  </div>

                  <div>
                    {DelayedTimeGraphData?.length?<>
                      <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="350px"
                      data={DelayedTimeGraphData}
                      options={{
                        colors: ["#494f9f", "#4bc0bf"],
                        annotations: {
                          alwaysOutside: true,
                        },
                        legend: { position: "top" },
                        vAxis: {
                          format: "0", // Display integers only on the vertical axis
                        },
                      }} //
                    />
                    </>:<></>}
                    
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout2>
    </>
  );
}

export default CarrierPerformance2;
