import React, { useEffect } from "react";
import Layout from "../../../components/global/layout";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import loader from "../../../methods/loader";

const Html = ({ form, handleSubmit, setform, submitted, back }) => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1 edit-page-common p-0">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <h3 className="ViewUser mb-0 user-back pl-3">
                    <Link to="/boards1" className="">
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    {form && form.id ? "Edit" : "Add"} Board
                  </h3>
                </div>
              </div>
            </div>
            <div className="row p-3">
              <div className="col-md-12">
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 class="profilelist">Basic Information</h5>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 mb-3">
                      <label>
                        Board Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={form.name}
                        onChange={(e) =>
                          setform({ ...form, name: e.target.value })
                        }
                      />
                      {submitted && !form.name ? (
                        <div className="invalid-feedback d-block">
                          Name is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Expiration Type<span className="text-danger">*</span>
                      </label>
                      <select
                        // required
                        value={form?.expiration_type}
                        className="form-control"
                        onChange={(e) => {
                          setform({ ...form, expiration_type: e.target.value });
                        }}
                      >
                        <option value="">Select Type</option>
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="days">Days</option>
                      </select>
                      {submitted && !form.expiration_type ? (
                        <div className="invalid-feedback d-block">
                          Expiration Type is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>
                        Expiration Value<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        value={form.expiration_value}
                        onChange={(e) =>
                          setform({ ...form, expiration_value: e.target.value })
                        }
                      />
                      {submitted && !form.expiration_value ? (
                        <div className="invalid-feedback d-block">
                          Expiration Value is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label>Expiry Date</label>
                      <Datetime
                        exclude={moment(Date.now())}
                        value={moment.utc(form?.expiration_date)}
                        onChange={(e) => {
                          setform({
                            ...form,
                            expiration_date: e.toISOString(),
                          });
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right mr-2 mb-2">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
