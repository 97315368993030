import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { useSelector } from "react-redux";
import DateRangeInput from "../../components/common/DateRangeInput";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import moment from "moment";
import loader from "../../methods/loader";
import GaugeComponent from "react-gauge-component";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "antd";
import states from "us-state-codes";
import MapChart from "./StateGraphChart";
import SelectDropdown from "../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import environment from "../../environment";
function Statistics() {
  const user = useSelector((state) => state.user);
  const [StatisticsData, setStatistics] = useState([]);
  const [PostedDuringBuisnessHour, setPostedDuringBuisnessHour] = useState({});
  const [PostedOutsideBuisnessHour, setPostedOutsideBuisnessHour] = useState(
    {}
  );

  const [RavenStatisticsData, setRavenStatistics] = useState([]);
  const [AwardedStatisticsData, setAwardedStatistics] = useState([]);
  const [statisticsBid, setStatisticsBid] = useState();
  const [OutSideHoursData, setOutsideHours] = useState({});
  const [MapMarkers, setMapMarkers] = useState([]);
  const [MapStateData, setMapStateData] = useState([]);
  const [ChartLoading, setChartLoading] = useState(false);
  const [OutSide24HoursData, setOutside24Hours] = useState({});
  const [AverageHourPickData, SetAverageHourPickup] = useState({});
  const [BuisnesData, setBuisnessData] = useState({});
  const [TimetoPick, setTimePickup] = useState([]);
  const [TableHeader, setTableHeader] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [BidSummary, setBidSummary] = useState([]);
  const [BidsData, setBidsData] = useState([]);
  const [BidsBreakDown, setBidsBreakDown] = useState([]);
  const [BidsBreakDownSummary, setBidsBreakDownSummary] = useState([]);
  const [PostedBreakDown, setPostedBreakDown] = useState([]);
  const [PostedBreakDownSummary, setPostedBreakDownSummary] = useState([]);
  const [OutSideHourBreakHeader, setOutSideHourBreakHeader] = useState([]);
  const [PostedOutSideHourBreakHeader, setPostedOutSideHourBreakHeader] =
    useState([]);
  const [BidsPicked, setBidsPicked] = useState([]);
  const [BidsPickedSummary, setBidsPickedSummary] = useState([]);
  const [BidsPickup, setBidsPickup] = useState([]);
  const [BidsPickupHeader, setBidsPickupHeader] = useState([]);
  const [BidsPickupSummary, setBidsPickupSummary] = useState([]);



  const [filters, setFilter] = useState({
    board_id: environment.uspsId,
    start_date: "",
    type: "months",
    value: "1",
    direction: "last",
  });
  const initialLocationData = [
    {
      location: "AL/BIRMINGHAM",
      data: [
        { hour: 0, count: 4 },
        { hour: 1, count: 20 },
        { hour: 2, count: 40 },
        // Add more data objects as needed
      ],
    },
    {
      location: "AL/HUNTSVILLE",
      data: [
        { hour: 0, count: 2 },
        { hour: 1, count: 4 },
        { hour: 2, count: 6 },
      ],
    },
    // Add more location data objects as needed
  ];
  const [locationData, setLocationData] = useState(initialLocationData);
  const [hours, setHours] = useState([]);
  useEffect(() => {
    const allHours = [
      ...new Set(
        locationData.flatMap((location) => location.data.map((d) => d.hour))
      ),
    ].sort();
    setHours(allHours);
  }, [locationData]);

  const GetBidsBreakDownHours = (p) => {
    ApiClient.get("statistics/bid/posted-by-hour-breakdown", { board_id: p?.board_id || '' }).then((res) => {
      if (res.success) {
        let data = res?.data;
        setBidsBreakDown(data);
        const uniqueHours = res?.data?.filter(
          (bid, index, self) =>
            index ===
            self.findIndex(
              (b) => b?.data[0]?._id?.hour === bid?.data[0]?._id?.hour
            )
        );
        setOutSideHourBreakHeader(uniqueHours);
        setBidsBreakDownSummary(res?.totalPercentages);
      }
    });
  };

  const GetBidsPuckupData = () => {
    ApiClient.get("statistics/bid/picked-by-hour-rate").then((res) => {
      if (res.success) {
        let data = res?.data;
        setBidsPickup(data);
        const uniqueHours = res?.data?.filter(
          (bid, index, self) =>
            index ===
            self.findIndex(
              (b) => b?.data[0]?._id?.hour === bid?.data[0]?._id?.hour
            )
        );
        setBidsPickupHeader(uniqueHours);
        setBidsPickupSummary(res?.totalPercentages);
      }
    });
  };
  const GetPostedBreakDownHours = () => {
    ApiClient.get("statistics/bid/posted-outside-business-hour-breakdown").then(
      (res) => {
        if (res.success) {
          const uniqueData = res?.data?.filter(
            (bid, index, self) =>
              index ===
              self.findIndex(
                (b) =>
                  b?.data[0]?._id?.month === bid?.data[0]?._id?.month &&
                  b?.data[0]?._id?.year === bid?.data[0]?._id?.year
              )
          );
          let data = res?.data;
          setPostedBreakDown(data);
          setPostedOutSideHourBreakHeader(uniqueData);

          setPostedBreakDownSummary(res?.percentages);
        }
      }
    );
  };
  const GetBidsPickedData = (p) => {
    ApiClient.get("statistics/bid/picked-by-hour", { board_id: p?.board_id || '' }).then((res) => {
      if (res.success) {
        let data = res?.data[0]?.data;
        setBidsPicked(data);
        // setPostedOutSideHourBreakHeader(uniqueData);

        setBidsPickedSummary(res?.data[0]?.summary);
      }
    });
  };
  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    let f = {
      ...filters,
      ...p,
    };

    GetLOadsByOrigin(f);
    GetBidsPickedData(f);
    GetDuringBuisnessHours(f);
    GetLOadsByOriginState(f);
    GetStatistics(f);
    GetTimeToPickup(f);
    GetAverageTimeToPickup(f);
    Getbid_posted_by_hour(f);
    GetBidsBreakDownHours(f);
    GetBidsPuckupData(f);
    GetPostedBreakDownHours(f);
    GetAwardedStatistics(f);
    PostedDuringBuisnessHours(f);
    PostedOutsideBuisnessHours(f);
    GetRavenStatistics(f);
    GetAveragehourbeforepickup(f);
    GetStatisticsLoadsOutSideBuisnessHour(f);
    GetStatisticsLoadsOutSideBuisness24Hours(f);

    GetStatisticsBids({ start_date: f.start_date });
  };


  const filterAll = (p = {}) => {
    filter(p)
    getAwardStat(p)
    getDeliveryTimeStat(p)
    getLoadRejetionStat(p)
    getPerformingRoutes(p)
  }


  const [awardsStat, setAwardStat] = useState();
  const [loadRejectionStat, setLoadRejectionStat] = useState();
  const [deliveryStat, setDeliveryStat] = useState(0);
  const [performingRoutes, setPerformingRoutes] = useState([]);
  const [performingRoutesTotal, setPerformingRoutesTotal] = useState();
  const getAwardStat = (p = {}) => {
    let f = {
      board_id: filters.board_id,
      ...p
    }
    ApiClient.get('statistics/average/all-awarded-stats', f).then(res => {
      if (res.success) {
        let data = res.data?.[0]
        setAwardStat(data)
      }
    })
  }

  const getPerformingRoutes = (p = {}) => {
    let f = {
      board_id: filters.board_id,
      ...p
    }
    ApiClient.get('performance/top-performing-routes', f).then(res => {
      if (res.success) {
        let data = res.data?.data?.[0]?.total_amount
        setPerformingRoutes(data)
        let summary_load = res.data?.data?.[0]?.summary_load?.[0]?.count
        let summary_cost = res.data?.data?.[0]?.summary_cost?.[0]?.sum_cost
        setPerformingRoutesTotal({ summary_load, summary_cost })
      }
    })
  }

  const getLoadRejetionStat = (p = {}) => {
    let f = {
      board_id: filters.board_id,
      ...p
    }
    ApiClient.get('statistics/load/rejection-rate', f).then(res => {
      if (res.success) {
        let data = res.data?.[0]
        setLoadRejectionStat(data)
      }
    })
  }

  const getDeliveryTimeStat = (p = {}) => {
    let f = {
      board_id: filters.board_id,
      ...p
    }
    ApiClient.get('statistics/average/delivery-time-load', f).then(res => {
      if (res.success) {
        let data = res.data?.[0]
        setDeliveryStat(data?.average_delivery_time)
      }
    })
  }

  const [Board, setBoard] = useState([]);
  const GetBoards = () => {
    ApiClient.get("boards").then((res) => {
      if (res.success) {
        setBoard(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getAwardStat()
    getDeliveryTimeStat()
    getLoadRejetionStat()
    getPerformingRoutes()
    GetBoards()
  }, [])


  const GetStatisticsBids = (f) => {
    // delete f?.start_date;
    let fltr = {
      board_id: filters.board_id,
      ...f,
    };
    ApiClient.get("statistics/bids", fltr).then((res) => {
      if (res.success) {
        setStatisticsBid(res?.data);
      }
    });
  };

  const GetStatisticsLoadsOutSideBuisnessHour = (f) => {
    let fltr = { ...filters, ...f };
    ApiClient.get("statistics/posted/outside-business-hours", fltr).then(
      (res) => {
        if (res.success) {
          setOutsideHours(res?.data[0]);
        }
      }
    );
  };

  const GetStatisticsLoadsOutSideBuisness24Hours = (f) => {
    let fltr = { ...filters, ...f };
    ApiClient.get("statistics/posted/outside-business-hours/24", fltr).then(
      (res) => {
        if (res.success) {
          setOutside24Hours(res?.data[0]);
        }
      }
    );
  };
  const GetAveragehourbeforepickup = (f) => {
    let fltr = { ...filters, ...f };
    ApiClient.get("statistics/posted/average-hour-before-pickup", fltr).then(
      (res) => {
        if (res.success) {
          SetAverageHourPickup(res?.data[0]);
        }
      }
    );
  };

  const GetStatistics = (f) => {
    loader(true);

    let fltr = { ...filters, ...f, start_date: '' };
    ApiClient.get("statistics/all-loads", fltr).then((res) => {
      if (res.success) {
        setStatistics(res?.data[0]);
        loader(false);
      }
      loader(false);
    });
  };
  const GetRavenStatistics = (f) => {
    loader(true);

    let fltr = { ...filters, ...f };
    ApiClient.get("statistics/loads/not-bidded", fltr).then((res) => {
      if (res.success) {
        setRavenStatistics(res?.data[0]);
        loader(false);
      }
      loader(false);
    });
  };

  const GetAwardedStatistics = (f) => {
    loader(true);

    let fltr = { ...filters, ...f, status: "awarded" };
    ApiClient.get("statistics/loads/bidded", fltr).then((res) => {
      if (res.success) {
        setAwardedStatistics(res?.data[0]);
        loader(false);
      }
      loader(false);
    });
  };

  const PostedOutsideBuisnessHours = (p) => {
    ApiClient.get("statistics/posted/outside-business-hours/amount", { board_id: p?.board_id || '' }).then(
      (res) => {
        if (res.success) {
          setPostedOutsideBuisnessHour(res?.data[0]);
        }
      }
    );
  };

  const PostedDuringBuisnessHours = (p) => {
    ApiClient.get("statistics/posted/during-business-hours/amount", { board_id: p?.board_id || '' }).then(
      (res) => {
        if (res?.success) {
          setPostedDuringBuisnessHour(res?.data[0]);
        }
      }
    );
  };

  const onRangeChange = (e) => {
    let f = {
      start_date: datepipeModel.datetostring(e.startDate),
      type: e.type,
      value: e.value,
      direction: e.direction,
    };
    filter({ ...f });
  };


  const status = [
    {
      title: "Records Loaded",
      value: (
        <>{StatisticsData?.count || 0}</>
      ),
    },
    {
      title: "Earliest Auction Date",
      value: (
        <>
          {moment(StatisticsData?.earliest_auction_date).format(
            "YYYY-MM-DD, H:mm A"
          ) || 0}
        </>
      ),
    },
    {
      title: "Latest Auction Date",
      value: (
        <>
          {moment(StatisticsData?.latest_auction_date).format(
            "YYYY-MM-DD, H:mm A"
          ) || 0}
        </>
      ),
    },
    {
      title: "Auctions Loaded (Filtered)",
      value: statisticsBid?.total_loads || 0,
    },
    {
      title: "OB No-Bid",
      value: RavenStatisticsData?.count || 0,
    },
    {
      title: "Engagement Rate",
      value: `${statisticsBid?.engagement_rate || 0}%`,
    },
    {
      title: "No Winner Awarded",
      value: AwardedStatisticsData?.count || 0,
    },
    {
      title: "Tendered (Won)",
      value: statisticsBid?.won_bid || 0,
    },
    {
      title: "Success Rate",
      value: `${statisticsBid?.success_rate || 0}%`,
    },
    {
      title: "Lost",
      value: statisticsBid?.lost_bid || 0,
    },
    {
      title: "Posted Outside of Business Hours",
      isGraph: true,

      value: (
        <>
          {OutSideHoursData?.total_loads || 0}
        </>
      ),
    },
    {
      title: "Posted Outside of Buisness Hours Rate",
      isGraph: true,
      className: 'alag',
      value: (
        <GaugeComponent
          arc={{
            subArcs: [
              {
                limit: 10,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "10%" },
              },
              {
                limit: 30,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "30%" },
              },
              {
                limit: 60,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "60%" },
              },
              {
                limit: 100,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "100%" },
              },
            ],
          }}
          value={`${OutSideHoursData?.percentage?.toFixed(2) || 0}`}
        />
      ),
    },
    {
      title: "Average Hour Before Pickup",
      isGraph: true,

      value: (
        <>
          {AverageHourPickData?.averageHours?.toFixed() || 0}
        </>
      ),
    },
    {
      title: "Posted Outside of Business Hours",
      sub_title: "with 24+ Hours to Pickup",
      isGraph: true,

      value: (
        <>
          {OutSide24HoursData?.total_pickup_hours_load || 0}
        </>
      ),
    },
    {
      title: "Posted Outside of Buisness Hours Rate",
      sub_title: "with 24+ Hours to Pickup",
      className: 'alag',

      isGraph: true,
      value: (
        <GaugeComponent
          arc={{
            subArcs: [
              {
                limit: 10,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "10%" },
              },
              {
                limit: 30,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "30%" },
              },
              {
                limit: 60,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "60%" },
              },
              {
                limit: 100,
                color: "rgb(73 79 159)",
                showTick: true,
                tooltip: { text: "100%" },
              },
            ],
          }}
          value={`${OutSide24HoursData?.percentage?.toFixed(2) || 0}`}
        />
      ),
    },
  ];
  const statusTab2 = [
    {
      title: "Posted During Business hours",
      value: `$${PostedDuringBuisnessHour?.total_amount?.toFixed(2) || 0}`,
      isGraph:true,
    },
    {
      title: "Posted During Business hours For 0-24",
      value: `$${PostedDuringBuisnessHour?.zero_to_24?.toFixed(2) || 0}`,
    },
    {
      title: "Posted During Business hours For 24-48",
      value: `$${PostedDuringBuisnessHour?.twenty_four_to_48?.toFixed(2) || 0}`,
    },
    {
      title: "Posted During Business hours For 48-72",
      value: `$${PostedDuringBuisnessHour?.fourty_eight_to_72?.toFixed(2) || 0}`,
    },
    {
      title: "Posted Outside Business hours",
      value: `$${PostedOutsideBuisnessHour?.total_amount?.toFixed(2) || 0}`,
    },
    {
      title: "Posted Outside Business hours For 0-24",
      value: `$${PostedOutsideBuisnessHour?.zero_to_24?.toFixed(2) || 0}`,
    },
    {
      title: "Posted Outside Business hours For 24-48",
      value: `$${PostedOutsideBuisnessHour?.twenty_four_to_48?.toFixed(2) || 0}`,
    },
    {
      title: "Posted Outside Business hours For 48-72",
      value: `$${PostedOutsideBuisnessHour?.fourty_eight_to_72?.toFixed(2) || 0}`,
    },

  ];


  const status2 = [
    {
      title: "Average Load Value (Awarded Loads Only)",
      value: (
        <>{awardsStat?.total_awarded_loads || 0}</>
      ),
    },
    {
      title: "Average Distance Per Load (Awarded Loads Only)",
      value: (
        <>{awardsStat?.average_distance_per_load_result.toFixed(2) || 0}</>
      ),
    },
    {
      title: "Total Revenue (Awarded Loads Only)",
      value: (
        <>{awardsStat?.sum_of_load_value || 0}</>
      ),
    },
    {
      title: "Revenue Per Mile (Awarded Loads Only)",
      value: (
        <>{awardsStat?.revenue_per_mile_result?.toFixed(2) || 0}</>
      ),
    },
    {
      title: "Average Delivery Time (Awarded Loads Only)",
      value: (
        <>{deliveryStat?.toFixed(2) || 0}</>
      ),
    },
    {
      title: "Load Rejection Rate (Awarded Loads Only)",
      value: (
        <>{loadRejectionStat?.rejection_rate_in_percentage_result?.toFixed(2) || 0}</>
      ),
    },
    // {
    //   title: "Carrier Performance (Awarded Loads Only)",
    //   value: (
    //     <>{0 || 0}</>
    //   ),
    // },
    // {
    //   title: "Average Load Handling Time (Awarded Loads Only)",
    //   value: (
    //     <>{0 || 0}</>
    //   ),
    // },
  ]

  let markers = [];
  const GetLOadsByOrigin = (p) => {
    let array = [];
    ApiClient.get("statistics/loads-origin", { board_id: p?.board_id || '' }).then((res) => {
      if (res.success) {
        let SplitedData = res?.data?.splice(0, 100);

        SplitedData?.map((itm) => {
          if (itm?.origin_lat && itm?.origin_lng) {
            array.push({
              markerOffset: -20,
              name: itm?.origin_address,
              coordinates: [
                Number(parseFloat(itm?.origin_lng).toFixed(4)),
                Number(parseFloat(itm?.origin_lat).toFixed(4)),
              ],
              count: itm?.count,
            });
          }
        });
        setMapMarkers(array);
        setChartLoading(true);
      }
    });
  };


  const [summary_avg_total, setSummaryAvgTotal] = useState(0);
  const [summary_load_total, setSummaryLoadTotal] = useState(0);
  const GetTimeToPickup = (f = {}) => {
    let fltr = { ...filters, ...f, start_date: '' };

    ApiClient.get("statistics/time-to-pickup", fltr).then((res) => {
      if (res.success) {
        let array = res?.data?.data?.[0]?.total_amount?.map((itm) => {
          return {
            origin: itm?.origin_address,
            count: itm?.count,
            time: itm?.avg_time_difference_hours,
          }
        });
        let avgTotal = res?.data?.data?.[0]?.summary_avg?.[0]?.avg_time_difference_hours || 0
        let loadTotal = res?.data?.data?.[0]?.summary_load?.[0]?.count || 0
        setSummaryAvgTotal(avgTotal)
        setSummaryLoadTotal(loadTotal)
        setTimePickup(array);
      }
    });
  };
  const GetAverageTimeToPickup = (f = {}) => {
    let fltr = { ...filters, ...f };

    ApiClient.get("statistics/average-time-to-pickup", fltr).then((res) => {
      if (res.success) {
        let array = [];
        setTableHeader(res?.data?.data[0]?.headers);
        setTableData(res?.data?.data[0]?.data);
        setSummary(res?.data?.data[0]?.summary);
        // let fltr = res?.data?.data?.map((itm) => {
        //   array.push({
        //     origin: itm?.total_amount?._id?.origin_address,
        //     count: itm?.total_amount?.count,
        //     time: itm?.total_amount?.avg_time_difference_hours,
        //   });
        // });
        // setAverageTimePickup(array);
      }
    });
  };
  const Getbid_posted_by_hour = (f = {}) => {
    let fltr = { ...filters, ...f };

    ApiClient.get("statistics/bid/posted-by-hour", fltr).then((res) => {
      if (res.success) {
        let array = [];
        setBidsData(res?.data[0]?.data);

        setBidSummary(res?.data[0]?.summary);
        // setBidHeader(res?.data?.data[0]?.headers);
        // setBidTable(res?.data?.data[0]?.data);
        // setBidSummary(res?.data?.data[0]?.summary);
        // let fltr = res?.data?.data?.map((itm) => {
        //   array.push({
        //     origin: itm?.total_amount?._id?.origin_address,
        //     count: itm?.total_amount?.count,
        //     time: itm?.total_amount?.avg_time_difference_hours,
        //   });
        // });
        // setAverageTimePickup(array);
      }
    });
  };

  const GetLOadsByOriginState = (p) => {
    let array = [];
    ApiClient.get("statistics/loads-origin-state", { board_id: p?.board_id || '' }).then((res) => {
      if (res.success) {
        setMapStateData(res?.data);
        let SplitedData = res?.data?.splice(0, 300);
        SplitedData?.map((itm) => {
          if (itm?.origin_location_state) {
            array.push({
              state: itm?.origin_location_state,
              address: itm?.origin_address,
              count: itm?.count,
            });
          }
        });
        // array.push({
        //   state: "TX",
        //   address: "Texas",
        //   count: 0,
        // })
        array.push({
          state: "WY",
          address: "Wyoming",
          count: 0,
        })
        array.push({
          state: "VT",
          address: "Vermont",
          count: 0,
        })
        array.push({
          state: "HI",
          address: "Hawaii",
          count: 0,
        })
        setMapStateData(array);
      }
    });
  };
  const BidsByChartData = [
    ["State", "Adress", "Total Loads"],
    ...((MapStateData?.length > 0 &&
      MapStateData?.map((item) => {
        return [
          `${item?.state}`,
          `${states.getStateNameByStateCode(item?.state)} | ${item?.address} `,
          item?.count,
        ];
      })) || [
        ["TX", "Texas", 345],
        ["UT", "Utah", 68],
        ["WY", "Wyoming", 45],
      ]),
  ];

  const GetDuringBuisnessHours = (p) => {
    ApiClient.get("statistics/posted/during-business-hours", { board_id: p?.board_id || '' }).then((res) => {
      if (res.success) {
        setBuisnessData(res?.data[0]);
      }
    });
  };

  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";


  const GetPercentageRelativeToHoursForColumns = (key, address, index, i) => {
    let value = "";
    BidsBreakDown?.map((itm, i) => {
      if (
        itm?.data[0]?.hour == key &&
        itm?.data[0]?.origin_address == address
      ) {
        value = itm?.data[0]?.percentage;
      }
    });
    if (value) {
      return value + "%";
    } else {
      return "0%";
    }
  };

  const GetPercentageRelativeToHoursForColumnsBidsPickUP = (
    key,
    address,
    index,
    i
  ) => {
    let value = "";
    BidsPickup?.map((itm, i) => {
      if (
        itm?.data[0]?.hour == key &&
        itm?.data[0]?.origin_address == address
      ) {
        value = itm?.data[0]?.percentage;
      }
    });
    if (value) {
      return value + "%";
    } else {
      return "0%";
    }
  };

  const GetBidsPickedRelativeToHoursForColumns = (key, address, index, i) => {
    let value = "";
    BidsPicked?.map((itm, i) => {
      if (itm?.hour == key && itm?.data[0]?.destination_address == address) {
        value = itm?.data[0]?.bid_count;
      }
    });
    if (value) {
      return value;
    } else {
      return "0";
    }
  };

  const GetPostedPercentageRelativeToHoursForColumns = (
    month,
    year,
    address
  ) => {
    let value = "";
    PostedBreakDown?.map((itm, i) => {
      if (
        itm?.data[0]?.month == month &&
        itm?.data[0]?.year == year &&
        itm?.data[0]?.origin_address == address
      ) {
        value = itm?.data[0]?.percentage;
      }
    });
    if (value) {
      return value + "%";
    } else {
      return "0%";
    }
  };
  const GetPostedPercentageRelativeToHours = (month, year) => {
    let value = 0;
    PostedBreakDownSummary?.map((itm, i) => {
      if (itm?.month == month && itm?.year == year) {
        value = itm?.percentage;
      }
    });
    return value;
  };

  const GetTotalHoursRelativeToHoursForColumns = (key, address) => {
    let value = "";
    BidsData?.map((itm, i) => {
      if (itm?.hour == key && itm?.data[0]?.origin_address == address) {
        value = itm?.data[0]?.bid_count;
      }
    });
    if (value) {
      return value;
    } else {
      return "0";
    }
  };
  const GetAverageByMonthRelativeToHoursForColumns = (month, year, address) => {
    let value = 0;
    TableData?.map((itm, i) => {
      if (
        itm?.data[0]?.month == month &&
        itm?.data[0]?.year == year &&
        itm?.origin_address == address
      ) {
        value = itm?.data[0]?.avg_time_difference_hours?.toFixed(2);
      } else {
        value = "";
      }
    });
    return value;
  };

  const GetAddressAbbrivation = (str) => {
    var parts = str?.split(",");

    // Getting the city name
    var city = parts[0].split(" ").slice(-2).join(" "); // Extracting the city name

    const regex = /(.+),\s([A-Z]{2}),/; // Regular expression to match city name and state abbreviation
    const cityStateMatch = str?.match(regex);
    let cityAddress = "";
    let state = "";
    if (cityStateMatch) {
      const city = cityStateMatch[1];
      const stateAbbr = cityStateMatch[2];
      cityAddress = city?.trim();
      state = stateAbbr;
    } else {
    }

    return { city: city || "", state: state || "" };
  };


  const statusRows=[
    {
      name: 'Records Loaded',
      show: true,
    },
    {
      name: 'Earliest Auction Date',
      show: true,
    },
    {
      name: 'Latest Auction Date',
      show: true,
    },
    {
      name: 'Auctions Loaded (Filtered)',
      show: true,
    },
    {
      name: 'OB No-Bid',
      show: true,
    },
    {
      name: 'Engagement Rate',
      show: true,
    },
    {
      name: 'No Winner Awarded',
      show: true,
    },
    {
      name: 'Tendered (Won)',
      show: true,
    },
    {
      name: 'Success Rate',
      show: true,
    },
    {
      name: 'Lost',
      show: true,
    },
    {
      name: 'Posted Outside of Business Hours',
      show: true,
    },
    {
      name: 'Posted Outside of Buisness Hours Rate',
      show: true,
    },
    {
      name: 'Average Hour Before Pickup',
      show: true,
    },
    {
      name: 'Posted During Business hours',
      show: true,
    },
    {
      name: 'Posted During Business hours For 0-24',
      show: true,
    },
    {
      name: 'Posted During Business hours For 24-48',
      show: true,
    },
    {
      name: 'Posted During Business hours For 48-72',
      show: true,
    },
    {
      name: 'Average Load Value (Awarded Loads Only)',
      show: true,
    },
    {
      name: 'Average Distance Per Load (Awarded Loads Only)',
      show: true,
    },
    {
      name: 'Total Revenue (Awarded Loads Only)',
      show: true,
    },
    {
      name: 'Revenue Per Mile (Awarded Loads Only)',
      show: true,
    },
    {
      name: 'Average Delivery Time (Awarded Loads Only)',
      show: true,
    },
    {
      name: 'Load Rejection Rate (Awarded Loads Only)',
      show: false,
    },
    {
      name: 'Posted Outside Business hours',
      show: false,
    },
    {
      name: 'Posted Outside Business hours For 0-24',
      show: false,
    },
    {
      name: 'Posted Outside Business hours For 24-48',
      show: false,
    },
    {
      name: 'Posted Outside Business hours For 48-72',
      show: false,
    },
    {
      name: 'Top Performing Routes (Awarded Loads Only)',
      show: false,
    },
    {
      name: 'All Postings',
      show: false,
    },
    {
      name: 'Station Average By Month',
      show: false,
    },
    {
      name: 'Bids Posted By Hour',
      show: false,
    },
    {
      name: 'Bids Posted By Hour Breakdown',
      show: false,
    },
    {
      name: 'Bids Posted Outside By Hour Breakdown',
      show: false,
    },
    {
      name: 'Bids Picked By Hour',
      show: false,
    },
    {
      name: 'Bids Pick Up By Hour Rate',
      show: false,
    },
    {
      name: 'Loads By Origin',
      show: false,
    },
    {
      name: 'Loads By Origin State',
      show: false,
    },

  ]

  const [statsRow, setStatsRow] = useState(statusRows.slice(0,10).map(itm=>itm.name))

  const getStatRow = (name) => {
    let arr = statsRow
    return arr.includes(name)
  }

  


  return (
    <>
      <Layout>
        <div className="row d-flex align-items-center">
          <div className="col-md-9 head_cls">
            <h2 className="mb-1 mainHeading_cls">Statistics</h2>
            <p className="paraCls"></p>
          </div>
          <div className="col-md-3">
            <SelectDropdown
              intialValue={filters.board_id}
              options={Board}
              placeholder="All Loads"
              disabled
              result={e => {
                filterAll({ board_id: e.value })
              }}
            />
          </div>
          <div className="col-md-12 mt-3">
            <MultiSelectDropdown
            intialValue={statsRow}
            options={statusRows.map(itm=>({name:itm.name,id:itm.name}))}
            result={e=>{
           
              setStatsRow(e.value)
            }}
            />

          </div>
          <div className="col-lg-8 my-2">
            {" "}
            <div className="sales_section enterderstats">
              {" "}
              <label className="enterslabel">
                Entered Auction Date (Filters subsequent pages)
              </label>
              <div className="d-flex gap-2 ml-auto justify-content-start flex-wrap align-items-center">
                <DateRangeInput
                  value={{
                    startDate: filters.start_date,
                    value: filters.value,
                    type: filters.type,
                    direction: filters.direction,
                  }}
                  onChange={(e) => onRangeChange(e)}
                  fullWidth={false}
                  load={true}
                />
              </div>
            </div>
          </div>
          {status.map((itm, i) => {
            if (getStatRow(itm.title))
              return (
                <>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 my-3"
                    key={i}
                  >
                    <div
                      className={`sales_section ${itm?.isGraph ? "sales_section_stats" : "sales_section_top"
                        }  ${itm?.title == "Auctions Loaded (Filtered)"
                          ? "sales_section_top"
                          : ""
                        }`}
                    >
                      <div className="main_sales pointer">
                        <div className="sales_headings justify-content-center ">
                          <div>
                            <p className="sub_heading ">{itm.title}</p>
                            {itm?.sub_title ? (
                              <p className="subptag">{itm.sub_title}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className={`${itm.className || 'Amount_sales pt-0'}`}>
                          {itm?.value}
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              );
          })}

          {/* <div className="col-12 col-sm-12"></div> */}
          {statusTab2.map((itm, i) => {
            if (getStatRow(itm.title))
              return (
                <>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 my-3"
                    key={i}
                  >
                    <div
                      className={`sales_section ${itm?.isGraph ? "sales_section_stats" : "sales_section_top"
                      }  ${itm?.title == "Auctions Loaded (Filtered)"
                        ? "sales_section_top"
                        : ""
                      }`}
                    >
                      <div className="main_sales pointer">
                        <div className="sales_headings justify-content-center">
                          <div>
                            <p className="sub_heading ">{itm.title}</p>
                          </div>
                        </div>
                        <div className="sale s_icons">
                          <h3 className="Amount_sales pt-0">{itm?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
          })}

          {/* <div className="col-12 col-sm-12"></div> */}
          {status2.map((itm, i) => {
            if (getStatRow(itm.title))
              return (
                <>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 my-3"
                    key={i}
                  >
                    <div
                      className={`sales_section ${itm?.isGraph ? "sales_section_stats" : "sales_section_top"
                      }  ${itm?.title == "Auctions Loaded (Filtered)"
                        ? "sales_section_top"
                        : ""
                      }`}
                    >
                      <div className="main_sales pointer">
                        <div className="sales_headings justify-content-center">
                          <div>
                            <p className="sub_heading ">{itm.title}</p>
                            {/* <p className="sub_description">{itm.description}</p> */}
                          </div>
                        </div>
                        <div className="sale s_icons">
                          <h3 className="Amount_sales pt-0">{itm?.value}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
          })}



          {getStatRow('Top Performing Routes (Awarded Loads Only)') ? <>
            <div className="container mt-5">
              <h4 className="mb-3">Top Performing Routes (Awarded Loads Only)</h4>
              <div className="table-responsive postings_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Load Count</th>
                      <th>Revenue Per Route</th>
                    </tr>
                  </thead>
                  <tbody>
                    {performingRoutes?.map((item, index) => (

                      <tr key={index}>
                        <td>
                          {item.origin_location_state}
                          {" / "}
                          {item.destination_location_city}
                        </td>
                        <td>{item.count}</td>
                        <td>{item.total_cost?.toFixed(2)}</td>
                      </tr>
                    ))}

                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{performingRoutesTotal?.summary_load}</strong>
                      </td>
                      <td>
                        <strong>{performingRoutesTotal?.summary_cost?.toFixed(2)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </> : <></>}

          {getStatRow('All Postings') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">All Postings</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Load Count</th>
                      <th>Average Hours to Pickup</th>
                      <th>Cost per Mile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TimetoPick?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {GetAddressAbbrivation(item.origin)?.state}
                          {" / "}
                          {GetAddressAbbrivation(item.origin)?.city}
                        </td>
                        <td>{item.count}</td>
                        <td>{item.time?.toFixed(2)}</td>
                        <td>{item.costPerMile ? item.costPerMile : "-"}</td>
                      </tr>
                    ))}

                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{summary_load_total}</strong>
                      </td>
                      <td>
                        <strong>{summary_avg_total?.toFixed(2)}</strong>
                      </td>
                      <td>
                        <strong>{"--"}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </> : <></>}

          {getStatRow('Station Average By Month') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">Station Average By Month</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Destination</th>
                      {TableHeader &&
                        TableHeader?.map((itm) => {
                          return (
                            <th>
                              {itm?.month}-{itm?.year}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {TableData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {GetAddressAbbrivation(item.origin_address)?.state}
                          {" / "}
                          {GetAddressAbbrivation(item.origin_address)?.city}
                        </td>
                        <td>
                          {GetAddressAbbrivation(item.destination_address)?.state}{" "}
                          {GetAddressAbbrivation(item.destination_address)?.city}
                        </td>
                        {TableHeader?.map((itm) => {
                          return (
                            <td>
                              {GetAverageByMonthRelativeToHoursForColumns(
                                itm?.month,
                                itm?.year,
                                item?.origin_address
                              ) || 0}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <strong>Summary</strong>
                      </td>
                      <td>
                        <strong></strong>
                      </td>
                      {Summary?.map((itm) => {
                        return (
                          <td>
                            <strong>
                              {itm?.avg_time_difference_hours?.toFixed(2) || 0}
                            </strong>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </> : <></>}

          {getStatRow('Bids Posted By Hour') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">Bids Posted By Hour</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin By Hour Of Day</th>
                      {/* <th>Destination</th> */}

                      {BidsData?.map((itm) => {
                        return <th>{itm?.hour}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {BidsData?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {
                              GetAddressAbbrivation(itm?.data[0]?.origin_address)
                                ?.state
                            }
                            {" / "}
                            {
                              GetAddressAbbrivation(itm?.data[0]?.origin_address)
                                ?.city
                            }
                          </td>
                          {/* <td>
                          {
                            GetAddressAbbrivation(
                              itm?.data[0]?.destination_address
                            )?.state
                          }{" "}
                          {
                            GetAddressAbbrivation(
                              itm?.data[0]?.destination_address
                            )?.city
                          }
                        </td> */}
                          {BidsData?.map((item, index) => {
                            return (
                              <td>
                                {GetTotalHoursRelativeToHoursForColumns(
                                  item?.hour,
                                  itm?.data[0]?.origin_address
                                )}
                                {/* {item?.data[0]?.bid_count || 0} */}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>

                      {BidSummary?.map((itm) => {
                        return <td>{itm?.count || 0}</td>;
                      })}
                    </tr>
                  </tbody>
                </table>
                {/* <table border="1">
                <thead>
                  <tr>
                    <th>Origin Address</th>
                    <th>Destination Address</th>

                    <th>Hour</th>
                    {hoursRow.map((hour) => (
                      <th key={hour}>{hour}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>{loadsMap[hour]}</td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          ?.filter((item) => item.hour === hour)
                          ?.flatMap((item) =>
                            item?.data?.map((load) => load?.origin_address)
                          )
                          ?.join(", ")}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          .filter((item) => item.hour === hour)
                          .flatMap((item) =>
                            item.data.map((load) => load.destination_address)
                          )
                          .join(", ")}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          .filter((item) => item.hour === hour)
                          .flatMap((item) => item.data)
                          .reduce((acc, load) => acc + load.bid_count, 0)}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table> */}
              </div>
            </div>
          </> : <></>}

          {getStatRow('Bids Posted By Hour Breakdown') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">Bids Posted By Hour Breakdown</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin By Hour Of Day</th>
                      {/* <th>Destination</th> */}

                      {/* {OutSideHourBreakHeader?.map((itm) => {
                      return (
                        <>
                          <th>{itm?.data[0]?._id?.hour}</th>
                        </>
                      );
                    })} */}
                      {BidsBreakDownSummary?.map((itm) => {
                        return (
                          <>
                            <th>{itm?.hour}</th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {BidsBreakDown?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {
                              GetAddressAbbrivation(itm?._id?.origin_address)
                                ?.state
                            }
                            {" / "}
                            {
                              GetAddressAbbrivation(itm?._id?.origin_address)
                                ?.city
                            }
                          </td>
                          {/* <td>
                          {
                            GetAddressAbbrivation(itm?._id?.destination_address)
                              ?.state
                          }{" "}
                          {
                            GetAddressAbbrivation(itm?._id?.destination_address)
                              ?.city
                          }
                        </td> */}
                          {OutSideHourBreakHeader?.map((item, i) => {
                            return (
                              <td>
                                {" "}
                                {GetPercentageRelativeToHoursForColumns(
                                  item?.data[0]?._id?.hour,
                                  itm?.data[0]?.origin_address,
                                  index,
                                  i
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      {/* <td></td> */}
                      {BidsBreakDownSummary?.map((itm) => {
                        return (
                          <td>{itm?.percentage?.toFixed() + "%" || "0%"}</td>
                        );
                      })}
                      {/* {BidsBreakDown?.map((itm) => {
                      return (
                        <td>
                          {GetPercentageRelativeToHours(
                            itm?.data[0]?._id?.hour
                          ) + "%" || 0}
                        </td>
                      );
                    })} */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </> : <></>}

          {getStatRow('Bids Posted Outside By Hour Breakdown') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">Bids Posted Outside By Hour Breakdown</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Destination By Month</th>

                      {PostedOutSideHourBreakHeader?.map((itm) => {
                        return (
                          <th>
                            {itm?.data[0]?._id?.month}-{itm?.data[0]?._id?.year}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {PostedBreakDown?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {
                              GetAddressAbbrivation(itm?._id?.origin_address)
                                ?.state
                            }
                            {" / "}
                            {
                              GetAddressAbbrivation(itm?._id?.origin_address)
                                ?.city
                            }
                          </td>
                          <td>
                            {
                              GetAddressAbbrivation(itm?._id?.destination_address)
                                ?.state
                            }{" "}
                            {
                              GetAddressAbbrivation(itm?._id?.destination_address)
                                ?.city
                            }
                          </td>
                          {PostedOutSideHourBreakHeader?.map((item, index) => {
                            return (
                              <td>
                                {" "}
                                {GetPostedPercentageRelativeToHoursForColumns(
                                  item?.data[0]?._id?.month,
                                  item?.data[0]?._id?.year,

                                  itm?.data[0]?.origin_address
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      <td></td>

                      {PostedOutSideHourBreakHeader?.map((itm) => {
                        return (
                          <td>
                            {GetPostedPercentageRelativeToHours(
                              itm?.data[0]?._id?.month,
                              itm?.data[0]?._id?.year
                            )?.toFixed() + "%" || 0}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                {/* <table border="1">
                <thead>
                  <tr>
                    <th>Origin Address</th>
                    <th>Destination Address</th>

                    <th>Hour</th>
                    {hoursRow.map((hour) => (
                      <th key={hour}>{hour}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>{loadsMap[hour]}</td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          ?.filter((item) => item.hour === hour)
                          ?.flatMap((item) =>
                            item?.data?.map((load) => load?.origin_address)
                          )
                          ?.join(", ")}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          .filter((item) => item.hour === hour)
                          .flatMap((item) =>
                            item.data.map((load) => load.destination_address)
                          )
                          .join(", ")}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {hoursRow.map((hour) => (
                      <td key={hour}>
                        {hoursData
                          .filter((item) => item.hour === hour)
                          .flatMap((item) => item.data)
                          .reduce((acc, load) => acc + load.bid_count, 0)}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table> */}
              </div>
            </div>
          </> : <></>}

          {getStatRow('Bids Picked By Hour') ? <>
            <div className="container mt-5">
              <h4  className="mb-3">Bids Picked By Hour</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin By Hour Of Day</th>

                      {/* {OutSideHourBreakHeader?.map((itm) => {
                      return (
                        <>
                          <th>{itm?.data[0]?._id?.hour}</th>
                        </>
                      );
                    })} */}
                      {BidsPicked?.map((itm) => {
                        return (
                          <>
                            <th>{itm?.hour}</th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {BidsPicked?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {
                              GetAddressAbbrivation(
                                itm?.data[0]?.destination_address
                              )?.state
                            }
                            {" / "}
                            {
                              GetAddressAbbrivation(
                                itm?.data[0]?.destination_address
                              )?.city
                            }
                          </td>
                          {/* <td>
                          {
                            GetAddressAbbrivation(itm?._id?.destination_address)
                              ?.state
                          }{" "}
                          {
                            GetAddressAbbrivation(itm?._id?.destination_address)
                              ?.city
                          }
                        </td> */}
                          {BidsPicked?.map((item, i) => {
                            return (
                              <td>
                                {" "}
                                {GetBidsPickedRelativeToHoursForColumns(
                                  item?.hour,
                                  itm?.data[0]?.destination_address,
                                  index,
                                  i
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      {BidsPickedSummary?.map((itm) => {
                        return <td>{itm?.count || "0"}</td>;
                      })}
                      {/* {BidsBreakDown?.map((itm) => {
                      return (
                        <td>
                          {GetPercentageRelativeToHours(
                            itm?.data[0]?._id?.hour
                          ) + "%" || 0}
                        </td>
                      );
                    })} */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </> : <></>}


          {getStatRow('Bids Pick Up By Hour Rate') ? <>

            <div className="container mt-5">
              <h4  className="mb-3">Bids Pick Up By Hour Rate</h4>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      {/* <th>Destination</th> */}

                      {/* {OutSideHourBreakHeader?.map((itm) => {
                      return (
                        <>
                          <th>{itm?.data[0]?._id?.hour}</th>
                        </>
                      );
                    })} */}
                      {BidsPickupSummary?.map((itm) => {
                        return (
                          <>
                            <th>{itm?.hour}</th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {BidsPicked?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {
                              GetAddressAbbrivation(itm?.data[0]?.origin_address)
                                ?.state
                            }
                            {" / "}
                            {
                              GetAddressAbbrivation(itm?.data[0]?.origin_address)
                                ?.city
                            }
                          </td>
                          {BidsPickupHeader?.map((item, i) => {
                            return (
                              <td>
                                {" "}
                                {GetPercentageRelativeToHoursForColumnsBidsPickUP(
                                  item?.data[0]?._id?.hour,
                                  itm?.data[0]?.origin_address,
                                  index,
                                  i
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      {/* <td></td> */}
                      {BidsPickupSummary?.map((itm) => {
                        return (
                          <td>{itm?.percentage?.toFixed() + "%" || "0%"}</td>
                        );
                      })}
                      {/* {BidsBreakDown?.map((itm) => {
                      return (
                        <td>
                          {GetPercentageRelativeToHours(
                            itm?.data[0]?._id?.hour
                          ) + "%" || 0}
                        </td>
                      );
                    })} */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </> : <></>}


          {ChartLoading && getStatRow('Loads By Origin') ? (
            <div className="col-12 my-3 originmap">
              <p className="sub_heading ">Loads By Origin</p>
              <ComposableMap projection="geoAlbersUsa">
                <Geographies geography={geoUrl}>
                  {({ geographies, outline, borders }) => (
                    <>
                      <Geography geography={outline} fill="#E9E3DA" />
                      <Geography
                        geography={borders}
                        fill="#C7C7C7"
                        stroke="#FFF"
                      />
                    </>
                  )}
                </Geographies>
                {MapMarkers?.map((itm) => (
                  <Tooltip title={`Total Loads ${itm?.count} | ${itm?.name}`}>
                    <Marker
                      key={itm?.name}
                      coordinates={itm?.coordinates}
                      id={itm?.name}
                    >
                      <circle
                        r={itm?.count / 100 + 5}
                        fill="#148EFF"
                        stroke="#fff"
                        strokeWidth={2}
                      />
                      {/* <text
                      textAnchor="middle"
                      y={itm?.markerOffset}
                      style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                      {itm?.name}
                    </text> */}
                    </Marker>
                  </Tooltip>
                ))}
              </ComposableMap>
            </div>
          ) : null}


          {getStatRow('Loads By Origin State') ? <>
            <div className="col-12 my-3">
              <p className="sub_heading pb-3">Loads By Origin State</p>
            </div>
            <div>
              <MapChart data={BidsByChartData} />
            </div>
          </> : <></>}


        </div>

      </Layout>
    </>
  );
}

export default Statistics;
