import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import datepipeModel from "../../../models/datepipemodel";
import { useSelector } from "react-redux";
import "./style.scss";
import { Menu } from "@headlessui/react";
import { Tooltip } from "react-tooltip";

const DateRangeInput = ({
  value={type:'months',value:'1',direction:'last'},
  onChange,
  title = '',
  load=false,
}) => {
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  const [range, setRange] = useState("");
  const [selected, setSelected] = useState(value);

 const handleChange=(p)=>{
  let f={
    ...selected,
    ...p,
  }
  setSelected({...f})

  let value=Number(f.value||1)
  if(f.type=='months'){
    let start=previousMonth(value).start
    onChange({...f,startDate:start})
  }
  if(f.type=='years'){
    let start=previousYear(value).start
    onChange({...f,startDate:start})
  }
 }

 useEffect(()=>{
  setSelected({...selected,...value})
  if(load){
    let f={
      ...selected,...value
    }
    handleChange(f)
  }
  
 },[value.type,value.value,value.direction])

  function monthsBetweenDates(d1, d2) {
    if(!d1||!d2) return 0
    let date1=new Date(d1)
    let date2=new Date(d2)
    var months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
}

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const rangeClick = (e) => {
    let startDate = "";
    let endDate = "";

    if (e == "Last Month") {
      let month1 = new Date();
      month1 = month1.setMonth(month1.getMonth() - 1);
      month1 = datepipeModel.datetostring(month1);

      let current = new Date();
      let monthdate = current.setDate(0);
      monthdate = datepipeModel.datetostring(monthdate);
      startDate = `${month1.split("-")[0]}-${month1.split("-")[1]}-01`;
      endDate = monthdate;
    } else if (e == "This Month") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-${current.split("-")[1]}-01`;

      let month2 = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "This Year") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-01-01`;

      let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "Last Year") {
      let current = new Date();
      startDate = `${current.getFullYear() - 1}-01-01`;
      let month2 = new Date(current.getFullYear(), 0, 0);
      endDate = datepipeModel.datetostring(month2);
    }
    setRange(e);
    let months=monthsBetweenDates(startDate,endDate)
    onChange({ ...value, startDate, endDate, compare: "",type: months<1?'daily':'monthly' });
    if (e != "") {
      setToggle(false);
    }
  };

  const getDays = (s, e) => {
    // Define two date objects
    const startDate = new Date(s); // Replace with your start date
    const endDate = new Date(e); // Replace with your end date
    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;
    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const previousYear = (y=1) => {
    let start = "";
    let startDate=datepipeModel.datetostring(new Date())
    if (startDate) {
      let ssplit = startDate.split("-");
      let year = Number(ssplit[0]);
      start = datepipeModel.datetostring(`${year-y}-01-01`);
    }
    return { start };
  };

  const previousMonth = (month=1) => {
    let start = "";
    let startDate=new Date().toISOString()
    if (startDate) {
      let sMonth = new Date(startDate).getMonth();
      start = new Date(startDate).setMonth(sMonth - month);
      let year = new Date(start).getFullYear()
      let sm = new Date(start).getMonth()+1

      start = datepipeModel.datetostring(`${year}-${sm}-01`);
    }
    return { start };
  };

  const previousPeriod = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let days = getDays(value.startDate, value.endDate) + 1;

      let d = new Date(value.startDate);
      d.setDate(d.getDate() - days);
      start = datepipeModel.datetostring(d);

      let ed = new Date(value.startDate);
      ed.setDate(ed.getDate() - 1);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

 

  const months=[]
  for(let i=1;i<=12;i++){
    months.push({id:i,name:i})
  }

  return (
    <>
      <div className="d-flex gap-3 intoflex">
        <select className="form-control w-auto" 
        value={selected.direction} 
        onChange={e=>{
          handleChange({direction:e.target.value})
          
        }}
        >
          <option value="" disabled>Select Option</option>
          <option value="last">Last</option>
        </select>
        <select className="form-control"
        value={selected.value} 
        onChange={e=>{
          handleChange({value:e.target.value})
        }}
        >
          <option value="" disabled>Select Option</option>
          {months.map(itm => {
            return <option value={itm.id}>{itm.name}</option>
          })}
        </select>
        <select className="form-control"
        value={selected.type} 
        onChange={e=>{
          handleChange({type:e.target.value})
        }}
        >
          <option value="" disabled>Select Option</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>
      </div>
      {title ? <>
        <Tooltip
          id={`tooltip`}
          classNameArrow="border-b border-r border-primary"
          className="z-[999] bg-white border border-primary"
          place="bottom"><div className="tooltipCal">{title}</div></Tooltip>
      </> : <></>}
      <div
        className={`dropdownOverlap ${toggle ? "d-block" : ""}`}
        onClick={(e) => toggleChange(false)} />
    </>
  )
};
export default DateRangeInput;
