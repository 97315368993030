import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import AllChart from "../../components/common/AllChart";
import { useHistory } from "react-router-dom";
import methodModel from "../../methods/methods";
import DateRangePicker from "../../components/common/DateRangePicker";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Chart from "react-google-charts";
import BarChart from "../../components/common/BarChart";
import moment from "moment";

const Dashboard = () => {
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [ScansOverTime, setScansOverTime] = useState([]);
  let current = datepipeModel.datetostring(new Date());
  const [DileveryOutside, SetDileveryOutside] = useState([]);
  const [PeakPostingHour, setPeakPostingHour] = useState([]);
  const [PeakPostingMonths, setPeakPostingMonths] = useState([]);
  const [Filtername, setFIltername] = useState("");
  const [MostInbound, setMostInbound] = useState([]);
  const [StatisticsData, setStatistics] = useState([]);
  const [LoadsData, setLoadData] = useState({});
  const [CarrierAnalytics, setCarrierAnalytcs] = useState([]);
  let startDate = `${current.split("-")[0]}-01-01`;
  const [destinations, setdestination] = useState([]);
  const [DileveyHour, setDileveyHour] = useState([]);
  const [PeakPickupDays, setPeakPickDay] = useState([]);
  const [Awarded, SetAwarded] = useState(0);
  const [MostBound, setMostBound] = useState([]);
  const [maxDistance, setMaxdistance] = useState([]);
  const [StopTotalTime, setStopTotalTime] = useState([]);
  const [PickUpHours, setPickUpHours] = useState([]);
  const [AverageCost, setAverageCost] = useState([]);
  let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
  let endDate = datepipeModel.datetostring(month2);
  const [origins, setorigins] = useState([]);
  const [ArryData, setArryData] = useState([]);
  const [filters, setFilter] = useState({
    startDate: startDate,
    endDate: endDate,
    compare: "",
    compareStart: "",
    compareEnd: "",
    type: "monthly",
  });
  const [bidChart, setBidChart] = useState([]);
  const [loadChart, setLoadChart] = useState([]);
  const [boards, setBoards] = useState([]);
  const [Frequency, setFrequency] = useState({});
  const [ChartData, setData] = useState([]);
  const [Board, setBoard] = useState([]);
  const [cartData, setChartData] = useState({
    totalBids: 0,
    totalLoads: 0,
  });

  const GetBoards = () => {
    ApiClient.get("boards").then((res) => {
      if (res.success) {
        setBoard(res?.data?.data);
      }
    });
  };
  const GetAwardedBids = (p) => {
    let f = { board_id: p?.board_id };

    ApiClient.get("bids?status=awarded", f).then((res) => {
      if (res.success) {
        SetAwarded(res?.data?.total);
      }
    });
  };

  const GetAnalytics = (p) => {
    let f = {};
    if (p?.board_id) {
      f = { board_id: p?.board_id };
    }

    ApiClient.get("total-loads", f).then((res) => {
      if (res.success) {
        setLoadData(res);
      }
    });
  };
  const options = {
    title: "Loads from diffrent Location",
    chartArea: { width: "50%" },
    colors: ["#b0120a", "#ffab91"],
    hAxis: {
      title: "Total Loads",
      minValue: 0,
    },
    vAxis: {
      title: "Loads",
    },
  };
  // let ChartData = [["Loads", "2010 Population", "2000 Population"]];
  const GetFrequency = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/lane-frequency", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.count,
            address: itm?.origin_address,
            date: `${itm?.origin_address} - ${itm?.destination_address}`,
          });
        });
      }

      setData(array);
    });
  };
  const BidsByChartData = [
    ["Location", "Total Loads"],
    ...((ChartData?.length > 0 &&
      ChartData?.map((item) => {
        return [`${item?.date}`, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetOrigins = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/orgin-volume", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.count,
            address: itm?.origin_address,
            date: itm?.origin_address,
          });
        });
      }

      setorigins(array);
    });
  };
  const GetAverage = [
    ["Origin Address", "Total", "Avg Cost ($)"],
    ...((AverageCost?.length > 0 &&
      AverageCost?.map((item) => {
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetAverageCost = (p) => {
    let array = [];
    let f = { ...filters, ...p };

    ApiClient.get("admin/dashboard/lane-frequency/cost-analysis").then(
      (res) => {
     
        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              avg_cost: itm?.avg_cost,
            });
          });
        }

        setAverageCost(array);
      }
    );
  };

  // const CarrierAnalytcics = [
  //   ["Origin Address", "Total", "Avg Cost ($)"],
  //   ...((CarrierAnalytics?.length > 0 &&
  //     CarrierAnalytics?.map((item) => {
  //       return [
  //         `${item?.address}`,
  //         item?.totalLoad,
  //         Math.round(item?.avg_cost),
  //       ];
  //     })) || [
  //     ["Element", "Density", { role: "style" }],
  //     ["Copper", 8.94, "#b87333"], // RGB value
  //     ["Silver", 10.49, "silver"], // English color name
  //     ["Gold", 19.3, "gold"],
  //     ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
  //   ]),
  // ];
  // const GetCarrierAnalytcs = () => {
  //   let array = [];
  //   ApiClient.get("admin/dashboard/lane-frequency/carrier-analysis").then(
  //     (res) => {
  //       if (res.success) {
  //         // setFrequency(res?.data);
  //         let croped = res?.data?.splice(0, 8);
  //         croped?.map((itm) => {
  //           array.push({
  //             totalLoad: itm?.count,
  //             address: itm?.origin_address,
  //             avg_cost: itm?.avg_cost,
  //           });
  //         });
  //       }

  //       setCarrierAnalytcs(array);
  //     }
  //   );
  // };

  const StopsTotalTimes = [
    ["Origin Address", "Total", "Avg. Cost ($)", "Time"],
    ...((StopTotalTime?.length > 0 &&
      StopTotalTime?.map((item) => {
        return [
          `${item?.address}`,
          item?.totalLoad,
          Math.round(item?.avg_cost),
          item?.total_time,
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetStopTotalTimes = (p) => {
    let f = { board_id: p?.board_id };

    let array = [];
    ApiClient.get("admin/dashboard/lane-frequency/stop-totaltime", f).then(
      (res) => {
     
        if (res.code == 200) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              address: itm?.origin_address,
              totalLoad: itm?.count,
              avg_cost: itm?.avg_stop_parload,
              total_time: itm?.total_time,
            });
          });
        }

        setStopTotalTime(array);
      }
    );
  };

  const DileveyHours = [
    ["Location", "Total Loads"],
    ...((DileveyHour?.length > 0 &&
      DileveyHour?.map((item) => {
        return [
          `${item?.address} delivered at ${moment(item?.delivered_at).format(
            "DD-MM-YYYY HH:MM A"
          )}`,
          item?.totalLoad,
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  
  const GetDelveryHours = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/peak-delvery-hours", f).then((res) => {

      if (res.code == 200) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            address: itm?.origin_address,
            totalLoad: itm?.count,
            delivered_at: itm?.delivered_at,
            // destination_address: itm?.destination_address,
          });
        });
      }

      setDileveyHour(array);
    });
  };

  const GetOirignBids = [
    ["Origin", "Total Loads"],
    ...((origins?.length > 0 &&
      origins?.map((item) => {
        return [`${item?.date} `, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const MaxInbound = (p) => {
    let array = [];

    let array1 = [];
    let f = { board_id: p?.board_id };
    ApiClient.get("admin/dashboard/orgin-volume", f).then((res) => {
      if (res.success) {
        ApiClient.get("admin/dashboard/destination-volume").then((resp) => {
          if (resp.success) {
            let croped = res?.data?.splice(0, 8);
            let croped2 = resp?.data?.splice(0, 8);
            croped?.map((itm) => {
              array1.push({
                totalLoad: itm?.count,
                address: itm?._id,
                date: itm?.origin_address,
              });
            });
            croped2?.map((itm) => {
              array.push({
                totalLoad: itm?.count,
                // address: itm?.origin_address,
                date: itm?.destination_address,
              });
            });
          }
        });
        // setFrequency(res?.data);
      }
      setMostInbound(array1);
      setMostBound(array);
    });
  };
  const MaxBounding = [
    ["Month", "Total Loads"],
    ...((MostBound?.length > 0 &&
      MostBound?.map((item) => {
        return [`${item?.date} `, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const MaxInboundArr = [
    ["Location", "Total Loads"],
    ...((MostInbound?.length > 0 &&
      MostInbound?.map((item) => {
        return [`${item?.date} `, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const LoadsByDistance = [
    ["Location", "Total Loads"],
    ...((maxDistance?.length > 0 &&
      maxDistance?.map((item) => {
        return [`${item?.date} `, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetDestination = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/destination-volume", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 8);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.count,
            address: itm?.origin_address,
            date: `${itm?.destination_address}`,
          });
        });
      }

      setdestination(array);
    });
  };
  const GetLoadsByDistance = [
    ["Location", "Total Loads"],
    ...((destinations?.length > 0 &&
      destinations?.map((item) => {
        return [`${item?.date}`, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const GetMaxDistance = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/lane-frequency/distance-analysis", f).then(
      (res) => {
        if (res.success) {
          setFrequency(res?.data);
          let croped = res?.data?.splice(0, 8);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.count,
              address: itm?.origin_address,
              date: `${Math.round(itm?.max_distance)} Mi`,
            });
          });
        }

        setMaxdistance(array);
      }
    );
  };
  const GetPickUpHours = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/peak-pickup-hours", f).then((res) => {
      if (res.success) {
        setFrequency(res?.data);
        let croped = res?.data?.splice(0, 10);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.total,
            percentage: itm?.percentage,
            date: itm?.hour,
          });
        });
      }

      setPickUpHours(array);
    });
  };
  const scansOverTimeOption = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };
  const scansOverTimeData = [
    ["Hours", "Total", "Percentage"],
    ...((PickUpHours?.length > 0 &&
      PickUpHours?.map((item) => {
        return [
          `${item?.date} ${item?.date > 12 || item?.date == 12 ? "PM" : "AM"}`,
          item?.totalLoad,
          Math.round(item?.percentage),
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const Getdeliveryoutside = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/delivery/outside-business-hours", f).then(
      (res) => {
        if (res.success) {
          // setFrequency(res?.data);
          let croped = res?.data?.splice(0, 10);
          croped?.map((itm) => {
            array.push({
              totalLoad: itm?.total,
              percentage: itm?.percentage,
              date: itm?.hour,
            });
          });
        }

        SetDileveryOutside(array);
      }
    );
  };
  const scansOverTimeOptionDilevery = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };
  const scansOverTimeDataDilevery = [
    ["Hour", "Percentage", "Total Loads"],
    ...((DileveryOutside?.length > 0 &&
      DileveryOutside?.map((item) => {
        return [
          `${item?.date} ${item?.date > 12 || item?.date == 12 ? "PM" : "AM"}`,
          Math.round(item?.percentage),
          item?.totalLoad,
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const GetPeakPickday = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/peak-pickup-day", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 10);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.total,
            percentage: itm?.percentage,
            date: itm?.weekday,
          });
        });
      }

      setPeakPickDay(array);
    });
  };

  const PeackPickupDayOptions = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };

  const PeackPickupDay = [
    ["Day", "Percentage", "Total Loads"],
    ...((PeakPickupDays?.length > 0 &&
      PeakPickupDays?.map((item) => {
        return [`${item?.date}`, Math.round(item?.percentage), item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const GetPeakPostingHOurs = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/peak-posting-hours", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 10);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.total,
            percentage: itm?.percentage,
            hour: itm?.hour,
          });
        });
      }

      setPeakPostingHour(array);
    });
  };

  const PeackPostingHoursOptions = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };
  const PeackPostingHours = [
    ["Hours", "Percentage", "Total Loads"],
    ...((PeakPostingHour?.length > 0 &&
      PeakPostingHour?.map((item) => {
        return [
          `${item?.hour} ${item?.hour > 12 || item?.hour == 12 ? "PM" : "AM"}`,
          Number(Math.round(item?.percentage)),
          item?.totalLoad,
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const GetPeakPostingMonths = (p) => {
    let array = [];
    let f = { board_id: p?.board_id };

    ApiClient.get("admin/dashboard/peak-pickup-month", f).then((res) => {
      if (res.success) {
        // setFrequency(res?.data);
        let croped = res?.data?.splice(0, 10);
        croped?.map((itm) => {
          array.push({
            totalLoad: itm?.total,
            percentage: itm?.percentage,
            date: itm?.month,
          });
        });
      }

      setPeakPostingMonths(array);
    });
  };

  const PeackPostingMonthsOptions = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };
  const GetMonths = (id) => {
    switch (id) {
      case 1:
        return "January";
        break;
      case 2:
        return "February";
        break;
      case 3:
        return "March";
        break;
      case 4:
        return "April";
        break;
      case 5:
        return "May";
        break;
      case 6:
        return "June";
        break;
      case 7:
        return "July";
        break;
      case 8:
        return "August";
        break;
      case 9:
        return "September";
        break;
      case 10:
        return "October";
        break;
      case 11:
        return "November";
        break;
      case 12:
        return "December";
    }
  };
  const PeackPostingMonths = [
    ["Month", "Percentage", "Total Loads"],
    ...((PeakPostingMonths?.length > 0 &&
      PeakPostingMonths?.map((item) => {
        return [GetMonths(item?.date), item?.percentage, item?.totalLoad];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const [bidData, setbidData] = useState();

  const getData = (p = {}) => {
    getAnalytic(p);
    getTotalBids(p);
    GetStatistics(p);
    getTotalBoard(p);
    GetPickUpHours();
    Getdeliveryoutside();
    GetPeakPickday();
    GetPeakPostingHOurs(p);
    GetPeakPostingMonths(p);
    // // GetCarrierAnalytcs();
    GetStopTotalTimes(p);
    GetFrequency(p);
    GetOrigins(p);
    GetDestination(p);
    MaxInbound(p);
    GetAnalytics(p);
    GetMaxDistance(p);
    GetDelveryHours(p);
    GetAwardedBids(p);
  };

  const ChangeStatus = (key) => {
    getData({ ...filters, board_id: key?.id });
    setFilter({ ...filters, board_id: key?.id });
  };

  const getAnalytic = (p = {}) => {
    let f = { ...filters, ...p };
    loader(true);
    ApiClient.get("analytics", f).then((res) => {
      if (res.success) {
        let totalBids = 0;

        let data = res.BidsCounts.response.map((itm) => {
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == "daily") itm.date = `${itm.day} ${month}`;
          totalBids += itm.totalBidsCounts;
          return itm;
        });

        let totalLoads = 0;
        let ldata = res.LoadsCounts.response.map((itm) => {
          let month = `${datepipeModel.monthfind(itm.month - 1)}`;
          itm.date = `${month} ${itm.year}`;
          if (f.type == "daily") itm.date = `${itm.day} ${month}`;
          totalLoads += itm.totalBidsCounts;
          return itm;
        });

        setChartData({
          totalBids: totalBids,
          totalLoads: totalLoads,
        });

        setBidChart(data);
        setLoadChart(ldata);
      }
      loader(false);
    });
  };

  const GetStatistics = (f) => {
    let fltr = { ...filters, ...f };
    ApiClient.get("statistics/all-loads", fltr).then((res) => {
      if (res.success) {
        setStatistics(res?.data);
      }
    });
  };

  const getTotalBids = (p) => {
    let f = { ...filters, ...p };
    ApiClient.get("total-bids", f).then((res) => {
      if (res.success) {
        setbidData(res);
      }
    });
  };

  const getTotalBoard = (p) => {
    let f = { board_id: p?.board_id };
    ApiClient.get("total-load/board", f).then((res) => {
      if (res.success) {
        setBoards(res.data);
      }
    });
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData(p);
  };

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
      type: e.type,
    };
    if (
      datepipeModel.datetostring(e.startDate) ==
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      filter({ ...f });
    }
  };

  useEffect(() => {
    if (user && user?.id) {
      getData();
      GetBoards();
    }

    // GetAverageCost();
  }, [user]);

  return (
    <Layout>
      <div className="row d-flex align-items-center">
        <div className="col-md-9 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {" "}
            <span className="dsh_hedding">Hi</span>
            {user && methodModel.capitalizeFirstLetter(user?.fullName)}
          </h2>
          <p className="paraCls">
            Here’s what’s going on with your team One Brokerage
          </p>
        </div>
        <div>
          <div className="d-flex gap-2 ml-auto justify-content-end flex-wrap align-items-center">
            <div className="dropdown addDropdown mt-0">
              <button
                className="btn btn-primary dropdown-toggle "
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {Filtername
                  ? Filtername == ""
                    ? "All"
                    : methodModel.capitalizeFirstLetter(Filtername)
                  : "All"}
              </button>
              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={
                    Filtername == "" ? "dropdown-item active" : "dropdown-item"
                  }
                  onClick={() => {
                    ChangeStatus("");
                    setFIltername("All");
                  }}
                >
                  All
                </a>
                {Board &&
                  Board?.map((itm) => {
                    return (
                      <a
                        className={
                          filters.board_id == itm?.id
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        onClick={() => {
                          ChangeStatus(itm);
                          setFIltername(itm?.name);
                        }}
                      >
                        {methodModel.capitalizeFirstLetter(itm?.name)}
                      </a>
                    );
                  })}
              </div>
            </div>
            <DateRangePicker
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
          </div>
        </div>
        <div className="cards_sales">
          <div className="row">
            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{bidData?.total_bids}</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("BidStatus", "awarded");
                    Navigate.push("/awarded-bids");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Awarded Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {bidData?.awarded_bids || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("BidStatus", "accepted");
                    Navigate.push("/accepted-bids");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Accepted Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {bidData?.accepted_bids || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("BidStatus", "rejected");
                    Navigate.push("/rejected-bids1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Rejected Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {bidData?.rejected_bids || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("BidStatus", "pending");
                    Navigate.push("/pending-bids1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Pending Bids</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {bidData?.pending_bids || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    // localStorage.setItem("tabs", "pending-pickup");
                    Navigate.push("/pickedUp-loads1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Pickedup Loads</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {LoadsData?.pickedup_loads || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    Navigate.push("/loads1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Loads</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/wallet.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {LoadsData?.total_loads || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    // localStorage.setItem("ShipmentStatus", "pending");
                    Navigate.push("/loads1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Pending Loads</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/wallet.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {LoadsData?.pending_loads || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("ShipmentStatus", "delivered");
                    Navigate.push("/delivered-loads1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Delivered Loads</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/wallet.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {LoadsData?.delivered_loads || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 my-3">
              <div className="sales_section">
                <div
                  className="main_sales pointer"
                  onClick={() => {
                    localStorage.setItem("ShipmentStatus", "in_transit");
                    Navigate.push("/intransit-loads1");
                  }}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total In-Transit Loads</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/wallet.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {LoadsData?.intransit_loads || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* graph Design */}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Bids</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/bids1`)}
                    >
                      View Bids
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="">
                  <AllChart
                    data={bidChart}
                    legends={[{ label: "Total Bids", key: "totalBidsCounts" }]}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="300px"
                    data={BidsByChartData}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads By Origin</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="350px"
                    data={GetOirignBids}
                    options={{ pieSliceText: "none" }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads By Destinations</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="300px"
                    data={GetLoadsByDistance}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Peak Pickup Hours</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="350px"
                    data={scansOverTimeData}
                    // options={scansOverTimeOption} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Peak Pickup Days Of Week</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={PeackPickupDay}
                    options={PeackPickupDayOptions} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Peak Posting Hours</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={PeackPostingHours}
                    // options={PeackPostingHoursOptions} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Peak Pickup Months</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={PeackPostingMonths}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Delivery Outside Business Hours
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={scansOverTimeDataDilevery}
                    options={scansOverTimeOptionDilevery} //
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Most Outbound Locations</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={MaxBounding}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Most Inbound Locations</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={MaxInboundArr}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads By Max Distance</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={LoadsByDistance}
                    options={{
                      colors: ["#fc466b", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads by Average Cost</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={GetAverage}
                    options={{
                      colors: ["#febf01", "#a5c956"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                 
                </div>
              </div>
            </div> */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      Loads by Stops with Total Time
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={StopsTotalTimes}
                    options={{
                      colors: ["#fc466b", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">
                      {" "}
                      Tops Loads by dilevery Time
                    </h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="300px"
                    data={DileveyHours}
                    options={{
                      colors: ["#42a2eb", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Loads</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/loads1`)}
                    >
                      View Loads
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="">
                  <AllChart
                    data={loadChart}
                    legends={[
                      { label: "Total Loads", key: "totalLoadsCounts" },
                    ]}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 cls">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Pickup by Hours</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/bids`)}
                    >
                      View Bids
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div>
                 
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="350px"
                    data={scansOverTimeData}
                    // options={scansOverTimeOption} //
                  />
                </div>
              </div>
            </div> */}
            <div className="col-12 col-sm-12 mt-2">
              <div className="graph_section">
                <div className="graph_range">
                  <div>
                    <h3 className="Amount_sales">Load Classification</h3>
                  </div>

                  <div>
                    <p
                      className="view_graph"
                      onClick={(e) => Navigate.push(`/boards1`)}
                    >
                      View Load Classification
                      <span className="arrowss">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="revenue_graph">
                  {/* table */}

                  <div className="table-responsive table_section  add mt-0 p-0">
                    <table class="table ">
                      <tbody>
                        {boards.map((itm) => {
                          return (
                            <tr className="dashboard">
                              <td>
                                <div className="shipment_section">
                                  <div className="orders">
                                    <div className="ship_items">
                                      <img
                                        src="/assets/img/Item.png"
                                        className="order_ship"
                                      />
                                    </div>
                                    <div>
                                      <h3 className="item_no text-capitalize">
                                        {itm.load_name}
                                      </h3>
                                      {/* <p className="item_name">{itm.load_name}</p> */}
                                    </div>
                                  </div>

                                  <div className="item_rate">
                                    <div>
                                      <h5 className="item_price">
                                        {itm.total_load}
                                      </h5>
                                      {/* <p className="shipment_time">
                                    Overdue 10 hours
                                  </p> */}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
