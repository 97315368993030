import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChangePassword from "../../components/Profile/ChangePassword";
import EditProfile from "../../components/Profile/Edit";
import Layout2 from "../../components/global/layout2";
// import { useSearchParams } from "react-router-dom";
const Profile2 = () => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [tab, settab] = useState("view");
  const history = useHistory();
  // const Params = useSearchParams()
  const url = new URL(window.location.href);

  // Get the search query parameters
  const searchParams = new URLSearchParams(url.search);

  // Get a specific parameter value
  const paramValue = searchParams.get("id");

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`admin/profile`).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };
  function removeQueryParam(key) {
    var url = window.location.href;
    var urlParts = url.split("?");

    if (urlParts.length >= 2) {
      var prefix = encodeURIComponent(key) + "=";
      var query = urlParts[1].split("&");

      for (var i = 0; i < query.length; i++) {
        if (query[i].lastIndexOf(prefix, 0) !== -1) {
          query.splice(i, 1);
        }
      }

      url = urlParts[0] + (query.length > 0 ? "?" + query.join("&") : "");
    }

    window.history.replaceState({}, document.title, url);
  }

  useEffect(() => {
    if (paramValue) {
      document.getElementById("v-pills-profile-tab").click();
    }
    // if (user && user?.loggedIn) {
    gallaryData();
    // }
  }, []);

  return (
    <Layout2 title="profile" Sidetitle="Profile">
      {/* /////////////////////////////////////////////////////////////////////////////////////////// */}
    <div className="mainareas">
    <div className="portal-page profile-tabs new-profile-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-9 mx-auto">
              <div className="box-shadow-prop">
                <div className="row">
                  <div className="col-lg-4 p-0">
                    <div className="profile-tabs-left">
                      <div
                        class="nav flex-column nav-pills set_pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          onClick={() => {
                            removeQueryParam("id");
                          }}
                          class="nav-link active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <i
                            class="fa fa-user-circle me-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Profile
                        </button>
                        <button
                          class="nav-link"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          <i class="fa fa-lock me-2" aria-hidden="true"></i>{" "}
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 p-0">
                    <div className="pprofile1">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                          tabindex="0"
                        >
                          {tab == "view" ? (
                            <>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className=" d-flex justify-content-between  mb-5">
                                    <h3 className="ViewUser mb-0 user-back">
                                      {/* <i
                                        class="fa fa-arrow-left mr-2 "
                                        onClick={() => {
                                          history.goBack();
                                        }}
                                        title="Back"
                                        aria-hidden="true"
                                      ></i> */}
                                      View Profile
                                    </h3>

                                    <div className="edit-bg">
                                      <i
                                        style={{ cursor: "pointer" }}
                                        class="material-icons edit"
                                        title="Edit"
                                        onClick={() => {
                                          settab("edit");
                                        }}
                                      >
                                        edit
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 inputFlex-main">
                                  <label>Image:</label>
                                  <div>
                                    <label className="profileImageLabel">
                                      <img
                                        src={methodModel.userImg(
                                          data && data.image
                                        )}
                                        className="profileImage"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12 inputFlex-main mt-3">
                                  <label> Name:</label>
                                  <div>
                                    <p className="profile_data">
                                      {data &&
                                        methodModel.capitalizeFirstLetter(
                                          data.fullName
                                        )}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-12 inputFlex-main mt-3">
                                  <label>Email:</label>
                                  <div>
                                    <p className="profile_data">
                                      {data && data.email}
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="col-md-12 inputFlex-main mt-3">
                                  <label>Role:</label>
                                  <div>
                                    <p className="profile_data">
                                      {data &&
                                        methodModel.capitalizeFirstLetter(
                                          data.role
                                        )}
                                    </p>
                                  </div>
                                </div> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <EditProfile
                                result={() => {
                                  gallaryData();
                                  settab("view");
                                }}
                              />
                            </>
                          )}
                        </div>
                        <div
                          class="tab-pane fade"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                          tabindex="0"
                        >
                          <ChangePassword removeQueryParam={removeQueryParam} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout2>
  );
};

export default Profile2;
