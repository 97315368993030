import React from "react";
import methodModel from "../../../methods/methods";
import statusModel from "../../../models/status.model";
import ImageUpload from "../../../components/common/ImageUpload";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import Layout from "../../../components/global/layout";
import rolesModel from "../../../models/roles.model";
import SelectDropdown from "../../../components/common/SelectDropdown";
import PhoneInput from "react-phone-input-2";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ApiClient from "../../../methods/api/apiClient";
// import Input, { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
// import countryNames from 'react-phone-number-input/locale/en'

const Html = ({
  role,
  form,
  handleSubmit,
  setform,
  roles,
  addressResult,
  submitted,
  images,
  imageResult,
  getError,
  setEyes,
  eyes,
  back,
  emailCheck,
  emailErr,
  emailLoader,
  detail,
  ChangeStatus,
  statusChange,
}) => {
  const user = useSelector((state) => state.user);
  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const Roles = [
    {
      id: "Staff",
      name: "Staff",
    },
    {
      id: "Carrier",
      name: "Carrier",
    },
  ];
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <h3 className="ViewUser">
              {form && form.id ? "Edit" : "Add"}{" "}
              {role ? rolesModel.name(role) : "User"}
            </h3>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label>
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.firstName}
                  onChange={(e) =>
                    setform({ ...form, firstName: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.lastName}
                  onChange={(e) =>
                    setform({ ...form, lastName: e.target.value })
                  }
                  required
                />
              </div>
              {role ? (
                <></>
              ) : (
                <div className="col-md-6 mb-3">
                  <label>
                    Role<span className="text-danger">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Role"
                    intialValue={form.role}
                    disabled={form.id ? true : false}
                    result={(e) => {
                      setform({ ...form, role: e.value });
                    }}
                    options={Roles}
                    required
                  />
                  {submitted && !form.role ? (
                    <div className="invalid-feedback d-block">
                      Role is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div className="col-md-6 mb-3">
                <label>Address</label>
                <GooglePlaceAutoComplete
                  value={form.address}
                  result={addressResult}
                  id="address"
                  placeholder=""
                />
              </div>

              <div className="col-md-6 mb-3 ">
                <label className=" d-block">
                  Mobile No<span className="star">*</span>
                </label>

                <div className="w-100  d-inline-flex">
                  <PhoneInput
                    value={form.dialCode + "" + form.mobileNo}
                    countryCodeEditable={false}
                    enableSearch={true}
                    placeholder=""
                    country="us"
                    onChange={(phone, country) => {
                      let phonenumber = phone.replace(country.dialCode, "");
                      // if(phonenumber!=form.mobileNo){
                      // setform({ ...form, mobileNo: phonenumber })
                      // }
                      setform({
                        ...form,
                        dialCode: country.dialCode,
                        mobileNo: phonenumber,
                      });
                    }}
                    required
                  />
                </div>
                {submitted && !form.dialCode ? (
                  <div className="invalid-feedback d-block">
                    DialCode is Required
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={form.email}
                  onChange={(e) => {
                    setform({ ...form, email: e.target.value });
                    emailCheck(e.target.value);
                  }}
                  required
                />
                {emailErr ? (
                  <div className="invalid-feedback d-block">{emailErr}</div>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label>Buisness Name </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.companyName}
                  onChange={(e) => {
                    setform({ ...form, companyName: e.target.value });
                  }}
                />
              </div>

              {detail &&
                detail.role &&
                detail.role._id == "6540cfa35a7513892be61415" ? (
                <div className="col-md-6 mb-3">
                  <label>Merchant Fee (%)</label>
                  <input
                    className="form-control"
                    type="number"
                    // onKeyPress={e=>methodModel.isNumber(e)}
                    value={form.merchant_fee}
                    onChange={(e) => {
                      setform({ ...form, merchant_fee: e.target.value });
                    }}
                  />
                </div>
              ) : null}

              <div className="col-md-6  mb-3">
                <label className="lablefontcls">Image</label>
                <br></br>
                <ImageUpload
                  model="users"
                  result={(e) => imageResult(e, "image")}
                  value={images.image || form.image}
                  multiple={false}
                />
              </div>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary  mr-2"
                onClick={(e) => back()}
              >
                Back
              </button>
              {!form.id || (form.id && isAllow("editAdmins")) ? (
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
