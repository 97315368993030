import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import CommonRejectModal from "../CommonDelete&ActiveModal/CommonRejectModel";
import ApiClient from "../../methods/api/apiClient";
import { Tooltip } from "antd";

const Requests = ({
  view,
  edit,
  AcceptCarrier,
  RejectCarrier,
  user,
  ChangeFilter,
  setShowRejectModel,
  showRejectModel,
  reset,
  sorting,
  tab,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  AssignBoard,
  total = { total },
}) => {
  const Navigate = useHistory();
  const [IDSArray, setIDSArry] = useState([]);
  const [enableNote, setEnableNote] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [Reason, setReason] = useState("");
  const [AccecptID, setAcceptID] = useState("");
  const [RejectID, setRejectID] = useState("");
  const [notes, setNote] = useState("");
  const [selectedBoards, setSelectedBoards] = useState([]);
  const Columns = JSON.parse(localStorage.getItem("RequestColumn")) || [];
  const columns = ["email", "createdAt", "updatedAt"];
  const [visibleColumns, setVisibleColumns] = useState(Columns);

  useEffect(() => {
    localStorage.setItem("RequestColumn", JSON.stringify(visibleColumns));
  }, [visibleColumns]);
  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "name":
        return value;
        break;
      case "email":
        return value;
        break;
      case "createdAt":
        return datepipeModel.date(value);
        break;
      case "updatedAt":
        return datepipeModel.date(value);
        break;

      case "createdAt":
        return datepipeModel.date(value);
        break;
      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return value;
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }
  const Delete = () => {
    deleteItem(DeleteId);
  };

  const Reject = () => {
    RejectCarrier(RejectID, Reason);
  };
  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const [boards, setBoards] = useState([]);
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }
  const getboards = () => {
    ApiClient.get("boards", { status: "active" }).then((res) => {
      if (res.success) {
        setBoards(res?.data?.data);
      }
    });
  };

  const handleNotes = () => {
    setEnableNote(true);
  };

  useEffect(() => {
    getboards();
  }, []);

  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonRejectModal
        show={showRejectModel}
        setShow={setShowRejectModel}
        confirm={Reject}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between align-items-lg-center align-items-md-start align-items-center ">
        <h3 className="hedding">
          {role ? rolesModel.name(role) : "Carriers Request"}
        </h3>

        <article className="d-flex filterFlex phView">
          {/* <div className="dropdown addDropdown chnagesg mr-2 d-flex align-items-center">
            <div
              className="dropdown-menu shadow bg_hover"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("") & console.log(filters)}
              >
                All
              </a>
              <a
                className={
                  filters.status == "active"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("active")}
              >
                Active
              </a>
              <a
                className={
                  filters.status == "deactive"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("deactive")}
              >
                Inactive
              </a>
            </div>
          </div> */}
          <div className="dropdown addDropdown chnagesg mr-2 d-flex align-items-center equal-width-50 w-100 mb-2 mt-0">
            <button
              className="btn blck-border-btn dropdown-toggle removeBg mb-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {filters?.status ? (
                <>{filters?.status == "deactive" ? "Inactive" : "Active"}</>
              ) : (
                "All Status"
              )}
            </button>
            <div
              className="dropdown-menu shadow bg_hover "
              aria-labelledby="dropdownMenuButton"
            >
              {/* <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("")}
              >
                All Status
              </a> */}

              <a
                className={
                  filters.status == ""
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("") }
              >
                All
              </a>
              <a
                className={
                  filters.status == "active"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("active")}
              >
                Active
              </a>
              <a
                className={
                  filters.status == "deactive"
                    ? "dropdown-item active"
                    : "dropdown-item"
                }
                onClick={() => ChangeStatus("deactive")}
              >
                Inactive
              </a>
            </div>
          </div>
          <div className="dropdown addDropdown mr-2 equal-width-50 w-100 mt-0">
            <button
              className="btn btn blck-border-btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Add Columns
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              {findUniqueElements(visibleColumns, columns).map((itm) => {
                return (
                  <a
                    className={"dropdown-item"}
                    onClick={() => handleColumnToggle(itm)}
                  >
                    {replaceUnderscoresWithSpace(itm)}
                  </a>
                );
              })}
            </div>
          </div>
          {filters?.status ? (
            <>
              <a
                className="btn btn-primary text-white mr-2"
                onClick={(e) => reset()}
              >
                <i className="fas fa-redo-alt me-2"></i>
                Reset
              </a>
            </>
          ) : (
            <></>
          )}
        </article>
      </div>

      {tab == "grid" ? (
        <>
          <div className="cardList">
            <div className="row">
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <div className="new_cards">
                        <div className="user_card">
                          <div
                            className="user_detail"
                            onClick={(e) => view(itm.id)}
                          >
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />

                            <div className="user_name">
                              <h4 className="user">{itm.fullName}</h4>
                              <p className="user_info">{itm.email}</p>
                            </div>
                          </div>

                          <div
                            className={`user_hours ${itm.status}`}
                            onClick={() => statusChange(itm)}
                          >
                            <span className="contract">{itm.status}</span>
                          </div>
                        </div>

                        <div className="user_proff user_proff1">
                          <div className="id_name">
                            <ul className="user_list">
                              <li className="list_name">
                                <a className="id">Role</a>
                              </li>
                              <li className="list_name">
                                <a className="id">Phone number</a>
                              </li>
                            </ul>
                          </div>
                          <div className="detail_list">
                            <ul className="user_list">
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  {itm.role?.name}
                                </a>
                              </li>
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <span className="call_icon"></span>
                                  {itm.mobileNo ? (
                                    <>
                                      <i
                                        class="fa fa-phone"
                                        aria-hidden="true"
                                      ></i>
                                      {itm.dialCode} {itm.mobileNo}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table-responsive table_section">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row">
                  <th
                    scope="col"
                    className="table_data pointer"
                    onClick={(e) =>
                      filters?.sortBy == "fullName asc"
                        ? sorting("fullName desc", "desc")
                        : sorting("fullName asc", "asc")
                    }
                  >
                    {/* Name {filters?.sortBy === "fullName asc" ? "↑ A-Z" : "↓ Z-A"} */}
                    <span className="d-flex align-items-center"> Carrier Name{" "}
                      {filters?.sortBy === "fullName asc"
                        ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                        : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="table_data pointer"
                    onClick={(e) =>
                      filters?.sortBy == "fullName asc"
                        ? sorting("fullName desc", "desc")
                        : sorting("fullName asc", "asc")
                    }
                  >
                    {/* Name {filters?.sortBy === "fullName asc" ? "↑ A-Z" : "↓ Z-A"} */}
                    <span className="d-flex align-items-center"> DOT#{" "}
                      {/* {filters?.sortBy === "fullName asc"
                      ? <div class="d-flex  zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">A </span> <span className="afont">Z</span></div> <span><span class="material-icons arrfont">north</span></span></div>
                      : <div class="d-flex zfonts align-items-center"><div className="d-flex flex-column"><span className="zfont">Z </span>  <span className="afont">A</span></div><span><span class="material-icons arrfont">south</span></span></div>} */}
                    </span>
                  </th>
                  {visibleColumns.map((item) => (
                    <th className="text-capitalize table_data">
                      {replaceUnderscoresWithSpace(item)}{" "}
                      <i
                        className="fa fa-times"
                        onClick={(e) => handleColumnToggle(item)}
                      ></i>{" "}
                    </th>
                  ))}
                  <th scope="col" className="table_data ">
                    Status
                  </th>

                  <th scope="col" className="table_data">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  ListingData &&
                  ListingData.map((itm, i) => {
                    return (
                      <tr className="data_row">
                        <td
                          className="table_dats"
                          onClick={(e) => {
                            if (
                              Permission?.carrier_get ||
                              user?.role == "admin"
                            ) {
                              view(itm.id);
                            } else {
                              toast.warn("You do not have valid permission");
                            }
                          }}
                        >
                          <div className="user_detail">
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />
                            {/* <img alt="image" src={methodModel.userImg(user.image)} className="rounded-circle mr-1 user_imgs" /> */}

                            <div className="user_name">
                              <h4 className="user">
                                {methodModel.capitalizeFirstLetter(
                                  itm.fullName
                                )}
                              </h4>
                              {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                            </div>
                          </div>
                        </td>
                        <td className="table_dats">{itm?.dot_number || "--"}</td>
                        {visibleColumns.map((item, index) => (
                          <td className="text-capitalize">
                            {ColumnReturner(item, itm[[item]], itm)}
                          </td>
                        ))}

                        <td className="table_dats">
                          {" "}
                          <div className={` ${itm.status}`}>
                            <span className="custom-toggle-btn">
                              {itm.status == "deactive" ? (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={false}
                                />
                              ) : (
                                <Switch
                                  onChange={(e) => {
                                    if (
                                      Permission?.carrier_edit ||
                                      user?.role == "admin"
                                    ) {
                                      setStatusData(itm);
                                      setShowActiveModal("block");
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                  checked={true}
                                />
                              )}
                            </span>
                          </div>
                        </td>

                        {/* dropdown */}
                        <td className="table_dats">
                          <div className="action_icons">
                            {itm?.request_status != "pending" ? (
                              <>
                                {isAllow("editAdmins") ? (
                                  <>
                                    <Tooltip placement="top" title="Edit">
                                      <a
                                        className="edit_icon"
                                        title="Edit"
                                        onClick={(e) => {
                                          if (
                                            Permission?.carrier_edit ||
                                            user?.role == "admin"
                                          ) {
                                            edit(itm.id);
                                          } else {
                                            toast.warn(
                                              "You do not have valid permission"
                                            );
                                          }
                                        }}
                                      >
                                        <i
                                          class="material-icons edit"
                                          
                                        >
                                          edit
                                        </i>
                                      </a>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {isAllow("deleteAdmins") ? (
                                  <>
                                    <Tooltip placement="top" title="Delete">
                                      <span
                                        className="edit_icon"
                                        
                                        onClick={() => {
                                          if (
                                            Permission?.carrier_delete ||
                                            user?.role == "admin"
                                          ) {
                                            setDeleteId(itm.id);
                                            setShowDeleteModal("block");
                                          } else {
                                            toast.warn(
                                              "You do not have valid permission"
                                            );
                                          }
                                        }}
                                      >
                                          <i
                                          class="material-icons delete"
                                          
                                        >
                                          {" "}
                                          delete
                                        </i>
                                      </span>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {
                                  user?.role == 'admin' || Permission?.carrier_edit ? <Tooltip placement="top" title="Accept">
                                    <span
                                      data-bs-toggle="modal"
                                      data-target={`${user?.role == 'admin' || Permission?.carrier_edit ? '#exampleModal' : ''}`}
                                      className="edit_icon text-success me-2"
                                      onClick={() => {
                                        if (
                                          Permission?.carrier_edit ||
                                          user?.role == "admin"
                                        ) {
                                          document
                                            .getElementById("modelBoard")
                                            .click();

                                          setAcceptID(itm?.id);
                                          // AcceptCarrier(itm?.id);
                                          // setShowAcceptModal("block");
                                        } else {
                                          toast.warn(
                                            "You do not have valid permission"
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        color="green"
                                        className="fa fa-check"
                                      ></i>
                                    </span>
                                  </Tooltip> : null
                                }
                                {
                                  user?.role == 'admin' || Permission?.carrier_edit ? <Tooltip placement="top" title="Reject">
                                    <span
                                      className="edit_icon text-danger"
                                      onClick={() => {
                                        if (
                                          Permission?.carrier_edit ||
                                          user?.role == "admin"
                                        ) {
                                          document
                                            .getElementById("OpenReasonModel")
                                            .click();
                                          setRejectID(itm?.id);
                                          // setShowRejectModel("block");
                                        } else {
                                          toast.warn(
                                            "You do not have valid permission"
                                          );
                                        }
                                      }}
                                    >
                                      <i color="red" className="fa fa-times"></i>
                                    </span>
                                  </Tooltip> : null
                                }
                              </>
                            )}
                            {user?.role == "admin" ||
                              Permission?.carrier_request_get ? (
                              <Tooltip placement="top" title="View">
                                <span
                                  className="edit_icon"

                                  onClick={() => {
                                    if (
                                      Permission?.carrier_request_get ||
                                      user?.role == "admin"
                                    ) {
                                      Navigate.push(`userdetail/${itm.id}`);
                                    } else {
                                      toast.warn(
                                        "You do not have valid permission"
                                      );
                                    }
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Tooltip>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {(!loaging && total == 0) || data?.length < 1 ? (
        <div className=" no-data">
          <img src="assets/img/no-data.png" />
          No Data
        </div>
      ) : (
        <></>
      )}

      {!loaging && total > 0 ? (
        <div className="paginationWrapper">
          <div className="d-flex align-items-center">
            <div className="me-2 user-name-color">Show</div>
            {total > 0 && (
              <div className="dropdown addDropdown chnagesname ">
                <button
                  className="btn btn-primary dropdown-toggle removeBg"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filters?.count}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 5 });
                    }}
                  >
                    5
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 10, role: "carrier" });
                    }}
                  >
                    10
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 20, role: "carrier" });
                    }}
                  >
                    20
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 30, role: "carrier" });
                    }}
                  >
                    30
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      ChangeFilter({ ...filters, count: 40, role: "carrier" });
                    }}
                  >
                    40
                  </a>
                </div>
              </div>
            )}{" "}
            <div className="ms-2 user-name-color">from {total} Users</div>
          </div>

          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      <button
        style={{ display: "none" }}
        id="modelBoard"
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade select-board-modal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Board
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!enableNote && (
              <>
                <div
                  style={{ justifyContent: "space-around" }}
                  class="modal-body "
                >
                  <div className="row">
                    <div className="col-md-12">
                      <label className="profileheddingcls"> Select Board</label>
                      <div
                        className={`${boards?.length > 6 ? "scroll-height" : ""
                          } boards-details`}
                      >
                        {boards?.map((itm) => {
                          return (
                            <div
                              className="d-flex justify-content-between my-1 board-view"
                              key={itm.id}
                            >
                              <label className="mb-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedBoards(
                                        (prevSelectedBoards) => [
                                          ...prevSelectedBoards,
                                          itm.id,
                                        ]
                                      );
                                    } else {
                                      setSelectedBoards((prevSelectedBoards) =>
                                        prevSelectedBoards.filter(
                                          (id) => id !== itm.id
                                        )
                                      );
                                    }
                                  }}
                                  checked={selectedBoards.includes(itm.id)}
                                />
                                {methodModel.capitalizeFirstLetter(itm?.name)}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3  mt-4">
                      <label className="profileheddingcls">
                        {" "}
                        Note <span className="add-notes">(Private Notes)</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={notes}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    id="CloseBoardModel"
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      if (selectedBoards.length > 0) {
                        AssignBoard(AccecptID, selectedBoards, notes);
                      } else {
                        toast.error("select one board");
                      }
                    }}
                  >
                    Accept
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <button
        style={{ display: "none" }}
        type="button"
        class="btn btn-primary"
        id="OpenReasonModel"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        data-bs-whatever="@mdo"
      >
        Open modal for @mdo
      </button>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Reason to Reject
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label class="profileheddingcls"> Reason</label>
              <form>
                <div class="mb-3">
                  {/* <label for="message-text" class="col-form-label">Message:</label> */}
                  <textarea
                    value={Reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    class="form-control"
                    id="message-text"
                  ></textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                id="CloseReasonModel"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  Reject();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Requests;
