import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
// import Faq from "./Faqlisting";
import loader from "../../methods/loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddeditContent = () => {
  const [form, setform] = useState({
    title: "",
    meta_title: "",
    description: "",
    slug:'',
  });
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoader] = useState(true);
  const { id } = useParams();
  const history = useHistory();

  const AddFaqdata = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!form?.title || !form?.description) {
      return;
    }
    let payload = { ...form, id: id };
    if (id) {
      loader(true);
      delete payload.slug
      ApiClient.put("content", payload).then((res) => {
        history.push("/contents1");
        toast.success(res.message);
        loader(false);
      });
    } else {
      delete payload.id;
      ApiClient.post("content", payload).then((res) => {
        if (res.success) {
          loader(true);
          history.push("/contents1");
          toast.success(res.message);
          loader(false);
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      loader(true);
      setLoader(true)
      ApiClient.get(`content?id=${id}`).then((res) => {
        const newdata = res.data;
        setform({
          title: res?.data?.title,
          description: res?.data?.description,
          meta_title: res?.data?.meta_title,
          meta_key: res?.data?.meta_key,
          slug: res?.data?.slug,
        });
        setLoader(false)
        loader(false);
      });
    }
  }, [id]);

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);

    // if (!Permission?.faq_add) {
    //   history.push("/dashboard");
    // }
  }, []);

  return (
    <Layout>
      <form onSubmit={(e) => AddFaqdata(e)} className="reverseprofile">
        <div className="pprofile1 edit-page-common p-0">
          <div className="row">
            <div className="col-lg-12 ">
              <div className=" title-head">
                <h3 className="ViewUser mb-0 user-back">
                  <Link to="/contents1" className="">
                    <i
                      className="fa fa-arrow-left ml-1 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </Link>
                  {id ? "Edit" : "Add"} Content
                </h3>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Content Details</h5>
                </div>
                <div className="row ">
                  <div className="col-md-12 mb-3">
                    <label>
                      Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form?.title}
                      // required
                      onChange={(e) =>
                        setform({ ...form, title: e.target.value })
                      }
                    />
                    {submitted && !form?.title ? (
                      <div className="invalid-feedback d-block">
                        Title is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form?.slug}
                      disabled={id?true:false}
                      onChange={(e) =>
                        setform({ ...form, slug: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Meta Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form?.meta_title}
                      name="question"
                      // required
                      onChange={(e) =>
                        setform({ ...form, meta_title: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Meta Key</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form?.meta_key}
                      name="question"
                      // required
                      onChange={(e) =>
                        setform({ ...form, meta_key: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3 react_desc">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    {loading?<>
                    <div>Loading...</div>
                    </>:<>
                    <ReactQuill
                      theme="snow"
                      value={form?.description}
                      onChange={(e) => {
                        setform({ ...form, description: e });
                      }}
                    />
                    </>}
                   
                    {submitted && !form?.description ? (
                      <div className="invalid-feedback d-block">
                        Description is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-3">
                                        <label>
                                            Status<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                        // value={form.name}
                                        // onChange={(e) =>
                                        //   setform({ ...form, name: e.target.value })
                                        // }
                                        />

                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="text-right mr-2 mb-2">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
};
export default AddeditContent;
