import React from "react";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineDeliveredProcedure, AiOutlineUser } from "react-icons/ai";
import { LuUsers2 } from "react-icons/lu";
import { BiMessageSquare } from "react-icons/bi";
import { BiBookContent } from "react-icons/bi";

import { FiShoppingCart } from "react-icons/fi";
import { TbHandOff, TbShoppingCartCheck } from "react-icons/tb";
import { FcStatistics } from "react-icons/fc";
import { Tooltip } from "antd";
import { IoHammerOutline } from "react-icons/io5";
import { LiaFileSolid } from "react-icons/lia";
import { RiMenuSearchLine } from "react-icons/ri";
import { FaQuestion } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { FaAward } from "react-icons/fa";
import { GiCardPickup } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import { TbWeight } from "react-icons/tb";
import { GoQuestion } from "react-icons/go";
import { GrDocumentNotes } from "react-icons/gr";
import { GrNotes } from "react-icons/gr";
import { FaRegNewspaper } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { ImHammer2 } from "react-icons/im";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlinePendingActions } from "react-icons/md";
import { BsAward } from "react-icons/bs";
import { TbTransitionRight } from "react-icons/tb";
import { BsCartCheck } from "react-icons/bs";
import { FaBook } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { FaNoteSticky } from "react-icons/fa6";
import { MdPending } from "react-icons/md";
import { TbArrowsRight } from "react-icons/tb";
import { GoContainer } from "react-icons/go";
import { FaRegListAlt } from "react-icons/fa";
import { LuFileCog } from "react-icons/lu";
import { CgCopy } from "react-icons/cg";
import { BsJournalText } from "react-icons/bs";
import { PiDotsThreeCircle } from "react-icons/pi";
import { BsFileText } from "react-icons/bs";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { MdOutlinePending } from "react-icons/md";
import { IoStatsChartOutline } from "react-icons/io5";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { CiCircleCheck } from "react-icons/ci";
import { IoCheckmarkDone } from "react-icons/io5";
import { GrDocumentPerformance } from "react-icons/gr";
import { GrUserSettings } from "react-icons/gr";
import { BsCart } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
const Html = ({
  ListItemLink,
  tabclass,
  urlAllow,
  route,
  user,
  Permission,
}) => {

  const ToggleSideBar = localStorage.getItem("ToggleSideBar");

  const menus = [
    {
      name: 'Dashboard',
      path: '/dashboard1',
      icon: <RxDashboard />,
      check: 'readDashboard',
    },
    {
      name: 'Statistics',
      path: '/statistics1',
      icon: <IoStatsChartOutline />,
      check: 'readDashboard',
    },
    {
      name: 'Performance Matrix',
      icon: <GrDocumentPerformance />,
      check: 'readDashboard',
      tabclass: 'performance',
      menus: [
        {
          name: 'Carriers',
          path: '/performance-carriers1',
          icon: <BsCart />,
        },
        {
          name: 'Drivers',
          path: '/performance-drivers1',
          icon: <FiTruck />
        },
      ]
    },
    {
      name: 'User Management',
      icon: <GrUserSettings />,
      check: 'readAdmins',
      tabclass: 'user',
      menus: [
        {
          name: 'Staff',
          path: '/staff1',
          icon: <FaRegIdCard />
        },
        {
          name: `Carrier's Staff`,
          path: '/carrier-users1',
          icon: <FaRegCircleUser />
        },
        {
          name: `Approved Sub-Carriers`,
          path: '/invited-users1',
          check: user?.role == "admin" || Permission?.invite_user_get ? '' : 'NoAccess',
          icon: <IoCheckmarkDone />
        },
        {
          name: `carriers`,
          path: '/carriers1',
          icon: <FiShoppingCart />
        },
        {
          name: `Carrier Requests`,
          path: '/carrier-requests1',
          icon: <BiMessageSquare />
        },
      ]
    },
    {
      name: 'Loads Management',
      icon: <MdManageHistory />,
      check: 'readAdmins',
      tabclass: 'load',
      menus: [
        {
          name: 'All Loads',
          path: '/loads1',
          icon: <TbWeight />
        },
        {
          name: `Prebook`,
          path: '/prebook-loads1',
          icon: <BsFileText />
        },
        {
          name: `RFPS`,
          path: '/rfps-loads1',
          icon: <CgNotes />
        },
        {
          name: `USPS`,
          path: '/usps-loads1',
          icon: <BsReverseLayoutTextWindowReverse />
        },
        {
          name: `Pending-Pickup`,
          path: '/pendingPickup-loads1',
          icon: <MdOutlinePending />
        },
        {
          name: `PickedUp`,
          path: '/pickedUp-loads1',
          icon: <MdOutlinePending />
        },
        {
          name: `In-Transit`,
          path: '/intransit-loads1',
          icon: <TbArrowsRight />
        },
        {
          name: `Delivered`,
          path: '/delivered-loads1',
          icon: <TbShoppingCartCheck />
        },


      ]
    },
    {
      name: 'Bids Management',
      icon: <LuFileCog />,
      check: 'readAdmins',
      tabclass: 'bids',
      menus: [
        {
          name: 'Available Bids',
          path: '/available-loads1',
          icon: <ImHammer2 />
        },
        {
          name: `All Bids`,
          path: '/bids1',
          icon: <FaRegListAlt />
        },
        {
          name: `Pending Bids`,
          path: '/pending-bids1',
          icon: <MdOutlinePendingActions />
        },
        {
          name: `Rejected Bids`,
          path: '/rejected-bids1',
          icon: <TbHandOff />
        },
        {
          name: `Accepted Bids`,
          path: '/accepted-bids1',
          icon: <FaRegCheckCircle />
        },
        {
          name: `Awarded Bids`,
          path: '/awarded-bids1',
          icon: <BsAward />
        },


      ]
    },
  ]


  return (
    // <ul className="nav flex-column" component="siderbar">
    <div className="socrollingcol">
      <ul className="nav flex-column" component="siderbar">
        {menus.map((item, i) => {
          if (item?.menus?.length) {
            return <li className="nav-item" key={i}>
              <>
                {/* <a
                class={`col_side ${tabclass(item.tabclass) ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
                href={`#collapseExample_${i}`}
                role="button"
                aria-expanded="false"
                aria-controls={`collapseExample_${i}`}
              >
                <div className="sidees">
                  <Tooltip title={item.name}>
                    {item.icon}
                  </Tooltip>

                  <span>{item.name}</span>
                </div>
                <div>
                  <span className="side_icon ">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </span>
                </div>
              </a> */}
                <p class="d-inline-flex gap-1 mb-0">


                  {/* New */}
                 <button class="col_side colbtn nav-link " type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample_${i}`} aria-expanded="false" aria-controls={`collapseExample_${i}`}>
                    <div className="sidees">
                      <Tooltip title={item.name}>
                        {item.icon}
                      </Tooltip>

                      <span className="hidebtn_side">{item.name}</span>
                    </div>  <span className="side_icon sideTwo hidebtn_side">
                    <i class="material-icons">keyboard_arrow_down</i>
                    </span>   <span className="side_icon sideOne hidebtn_side">
             
                      <i class="material-icons">keyboard_arrow_right</i>
                    </span>
                  </button>

                </p>
                <div class="collapse" id={`collapseExample_${i}`}>

                  <div class="card card-body sides ">
                    {item.menus.map(itm => {
                      return <ListItemLink to={itm.path} key={itm.name}>
                        {itm.icon}
                        <span>{itm.name}</span>
                      </ListItemLink>
                    })}
                  </div>

                </div>

                {/* New  end */}

              </>

              {/* <div
              class={`collapse ${tabclass(item.tabclass) ? "show" : ""}`}
              id={`collapseExample_${i}`}
            >
              <div class="card card-body sides pl-3">
                {item.menus.map(itm=>{
                  return <ListItemLink to={itm.path} key={itm.name}>
                  {itm.icon}
                  <span>{itm.name}</span>
                </ListItemLink>
                })}
              </div>
            </div> */}
            </li>
          } else {
            return <>

              <li key={i}>
                {urlAllow(item.check) ? (
                  <ListItemLink to={item.path}>
                    <Tooltip title={ToggleSideBar ? item.name : item.name}>
                      {item.icon}
                    </Tooltip>
                    <span class="side_head">{item.name}</span>
                  </ListItemLink>
                ) : null}
              </li>
            </>
          }

        })}


        <>
          {user?.role == "admin" || Permission?.notes_get ? (
            <ListItemLink to="/notes1">
              {/* <i class="fa fa-file me-2" aria-hidden="true"></i> */}
              <Tooltip title={"Notes"}>
                <GrDocumentNotes />
              </Tooltip>

              <span>Notes</span>
            </ListItemLink>
          ) : null}
          {user?.role == "admin" || Permission?.shipment_note_get ? (
            <ListItemLink to="/shipment-notes">
              {/* <i class="fa fa-file me-2" aria-hidden="true"></i> */}
              <Tooltip title={"Shipment Notes"}>
                <GrNotes />
              </Tooltip>

              <span>Shipment Notes</span>
            </ListItemLink>
          ) : null}
          {user?.role == "admin" || Permission?.news_letter_get ? (
            <ListItemLink to="/newsletter1">
              {/* <i class="fa fa-file me-2" aria-hidden="true"></i> */}
              <Tooltip title={"News Letters"}>
                <FaRegNewspaper />
              </Tooltip>

              <span>News Letters</span>
            </ListItemLink>
          ) : null}
          {user?.role == "admin" || Permission?.news_letter_get ? (
            <ListItemLink to="/queries1">
              {/* <i class="fa fa-file me-2" aria-hidden="true"></i> */}
              <Tooltip title={"Query"}>
                <GoQuestion />
              </Tooltip>

              {/* <LiaFileSolid /> */}

              <span>Query</span>
            </ListItemLink>
          ) : null}

          <ListItemLink to="/boards1">
            {/* <i class="fa fa-list mr-2"></i> */}
            <Tooltip title={"Load Classification"}>
              <RiMenuSearchLine />
            </Tooltip>

            <span>Load Classification</span>
          </ListItemLink>
          <ListItemLink to="/faq">
            <Tooltip title={"FAQ"}>
              <FaQuestion />
            </Tooltip>

            {/* <i class="fa fa-cog mr-2"></i> */}

            <span>FAQ</span>
          </ListItemLink>

          <ListItemLink to="/contents1">
            {/* <i class="fa fa-file me-2" aria-hidden="true"></i> */}
            <Tooltip title={"Content Management"}>
              <BiBookContent />
            </Tooltip>

            <span>Content Management</span>
          </ListItemLink>
          {user?.role == "admin" ? (
            <ListItemLink to="/settings">
              {/* <i class="fa fa-cog mr-2"></i> */}
              <Tooltip title={"Settings"}>
                <IoSettingsOutline />
              </Tooltip>

              <span>Settings</span>
            </ListItemLink>
          ) : null}
        </>
      </ul>
    </div>
  );
};

export default Html;
