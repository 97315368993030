import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import { Tooltip } from "antd";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import loader from "../../methods/loader";
import Pagination from "react-pagination-js";
import { useSelector } from "react-redux";
import Layout2 from "../../components/global/layout2";

const Faq = () => {
  const [data, setdata] = useState([]);
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [DeleteId, setDeleteId] = useState("");
  const [loaderr,setLoaderr] = useState(false)
  const Delete = () => {
    Deletedata(DeleteId);
  };

  const user = useSelector((state) => state.user);
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    search: "",
    sorder: "",
  });

  const ChangeFilter = (e) => {
    setFilter(e);
    Getdata(e);
  };
  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    Getdata({ page: e });
  };

  const Getdata = (p = {}) => {
    loader(true);
    setLoaderr(true)
    let filter = { ...filters, ...p };
    ApiClient.get("faq/all", filter).then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
        setTotal(res?.data?.total_count);
        loader(false);
        setLoaderr(false)
      }
    });
  };

  useEffect(() => {
    Getdata();
  }, []);

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      Getdata({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const Deletedata = (id) => {
    loader(true);
    ApiClient.delete(`faq?id=${id}`).then((res) => {
      if (res.success) {
        Getdata();
        setShowDeleteModal("none");
        toast.success(res.message);
        loader(false);
      }
    });
  };

  return (
    <Layout2 title="FAQ" Sidetitle="FAQ">
      <div className="mainareas new-table-set">
        <CommonDeleteModal
          show={ShowDeleteModal}
          setShow={setShowDeleteModal}
          confirm={Delete}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div class="bids-top ">
            <h4>
              Results<span>{total}</span>
            </h4>
          </div>
          {user?.role == "admin" || Permission?.faq_add ? (
            <a
              className="btn btn-primary  btn-height"
              onClick={() => history.push("/faq/add")}
            >
              <i className="fas fa-plus-circle me-2"></i>
              Add FAQ
            </a>
          ) : null}
        </div>

        <div className="table-responsive table_section mt-4 new-table">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
                {/* <th scope="col">Status</th> */}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((itm, index) => (
                <tr>
                  <td>
                    {" "}
                    <div className="user_detail">
                      <div className="user_name">
                        <h4
                          className="user"
                          title="View"
                          onClick={(e) =>
                            {
                              if(Permission?.faq_view || user?.role=="admin")
                              {
                                history.push(`/faq/view/${itm?.id}`)
                              }
                              else
                              {
                                toast.warn("You do not have valid permission")
                              }
                              
                            }
                           } 
                           
                        >
                          {methodModel
                            .capitalizeFirstLetter(itm?.question)
                            ?.substr(0, 60)}
                          {itm?.question?.length > 60 ? "..." : ""}
                        </h4>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (itm?.answer)
                      }} className="ellipses-set"
                    ></div>
                  </td>
                  {/* <td>{itm?.status}</td> */}
                  <td className="">
                    <div className="action_icons">
                      {user?.role == "admin" || Permission?.faq_edit ? (
                        <Tooltip placement="top" title="Edit">
                        <span  className="edit_icon pointer"
                        onClick={(e) => history.push(`/faq/edit/${itm?.id}`)}>
                        <i
                        
                          class="material-icons pointer edit "
                          // title="Edit"
                        >
                          edit
                        </i>
                        </span>
                        </Tooltip>
                      ) : null}

                      {user?.role == "admin" || Permission?.faq_delete ? (
                        <Tooltip placement="top" title="Delete">
                          <span
                            className="edit_icon pointer"
                            // title="Delete"
                            onClick={(e) => {
                              setShowDeleteModal("block");
                              setDeleteId(itm?.id);
                            }}
                          >
                            <i class="material-icons delete" title="Delete">
                              {" "}
                              delete
                            </i>
                          </span>
                        </Tooltip>
                        
                      ) : null}
                      {user?.role == "admin" || Permission?.faq_view ? (
                         <Tooltip placement="top" title="View">
                         <a
                           className="edit_icon pointer"
                          //  title="View"
                           onClick={(e) => {
                             history.push("/faq/view/" + itm?.id);
                           }}
                         >
                           <i className="fa fa-eye"></i>
     
                         </a>
                       </Tooltip>
                       ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {total == 0 && !loaderr? (
          <div className=" no-data">
            <img src="assets/img/no-data.png" />
            No Data
          </div>
        ) : (
          <></>
        )}

        {total > 0 ? (
          <div className="paginationWrapper">
            <div className="d-flex align-items-center">
              <div className="me-2 user-name-color">Show</div>
              {total > 0 && (
                <div className="dropdown addDropdown chnagesname ">
                  <button
                    className="btn btn-primary dropdown-toggle removeBg"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {filters?.count}
                  </button>
                  <div
                    className="dropdown-menu shadow bg_hover"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 5 });
                      }}
                    >
                      5
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 10 });
                      }}
                    >
                      10
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 20 });
                      }}
                    >
                      20
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 30 });
                      }}
                    >
                      30
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        ChangeFilter({ ...filters, count: 40 });
                      }}
                    >
                      40
                    </a>
                  </div>
                </div>
              )}{" "}
              <div className="ms-2 user-name-color">from {total} Faq</div>
            </div>

            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout2>
  );
};

export default Faq;
