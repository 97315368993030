
let apiUrl = "https://api.onebrokerage.org/";
// let apiUrl = "http://74.208.206.18:4020/";

// let host = document.location.host;
// if (host.includes("localhost")) {
//   apiUrl = "http://74.208.206.18:4020/";
// } else if (host.includes("jcsoftwaresolution.com")) {
//   apiUrl = "http://74.208.206.18:4020/";
// } else if (host.includes("onebrokerage.org")) {
//   apiUrl = "https://api.onebrokerage.org/";
// } else {
//   apiUrl = "http://74.208.206.18:4020/";
// }
const environment = {
  api: apiUrl,
  imageapi: apiUrl,
  map_api_key: "AIzaSyDhsAnr0k0oWjWn48iqiW4XUX4dvWzvtMA",
  planTypeId: "64ad2ebce5aa778d26a54f02",
  userRoleId: "64b15102b14de6c28838f7d2",
  adminRoleId: "6540cfa35a7513892be61413",
  googleAPIKey: "AIzaSyDhsAnr0k0oWjWn48iqiW4XUX4dvWzvtMA",
  rfpsId: "6602b2c6867363c3bb83de23",
  uspsId: "65cb18089eaa51c429c42501",
  prebooksId: "662b252bc5657082cc45b5bd",
  // rfpsId: "6602b2c6867363c3bb83de23",
  // uspsId: "65cb18089eaa51c429c42501",
  // prebooksId: "662b53b16daba4920d4cb6ff",
};
export default environment;
