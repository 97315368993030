import React, { useEffect } from "react";

import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from "connected-react-router";
import { Provider, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import configureStore from "./config";
import { createBrowserHistory } from "history";
import { Auth } from "./methods/auth";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import Profile from "./pages/Profile";

import "./scss/main.scss";
import "react-pagination-js/dist/styles.css"; // import css
import ContactDetails from "./pages/Settings/ContactDetails";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import Users from "./pages/Users";
import AddEditUser from "./pages/Users/AddEditUser";
import UserDetail from "./pages/Users/Profiledetail";
import UserDetail2 from "./pages/Users2/Profiledetail"
import Roles from "./pages/Roles";
import AddEditRole from "./pages/Roles/AddEdit";

import { ToastContainer } from "react-toastify";

import UpdateSettingsForm from "./pages/UpdateSettings";
import Request from "./pages/Users/Request_Logic";
// import AddEditLoad from "./pages/Loads/Addcarrier-requests2/EditLoads";
import AddEditLoad from "./pages/Loads/AddEditLoads";
import Loads from "./pages/Loads";
import LoadDetails from "./pages/Loads/ViewLoads";
import Carrier from "./pages/Users/CarrierIndex";
import Bids from "./pages/Bids";
import BidDetails from "./pages/Bids/ViewBids";
import AddEditStaff from "./pages/Roles/AddEditStaff";
import AddEditBoards from "./pages/Boards/AddEditBoards";
import Boards from "./pages/Boards";
import AddEditCarrierUsers from "./pages/Roles/AddCarrierUser/html";
import BoardDetails from "./pages/Boards/ViewBoard";
import CarrierUsers from "./pages/Roles/CarrierUserLogic";
import ViewSettings from "./pages/Settings";
import ChangePassword from "./components/Profile/ChangePassword/index";
import EditProfile from "./components/Profile/Edit";
import Notes from "./pages/Notes";
import ViewNotes from "./pages/Notes/ViewNotes";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ContactQuery from "./pages/Queries";
import ViewQuery from "./pages/Queries/ViewQuery";
import StopsNotes from "./pages/StopsNotes";
import ViewStopsNotes from "./pages/StopsNotes/ViewNotes";
import NewsLetter from "./pages/NewsLetter";
import Faq from "./pages/Faq/Faqlisting";
import Addeditfaq from "./pages/Faq/Addeditfaq";
import Faqview from "./pages/Faq/Faqview";
import environment from "./environment";
import RFPS_Loads from "./pages/RFPS-Loads";
import Prebook_Loads from "./pages/Prebook-Loads";
import InvitedUser from "./pages/InvitedUsers";
import CarriersStaff from "./pages/CarriersStaff";
import Statistics from "./pages/Dashboard/Statistics";
import MapChart from "./pages/Dashboard/StateGraphChart";
import AddeditContent from "./pages/ContentManagement/AddEdit";
import Contents from "./pages/ContentManagement/html";
import CMSView from "./pages/ContentManagement/View";
import CarrierPerformance from "./pages/PerformanceMatrix/Html";
import DriverPerformance from "./pages/PerformanceMatrix/Drivers";
import Dashboard2 from "./pages/Dashboard2";
import Statistics2 from "./pages/Dashboard2/Statistics";
import CarrierPerformance2 from "./pages/PerformanceMatrix2/Html";
import DriverPerformance2 from "./pages/PerformanceMatrix2/Drivers";
import Users2 from "./pages/Users2";
import CarriersStaff2 from "./pages/CarriersStaff2";
import InvitedUser2 from "./pages/InvitedUsers2";
import Carrier2 from "./pages/Users2/CarrierIndex";
import Requests2 from "./pages/Users2/Request_Logic";
import Loads2 from "./pages/Loads2";
import Prebook_Loads2 from "./pages/Prebook-Loads2";
import RFPS_Loads2 from "./pages/RFPS-Loads2";
import Bids2 from "./pages/Bids2";
import Notes2 from "./pages/Notes2";
import StopsNotes2 from "./pages/StopsNotes2";
import NewsLetter2 from "./pages/NewsLetter2";
import ContactQuery2 from "./pages/Queries2";
import Boards2 from "./pages/Boards2";
import Faq2 from "./pages/Faq2/Faqlisting";
import Contents2 from "./pages/ContentManagement2/html";
import ViewSettings2 from "./pages/Settings2";
import AddEditStaff2 from "./pages/Roles2/AddEditStaff";
import AddEditRole2 from "./pages/Roles2/AddEdit";
import ViewNotes2 from "./pages/Notes2/ViewNotes";
import AcceptedCarrier from "./pages/AcceptedCarrier";
import RejectCarrier from "./pages/RejectCarrier";
import ViewStopsNotes2 from "./pages/StopsNotes2/ViewNotes";
import ViewQuery2 from "./pages/Queries2/ViewQuery";
import AddEditBoards2 from "./pages/Boards2/AddEditBoards";
import BoardDetails2 from "./pages/Boards2/ViewBoard";
import Addeditfaq2 from "./pages/Faq2/Addeditfaq";
import AddeditContent2 from "./pages/ContentManagement2/AddEdit";
import LoadDetails2 from "./pages/Loads2/ViewLoads";
import BidDetails2 from "./pages/Bids2/ViewBids";
import Faqview2 from "./pages/Faq2/Faqview";
import CMSView2 from "./pages/ContentManagement2/View";
import AddEditLoad2 from "./pages/Loads2/AddEditLoads";
import Profile2 from "./pages/Profile2";
import Notifications from "./pages/Notifications";
import ContactDetails2 from "./pages/Settings2/ContactDetails";
import JobSearch from "./pages/JobSearch"

export const history = createBrowserHistory();
/************ store configration *********/
const { persistor, store } = configureStore(history);
export default () => {
  const dispatch = useDispatch();

  return (
    <>
      <PersistGate loading={"loading ..."} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router>
            <Switch>
              <Route
                exact={true}
                path="/dashboard1"
                store={store}
                requireAuth={Auth}
                component={Dashboard}
              />
              <Route
                exact={true}
                path="/dashboard"
                store={store}
                requireAuth={Auth}
                component={Dashboard2}
              />
              <Route
                exact={true}
                path="/dashboardpage"
                store={store}
                requireAuth={Auth}
                component={DashboardPage}
              />
              <Route
                exact={true}
                path="/map"
                store={store}
                requireAuth={Auth}
                component={MapChart}
              />
              <Route
                exact={true}
                path="/edit-settings1"
                store={store}
                requireAuth={Auth}
                component={ContactDetails}
              />
              <Route
                exact={true}
                path="/edit-settings"
                store={store}
                requireAuth={Auth}
                component={ContactDetails2}
              />
              <Route
                exact={true}
                path="/settings1"
                store={store}
                requireAuth={Auth}
                component={ViewSettings}
              />
              <Route
                exact={true}
                path="/settings"
                store={store}
                requireAuth={Auth}
                component={ViewSettings2}
              />
              <Route
                exact={true}
                path="/staff1"
                store={store}
                requireAuth={Auth}
                component={Users}
              />
              <Route
                exact={true}
                path="/staff"
                store={store}
                requireAuth={Auth}
                component={Users2}
              />
              <Route
                exact={true}
                path="/invited-users1"
                store={store}
                requireAuth={Auth}
                component={InvitedUser}
              />
              <Route
                exact={true}
                path="/invited-users"
                store={store}
                requireAuth={Auth}
                component={InvitedUser2}
              />
              <Route
                exact={true}
                path="/carrier-users1"
                store={store}
                requireAuth={Auth}
                component={CarriersStaff}
              />
              <Route
                exact={true}
                path="/carrier-users"
                store={store}
                requireAuth={Auth}
                component={CarriersStaff2}
              />
              <Route
                exact={true}
                path="/carriers1"
                store={store}
                requireAuth={Auth}
                component={Carrier}
              />
              <Route
                exact={true}
                path="/carriers"
                store={store}
                requireAuth={Auth}
                component={Carrier2}
              />
              <Route
                exact={true}
                path="/acceptedCarriers"
                store={store}
                requireAuth={Auth}
                component={AcceptedCarrier}
              />
              <Route
                exact={true}
                path="/rejectedCarriers"
                store={store}
                requireAuth={Auth}
                component={RejectCarrier}
              />
              <Route
                exact={true}
                path="/carrier-requests1"
                store={store}
                requireAuth={Auth}
                component={Request}
              />
              <Route
                exact={true}
                path="/carrier-requests"
                store={store}
                requireAuth={Auth}
                component={Requests2}
              />
              <Route
                exact={true}
                path="/roles"
                store={store}
                requireAuth={Auth}
                component={Roles}
              />
              <Route
                exact={true}
                path="/roles/add"
                store={store}
                requireAuth={Auth}
                component={AddEditRole}
              />
              <Route
                exact={true}
                path="/roles/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditRole}
              />
              <Route
                exact={true}
                path="/staff1/add"
                store={store}
                requireAuth={Auth}
                component={AddEditStaff}
              />
              <Route
                exact={true}
                path="/staff/add/:pageName"
                store={store}
                requireAuth={Auth}
                component={AddEditStaff2}
              />
              <Route
                exact={true}
                path="/content1/add"
                store={store}
                requireAuth={Auth}
                component={AddeditContent}
              />
              <Route
                exact={true}
                path="/content1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddeditContent}
              />
              <Route
                exact={true}
                path="/content/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddeditContent2}
              />
              <Route
                exact={true}
                path="/contents1"
                store={store}
                requireAuth={Auth}
                component={Contents}
              />
              <Route
                exact={true}
                path="/content"
                store={store}
                requireAuth={Auth}
                component={Contents2}
              />
              <Route
                exact={true}
                path="/performance-carriers1"
                store={store}
                requireAuth={Auth}
                component={CarrierPerformance}
              />
              <Route
                exact={true}
                path="/performance-carriers"
                store={store}
                requireAuth={Auth}
                component={CarrierPerformance2}
              />
              <Route
                exact={true}
                path="/performance-drivers1"
                store={store}
                requireAuth={Auth}
                component={DriverPerformance}
              />
              <Route
                exact={true}
                path="/performance-drivers"
                store={store}
                requireAuth={Auth}
                component={DriverPerformance2}
              />
              <Route
                exact={true}
                path="/content1/view/:id"
                store={store}
                requireAuth={Auth}
                component={CMSView}
              />
              <Route
                exact={true}
                path="/content/view/:id"
                store={store}
                requireAuth={Auth}
                component={CMSView2}
              />
              <Route
                exact={true}
                path="/carriers1/add"
                store={store}
                requireAuth={Auth}
                component={AddEditRole}
              />
              <Route
                exact={true}
                path="/carriers/add/:pageName"
                store={store}
                requireAuth={Auth}
                component={AddEditRole2}
              />
              <Route
                exact={true}
                path="/profile1/edit"
                store={store}
                requireAuth={Auth}
                component={EditProfile}
              />

              <Route
                exact={true}
                path="/carrier/add"
                store={store}
                requireAuth={Auth}
                component={AddEditRole}
              />
              <Route
                exact={true}
                path="/carrier/users"
                store={store}
                requireAuth={Auth}
                component={CarrierUsers}
              />

              <Route
                exact={true}
                path="/carrier/user-add/:CarrierID"
                store={store}
                requireAuth={Auth}
                component={AddEditCarrierUsers}
              />
              <Route
                exact={true}
                path="/carrier/user-edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditCarrierUsers}
              />
              <Route
                exact={true}
                path="/boards1/add"
                store={store}
                requireAuth={Auth}
                component={AddEditBoards}
              />
              <Route
                exact={true}
                path="/boards/add"
                store={store}
                requireAuth={Auth}
                component={AddEditBoards2}
              />
              <Route
                exact={true}
                path="/boards1/view/:id"
                store={store}
                requireAuth={Auth}
                component={BoardDetails}
              />
              <Route
                exact={true}
                path="/notification"
                store={store}
                requireAuth={Auth}
                component={Notifications}
              />
              <Route
                exact={true}
                path="/boards/view/:id"
                store={store}
                requireAuth={Auth}
                component={BoardDetails2}
              />
              <Route
                exact={true}
                path="/boards1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditBoards}
              />
              <Route
                exact={true}
                path="/boards/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditBoards2}
              />
              <Route
                exact={true}
                path="/boards1"
                store={store}
                requireAuth={Auth}
                component={Boards}
              />
              <Route
                exact={true}
                path="/boards"
                store={store}
                requireAuth={Auth}
                component={Boards2}
              />
              <Route
                exact={true}
                path="/users/:role"
                store={store}
                requireAuth={Auth}
                component={Users}
              />
              <Route
                exact={true}
                path="/staff1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditStaff}
              />
              <Route
                exact={true}
                path="/staff/edit/:id/:pageName"
                store={store}
                requireAuth={Auth}
                component={AddEditStaff2}
              />
              <Route
                exact={true}
                path={"/newsletter1"}
                store={store}
                requireAuth={Auth}
                component={NewsLetter}
              />
              <Route
                exact={true}
                path={"/newsletter"}
                store={store}
                requireAuth={Auth}
                component={NewsLetter2}
              />
              <Route
                exact={true}
                path="/carriers1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditRole}
              />
              <Route
                exact={true}
                path="/carriers/edit/:id/:pageName"
                store={store}
                requireAuth={Auth}
                component={AddEditRole2}
              />
              <Route
                exact={true}
                path="/users/:role/add"
                store={store}
                requireAuth={Auth}
                component={AddEditUser}
              />
              <Route
                exact={true}
                path="/users/:role/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditUser}
              />
              <Route
                exact={true}
                path="/userDetail1/:id"
                store={store}
                requireAuth={Auth}
                component={UserDetail}
              />
              <Route
                exact={true}
                path="/userDetail/:statusName/:id"
                store={store}
                requireAuth={Auth}
                component={UserDetail2}
              />
              <Route
                exact={true}
                path="/profile1"
                store={store}
                requireAuth={Auth}
                component={Profile}
              />
              <Route
                exact={true}
                path="/profile"
                store={store}
                requireAuth={Auth}
                component={Profile2}
              />
              <Route
                exact={true}
                path="/loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
                {/* <Route
                exact={true}
                path="/job"
                store={store}
                requireAuth={Auth}
                component={JobSearch}
              /> */}
                <Route
                exact={true}
                store={store}
                path="/loads/:id"
                component={Loads2}
              />
              <Route
                exact={true}
                path="/rfps-loads1"
                store={store}
                requireAuth={Auth}
                component={RFPS_Loads}
              />
              <Route
                exact={true}
                path="/rfps-loads"
                store={store}
                requireAuth={Auth}
                component={RFPS_Loads2}
              />
              <Route
                exact={true}
                path="/usps-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/usps-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/available-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/available-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/prebook-loads1"
                store={store}
                requireAuth={Auth}
                component={Prebook_Loads}
              />
              <Route
                exact={true}
                path="/prebook-loads"
                store={store}
                requireAuth={Auth}
                component={Prebook_Loads2}
              />
              <Route
                exact={true}
                path="/delivered-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/delivered-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/intransit-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/intransit-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/pendingPickup-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/pendingPickup-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/pickedUp-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />
              <Route
                exact={true}
                path="/pickedUp-loads"
                store={store}
                requireAuth={Auth}
                component={Loads2}
              />
              <Route
                exact={true}
                path="/awarded-loads1"
                store={store}
                requireAuth={Auth}
                component={Loads}
              />

              <Route
                exact={true}
                path="/loads1/view/:id"
                store={store}
                requireAuth={Auth}
                component={LoadDetails}
              />
              <Route
                exact={true}
                path="/loads/view/:id"
                store={store}
                requireAuth={Auth}
                component={LoadDetails2}
              />
              <Route
                exact={true}
                path="/loads1/add"
                store={store}
                requireAuth={Auth}
                component={AddEditLoad}
              />
              <Route
                exact={true}
                path="/loads/add/:statusData"
                store={store}
                requireAuth={Auth}
                component={AddEditLoad2}
              />
              <Route
                exact={true}
                path="/loads1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={AddEditLoad}
              />
              <Route
                exact={true}
                path="/bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/pending-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/pending-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/statistics1"
                store={store}
                requireAuth={Auth}
                component={Statistics}
              />
              <Route
                exact={true}
                path="/statistics"
                store={store}
                requireAuth={Auth}
                component={Statistics2}
              />
              <Route
                exact={true}
                path="/awarded-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/awarded-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/accepted-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/accepted-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/delivered-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/delivered-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/rejected-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/rejected-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/intransit-bids1"
                store={store}
                requireAuth={Auth}
                component={Bids}
              />
              <Route
                exact={true}
                path="/intransit-bids"
                store={store}
                requireAuth={Auth}
                component={Bids2}
              />
              <Route
                exact={true}
                path="/notes1"
                store={store}
                requireAuth={Auth}
                component={Notes}
              />
              <Route
                exact={true}
                path="/notes"
                store={store}
                requireAuth={Auth}
                component={Notes2}
              />
              <Route
                exact={true}
                path="/notes1/:id"
                store={store}
                requireAuth={Auth}
                component={ViewNotes}
              />
              <Route
                exact={true}
                path="/notes/:id"
                store={store}
                requireAuth={Auth}
                component={ViewNotes2}
              />
              <Route
                exact={true}
                path="/shipment-notes1"
                store={store}
                requireAuth={Auth}
                component={StopsNotes}
              />
              <Route
                exact={true}
                path="/shipment-notes"
                store={store}
                requireAuth={Auth}
                component={StopsNotes2}
              />
              <Route
                exact={true}
                path="/shipment-note1/:id"
                store={store}
                requireAuth={Auth}
                component={ViewStopsNotes}
              />
              <Route
                exact={true}
                path="/shipment-notes/:id"
                store={store}
                requireAuth={Auth}
                component={ViewStopsNotes2}
              />
              <Route
                exact={true}
                path="/bids1/view/:id"
                store={store}
                requireAuth={Auth}
                component={BidDetails}
              />
              <Route
                exact={true}
                path="/bids/view/:id/:pageName"
                store={store}
                requireAuth={Auth}
                component={BidDetails2}
              />
              {/* <Route exact={true} path="/profile/:tab" store={store} requireAuth={Auth} component={Settings} /> */}
              <Route
                exact={true}
                path="/login"
                store={store}
                requireAuth={Auth}
                component={Login}
              />
              <Route
                exact={true}
                path="/login/:id"
                store={store}
                requireAuth={Auth}
                component={Login}
              />
              <Route
                exact={true}
                path="/forgotpassword"
                store={store}
                requireAuth={Auth}
                component={Forgotpassword}
              />
              <Route
                exact={true}
                path="/resetpassword"
                store={store}
                requireAuth={Auth}
                component={Resetpassword}
              />
              <Route
                exact={true}
                path="/change-password"
                store={store}
                requireAuth={Auth}
                component={ChangePassword}
              />
              <Route
                exact={true}
                path="/queries1"
                store={store}
                requireAuth={Auth}
                component={ContactQuery}
              />
              <Route
                exact={true}
                path="/queries"
                store={store}
                requireAuth={Auth}
                component={ContactQuery2}
              />
              <Route
                exact={true}
                path="/queries1/view/:id"
                store={store}
                requireAuth={Auth}
                component={ViewQuery}
              />
              <Route
                exact={true}
                path="/queries/view/:id"
                store={store}
                requireAuth={Auth}
                component={ViewQuery2}
              />
              <Route
                exact={true}
                path="/faq1"
                store={store}
                requireAuth={Auth}
                component={Faq}
              />

              <Route
                exact={true}
                path="/faq"
                store={store}
                requireAuth={Auth}
                component={Faq2}
              />
              <Route
                exact={true}
                path="/faq1/add"
                store={store}
                requireAuth={Auth}
                component={Addeditfaq}
              />
              <Route
                exact={true}
                path="/faq/add"
                store={store}
                requireAuth={Auth}
                component={Addeditfaq2}
              />
              <Route
                exact={true}
                path="/faq1/edit/:id"
                store={store}
                requireAuth={Auth}
                component={Addeditfaq}
              />
              <Route
                exact={true}
                path="/faq/edit/:id"
                store={store}
                requireAuth={Auth}
                component={Addeditfaq2}
              />
              <Route
                exact={true}
                path="/faq1/view/:id"
                store={store}
                requireAuth={Auth}
                component={Faqview}
              />
              <Route
                exact={true}
                path="/faq/view/:id"
                store={store}
                requireAuth={Auth}
                component={Faqview2}
              />
              {/* <Route exact={true} path="/faq" store={store} requireAuth={Auth} component={Faq} /> */}
              {/* <Route exact={true} path="/AddEditfaq" requireAuth={Auth} store={store} component={AddEditFaq}/> */}
              {/* <Route exact={true} path="/AddEditfaq/:id?" requireAuth={Auth} store={store} component={AddEditFaq}/> */}
              {/* <Route exact={true} path="/faqDetails/:id?" requireAuth={Auth} store={store} component={FaqDetail}/> */}

              {/*  Rooute for settings */}
              <Route
                exact={true}
                path="/website/settings"
                requireAuth={Auth}
                store={store}
                component={UpdateSettingsForm}
              />

              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </Router>
        </ConnectedRouter>
      </PersistGate>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
