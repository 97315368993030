import React, { useEffect, useState } from "react";
import GooglePlaceAutoComplete from "../../../components/common/GooglePlaceAutoComplete";
import Layout from "../../../components/global/layout";
import "react-datetime/css/react-datetime.css";
import SelectDropdown from "../../../components/common/SelectDropdown";
import dateFormat from "dateformat";
import Datetime from "react-datetime";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ApiClient from "../../../methods/api/apiClient";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import loader from "../../../methods/loader";
import addressModel from "../../../models/address.model";
import ReactDatePicker from "react-datepicker";
import methodModel from "../../../methods/methods";

const Html = ({
  role,
  form,
  handleSubmit,
  setform,
  addressResult,
  submitted,
  back,
  Stops,
  SetStops,
  detail,
}) => {
  const user = useSelector((state) => state.user);
  const [boards, setBoards] = useState([]);
  const { id } = useParams();
  const [tags, settag] = useState([]);
  const [num, setnum] = useState(1);
  const [data, setdata] = useState([]);

  const AddTag = () => {
    Stops.push({
      address: "",
    });
    SetStops([...Stops]);
  };

  const removetag = (index) => {
    SetStops([...Stops.filter((itm, i) => i != index)]);
    setform({
      ...form,
      stops: [...Stops.filter((itm, i) => i != index)],
    });

    setnum(num - 1);
  };

  const updatetag = (index, key, value) => {
    let arr = Stops;
    arr[index][key] = value;
    SetStops([...arr]);
    setform({
      ...form,
      stops: [...arr],
      // no_of_reservation: arr.length,
    });
    setnum(arr.length);
    // console.log(Stops);

    // if (num > data[0]?.sizeOfVenue) {
    //   toast.warn("You have Exceed the limit of booking");
    // }
  };
  useEffect(() => {
    ApiClient.get("boards").then((res) => {
      let filter = res?.data?.data?.filter(
        (itm) => itm?.id != environment.uspsId
      );
      setBoards(filter);
    });
  }, [data]);

  const DestinationAddress = async (e) => {
    // console.log(e);
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress2(e.place);
    }

    setform({
      ...form,
      destination_address: e.value || "",
      destination_location_city: address.city || "",
      destination_location_state: address.state || "",
      destination_location_country: address.country || "",
      destination_location_postal_code: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
  };

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);

  //   useEffect(()=>{
  // if(id){
  //   SetStops(data?.stops)
  // }
  //   },[])

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1 edit-page-common">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <h3 className="ViewUser mb-0 user-back">
                    <Link to="/loads1" className="">
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    {form && form.id ? "Edit" : "Add"} Load
                  </h3>
                </div>
              </div>
            </div>

            <div className="common-padding">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Basic Information</h5>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label>
                      Load Type<span className="text-danger">*</span>
                    </label>
                    <SelectDropdown
                      id="statusDropdown"
                      class="role-color"
                      displayValue="name"
                      placeholder="Select Load Type"
                      intialValue={form.board_id}
                      disabled={form.id ? true : false}
                      result={(e) => {
                        setform({ ...form, board_id: e.value });
                      }}
                      options={boards}
                      required={true}
                    />
                    {submitted && !form.board_id ? (
                      <div className="invalid-feedback d-block">
                        Board id is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Customer Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.customer_name}
                      required
                      onChange={(e) =>
                        setform({ ...form, customer_name: e.target.value })
                      }
                    />
                    {submitted && !form.customer_name ? (
                      <div className="invalid-feedback d-block">
                        Customer Name is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {form?.board_id == environment.rfpsId && (
                    <div className="col-md-6 mb-3">
                      <label>
                        Lane ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        pattern="^[a-zA-Z0-9]+$"
                        onKeyPress={(e) => {
                          var regex = new RegExp("^[a-zA-Z0-9]+$");
                          var key = String.fromCharCode(
                            !e.charCode ? e.which : e.charCode
                          );
                          if (!regex.test(key)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        required
                        className="form-control"
                        value={form.customer_id}
                        onChange={(e) =>
                          setform({ ...form, lane_id: e.target.value })
                        }
                      />
                      {submitted && !form?.lane_id ? (
                        <div className="invalid-feedback d-block">
                          Lane ID is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  {form?.board_id == environment.prebooksId && (
                    <div className="col-md-6 mb-3">
                      <label>
                        Load ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        pattern="^[a-zA-Z0-9]+$"
                        onKeyPress={(e) => {
                          var regex = new RegExp("^[a-zA-Z0-9]+$");
                          var key = String.fromCharCode(
                            !e.charCode ? e.which : e.charCode
                          );
                          if (!regex.test(key)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        required
                        value={form?.load_id}
                        maxLength={7}
                        minLength={1}
                        onChange={(e) =>
                          setform({ ...form, load_id: e.target.value })
                        }
                      />
                      {submitted && !form.load_id ? (
                        <div className="invalid-feedback d-block">
                          Load ID is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  {role ? (
                    <></>
                  ) : (
                    <>
                      {form?.board_id == environment.prebooksId ? (
                        <div className="col-md-6 mb-3 addload_wraaper">
                          {/* <span className="d-block">
                            {" "}
                            <label htmlFor="" className="d-flex">
                              {" "}
                              PU DATE
                              <label className="text-danger d-block">*</label>
                            </label>
                          </span> */}
                          <label>
                            {" "}
                            PU DATE<span class="text-danger">*</span>
                          </label>
                          {/* <Datetime
                          value={moment(form?.pu_date_time)}

                          onChange={(e) => {
                            setform({
                              ...form,
                              pu_date_time: dateFormat(e, "isoUtcDateTime"),
                            });
                          }}

                          inputProps={{ readOnly: true }}
                        /> */}
                          <ReactDatePicker
                            selected={form?.pu_date_time}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            className="form-control w-100"
                            minDate={Date.now()}
                            onChange={(e) => {
                              setform({ ...form, pu_date_time: e });
                              
                            }}
                            showTimeSelect
                            dateFormat="dd-MM-yyyy h:mm a"
                          />
                          {submitted && !form?.pu_date_time ? (
                            <div className="invalid-feedback d-block">
                              PU DATE is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className="col-md-6 mb-3 startwrap">
                          <span className="d-block">
                            {" "}
                            <label htmlFor="" className="d-flex m-0">
                              {" "}
                              Start Date
                              <label className="text-danger d-block">*</label>
                            </label>
                          </span>
                          {/* <Datetime
                          value={moment(form?.load_start_date)}
                          onChange={(e) => {
                            setform({
                              ...form,
                              load_start_date: dateFormat(e, "isoUtcDateTime"),
                            });
                          }}
                          inputProps={{ readOnly: true }}
                        /> */}
                          <ReactDatePicker
                            selected={form?.load_start_date}
                            className="form-control w-100"
                            minDate={Date.now()}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            onChange={(e) => {
                              setform({ ...form, load_start_date: e });
                             
                            }}
                            showTimeSelect
                            dateFormat="dd-MM-yyyy h:mm a"
                          />

                          {submitted && !form?.load_start_date ? (
                            <div className="invalid-feedback d-block">
                              Load Start Date is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {form?.board_id == environment.uspsId && (
                    <div className="col-md-6 mb-3">
                      <label>
                        End Date<span className="text-danger">*</span>
                      </label>
                      <Datetime
                        exclude={moment(Date.now())}
                        value={moment(form?.load_end_date)}
                        onChange={(e) => {
                          setform({
                            ...form,
                            load_end_date: dateFormat(e, "isoUtcDateTime"),
                          });
                        }}
                        inputProps={{ readOnly: true }}
                      />

                      {submitted && !form?.load_end_date ? (
                        <div className="invalid-feedback d-block">
                          End Date is Required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Address</h5>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label>
                      Origin Location <span className="text-danger">*</span>
                    </label>
                    <div className="address-bx">
                      <GooglePlaceAutoComplete
                        key="Map1"
                        value={form?.origin_address}
                        name={"origin"}
                        result={addressResult}
                        required
                        id="address1"
                        placeholder=""
                      />
                    </div>
                    {submitted && !form?.origin_address ? (
                      <div className="invalid-feedback d-block">
                        Origin Location is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Origin City<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      value={form?.origin_location_city}
                      required
                      onChange={(e) => {
                        setform({
                          ...form,
                          origin_location_city: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.origin_location_city ? (
                      <div className="invalid-feedback d-block">
                        City is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Origin State<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      required
                      value={form?.origin_location_state}
                      onChange={(e) => {
                        setform({
                          ...form,
                          origin_location_state: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.origin_location_state ? (
                      <div className="invalid-feedback d-block">
                        State is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Origin Zipcode<span className="text-danger">*</span>
                    </label>
                    <input
                      pattern="^[a-zA-Z0-9]+$"
                      onKeyPress={(e) => {
                        var regex = new RegExp("^[a-zA-Z0-9]+$");
                        var key = String.fromCharCode(
                          !e.charCode ? e.which : e.charCode
                        );
                        if (!regex.test(key)) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      min={0}
                      type="number"
                      name=""
                      className="form-control"
                      id=""
                      required
                      value={form?.origin_location_postal_code}
                      onChange={(e) => {
                        setform({
                          ...form,
                          origin_location_postal_code: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.origin_location_state ? (
                      <div className="invalid-feedback d-block">
                        Pincode is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      value={form?.origin_location_country}
                      onChange={(e) => {
                        setform({
                          ...form,
                          origin_location_country: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form.origin_location_country ? (
                      <div className="invalid-feedback d-block">
                        Country is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div> */}
                  {/* <div className="col-md-6 mb-3">
                    <label>
                      Origin Street Address
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      value={form?.origin_location_street}
                      onChange={(e) => {
                        setform({
                          ...form,
                          origin_location_street: e.target.value,
                        });
                      }}
                    />
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <label>
                      Destination Location{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <GooglePlaceAutoComplete
                      key="Map2"
                      value={form?.destination_address}
                      result={DestinationAddress}
                      required
                      id="address2"
                      placeholder=""
                    />
                    {submitted && !form?.destination_address ? (
                      <div className="invalid-feedback d-block">
                        Destination Location is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Destination City<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      required
                      value={form?.destination_location_city}
                      onChange={(e) => {
                        setform({
                          ...form,

                          destination_location_city: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.destination_location_city ? (
                      <div className="invalid-feedback d-block">
                        City is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Destination State<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      required
                      value={form?.destination_location_state}
                      onChange={(e) => {
                        setform({
                          ...form,
                          destination_location_state: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.destination_location_state ? (
                      <div className="invalid-feedback d-block">
                        State is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Destination Zipcode<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name=""
                      className="form-control"
                      pattern="^[a-zA-Z0-9]+$"
                      onKeyPress={(e) => {
                        var regex = new RegExp("^[a-zA-Z0-9]+$");
                        var key = String.fromCharCode(
                          !e.charCode ? e.which : e.charCode
                        );
                        if (!regex.test(key)) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      id=""
                      required
                      value={form?.destination_location_postal_code}
                      onChange={(e) => {
                        setform({
                          ...form,
                          destination_location_postal_code: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form?.destination_location_postal_code ? (
                      <div className="invalid-feedback d-block">
                        Zipcode is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name=""
                      className="form-control"
                      id=""
                      value={form?.destination_location_country}
                      onChange={(e) => {
                        setform({
                          ...form,
                          destination_location_country: e.target.value,
                        });
                      }}
                    />

                    {submitted && !form.destination_location_country ? (
                      <div className="invalid-feedback d-block">
                        Country is Required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div> */}
                  {/* <div className="col-md-6 mb-3">
                    <label>
                      Destination Street Address
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      id=""
                      value={form?.destination_location_street}
                      onChange={(e) => {
                        setform({
                          ...form,
                          destination_location_street: e.target.value,
                        });
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3 d-flex justify-content-between align-items-center">
                  <h5 class="profilelist mb-0">Stop Details</h5>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={AddTag}
                    >
                      <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Stop
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      {Stops.map((itm, i) => {
                        return (
                          <div
                            className="col-md-6 mb-3"
                            onChange={(e) => {
                              if (num > data[0]?.sizeOfVenue) {
                                removetag(i);
                              }
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="w-100">
                                <GooglePlaceAutoComplete
                                  key={`Stop${i}`}
                                  value={itm?.address}
                                  name={"origin"}
                                  result={async (e) => {
                                    let address = {};
                                    if (e.place) {
                                      address = await addressModel.getAddress(
                                        e.place
                                      );
                                    }
                                    updatetag(i, "address", e.value);
                                 
                                  }}
                                  id={`StopsAddress${i}`}
                                  required
                                  placeholder=""
                                />
                                {submitted && !Stops?.length ? (
                                  <div className="invalid-feedback d-block">
                                    Stop Address is Required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="delete-btn-red  ms-2">
                                <i
                                  className="fa fa-trash text-danger pointer"
                                  onClick={(e) => removetag(i)}
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 mb-3">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={form.email}
                  onChange={(e) => {
                    setform({ ...form, email: e.target.value });
                    emailCheck(e.target.value);
                  }}
                  required
                />
                {emailErr ? (
                  <div className="invalid-feedback d-block">{emailErr}</div>
                ) : (
                  <></>
                )}
              </div> */}

                {detail &&
                detail.role &&
                detail.role._id == "6540cfa35a7513892be61415" ? (
                  <div className="col-md-6 mb-3">
                    <label>Merchant Fee (%)</label>
                    <input
                      className="form-control"
                      type="number"
                      // onKeyPress={e=>methodModel.isNumber(e)}
                      value={form.merchant_fee}
                      onChange={(e) => {
                        setform({ ...form, merchant_fee: e.target.value });
                      }}
                    />
                  </div>
                ) : null}

                {/* <div className="col-md-6 mb-3">
                <label>
                  Status<span className="star">*</span>
                </label>
                <SelectDropdown
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="Select Status"
                  intialValue={form.status}
                  result={e => { setform({ ...form, status: e.value }) }}
                  options={statusModel.list}
                />
                {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
              </div>   */}

                {/* <div className="col-md-6  mb-3">
                <label className="lablefontcls">Image</label>
                <br></br>
                <ImageUpload
                  model="users"
                  result={(e) => imageResult(e, "image")}
                  value={images.image || form.image}
                  multiple={false}
                />
              </div> */}
              </div>
              {form?.board_id ? (
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 class="profilelist">Load Details</h5>
                  </div>
                  <div className="row">
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Average Weight of the load
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name=""
                          // min={0}
                          // pattern="^[0-9]+(\.[0-9]{0,2})?$"
                          className="form-control"
                          value={form?.total_weight}
                          required
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(inputValue) || inputValue === "") {
                              setform({
                                ...form,
                                total_weight: inputValue,
                              });
                            }
                          }}
                        />

                        {submitted && !form?.total_weight ? (
                          <div className="invalid-feedback d-block">
                            Weight is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Capacity<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          // pattern="^[0-9]+(\.[0-9]{0,2})?$"
                          // min={0}
                          name=""
                          className="form-control"
                          id=""
                          value={form?.capacity}
                          required
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(inputValue) || inputValue === "") {
                              setform({
                                ...form,
                                capacity: inputValue,
                              });
                            }
                          }}
                        />

                        {submitted && !form?.capacity ? (
                          <div className="invalid-feedback d-block">
                            Capacity is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>EST Volume Based on Frequency</label>
                        <input
                          type="text"
                          // pattern="^[0-9]+(\.[0-9]{0,2})?$"
                          // min={0}
                          name=""
                          className="form-control"
                          id=""
                          value={form?.est_volume}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(inputValue) || inputValue === "") {
                              setform({
                                ...form,
                                est_volume: inputValue,
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Frequency
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          // pattern="^[0-9]+(\.[0-9]{0,2})?$"
                          // min={0}
                          name=""
                          required
                          className="form-control"
                          id=""
                          value={form?.frequency}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(inputValue) || inputValue === "") {
                              setform({
                                ...form,
                                frequency: inputValue,
                              });
                            }
                          }}
                        />

                        {submitted && !form?.frequency ? (
                          <div className="invalid-feedback d-block">
                            Frequency is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Dispatch Days<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          pattern="^[a-zA-Z0-9\s]*$"
                          required
                          value={form?.dispatch_days}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const regex = new RegExp("^[a-zA-Z0-9\\s]*$");
                            if (regex.test(inputValue)) {
                              setform({
                                ...form,
                                dispatch_days: inputValue,
                              });
                            }
                          }}
                        />

                        {submitted && !form.dispatch_days ? (
                          <div className="invalid-feedback d-block">
                            Dispatch Day is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          All in Rate<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name=""
                          required
                          className="form-control"
                          id=""
                          maxLength={12}
                          value={form?.all_in_rate}
                          onChange={(e) => {
                            setform({
                              ...form,
                              all_in_rate: methodModel.isNumber(e),
                            });
                          }}
                        />

                        {submitted && !form.all_in_rate ? (
                          <div className="invalid-feedback d-block">
                            All in Rate is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Duration of the lane
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={form?.lane_duration}
                          maxLength={7}
                          minLength={1}
                          required
                          onChange={(e) =>
                            setform({
                              ...form,
                              lane_duration: methodModel.isNumber(e),
                            })
                          }
                        />
                        {submitted && !form.lane_duration ? (
                          <div className="invalid-feedback d-block">
                            Lane Duration is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.rfpsId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Comment
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <textarea
                          className="form-control"
                          rows={5}
                          cols={5}
                          type="text"
                          value={form?.comments}
                          onChange={(e) => {
                            setform({ ...form, comments: e.target.value });
                          }}
                        />
                      </div>
                    )}
                    {form?.board_id == environment.prebooksId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Bid Price
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          min={0}
                          className="form-control"
                          value={form?.bid_rate}
                          maxLength={7}
                          minLength={1}
                          required
                          onChange={(e) =>
                            setform({
                              ...form,
                              bid_rate: methodModel.isNumber(e),
                            })
                          }
                        />
                        {submitted && !form.bid_rate ? (
                          <div className="invalid-feedback d-block">
                            Bid Price is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.prebooksId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          PU Price
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          min={0}
                          className="form-control"
                          value={form?.pu_rate}
                          maxLength={7}
                          minLength={1}
                          required
                          onChange={(e) =>
                            setform({
                              ...form,
                              pu_rate: methodModel.isNumber(e),
                            })
                          }
                        />
                        {submitted && !form.pu_rate ? (
                          <div className="invalid-feedback d-block">
                            PU Price is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.prebooksId && (
                      <div className="col-md-6 mb-3">
                        <label>
                          Total Distance
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={form?.total_distance}
                          maxLength={7}
                          minLength={1}
                          required
                          onChange={(e) =>
                            setform({
                              ...form,
                              total_distance: methodModel.isNumber(e),
                            })
                          }
                        />
                        {submitted && !form.total_distance ? (
                          <div className="invalid-feedback d-block">
                            Total Distance is Required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {form?.board_id == environment.prebooksId && (
                      <div className="col-md-6 mb-3">
                        <label>Note</label>
                        <textarea
                          className="form-control"
                          rows={5}
                          cols={5}
                          type="text"
                          value={form?.notes}
                          onChange={(e) => {
                            setform({ ...form, notes: e.target.value });
                          }}
                        />
                      </div>
                    )}
                    {/* <div className="col-md-6 mb-3">
                  <label>
                    Auction Type<span className="text-danger">*</span>
                  </label>
                  <SelectDropdown
                    id="statusDropdown"
                    class="role-color"
                    displayValue="name"
                    placeholder="Select Auction Type"
                    intialValue={form.auction_type}
                    disabled={form.id ? true : false}
                    result={(e) => {
                      setform({ ...form, auction_type: e.value });
                    }}
                    options={[
                      {
                        id: "adjusted",
                        name: "Adjusted",
                      },
                    ]}
                    required={true}
                  />
                  {submitted && !form.auction_type ? (
                    <div className="invalid-feedback d-block">
                      Auction Type is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div> */}
                  </div>
                </div>
              ) : null}

              <div className="text-right mr-2 mb-2">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
