import React from "react";
import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import "./style.scss";
import ApiClient from "../../../methods/api/apiClient";
import Layout from "../../global/layout";

const Html = ({
  handleSubmit,
  setForm,
  form,
  getError,
  uploadImage,
  submitted,
  result,
}) => {

  return (
    <>
      <div className="edit-p">
        <div className="row">
          <div className="col-lg-12">
            <div className=" title-head">
              <h3 className="ViewUser mb-0 user-back">
                {" "}
                  <i
                    className="fa fa-arrow-left me-2"
                    title="Back"
                    onClick={()=>result({event:'back'})}
                    aria-hidden="true"
                  ></i>
               Edit Profile
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className=" mt-5">
              <form name="profileForm" className="=" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3 inputFlex ">
                    <label>Image</label>
                    <div className="profile_img_side">
                     
                        
                          <img
                            src={methodModel.userImg(form && form.image)}
                            className="profileImage"
                          />
                       
                        <div className="profile_btn mt-2">
                          
                            <label className="new_images edit  ">
                              <input
                                id="bannerImage"
                                type="file"
                                className="d-none"
                                accept="image/*"
                                value={form.baseImg ? form.baseImg : ""}
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                            <span className="uploader"> <i class="material-icons">camera_alt</i></span>
                            </label>
                         
                         
                            {form.image ? (
                              <label
                                className=" delete ms-2"
                                onClick={(e) => {
                                  setForm({ ...form, image: "" });
                                }}
                              >
                              *
                              </label>
                            ) : (
                              <></>
                            )}
                       
                          {/* <input type="hidden" name='image' required value={form.image} /> */}
                          {submitted && getError("image")?.invalid ? (
                            <div className="invalid-feedback d-block">
                              Image is required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>


                      
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 inputFlex">
                    <label>
                      Name:
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="fullName"
                        value={form.fullName}
                        onChange={(e) =>
                          setForm({ ...form, fullName: e.target.value })
                        }
                        required
                      />
                      {submitted && getError("fullName")?.invalid ? (
                        <div className="invalid-feedback d-block">
                          Name is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-12 mb-3 inputFlex">
            <label>Last Name<span className='star'>*</span></label>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name='lastName'
                value={form.lastName}
                onChange={e => setForm({ ...form, lastName: e.target.value })}
                required
              />
              {submitted && getError('lastName')?.invalid ? <div className="invalid-feedback d-block">Name is required</div> : <></>}
            </div>
          </div> */}

                  <div className="col-md-12 mb-3 inputFlex">
                    <label>Email:</label>
                    <div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Name"
                        value={form.email ? form.email : ""}
                        disabled
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-12 mb-3 inputFlex">
            <label>Mobile No<span className='star'>*</span></label>
            <div>
              <div className="phoneInput">
                <input
                  type="text"
                  className="form-control" placeholder='+60'
                  name='dialCode'
                  value={form && form.dialCode || ''}
                  dialCode="true"
                  title="Phone number with + and remaing 9 digit with 0-9"
                  maxLength={4}
                  onChange={e => setForm({ ...form, dialCode: e.target.value })}
                  required
                />
                <input
                  type="text"
                  name='mobileNo'
                  className="form-control" placeholder='Mobile No.'
                  value={form && form.mobileNo}
                  minLength="10"
                  maxLength={12}
                  onChange={e => setForm({ ...form, mobileNo: methodModel.isNumber(e) })}
                  required
                />
              </div>
              {submitted && getError('dialCode').invalid ? <div className="invalid-feedback d-block">invalid country code</div> : <></>}
              {submitted && getError('mobileNo').invalid && !getError('dialCode').invalid ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}
            </div>

          </div> */}

                  {/* <div className="col-md-12 mb-3 inputFlex">
                    <label>Role:</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        value={methodModel.capitalizeFirstLetter(form?.role)}
                        disabled
                      />
                    </div>
                  </div> */}

                  <div className="col-md-12 text-right mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary "
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
