import React, { useEffect } from "react";
import Layout from "../../../components/global/layout";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import loader from "../../../methods/loader";
import Layout2 from "../../../components/global/layout2";

const Html = ({ form, handleSubmit, setform, submitted, back }) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);
  }, []);

  return (
    <>
      <Layout2 title="Load Classification">
        <div className="mainareas new-table-set">
          <form onSubmit={handleSubmit}>
            <div className=" edit-page-common new-edit edit-page-commons">
              <div className="row">
                <div className="col-lg-12">
                  <div class=" title-head">
                    <h3
                      className="ViewUseraa mb-3 user-back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left me-2 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                      {form && form.id ? "Edit" : "Add"} Load Classification
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div class=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div class=" white-bg-main mb-4">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <label>
                              Board Name<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fa fa-clipboard"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.name}
                                onChange={(e) =>
                                  setform({ ...form, name: e.target.value })
                                }
                              />
                              
                            </div>
                            {submitted && !form.name ? (
                                <div className="invalid-feedback d-block">
                                  Name is Required
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                          <div className="col-md-4 mb-3">
                            <label> Expiration Type<span className="text-danger">*</span></label>

                            <div className="input-new-design">
                              <div className="input-icon">
                          
                              <i class="fa fa-calendar" aria-hidden="true"></i>

                              </div>
                              <select
                                value={form?.expiration_type}
                                className="form-control"
                                onChange={(e) => {
                                  setform({
                                    ...form,
                                    expiration_type: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Select Type</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                                <option value="days">Days</option>
                              </select>
                            </div>
                            {submitted && !form.expiration_type ? (
                              <div className="invalid-feedback d-block">
                                Expiration Type is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className=""> Expiration Value<span className="text-danger">*</span></label>
                            <div className="input-new-design">
                              <div className="input-icon">
                              <i class="fa fa-clock" aria-hidden="true"></i>

                              </div>
                              <input
                                type="number"
                                min={1}
                                className="form-control"
                                value={form.expiration_value}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    expiration_value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            {submitted && !form.expiration_value ? (
                              <div className="invalid-feedback d-block">
                                Expiration Value is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-right mr-2 mb-2">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Layout2>
    </>
  );
};

export default Html;
