import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { roleType } from "../../models/type.model";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import environment from "../../environment";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../components/common/ImageUpload";
import addressModel from "../../models/address.model";
import { IoIosCamera } from "react-icons/io";

const AddEditStaff = () => {
  const { id } = useParams();
  const [PermissionId, setPermissionId] = useState("");
  const [form, setform] = useState(roleType);
  const history = useHistory();
  const [permission, setPermission] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "" });
  const [role, setRole] = useState([]);
  const [emailErr, setEmailErr] = useState("");
  const [editCheck, setEditTrue] = useState(true);
  const [AllRead, setisAllRead] = useState(false);
  const [AllEdit, setAllEdit] = useState(false);
  const [AllDelete, setAllDelete] = useState(false);
  const [AllAdd, setAllaDD] = useState(false);

  const [emailLoader, setEmailLoader] = useState(false);
  const [documents, setdocument] = useState({
    documentType: "Registration",
    documentNumber: "",
    issueDate: "",
    expirationDate: "",
    documents: "",
  });
  const [truck_info, setinfo] = useState({
    truck_id: null,
    brand: "",
    model: "",
    year: null,
  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");

  const defaultvalue = () => {
    let keys = { ...roleType };
    Object.keys(roleType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    Object.keys(roleType.permissions).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = "active";
    return keys;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!id) {
      if (
        !form?.address ||
        !form?.role ||
        !form?.firstName ||
        !form?.dialCode ||
        !form.mobileNo ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        !form?.country
      ) {
        return;
      }
    } else {
      if (
        (role != "staff" && !form?.address) ||
        !form?.role ||
        !form?.dialCode ||
        !form.mobileNo ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        !form?.country
      ) {
        return;
      }
    }

    let method = "post";
    let url = "admin/add-user";
    let value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      address: form?.address,
      mobileNo: form?.mobileNo,
      dialCode: form?.dialCode,
      image: form?.image,
      email: form?.email,
      role: form?.role,
      country: form?.country,
      city: form?.city,
      state: form?.state,
      pincode: form?.pincode,
    };

    setform({
      ...form,
      permissions: {
        ...form?.permissions,
        // carrier_complete_access: handlecompleteAccess(),
      },
    });

    value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      address: form?.address,
      mobileNo: form?.mobileNo,
      dialCode: form?.dialCode,
      image: form?.image,
      email: form?.email,
      country: form?.country,
      city: form?.city,
      state: form?.state,
      role: form?.role,
      pincode: form?.pincode,

      permissions: form?.permissions,
    };
    //   value = {
    //     ...value,
    //     permissions: {
    //       ...value?.permissions,
    //       carrier_complete_access: handlecompleteAccess(),
    //     },
    //   };

    if (id && form?.role == "staff") {
      method = "put";
      url = "admin/edit-user";
      value = {
        firstName: form?.firstName,
        lastName: form?.lastName,
        address: form?.address,
        mobileNo: form?.mobileNo,
        dialCode: form?.dialCode,
        image: form?.image,
        permissions: { ...form?.permissions, id: permission?.id },
        id: form?.id,
        country: form?.country,
        city: form?.city,
        state: form?.state,
        pincode: form?.pincode,
      };
      //   value = {
      //     ...value,
      //     permissions: {
      //       ...value?.permissions,
      //       carrier_complete_access: handlecompleteAccess(),
      //     },
      //   };
    } else {
      delete value.id;
    }
    delete value?.confirmPassword;
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
      }
      loader(false);
    });
  };

  const setpermission = (key, value) => {
    if (id) {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          id: PermissionId,
          [key]: value,
        },
      });
    } else {
      setform({
        ...form,
        permissions: {
          ...form.permissions,
          [key]: value,
        },
      });
      setPermission({
        ...permission,
        [key]: value,
      });
    }
  };

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        setPermission({
          id: res?.data?.id,
          carrier_add: res?.data?.carrier_add,
          // carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
          faq_add: res?.data?.faq_add,
          faq_delete: res?.data?.faq_delete,
          faq_edit: res?.data?.faq_edit,
          faq_get: res?.data?.faq_get,

          load_add: res?.data?.load_add,
          load_delete: res?.data?.load_delete,
          load_edit: res?.data?.load_edit,
          load_get: res?.data?.load_get,

          bid_add: res?.data?.bid_add,
          bid_delete: res?.data?.bid_delete,
          bid_edit: res?.data?.bid_edit,
          bid_get: res?.data?.bid_get,

          board_add: res?.data?.board_add,
          board_delete: res?.data?.board_delete,
          board_edit: res?.data?.board_edit,
          board_get: res?.data?.board_get,
          invite_user_get: res?.data?.invite_user_get,
          shipment_notes_get: res?.data?.shipment_notes_get,
          notes_get: res?.data?.notes_get,
          query_get: res?.data?.query_get,
          carrier_request_get: res?.data?.carrier_request_get,
          news_letter_get: res?.data?.news_letter_get,
        });
        setPermissionId(res?.data?.id);
        // setform({...form,permissions:res?.data})
      }
    });
  };

  useEffect(() => {
    if (id) {
      setform({ ...form, permissions: permission });
    }
  }, [permission]);

  useEffect(() => {
    if (id) {
      setform({});
      loader(true);
      ApiClient.get("user/detail", { id }).then((res) => {
        if (res.success) {
          let value = res?.data;
   
          setform({
            ...form,
            id: res?.data?.id,
            firstName: res?.data.firstName,
            lastName: res?.data?.lastName,
            mobileNo: res?.data?.mobileNo,
            dialCode: res?.data?.dialCode,
            address: res?.data?.address,
            email: res?.data?.email,
            image: res?.data?.image,
            role: res?.data?.role,
            gender: res?.data?.gender,
            state: res?.data?.state,
            pincode: res?.data?.pincode,
            country: res?.data?.country,
            city: res?.data?.city,
          });
          if (res?.data?.addedBy != null) {
            setEditTrue(true);
          }
          if (res?.data?.role == "staff") {
            getPermission();
          }
          let payload = {
            id: "",
            name: "",
            status: "active",
            permissions: {
              //  Done
              carrier_delete: false,
              carrier_get: false,
              carrier_complete_access: false,
              carrier_edit: false,
              carrier_add: false,
              faq_add: false,
              faq_delete: false,
              faq_edit: false,
              faq_get: false,

              load_add: false,
              load_delete: false,
              load_edit: false,
              load_get: false,

              bid_add: false,
              bid_delete: false,
              bid_edit: false,
              bid_get: false,

              board_add: false,
              board_delete: false,
              board_edit: false,
              board_get: false,
              invite_user_get: false,
              shipment_notes_get: false,
              notes_get: false,
              query_get: false,
              carrier_request_get: false,
              news_letter_get: false,
            },
          };

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });
          payload.loginPortal = value.loginPortal;
          if (value.permissions) {
            payload.permissions = value.permissions;
            // payload.permissions={ ...payload.permissions,...value.permissions}
          }


          // setform({
          //   ...payload,
          // });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  const handleAdminUser = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        carrier_edit: value,
        carrier_get: value,
        carrier_delete: value,
        carrier_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  const handleAdminLoads = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        load_add: value,
        load_edit: value,
        load_get: value,
        load_delete: value,
        load_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  const handleAdminBids = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        bid_add: value,
        bid_edit: value,
        bid_get: value,
        bid_delete: value,
        bid_complete_access: value,
      },
    });

    // setpermission('carrier_complete_access',value)
  };

  const handleAdminBoards = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        board_add: value,
        board_edit: value,
        board_get: value,
        board_delete: value,
        board_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  const handleAdminFAQ = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        faq_add: value,
        faq_edit: value,
        faq_get: value,
        faq_delete: value,
        faq_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  useEffect(() => {}, []);
  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = roleType.permissions;
    Object.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({ ...form, permissions: permissions });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = {
      carrier_add: form.permissions?.carrier_add,
      carrier_delete: form?.permissions?.carrier_delete,
      carrier_edit: form?.permissions?.carrier_edit,
      carrier_get: form?.permissions?.carrier_get,

      faq_add: form.permissions?.faq_add,
      faq_delete: form?.permissions?.faq_delete,
      faq_edit: form?.permissions?.faq_edit,
      faq_get: form?.permissions?.faq_get,

      load_add: form.permissions?.load_add,
      load_delete: form?.permissions?.load_delete,
      load_edit: form?.permissions?.load_edit,
      load_get: form?.permissions?.load_get,

      bid_add: form.permissions?.bid_add,
      bid_delete: form?.permissions?.bid_delete,
      bid_edit: form?.permissions?.bid_edit,
      bid_get: form?.permissions?.bid_get,

      board_add: form.permissions?.board_add,
      board_delete: form?.permissions?.board_delete,
      board_edit: form?.permissions?.board_edit,
      board_get: form?.permissions?.board_get,

      invite_user_get: form?.permissions?.invite_user_get,
      shipment_notes_get: form?.permissions?.shipment_notes_get,
      notes_get: form?.permissions?.notes_get,
      query_get: form?.permissions?.query_get,
      carrier_request_get: form?.permissions?.carrier_request_get,
      news_letter_get: form?.permissions?.news_letter_get,
    };
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    });
    return value;
  };

  const handlecompleteAccess = () => {
    let result = false;
    if (
      form?.permissions?.carrier_add &&
      form?.permissions?.carrier_delete &&
      form?.permissions?.carrier_edit &&
      form?.permissions?.carrier_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessLoads = () => {
    let result = false;
    if (
      form?.permissions?.load_add &&
      form?.permissions?.load_delete &&
      form?.permissions?.load_edit &&
      form?.permissions?.load_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessBids = () => {
    let result = false;
    if (
      form?.permissions?.bid_add &&
      form?.permissions?.bid_delete &&
      form?.permissions?.bid_edit &&
      form?.permissions?.bid_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessBoards = () => {
    let result = false;
    if (
      form?.permissions?.board_add &&
      form?.permissions?.board_delete &&
      form?.permissions?.board_edit &&
      form?.permissions?.board_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessFAQ = () => {
    let result = false;
    if (
      form?.permissions?.faq_add &&
      form?.permissions?.faq_delete &&
      form?.permissions?.faq_edit &&
      form?.permissions?.faq_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const arry = [
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
    1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
    2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
  ];
  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_get: value,
        invite_user_get: value,
        notes_get: value,
        query_get: value,
        shipment_notes_get: value,
        board_get: value,
        bid_get: value,
        load_get: value,
        faq_get: value,
        news_letter_get: value,
        carrier_request_get: value,
      },
    });
    setisAllRead(value);
    isAllChecked();
  };
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        faq_add: value,
        load_add: value,
        bid_add: value,
        board_add: value,
        faq_add: value,
      },
    });
    setAllaDD(value);
    isAllChecked();
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_edit: value,
        faq_edit: value,
        load_edit: value,
        bid_edit: value,
        board_edit: value,
      },
    });
    setAllEdit(value);
    isAllChecked();
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_delete: value,
        board_delete: value,
        bid_delete: value,
        load_delete: value,
        faq_delete: value,
      },
    });
    setAllDelete(value);
    isAllChecked();
  };

  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });

  };

  useEffect(() => {
    if (id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (!id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    } else if (!id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    }
  }, [param1]);

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setform({ ...form, image: image, baseImg: "" });
        } else {
          setform({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const imageResult = (e, key) => {

    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: images?.image });

  };

  useEffect(() => {
   
  }, [form]);
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1 edit-page-common">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <h3 className="ViewUser mb-0 user-back">
                    <Link
                      to={param1 == "carrier" ? "/carriers1" : "/staff1"}
                      className=""
                    >
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    {form && form.id ? (
                      <>
                        {form?.role == "carrier"
                          ? "Edit Carrier"
                          : "Edit Staff"}
                      </>
                    ) : (
                      <>Add {param1 == "carrier" ? "Carrier" : "Staff"}</>
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="common-padding">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" white-bg-main mb-4">
                        <div className=" white-head mb-3">
                          <h5 class="profilelist">Basic Information</h5>
                        </div>
                        <div className="row">
                          <div className="imagesRow col-md-3">
                            <div className="imagethumbWrapper">
                              <img
                                src={
                                  form?.image
                                    ? `${environment.api}images/users/${form?.image}`
                                    : "/assets/img/person.jpg"
                                }
                                className="thumbnail"
                              />
                              <div className="upload-img-icon">
                                {form?.image ? (
                                  <>
                                    <label
                                      className={`btn  `}
                                      onClick={() => {
                                        setform({ ...form, image: "" });
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </label>{" "}
                                  </>
                                ) : (
                                  <label className={`btn  `}>
                                    <input
                                      type="file"
                                      className="d-none"
                                      accept="image/*"
                                      onChange={(e) => {
                                        uploadImage(e);
                                      }}
                                    />
                                    <IoIosCamera />
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label>
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.firstName}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      firstName: e.target.value,
                                    })
                                  }
                                  // required
                                />
                                {submitted && !form.firstName ? (
                                  <div className="invalid-feedback d-block">
                                    First Name is Required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="col-md-6 mb-3">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={form.lastName}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      lastName: e.target.value,
                                    })
                                  }
                                  // required
                                />
                              </div>

                              <div className="col-md-6 mb-3">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={form.email}
                                  disabled={id ? true : false}
                                  onChange={(e) => {
                                    setform({ ...form, email: e.target.value });
                                    // emailCheck(e.target.value);
                                  }}
                                  // required
                                />
                                {/* {emailErr ? (
                                    <div className="invalid-feedback d-block">{emailErr}</div>
                                ) : (
                                    <></>
                                )} */}
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label className=" d-block">
                                  Mobile No<span className="star">*</span>
                                </label>

                                <div className="w-100  d-inline-flex">
                                  <PhoneInput
                                    value={form.dialCode + "" + form.mobileNo}
                                    countryCodeEditable={true}
                                    enableSearch={true}
                                    placeholder=""
                                    country="us"
                                    onChange={(phone, country) => {
                                      let phonenumber = phone.replace(
                                        country.dialCode,
                                        ""
                                      );
                                      // if(phonenumber!=form.mobileNo){
                                      // setform({ ...form, mobileNo: phonenumber })
                                      // }
                                      setform({
                                        ...form,
                                        dialCode: country.dialCode,
                                        mobileNo: phonenumber,
                                      });
                                    }}
                                    required
                                  />
                                </div>
                                {submitted && !form.mobileNo ? (
                                  <div className="invalid-feedback d-block">
                                    Mobile Number is Required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className=" white-bg-main mb-4">
                        <div className=" white-head mb-3">
                          <h5 class="profilelist">Address</h5>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label>
                              Address<span className="text-danger">*</span>
                            </label>
                            <GooglePlaceAutoComplete
                              value={form.address}
                              result={addressResult}
                              id="address"
                              placeholder=""
                            />
                            {submitted && !form.address ? (
                              <div className="invalid-feedback d-block">
                                Address is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>
                              City<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.city}
                              onChange={(e) =>
                                setform({ ...form, city: e.target.value })
                              }
                              // required
                            />
                            {submitted && !form.city ? (
                              <div className="invalid-feedback d-block">
                                City is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label>
                              State<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.state}
                              onChange={(e) =>
                                setform({ ...form, state: e.target.value })
                              }
                              // required
                            />
                            {submitted && !form.state ? (
                              <div className="invalid-feedback d-block">
                                State is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label>
                              Country<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={form.country}
                              onChange={(e) =>
                                setform({ ...form, country: e.target.value })
                              }
                              // required
                            />
                            {submitted && !form.country ? (
                              <div className="invalid-feedback d-block">
                                Country is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label>
                              Zipcode<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              pattern="^[a-zA-Z0-9]+$"
                              onKeyPress={(e) => {
                                var regex = new RegExp("^[a-zA-Z0-9]+$");
                                var key = String.fromCharCode(
                                  !e.charCode ? e.which : e.charCode
                                );
                                if (!regex.test(key)) {
                                  e.preventDefault();
                                  return false;
                                }
                              }}
                              min={0}
                              className="form-control"
                              value={form.pincode}
                              onChange={(e) =>
                                setform({ ...form, pincode: e.target.value })
                              }
                              // required
                            />
                            {submitted && !form.pincode ? (
                              <div className="invalid-feedback d-block">
                                Zipcode is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {!id ? (
                      <>
                        <div className="col-md-6 mb-3">
                          <label>
                            Password<span className="star">*</span>
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={eyes?.password ? "text" : "password"}
                              className="form-control"
                              value={form?.password}
                              onChange={(e) =>
                                setform({ ...form, password: e.target.value })
                              }
                            />
                            <i
                              className={
                                eyes?.password
                                  ? "fa fa-eye fa-set"
                                  : "fa fa-eye-slash fa-set"
                              }
                              onClick={() =>
                                setEyes({ ...eyes, password: !eyes?.password })
                              }
                            ></i>
                          </div>
                          {submitted &&
                            form?.password &&
                            form?.password?.length < 8 ? (
                            <div className="invalid-feedback d-block">
                              Password minimum length should be 8
                            </div>
                          ) : (
                            <></>
                          )}
                          {submitted && !form?.password ? (
                            <div className="invalid-feedback d-block">
                              Password is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Confirm Password <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={eyes?.confirmPassword ? "text" : "password"}
                              className="form-control"
                              value={form?.confirmPassword}
                              onChange={(e) =>
                                setform({
                                  ...form,
                                  confirmPassword: e.target.value,
                                })
                              }
                            // required={form?.password ? true : false}
                            />
                            <i
                              className={
                                eyes?.confirmPassword
                                  ? "fa fa-eye fa-set"
                                  : "fa fa-eye-slash fa-set"
                              }
                              onClick={() =>
                                setEyes({
                                  ...eyes,
                                  confirmPassword: !eyes.confirmPassword,
                                })
                              }
                            ></i>
                          </div>
                          {submitted &&
                            form?.password?.length > 0 &&
                            form?.confirmPassword !== form?.password &&
                            form?.confirmPassword?.length > 0 ? (
                            <div className="invalid-feedback d-block">
                              Comfirm Password is not matched with Password
                            </div>
                          ) : (
                            <></>
                          )}
                          {submitted && form?.confirmPassword?.length == 0 ? (
                            <div className="invalid-feedback d-block">
                              Confirm Password is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : null} */}
                    {form?.role == "carrier" && editCheck && (
                      <>
                        <div>
                          <p className="accopunt text-center">
                            Truck Information
                          </p>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label>
                            Truck ID <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={"text"}
                              className="form-control"
                              value={truck_info?.truck_id}
                              onChange={(e) =>
                                setinfo({
                                  ...truck_info,
                                  truck_id: e.target.value,
                                })
                              }
                            />
                          </div>
                          {submitted && !truck_info?.truck_id ? (
                            <div className="invalid-feedback d-block">
                              Truck ID is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Truck Brand <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type={"text"}
                              className="form-control"
                              value={truck_info?.brand}
                              onChange={(e) =>
                                setinfo({
                                  ...truck_info,
                                  brand: e.target.value,
                                })
                              }
                            />
                          </div>
                          {submitted && !truck_info?.brand ? (
                            <div className="invalid-feedback d-block">
                              Truck Brand is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Truck Year <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <select
                              className="form-control col-md-12 select-new"
                              onChange={(e) => {
                                setinfo({
                                  ...truck_info,
                                  year: parseInt(e.target.value),
                                });
                              
                              }}
                            >
                              {arry?.map((itm) => {
                                return <option value={itm}>{itm}</option>;
                              })}
                            </select>
                          </div>
                          {submitted && !truck_info?.year ? (
                            <div className="invalid-feedback d-block">
                              Year is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <p className="accopunt text-center">
                            Truck Documentation
                          </p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Document Number <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type="text"
                              className="form-control"
                              value={documents?.documentNumber}
                              onChange={(e) =>
                                setdocument({
                                  ...documents,
                                  documentNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                          {submitted && !documents?.documentNumber ? (
                            <div className="invalid-feedback d-block">
                              Document Number is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Issue Date <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type="date"
                              className="form-control"
                              value={documents?.issueDate}
                              onChange={(e) =>
                                setdocument({
                                  ...documents,
                                  issueDate: e.target.value,
                                })
                              }
                            />
                          </div>
                          {submitted && !documents?.issueDate ? (
                            <div className="invalid-feedback d-block">
                              Issue Date is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>
                            Expiration Date <span className="star">*</span>{" "}
                          </label>
                          <div className="inputWrapper quick-ic">
                            <input
                              type="date"
                              className="form-control"
                              value={documents?.expirationDate}
                              onChange={(e) =>
                                setdocument({
                                  ...documents,
                                  expirationDate: e.target.value,
                                })
                              }
                            />
                          </div>
                          {submitted && !documents?.expirationDate ? (
                            <div className="invalid-feedback d-block">
                              Expiration Date is Required
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 p-0 mt-3 inputWrapper">
                          <label className="form-label ml-2">
                            Document
                            <span className="text-danger">*</span>
                          </label>
                          {documents?.documents ? (
                            <>
                              <embed
                                style={{ borderRadius: "12px" }}
                                src={`http://74.208.206.18:4020/documents/${documents?.documents}`}
                                width="390"
                                height="200"
                              ></embed>
                              <button
                                type="button"
                                className="btn btn-secondary discard ml-3"
                                onClick={() => {
                                  loader(true);
                                  ApiClient.delete(
                                    `delete/document?fileName=${documents?.documents}`
                                  ).then((res) => {
                                    if (res.success) {
                                      setdocument({
                                        ...documents,
                                        documents: "",
                                      });
                                      loader(false);
                                    }
                                  });
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <input
                              className="form-control mb-0 bginput chnages"
                              type="file"
                              required
                              name=""
                              onChange={async (e) => {
                                loader(true);

                               

                                const Form = new FormData();
                                Form.append("file", e.target.files.item(0));

                                const Upload = await fetch(
                                  "http://74.208.206.18:4020/upload/document",
                                  {
                                    method: "POST",
                                    body: Form,
                                  }
                                );

                                const res = await Upload.json();
                           
                                loader(false);
                                setdocument({
                                  ...documents,
                                  documents: res?.data?.imagePath,
                                });
                              }}
                              placeholder="select your documents"
                              // onBlur={handleBlur}
                            />
                          )}
                        </div>
                      </>
                    )}
                    {form?.role == "staff" && (
                      <div className="col-md-12 ">
                        <div className=" white-bg-main ">
                          <div className=" white-head ">
                            <h5 className="profilelist">Permissions</h5>
                          </div>
                          <div class="table-responsive ">
                            <div class="table_section tablepadding table-bordered mt-0">
                              <table class="table mb-0">
                                <thead class="table_head roleTable">
                                  <tr class="heading_row">
                                    <th scope="col" class="table_data"></th>
                                    <th scope="col" class="table_data">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          HandleAll(e.target.checked)
                                        }
                                        checked={isAllChecked()}
                                      />
                                      All
                                    </th>
                                    <th scope="col" class="table_data">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          HandleAllRead(e.target.checked)
                                        }
                                        checked={AllRead || isAllChecked()}
                                      />
                                      Read
                                    </th>
                                    <th scope="col" class="table_data">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          HandleAllAdd(e.target.checked)
                                        }
                                        checked={AllAdd || isAllChecked()}
                                      />
                                      Add
                                    </th>
                                    <th scope="col" class="table_data">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          HandleallEdit(e.target.checked)
                                        }
                                        checked={AllEdit || isAllChecked()}
                                      />
                                      Edit
                                    </th>
                                    <th scope="col" class="table_data">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          HandleAllDelete(e.target.checked)
                                        }
                                        checked={AllDelete || isAllChecked()}
                                      />
                                      Delete
                                    </th>

                                    {/* <th scope="col" class="table_data"> <input
                            type="checkbox"  onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                                  </tr>
                                </thead>
                                <tbody className="roleTable">
                                  <tr>
                                    <td>
                                      <b>Carrier</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAdminUser(e.target.checked);
                                          //   if(e.target?.checked){
                                          //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                          //   }else
                                        }}
                                        checked={handlecompleteAccess()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.carrier_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.carrier_add}
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_add",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.carrier_edit
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_edit",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.carrier_delete
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_delete",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                    {/* <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.carrier_request_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_request_get",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td> */}
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Loads</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAdminLoads(e.target.checked);
                                          //   if(e.target?.checked){
                                          //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                          //   }else
                                        }}
                                        checked={handlecompleteAccessLoads()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.load_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "load_get",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.load_add}
                                        onChange={(e) =>
                                          setpermission(
                                            "load_add",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.load_edit}
                                        onChange={(e) =>
                                          setpermission(
                                            "load_edit",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.load_delete}
                                        onChange={(e) =>
                                          setpermission(
                                            "load_delete",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Bids</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAdminBids(e.target.checked);
                                          //   if(e.target?.checked){
                                          //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                          //   }else
                                        }}
                                        checked={handlecompleteAccessBids()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.bid_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "bid_get",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.bid_add}
                                        onChange={(e) =>
                                          setpermission(
                                            "bid_add",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.bid_edit}
                                        onChange={(e) =>
                                          setpermission(
                                            "bid_edit",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.bid_delete}
                                        onChange={(e) =>
                                          setpermission(
                                            "bid_delete",
                                            e.target.checked
                                          )
                                        }
                                      />{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Invited Users</b>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Carrier Request</b>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.carrier_request_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "carrier_request_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Query</b>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.query_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "query_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Notes</b>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.notes_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "notes_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>News Letter</b>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.news_letter_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "news_letter_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Boards</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAdminBoards(e.target.checked);
                                          //   if(e.target?.checked){
                                          //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                          //   }else
                                        }}
                                        checked={handlecompleteAccessBoards()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.board_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "board_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.board_add}
                                        onChange={(e) =>
                                          setpermission(
                                            "board_add",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.board_edit}
                                        onChange={(e) =>
                                          setpermission(
                                            "board_edit",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          form?.permissions?.board_delete
                                        }
                                        onChange={(e) =>
                                          setpermission(
                                            "board_delete",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>FAQ</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleAdminFAQ(e.target.checked);
                                          //   if(e.target?.checked){
                                          //     setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                          //   }else
                                        }}
                                        checked={handlecompleteAccessFAQ()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.faq_get}
                                        onChange={(e) =>
                                          setpermission(
                                            "faq_get",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.faq_add}
                                        onChange={(e) =>
                                          setpermission(
                                            "faq_add",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.faq_edit}
                                        onChange={(e) =>
                                          setpermission(
                                            "faq_edit",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={form?.permissions?.faq_delete}
                                        onChange={(e) =>
                                          setpermission(
                                            "faq_delete",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-right mt-3">
                    {
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={() => {
                          setSubmitted(true);
                        }}
                      >
                        Save
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditStaff;
