import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Chart from "react-google-charts";
import Layout2 from "../../components/global/layout2";
import { useSelector } from "react-redux";
function DriverPerformance2() {
  const [DelayedData, setDelayedData] = useState([]);
  const [DelayedGraphData,setDelayedGraphData]=useState([])
  const [View, setView] = useState("Table");
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  
  const [filters, setFilter] = useState({ isDeleted: false, search: "" });
  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data,});
      GetDelayedData({ search: searchState.data});
    }
  }, [searchState]);
  const GetDelayedData = (p = {}) => {
    loader(true);
    let f = {
      ...filters,
      ...p,
    };
  
    ApiClient.get("performance/driver", f).then((res) => {

      if (res.success) {     
        setDelayedData(res?.data);
        setDelayedGraphData(res?.data.length>0?res?.data:[{driver_name:"",total_loads:0,total_delayed_loads:0,on_time_delivery_rate:0}]);
      }
      loader(false);
    });
  };

  const filter = (p = {}) => {
    setFilter({
      ...filters,
      ...p,
    });
    GetDelayedData(p);
  };

  const DelayedTimeGraphData = [
    [
      "Carrier",
      // "Delayed Time",
      "Total Delivered Loads",
      "Delayed Loads",
      "Percentage",
    ],
    // ...((DelayedData?.length > 0 ?
      ...DelayedGraphData?.map((item) => {
        return [
          `${methodModel.capitalizeFirstLetter(item?.driver_name)}`,
          // item?.total_delay_time,
          item?.total_loads,
          item?.total_delayed_loads,
          item?.on_time_delivery_rate,
        ];
      })
    //  : [
    //   ["Element", "Density", { role: "style" }],
    //   ["Copper", 8.94, "#b87333"], // RGB value
    //   ["Silver", 10.49, "silver"], // English color name
    //   ["Gold", 19.3, "gold"],
    //   ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    // ])),
  ];
  useEffect(() => {
    GetDelayedData();
  }, []);
  return (
    <>
      <Layout2 title="Driver Performance" Sidetitle="Performance Matrix" searchShow = "true">
        <div className="mainareas stats-page">
          <div className="row d-flex align-items-center">
            {/* <div className="col-md-9 head_cls">
            <h3 className="hedding">Driver Performance</h3>
            <p className="paraCls"></p>
          </div> */}
            <div className="d-flex justify-content-end  w-100">
              <div className="dropdown addDropdown mt-0">
                <button
                  className="btn btn-primary dropdown-toggle "
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  View: {View}
                </button>
                <div
                  className="dropdown-menu shadow bg_hover"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className={` ${
                      View == "Table" ? "dropdown-item active" : "dropdown-item"
                    }  `}
                    onClick={() => {
                      setView("Table");
                    }}
                  >
                    Table
                  </a>

                  <a
                    className={` ${
                      View == "Graph" ? "dropdown-item active" : "dropdown-item"
                    }  `}
                    onClick={() => {
                      setView("Graph");
                    }}
                  >
                    Graph
                  </a>
                </div>
              </div>
            </div>

            {View == "Table" ? (
              <div className=" mt-4 ">
               <div className="d-flex justify-content-between align-items-center mb-3 flex-col-direct">
               <h5 className="mb-0">Delayed In Delivered</h5>
                <div className=" custom-dropdown">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <span
                        className={`nav-link cursor-pointer ${
                          filters.isDeleted ? "" : "active"
                        }`}
                        onClick={() => filter({ isDeleted: false })}
                      ><i class="far fa-check-circle me-2"></i>
                        Active
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        className={`nav-link cursor-pointer ${
                          filters.isDeleted ? "active" : ""
                        }`}
                        onClick={() => filter({ isDeleted: true })}
                      ><i class="far fa-times-circle me-2"></i>
                        Deleted
                      </span>
                    </li>
                  </ul>
                </div>
                </div>
                <div className="table-responsive postings_table">
                  {" "}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Driver</th>
                        <th>Total Delivered Loads</th>
                        <th>Delayed Loads</th>
                        <th>Delayed Time</th>
                        <th>Percentage</th>
                        <th>Review</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DelayedData.length>0 ? DelayedData?.map((itm, i) => {         
                        return (
                          <tr>
                            <td>
                              {methodModel.capitalizeFirstLetter(
                                itm?.driver_name
                              )}
                            </td>
                            <td>{itm?.total_delivered_loads}</td>
                            <td>{itm?.total_delayed_loads}</td>
                            <td>
                              {itm?.total_delay_time?.toFixed(1)}{" "}
                              {itm.isMinutes ? "minutes" : ""}{" "}
                              {itm.isHours ? "hours" : ""}
                            </td>

                            <td>{itm?.on_time_delivery_rate?.toFixed()}%</td>
                            <td>
                              <i className="fa fa-star"></i>{" "}
                              {itm.avg_rating?.toFixed(1)} ({itm.total_reviews})
                            </td>
                          </tr>
                        );
                      }):   
                      <tr>
                        <td colSpan="6" className="p-0">
                        <div className=" no-data">
                      <img src="assets/img/no-data.png" />
                      No Data
                    </div></td></tr>}

                      {/* <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="col-12 mt-2">
                <div className="graph_section">
                  <div className="graph_range mb-3">
                    <div>
                      <h3 className="Amount_sales">Delayed Loads</h3>
                    </div>
                  </div>

                  <div>
                    
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="350px"
                      data={DelayedTimeGraphData}
                      options={{
                        colors: ["#5146a0", "#4bc0bf"],
                        annotations: {
                          alwaysOutside: true,
                        },
                        legend: { position: "top" },
                        vAxis: {
                          format: "0", // Display integers only on the vertical axis
                        },
                      }} //
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout2>
    </>
  );
}

export default DriverPerformance2;
