import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";

const ViewSettings = () => {
  const [form, setform] = useState({ email: "", contactNo: "" });
  const history = useHistory();

  useEffect(() => {
    loader(true);
    ApiClient.get("settings").then((res) => {
      if (res.success) {
        setform(res.data);
      }
      loader(false);
    });
  }, []);
  const back = () => {
    history.goBack();
  };
  return (
    <>
      <Layout2 title="Settings" Sidetitle="Settings">

        <div className="mainareas">
          <div className="pprofile1 edit-page-common p-0 edit-page-commons">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="ViewUser  user-back">
                      {" "}
                      <a onClick={back}>
                        {" "}
                        <i
                          className="fa fa-arrow-left ml-1 "
                          title="Back"
                          aria-hidden="true"
                        ></i>
                      </a>
                      Settings Details
                    </h3>
                    <div className="edit-bg">
              <Link to="/edit-settings" className="">
                <i
                  style={{ cursor: "pointer" }}
                  class="material-icons edit"
                  title="Edit"
                >
                  edit
                </i>
              </Link>
            </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="row">
              <div className="col-lg-12">
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">
                      Settings information
                    </h5>
                  </div>

                  <div className="row">

                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">Margin Type :</label>
                          <div className="profiledetailscls">
                            {methodModel.capitalizeFirstLetter(form.margin_type)}
                          </div>
                        </div>


                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Margin Price:
                          </label>
                          <div className="profiledetailscls">
                            {form.margin_value}
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
};

export default ViewSettings;
