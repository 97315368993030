import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";

const Faqview2 = () => {
  const [addFaq, setAddFaq] = useState({
    question: "",
    answer: "",
  });
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`faq?id=${id}`).then((res) => {
        const newdata = res.data;
        setAddFaq({ question: newdata.question, answer: newdata.answer });
        loader(false);
      });
    }
  }, [id]);

  useEffect(() => {
    // if (!Permission?.faq_get) {
    //   history.push("/dashboard");
    // }
  }, []);
  const back = () => {
    history.goBack();
  };

  return (
    <Layout2 title="FAQ">

      <div className="mainareas">
        <div className="pprofile1 edit-page-common p-0 edit-page-commons">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="ViewUser mb-3 user-back">
                    {" "}
                    <a onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    Faq Details
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 className="profilelist">
                    FAQ information
                  </h5>
                </div>

                <div className="row">
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">Question :</label>
                        <div className="profiledetailscls">
                          {addFaq.question || "--"}
                        </div>
                      </div>




                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls ">
                          Answer:
                        </label>
                        <div className="profiledetailscls height-unset">
                          {!addFaq?.answer || addFaq?.answer == "<p><br></p>" ? "--" : <div

                            dangerouslySetInnerHTML={{ __html: addFaq?.answer }}
                          ></div>}

                        </div>
                      </div>

                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};
export default Faqview2;
