import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";
import methodModel from "../../methods/methods";

const CMSView2 = () => {
  const [addFaq, setAddFaq] = useState({
    question: "",
    answer: "",
  });
  const [showItem, setShowItem] = useState({});
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const history = useHistory();
  const { id } = useParams();
  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`content?id=${id}`).then((res) => {
        const newdata = res.data;
        setAddFaq(res?.data);
        loader(false);
      });
    }
  }, [id]);

  useEffect(() => {
    // if (!Permission?.faq_get) {
    //   history.push("/dashboard");
    // }
  }, []);
  const back = () => {
    history.goBack();
  };

  return (
    <Layout2 title="Content">
      <div className="mainareas">
        <div className=" edit-page-common  edit-page-commons">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="ViewUser mb-3 user-back">
                    {" "}
                    <a onClick={back}>
                      {" "}
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </a>
                    Content Details 
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className=" white-bg-main ">
                <div className=" white-head ">
                  <h5 className="profilelist">Content information</h5>
                </div>

                <div className="row">
                  <div className="col-md-12 mb-3 view-flex">
                    <label className="profileheddingcls">Title :</label>
                    <div className="profiledetailscls">
                      {addFaq.title || "--"}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 view-flex">
                    <label className="profileheddingcls">Meta Title :</label>
                    <div className="profiledetailscls">
                      {addFaq.meta_title || "--"}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 view-flex">
                    <label className="profileheddingcls">Meta Key:</label>
                    <div className="profiledetailscls">
                      {addFaq.meta_key || "--"}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3 view-flex">
                    <label className="profileheddingcls">Description:</label>
                  
                    <p className=" profiledetailscls mb-0 height-unset desc-set-m">
                    <div
                          dangerouslySetInnerHTML={{
                            __html:  methodModel.capitalizeFirstLetter(
                              addFaq?.description
                             
                            ) ||"--"
                          }}
                        ></div>

                             
                            </p>
                   
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout2>
  );
};
export default CMSView2;
