import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import loader from "../../methods/loader";

const ContactDetails = () => {
  const [form, setform] = useState({ margin_value: "", margin_type: "" });
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const handleSubmit = (e) => {
    loader(true);
    e.preventDefault();
    ApiClient.put("settings", {
      id: form?.id,
      margin_type: form?.margin_type,
      margin_value: form?.margin_value,
    }).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        history.goBack();
        // ToastsStore.success(res.message)
      }
      loader(false);
    });
  };

  useEffect(() => {
    loader(true);
    ApiClient.get("settings").then((res) => {
      if (res.success) {
        setform(res.data);
      }
      loader(false);
    });
  }, []);

  return (
    <>
      <Layout>
        <div className="pprofile1 edit-page-common p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head d-flex justify-content-between align-items-center">
                <h3 className="ViewUser mb-0 user-back">
                  <i
                    class="fa fa-arrow-left mr-2 "
                    onClick={() => {
                      history.goBack();
                    }}
                    title="Back"
                    aria-hidden="true"
                  ></i>
                  Settings
                </h3>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Basic Information</h5>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row p-3">
                    <div className="col-md-6 mb-3 ">
                      <label>Margin Type</label>
                      <select
                        className="form-control"
                        value={form?.margin_type}
                        onChange={(e) => {
                          setform({ ...form, margin_type: e.target.value });
                        }}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                      </select>
                    </div>
                    <div className="col-md-6 mb-3 ">
                      <label>Margin Price</label>
                      <input
                        type="tel"
                        min={0}
                        max={100}
                        className="form-control"
                        value={form.margin_value}
                        onChange={(e) => {
                          if (Number(e.target.value) > 100) {
                            e.target.value = 100;
                          }

                          setform({ ...form, margin_value: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-right mb-3">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactDetails;
