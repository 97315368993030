import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";

function ViewNotes2() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const [showItem, setShowItem] = useState({});
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  const GetNote = () => {
    loader(true);
    ApiClient.get("note", { id }).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetNote();
    // if (!Permission?.notes_get) {
    //   history.push("/dashboard");
    // }
  }, []);
  const back = () => {
    history.goBack();
  };
  return (
    <>
      <Layout2 title="Notes">
        <div className="mainareas">
          <div className="edit-page-common edit-page-commons">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h3 className="ViewUser mb-3 user-back">
                      {" "}
                      <a onClick={back}>
                        {" "}
                        <i
                          className="fa fa-arrow-left ml-1 "
                          title="Back"
                          aria-hidden="true"
                        ></i>
                      </a>
                      Notes Details
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={`${
                  data?.role == "staff" ? "col-lg-6" : "col-lg-12"
                }`}
              >
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">Basic information</h5>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">Full Name:</label>
                      <div className="profiledetailscls">
                        {methodModel.capitalizeFirstLetter(data && data?.user_id?.fullName)}
                      </div>
                    </div>

                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls">Email:</label>
                      <div className="profiledetailscls ">
                        {data && data?.user_id?.email}
                      </div>
                    </div>

                    {data?.user_id?.company_name && (
                      <div className="col-md-12 mb-3 view-flex">
                        <label className="profileheddingcls">
                          Company Name:
                        </label>
                        <div className="profiledetailscls Capitlize">
                          {" "}
                          {methodModel.capitalizeFirstLetter(data && data?.user_id?.company_name)}
                        </div>
                      </div>
                    )}

                    <div className="col-md-12 mb-3 view-flex">
                      <label className="profileheddingcls"> Description:</label>
                    
                        <p className=" profiledetailscls mb-0 height-unset ">
                          {methodModel.capitalizeFirstLetter(
                            data?.description
                            // ?.substr
                            // (
                            //   0,
                            //   showItem?.description
                            //     ? 2000000000000000000000
                            //     : 14
                            // )
                          )
                           || "--"}

                          {/* {data?.description?.length > 14 && (
                            <span
                              className="see-more"
                              onClick={() => {
                                toggleShowItem("description");
                              }}
                            >
                              {" "}
                              {showItem?.description
                                ? "see less"
                                : "...see more"}
                            </span>
                          )} */}
                        </p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </>
  );
}

export default ViewNotes2;
