// import './style.scss';
import { BsAward, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { Tooltip } from "antd";
import { GiWeight } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { LuFileCog } from "react-icons/lu";
import {
  MdManageHistory,
  MdOutlinePendingActions,
  MdPending,
} from "react-icons/md";
import {
  TbArrowsRight,
  TbHandOff,
  TbShoppingCartCheck,
  TbWeight,
} from "react-icons/tb";
import {
  FaNoteSticky,
  FaRegBell,
  FaRegCircleUser,
  FaRegIdCard,
  FaRegNewspaper,
} from "react-icons/fa6";
import { ImHammer2 } from "react-icons/im";
import ApiClient from "../../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { MdOutlinePending } from "react-icons/md";
import { LiaWeightHangingSolid } from "react-icons/lia";
import {
  IoCheckmarkDone,
  IoCheckmarkDoneSharp,
  IoSettingsOutline,
  IoStatsChartOutline,
} from "react-icons/io5";
import { TbHammer } from "react-icons/tb";
import { FaBook, FaQuestion, FaRegCheckCircle } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi2";
import { GoChecklist, GoQuestion } from "react-icons/go";
import { AiOutlineFileText } from "react-icons/ai";
import { BsFileText } from "react-icons/bs";
import { RiMenuSearchLine, RiUser6Line } from "react-icons/ri";
import {
  GrDocumentPerformance,
  GrNotes,
  GrUserPolice,
  GrUserSettings,
} from "react-icons/gr";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import { GrDocumentNotes } from "react-icons/gr";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiMenuFill } from "react-icons/ri";
import { LuLogOut } from "react-icons/lu";
import { logout } from "../../../actions/user";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import "./style.scss";
import { search_success } from "../../../actions/search";
import { BiBookContent, BiMessageSquare } from "react-icons/bi";
import { FiShoppingCart } from "react-icons/fi";
import { FiTruck } from "react-icons/fi";
import { BsCart } from "react-icons/bs";
import { FaRegListAlt } from "react-icons/fa";
import { MdOutlineFileDownloadDone } from "react-icons/md";

export default function Layout2({
  children,
  title = "",
  Sidetitle = "",
  title2 = "Title2",
  searchShow = false,
}) {
  const [board, setBoards] = useState([]);
  const [data, setdata] = useState([]);
  const [total, settotal] = useState(0);
  const [showCross, setShowcross] = useState(false);
  const [List, setList] = useState([]);
  const [isopen, setIsopen] = useState(false);
  const toggle = () => {
    setIsopen(!isopen);
  };
  const Permission = JSON.parse(localStorage.getItem("permission"));
  const pathname = window.location.pathname;
  const history = useHistory();
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  useEffect(() => {
    getBaords();
    if (user && user?.id) GetNotification();
  }, [user]);
  // useEffect(() => {
  //   setShowsearch()
  // }, []);
  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    toast.success("You have log out successfuly");
    history.push("/");
  };

  const getBaords = () => {
    ApiClient.get("boards?status=active").then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
        console.log()
 
        // const filteredBoards = res?.data?.data.filter((item) =>
        //   user?.board_id?.includes(item.id)
        // );
        setBoards(res?.data?.data);
      }
    });
  };
  const GetNotification = () => {
    ApiClient.get("notification/all", {
      send_to: user?.id,
      status: "unread",
    }).then((res) => {
      if (res.success) {
        settotal(res?.data?.total_count);
        setList(res?.data?.data);
      }
    });
  };
  const searchState = useSelector((state) => state.search);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setSearch(searchState.data);
  }, [searchState]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (searchState.data) {
      dispatch(search_success(""));
    }
  }, []);

  const searchHandle = (e) => {
    e.preventDefault();
    dispatch(search_success(search));
    setShowcross(true);
  };

  const searchChange = (e) => {
    setSearch(e);
    if (!e) {
      dispatch(search_success(""));
    }
  };

  const clear = () => {
    setSearch("");
    dispatch(search_success(""));
    setShowcross(false);
  };
  return (
    <>
      {/* <div className="d-flex">
        <h3>{title}</h3>

        <div className="ms-auto">
          <form class="d-flex searchForm">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div> */}
      <div className="main-wrappers hegiht100 d-flex overflow-hidden ">
        <div className="sidebar_new">
          <div
            className={`${scroll ? "ggg" : ""} inner_wraping d-flex  flex-row`}
          >
            <div className="small_sdies">
              <div className="top_data_showing">
                <ul className="ulclasssides ">
                  <li>
                    {/* <Link to="">
                      {' '} */}
                    <div className="logo-img-n">
                      <img src="/assets/img/white-fav.png" />
                    </div>
                    {/* </Link> */}
                  </li>
                 
                  <Tooltip placement="right" title={`${Permission?.dashboard_get || user?.role == "admin"?"Dashboard":`You don't have permission to access this module.`}`} >
                    <li>
                      <Link
                        to="/dashboard"
                        class={`${pathname == "/dashboard" ? "active" : ""} ${Permission?.dashboard_get || user?.role == "admin"?"":"disabled disabled-new"}`}                       
                      >
                        <RxDashboard />
                      </Link>
                    </li>
                  </Tooltip>


                  <Tooltip placement="right" title={`${Permission?.statistics_get || user?.role == "admin"?"statistics":`You don't have permission to access this module.`}`}>
                    <li >
                      <Link
                        to="/statistics"
                        class={`${pathname.includes("/statistics") ? "active" : ""
                          } ${Permission?.statistics_get || user?.role == "admin"?"":"disabled disabled-new"}`}
                      >
                        <IoStatsChartOutline />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title={`${Permission?.performance_matrix_get || user?.role == "admin"?"Performance Matrix":`You don't have permission to access this module.`}`}>
                    <li>
                      <Link
                        to="/performance-carriers"
                        class={`${pathname == "/performance-drivers" ||
                          pathname.includes("/performance-carriers")
                          ? "active"
                          : ""
                          } ${Permission?.performance_matrix_get || user?.role == "admin"?"":"disabled disabled-new"}`}
                      >
                        <GrDocumentPerformance />
                      </Link>
                    </li>
                  </Tooltip>

                  {user?.role=="staff"?<></>: <Tooltip placement="right" title="Staff">
                    <li>
                      <Link
                        to="/staff"
                        class={`${pathname.includes("/staff") ||
                          pathname.includes("/Staff1")
                          ? "active"
                          : ""
                          }`}
                      >
                        <FaRegIdCard />
                      </Link>
                    </li>
                  </Tooltip>}
                 
                  <Tooltip placement="right" title="Carrier">
                    <li>
                      <Link
                        to="/carriers"
                        class={`${pathname == "/carrier-users" ||
                          pathname == "/rejectedCarriers" ||
                          pathname == "/invited-users" ||
                          pathname == "/acceptedCarriers" ||
                          pathname.includes("/carriers") ||
                          pathname == "/carrier-requests"
                          ? "active"
                          : ""
                          }`}
                      >
                        <GrUserSettings />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title="Loads Management">
                    <li>
                      <Link
                        to="/loads"
                        class={`${pathname.includes("/loads") ||
                          pathname.includes("/loads") ||
                          pathname == "/prebook-loads" ||
                          pathname == "/intransit-loads" ||
                          pathname == "/rfps-loads" ||
                          pathname == "/usps-loads" ||
                          pathname == "/pendingPickup-loads" ||
                          pathname == "/pickedUp-loads" ||
                          pathname == "/intransit-loads" ||
                          pathname == "/delivered-loads"
                          ? "active"
                          : ""
                          }`}
                      >
                        <MdManageHistory />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title="Bids Management">
                    <li>
                      <Link
                        to="/bids"
                        class={`${pathname == "/available-loads" ||
                          pathname == "/delivered-bids" ||
                          pathname == "/intransit-bids" ||
                          pathname.includes("/bids") ||
                          pathname == "/pending-bids" ||
                          pathname == "/rejected-bids" ||
                          pathname == "/accepted-bids" ||
                          pathname == "/awarded-bids"
                          ? "active"
                          : ""
                          }`}
                      >
                        <LuFileCog />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title="Notes">
                    <li>
                      <Link
                        to="/notes"
                        class={`${pathname.includes("/notes") ? "active" : ""
                          }`}
                      >
                        <GrDocumentNotes />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title=" Shipment Notes">
                    <li>
                      <Link
                        to="/shipment-notes"
                        class={`${pathname.includes("/shipment-notes") ? "active" : ""
                          }`}
                      >
                        <GrNotes />
                      </Link>
                    </li>
                  </Tooltip>
                  <Tooltip placement="right" title="News Letters">
                    <li>
                      <Link
                        to="/newsletter"
                        class={`${pathname.includes("/newsletter") ? "active" : ""
                          }`}
                      >
                        <FaRegNewspaper />
                      </Link>
                    </li>
                  </Tooltip>
                  <Tooltip placement="right" title="Query">
                    <li>
                      <Link
                        to="/queries"
                        class={`${pathname.includes("/queries") ? "active" : ""
                          }`}
                      >
                        <GoQuestion />
                      </Link>
                    </li>
                  </Tooltip>
                  <Tooltip placement="right" title="Load Classification">
                    <li>
                      <Link
                        to="/boards"
                        class={`${pathname.includes("/boards") ? "active" : ""
                          }`}
                      >
                        <RiMenuSearchLine />
                      </Link>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title="FAQ">
                    <li>
                      <Link
                        to="/faq"
                        class={`${pathname.includes("/faq") ? "active" : ""}`}
                      >
                        <FaQuestion />
                      </Link>
                    </li>
                  </Tooltip>
                  <Tooltip placement="right" title="Content Management">
                    <li>
                      <Link
                        to="/content"
                        class={`${pathname.includes("/content") ? "active" : ""
                          }`}
                      >
                        <BiBookContent />
                      </Link>
                    </li>
                  </Tooltip>
                </ul>
              </div>
              <div className="bottom_data_showing">
                <ul className="ulclasssides ">
                  {/* <Tooltip placement="right" title="Notes">
                    <li>
                      <Link to="/notes2" class={`${pathname == "/notes2" ? "active" : ""}`}>
                        {' '}
                        <GrDocumentNotes />
                      </Link>
                    </li>
                  </Tooltip> */}
                  <Tooltip placement="right" title={`${Permission?.settings_get || user?.role == "admin"?"Settings":`You don't have permission to access this module.`}`}>
                    <li >
                      <Link
                        to="/settings"
                        class={`${pathname == "/settings" ? "active" : ""} ${Permission?.settings_get || user?.role == "admin"?"":"disabled disabled-new"}`}
                      >
                        {" "}
                        <IoSettingsOutline />
                      </Link>
                    </li>
                  </Tooltip>
                 
                 
                  <Tooltip placement="right" title={`${Permission?.notifications_get || user?.role == "admin"?"Notifications":`You don't have permission to access this module.`}`}>
                    <li  className={`notification-m position-relative`}>
                      {total > 0 ? (
                        <span className="notcount">{total}</span>
                      ) : (
                        <></>
                      )}
                      <Link
                        to="/notification"
                        class={`${pathname == "/notification" ? "active" : ""} ${Permission?.notifications_get || user?.role == "admin"?"":"disabled disabled-new"}`}
                      >
                        {" "}
                        <IoMdNotificationsOutline />
                      </Link>
                    </li>
                  </Tooltip>
                 
                  <Tooltip placement="right" title="Logout">
                    <li>
                      <a id="handleLogout" onClick={Logout} className="">

                        <LuLogOut className="me-1" />

                      </a>
                    </li>
                  </Tooltip>

                  <Tooltip placement="right" title="Profile">
                    <li className="profile-img">
                      <Link to="/profile" class="">
                        <img
                          src={methodModel.userImg(user && user?.image) || "--"}
                          className="rounded-circles"
                        />
                      </Link>
                    </li>
                  </Tooltip>
                </ul>
              </div>
            </div>
          </div>
          {!(
            pathname == "/performance-carriers" ||
            pathname == "/pendingPickup-loads" ||
            pathname == "/usps-loads" ||
            pathname == "/loads" ||
            ((pathname.includes("/loads"))&&(!pathname.includes("/loads/view"))&&(!pathname.includes("/loads/add")))||
            pathname == "/prebook-loads" ||
            pathname == "/pickedUp-loads" ||
            pathname == "/intransit-loads" ||
            pathname == "/loads" ||
            pathname == "/rfps-loads" ||
            pathname == "/delivered-loads" ||
            pathname == "/delivered-bids" ||
            pathname == "/intransit-bids" ||
            pathname == "/awarded-bids" ||
            pathname == "/available-loads" ||
            pathname == "//awarded-bids" ||
            pathname == "/bids" ||
            pathname == "/pending-bids" ||
            pathname == "/rejected-bids" ||
            pathname == "/accepted-bids" ||
            pathname == "/available-loads" ||
            pathname == "/content" ||
            pathname == "/faq" ||
            pathname == "/boards" ||
            pathname == "/queries" ||
            pathname == "/newsletter" ||
            pathname == "/notes" ||
            pathname == "/shipment-notes" ||
            pathname == "/acceptedCarriers" ||
            pathname == "/rejectedCarriers" ||
            pathname == "/staff" ||
            pathname == "/performance-drivers" ||
            pathname == "/carrier-users" ||
            pathname == "/invited-users" ||
            pathname == "/carriers" ||
            pathname == "/carrier-requests" ||
            pathname == "/carriers1/invited-users" ||
            pathname == "/carriers1/rejected-users" ||
            pathname == "/carriers1/user-request" ||
            pathname == "/drivers" ||
            pathname == "/trucks" ||
            pathname == "/bids/pending-bid" ||
            pathname == "/bids/rejected-bid" ||
            pathname == "/bids/accepted-bid" ||
            pathname == "/bids/awarded-bid" ||
            pathname == "/loads/delivered" ||
            pathname == "/carriers" ||
            pathname == "/loads/intransit" ||
            pathname == "/loads/pendingPickup-loads" ||
            pathname.includes("/jobs") ||
            pathname == "/settings" ||
            pathname == "/profile"
          ) ? (
            <></>
          ) : (
            <div
              className={`${isopen ? "toogle_hide" : ""
                } big_sideswrap sidebar_togle`}
            >
              <div className="headerlogo collpase_data">
                <div className="name_menus bars_right d-flex align-items-center ">
                  <h4 className="fs-16 side_title">{Sidetitle}</h4>
                  <div className="icons_bars pointer" onClick={() => toggle()}>
                    <RiMenuFill />
                  </div>
                </div>
              </div>

              <div className="itesm_list ">
                <ul className=" border-btm-grey ">
                  {pathname.includes("/performance-carriers") ||
                    pathname.includes("/performance-drivers") ? (
                    <>
                      <li>
                        <Link
                          to="/performance-carriers"
                          class={`${pathname == "/performance-carriers"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            <BsCart />
                          </div>
                          <span className="ms-2">Carriers</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/performance-drivers"
                          class={`${pathname == "/performance-drivers"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FiTruck />
                          </div>
                          <span className="ms-2">Drivers</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {pathname.includes("/available-loads") ||
                    pathname.includes("/intransit-bids") ||
                    pathname.includes("/delivered-bids") ||
                    pathname.includes("/bids") ||
                    pathname.includes("/pending-bids") ||
                    pathname.includes("/rejected-bids") ||
                    pathname.includes("/accepted-bids") ||
                    pathname.includes("/awarded-bids") ? (
                    <>
                      <li>
                        <Link
                          to="/bids"
                          class={`${pathname == "/bids" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegListAlt />
                          </div>
                          <span className="ms-2">All Bids</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/available-loads"
                          class={`${pathname == "/available-loads"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <ImHammer2 />
                          </div>
                          <span className="ms-2">Available Bids</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/pending-bids"
                          class={`${pathname == "/pending-bids" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            <MdOutlinePendingActions />
                          </div>
                          <span className="ms-2">Pending Bids</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/rejected-bids"
                          class={`${pathname == "/rejected-bids"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            <TbHandOff />{" "}
                          </div>
                          <span className="ms-2">Rejected Bids</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/accepted-bids"
                          class={`${pathname == "/accepted-bids"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegCheckCircle />{" "}
                          </div>
                          <span className="ms-2">Accepted Bids</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/awarded-bids"
                          class={`${pathname == "/awarded-bids" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <BsAward />{" "}
                          </div>
                          <span className="ms-2">Awarded Bids</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/intransit-bids"
                          class={`${pathname == "/intransit-bids"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <TbArrowsRight />{" "}
                          </div>
                          <span className="ms-2">In Transit</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/delivered-bids"
                          class={`${pathname == "/delivered-bids"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <TbShoppingCartCheck />{" "}
                          </div>
                          <span className="ms-2">Delivered Bids</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}

                  {pathname.includes("/loads") ||
                    pathname.includes("/loads") ||
                    pathname.includes("/prebook-loads") ||
                    pathname.includes("/rfps-loads") ||
                    pathname.includes("/usps-loads") ||
                    pathname.includes("/pendingPickup-loads") ||
                    pathname.includes("/pickedUp-loads") ||
                    pathname.includes("/intransit-loads") ||
                    pathname.includes("/delivered-loads") ? (
                    <>
                      <li>
                        <Link
                          to="/loads"
                          class={`${pathname == "/loads" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <TbWeight />{" "}
                          </div>
                          <span className="ms-2">All Loads</span>
                        </Link>
                      </li>

                      <li>
                        {/* <Link
                          to="/prebook-loads"
                          class={`${
                            pathname == "/prebook-loads"
                              ? "sidebar_active"
                              : ""
                          }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <BsFileText />
                          </div>
                          <span className="ms-2">Prebook</span>
                        </Link> */}
                        {board?.map((itm) => {
                          return (
                            <li>
                              <Link to={`/loads/${itm?.id}`} class={`${pathname == `/loads/${itm?.id}` ? "sidebar_active" : ""}`}>
                                {/* {itm?.name == "prebook" ? <AiOutlineFileText />: null}
                              {itm?.name == "usps" ? <BsFileText /> : null}
                              {itm?.name == "rfps" ? <CgNotes /> : null}
                              {itm?.name == "RFP" ? <CgNotes /> : null} */}
                                {/* <MdOutlinePendingActions /> */}
                                <div className="icon-border">
                                  {" "}
                                  <AiOutlineFileText />{" "}
                                </div>

                                <span className="ms-2">
                                  {methodModel.capitalizeFirstLetter(itm?.name)}
                                </span>
                              </Link>
                            </li>

                          );
                        })}

                      </li>

                      {/* <li>
                        <Link
                          to="/rfps-loads"
                          class={`${
                            pathname == "/rfps-loads" ? "sidebar_active" : ""
                          }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <CgNotes />
                          </div>
                          <span className="ms-2">RFPS</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/usps-loads"
                          class={`${
                            pathname == "/usps-loads" ? "sidebar_active" : ""
                          }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <BsReverseLayoutTextWindowReverse />
                          </div>
                          <span className="ms-2">USPS</span>
                        </Link>
                      </li> */}

                      <li>
                        <Link
                          to="/pendingPickup-loads"
                          class={`${pathname == "/pendingPickup-loads"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <MdOutlinePending />{" "}
                          </div>
                          <span className="ms-2">Pending-Pickup</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/pickedUp-loads"
                          class={`${pathname == "/pickedUp-loads"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <MdOutlinePending />{" "}
                          </div>
                          <span className="ms-2">PickedUp</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/intransit-loads"
                          class={`${pathname == "/intransit-loads"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <TbArrowsRight />{" "}
                          </div>
                          <span className="ms-2">In-Transit</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/delivered-loads"
                          class={`${pathname == "/delivered-loads"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <TbShoppingCartCheck />{" "}
                          </div>
                          <span className="ms-2">Delivered</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {/* staff */}

                  {pathname.includes("/staff") ? (
                    <>
                      <li>
                        <Link
                          to="/staff"
                          class={`${pathname == "/staff" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegIdCard />
                          </div>
                          <span className="ms-2">Staff</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* faq */}

                  {pathname.includes("/faq") ? (
                    <>
                      <li>
                        <Link
                          to="/faq"
                          class={`${pathname == "/faq" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegIdCard />
                          </div>
                          <span className="ms-2">FAq</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* Load Classification */}

                  {pathname.includes("/boards") ? (
                    <>
                      <li>
                        <Link
                          to="/boards"
                          class={`${pathname == "/boards" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <RiMenuSearchLine />
                          </div>
                          <span className="ms-2">Load Classification</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* query */}

                  {pathname.includes("/queries") ? (
                    <>
                      <li>
                        <Link
                          to="/queries"
                          class={`${pathname == "/queries" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <GoQuestion />
                          </div>
                          <span className="ms-2">Query</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {/* User Management */}
                  {pathname.includes("/carrier-users") ||
                    pathname.includes("/rejectedCarriers") ||
                    pathname.includes("/acceptedCarriers") ||
                    pathname.includes("/invited-users") ||
                    pathname.includes("/carriers") ||
                    pathname.includes("/carrier-requests") ? (
                    <>
                      <li>
                        <Link
                          to="/carriers"
                          class={`${pathname == "/carriers" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FiShoppingCart />
                          </div>
                          <span className="ms-2">Carriers</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/acceptedCarriers"
                          class={`${pathname == "/acceptedCarriers"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <IoCheckmarkDone />
                          </div>
                          <span className="ms-2">Accepted Carriers</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/rejectedCarriers"
                          class={`${pathname == "/rejectedCarriers"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            <RxCrossCircled />
                          </div>
                          <span className="ms-2">Rejected Carriers</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/carrier-requests"
                          class={`${pathname == "/carrier-requests"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <BiMessageSquare />
                          </div>
                          <span className="ms-2">Carrier Requests</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/carrier-users"
                          class={`${pathname == "/carrier-users"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegCircleUser />
                          </div>
                          <span className="ms-2">Carrier's Staff</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/invited-users"
                          class={`${pathname == "/invited-users"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <MdOutlineFileDownloadDone />{" "}
                          </div>

                          <span className="ms-2">Approved Sub-Carriers</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* notes */}
                  {pathname.includes("/notes") ? (
                    <>
                      <li>
                        <Link
                          to="/notes"
                          class={`${pathname == "/notes" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <GrDocumentNotes />{" "}
                          </div>
                          <span className="ms-2">Notes</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {/* News Letter*/}
                  {pathname.includes("/newsletter") ? (
                    <>
                      <li>
                        <Link
                          to="/newsletter"
                          class={`${pathname == "/newsletter" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <FaRegNewspaper />
                          </div>
                          <span className="ms-2">News Letters</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {/* shipmment notes */}
                  {pathname.includes("/shipment-notes") ? (
                    <>
                      <li>
                        <Link
                          to="/shipment-notes"
                          class={`${pathname == "/shipment-notes"
                            ? "sidebar_active"
                            : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <GrNotes />{" "}
                          </div>
                          <span className="ms-2">Shipment Notes</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {/* Content Management*/}
                  {pathname.includes("/content") ? (
                    <>
                      <li>
                        <Link
                          to="/content"
                          class={`${pathname == "/content" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <BiBookContent />
                          </div>
                          <span className="ms-2">Content Management</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*  */}
                  {pathname.includes("/Users2") ? (
                    <>
                      <li>
                        <Link
                          to="/Users2"
                          class={`${pathname == "/Users2" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <GrNotes />
                          </div>
                          <span className="ms-2">Staff</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {pathname == "/settings" ? (
                    <>
                      {" "}
                      <li>
                        <Link
                          to="/settings"
                          class={`${pathname == "/settings" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <IoSettingsOutline />{" "}
                          </div>
                          <span className="ms-2">Settings</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {pathname.includes("/trucks2") ? (
                    <>
                      <li>
                        <Link
                          to="/trucks2"
                          class={`${pathname == "/trucks2" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <HiOutlineTruck />
                          </div>
                          <span className="ms-2">Trucks</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {pathname.includes("/drivers2") ? (
                    <>
                      <li>
                        <Link
                          to="/drivers2"
                          class={`${pathname == "/drivers2" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <GrUserPolice />{" "}
                          </div>
                          <span className="ms-2">Drivers</span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {pathname.includes("/profile") ? (
                    <>
                      <li>
                        <Link
                          to="/profile"
                          class={`${pathname == "/profile" ? "sidebar_active" : ""
                            }`}
                        >
                          <div className="icon-border">
                            {" "}
                            <RiUser6Line />{" "}
                          </div>
                          <span className="ms-2">Profile</span>
                        </Link>
                      </li>
                      <li>
                        <a className="color-red">
                          <div onClick={Logout} className="d-flex align-items-center ">
                            <div className="icon-border">
                              <LuLogOut className="" />
                            </div>

                            <span className="ms-2">Log Out</span>
                          </div>
                        </a>

                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className={`mai_data_full ${isopen ? "dashboard_full" : ""} ${!(
            pathname == "/loads" ||
          ((pathname.includes("/loads"))&&(!pathname.includes("/loads/view"))&&(!pathname.includes("/loads/add")))||
            pathname == "/rfps-loads" ||
            pathname == "/usps-loads" ||
            pathname == "/pendingPickup-loads" ||
            pathname == "/pickedUp-loads" ||
            pathname == "/intransit-loads" ||
            pathname == "/delivered-loads" ||
            pathname == "/performance-carriers" ||
            pathname == "/intransit-bids" ||
            pathname == "/delivered-bids" ||
            pathname == "/awarded-bids" ||
            pathname == "/available-loads" ||
            pathname == "/awarded-bids" ||
            pathname == "/accepted-bids" ||
            pathname == "/rejected-bids" ||
            pathname == "/performance-carriers" ||
            pathname == "/pending-bids" ||
            pathname == "/bids" ||
            pathname == "/content" ||
            pathname == "/faq" ||
            pathname == "/boards" ||
            pathname == "/queries" ||
            pathname == "/newsletter" ||
            pathname == "/notes" ||
            pathname == "/shipment-notes" ||
            pathname == "/rejectedCarriers" ||
            pathname == "/acceptedCarriers" ||
            pathname == "/performance-drivers" ||
            pathname == "/staff" ||
            pathname == "/staff" ||
            pathname == "/carrier-users" ||
            pathname == "/invited-users" ||
            pathname == "/carriers" ||
            pathname == "/carrier-requests" ||
            pathname.includes("/jobs") ||
            pathname == "/settings" ||
            pathname == "/profile"
          )
            ? "dashboard_full"
            : ""
            }`}
        >
          <div className="header_sidebars">
            <div className="d-flex justify-content-between align-items-center h-60  width-calc dashboard_full">
              <div className="name_s name-margin icons-sidebar">
                <div
                  className={`${isopen ? "toggle-show" : ""
                    } toggle-icon-n me-2`}
                  onClick={toggle}
                >
                  <RiMenuFill />
                </div>
                <p className="text-capitalize">{title}</p>
              </div>
              {/* search */}
              {searchShow == "true" ? (
                <form className="ml-3 header-search" onSubmit={searchHandle}>
                  <input
                    type="text"
                    placeholder={pathname.includes("/loads") ||
                      pathname == "/prebook-loads" ||
                      pathname == "/rfps-loads" ||
                      pathname == "/usps-loads" ||
                      pathname == "/pendingPickup-loads" ||
                      pathname == "/pickedUp-loads" ||
                      pathname == "/intransit-loads" ||
                      pathname == "/delivered-loads" ? "Search By Load Id, City, state" : pathname == "/intransit-bids" ||
                        pathname == "/bids" ||
                        pathname == "/pending-bids" ||
                        pathname == "/delivered-bids" ||
                        pathname == "/awarded-bids" ||
                        pathname == "/available-loads" ||
                        pathname == "/awarded-bids" ||
                        pathname == "/accepted-bids" ||
                        pathname == "/rejected-bids" ? "Search By Load Id, City" : "Search..."
                    }
                    value={search}
                    onChange={(e) => searchChange(e.target.value)}
                    className="Searchbar form-control"
                  ></input>

                  {showCross ? (
                    <i
                      className="fa fa-times"
                      onClick={clear}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-search"
                      onClick={searchHandle}
                      aria-hidden="true"
                    ></i>
                  )}
                </form>
              ) : (
                ""
              )}

              {/*  */}
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
