//....import { firebaseApp } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { firebaseApp } from "./firebase";

export const requestForToken = async () => {
  let messaging = getMessaging(firebaseApp);

  return await getToken(messaging, {
    vapidKey:
      "BDL0b9G0GxMaBMpOWmY5_zgK2XE1fL0IUzl6fhbQFseuzgxehV5J0YQ-IV5HYYIwKRZqcKbhrXJHt6Pv1C7l4qw",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const message = (history) => {
  let messaging = getMessaging(firebaseApp);

  return onMessage(messaging, (payload) => {
    console.log({ payloadNoti: payload });
  });
};
