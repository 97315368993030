import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { roleType } from "../../models/type.model";
import { Link, useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import environment from "../../environment";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../components/common/ImageUpload";
import addressModel from "../../models/address.model";
import { IoIosCamera } from "react-icons/io";
import Layout2 from "../../components/global/layout2";

const AddEditStaff2 = () => {
  const { id, pageName } = useParams();
  const [PermissionId, setPermissionId] = useState("");
  const [form, setform] = useState(roleType);

  const history = useHistory();
  const [permission, setPermission] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const [images, setImages] = useState({ image: "" });
  const [role, setRole] = useState([]);
  const [emailErr, setEmailErr] = useState("");
  const [editCheck, setEditTrue] = useState(true);
  const [AllRead, setisAllRead] = useState(false);
  const [AllEdit, setAllEdit] = useState(false);
  const [AllDelete, setAllDelete] = useState(false);
  const [AllAdd, setAllaDD] = useState(false);

  const [emailLoader, setEmailLoader] = useState(false);
  const [documents, setdocument] = useState({
    documentType: "Registration",
    documentNumber: "",
    issueDate: "",
    expirationDate: "",
    documents: "",
  });
  const [truck_info, setinfo] = useState({
    truck_id: null,
    brand: "",
    model: "",
    year: null,
  });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("role");

  const defaultvalue = () => {
    let keys = { ...roleType };
    Object.keys(roleType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    Object.keys(roleType.permissions).map((itm) => {
      keys.permissions[itm] = false;
    });
    keys.status = "active";
    return keys;
  };

  const emailvalidation = () => {
    if (form?.email) {
      let splitEmail = form?.email?.split("@")[1]
      if (splitEmail && (splitEmail.includes("yahoo.com") || splitEmail.includes("gmail.com") || splitEmail.includes("outlook.com") || splitEmail.includes("hotmail.com"))) {
        return false
      }
      else {
        return true
      }
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!id) {
      if (
        !emailvalidation() ||
        !form?.address ||
        !form?.role ||
        !form?.firstName ||
        // !form?.dialCode ||
        // !form.mobileNo ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        !form?.country
      ) {
        return;
      }
    } else {
      if (
        (role != "staff" && !form?.address) ||
        !form?.role ||
        // !form?.dialCode ||
        // !form.mobileNo ||
        !form?.pincode ||
        !form?.city ||
        !form?.state ||
        !form?.country
      ) {
        return;
      }
    }

    let method = "post";
    let url = "admin/add-user";
    let value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      address: form?.address,
      mobileNo: form?.mobileNo,
      dialCode: form?.dialCode,
      image: form?.image,
      email: form?.email,
      role: form?.role,
      country: form?.country,
      city: form?.city,
      state: form?.state,
      pincode: form?.pincode,
    };

    setform({
      ...form,
      permissions: {
        ...form?.permissions,
        // carrier_complete_access: handlecompleteAccess(),
      },
    });

    value = {
      firstName: form?.firstName,
      lastName: form?.lastName,
      address: form?.address,
      mobileNo: form?.mobileNo,
      dialCode: form?.dialCode,
      image: form?.image,
      email: form?.email,
      country: form?.country,
      city: form?.city,
      state: form?.state,
      role: form?.role,
      pincode: form?.pincode,

      permissions: form?.permissions,
    };
    //   value = {
    //     ...value,
    //     permissions: {
    //       ...value?.permissions,
    //       carrier_complete_access: handlecompleteAccess(),
    //     },
    //   };

    if (id) {
      method = "put";
      url = "admin/edit-user";
      if (form?.role == "staff") {
        value = {
          firstName: form?.firstName,
          lastName: form?.lastName,
          address: form?.address,
          mobileNo: form?.mobileNo,
          dialCode: form?.dialCode,
          image: form?.image,
          permissions: { ...form?.permissions, id: permission?.id },
          id: form?.id,
          country: form?.country,
          city: form?.city,
          state: form?.state,
          pincode: form?.pincode,
        };
      } else {
        value = {
          firstName: form?.firstName,
          lastName: form?.lastName,
          address: form?.address,
          mobileNo: form?.mobileNo,
          dialCode: form?.dialCode,
          image: form?.image,
          id: form?.id,
          country: form?.country,
          city: form?.city,
          state: form?.state,
          pincode: form?.pincode,
        };
      }
      //   value = {
      //     ...value,
      //     permissions: {
      //       ...value?.permissions,
      //       carrier_complete_access: handlecompleteAccess(),
      //     },
      //   };
    } else {
      delete value.id;
    }
    delete value?.confirmPassword;
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        history.goBack();
      }
      loader(false);
    });
  };

  const setpermission = (key, value) => {
    const start  = key.lastIndexOf('_');  
    var newKey = key.substring(0, start);
    let newValue=true
    let Readcheckbox=""
    if(key==newKey+"_add" || key==newKey+"_edit" || key==newKey+"_delete")
    {
      Readcheckbox=newKey+"_get"
    }
   
    if (id)
   {
      if(Readcheckbox)
      {
        setform({
          ...form,
          permissions: {
            ...form.permissions,
            id: PermissionId,
            [Readcheckbox]:newValue,
            [key]: value,
          },
        });
      }
      else
      {
        setform({
          ...form,
          permissions: {
            ...form?.permissions,
            id: PermissionId,        
            [key]: value,
          },
        });
      }
    
    } else {
      if(Readcheckbox)
      {
        setform({
          ...form,
          permissions: {
            ...form.permissions,
            [Readcheckbox]:newValue,
            [key]: value,
          },
        });
        setPermission({
          ...permission,
          [Readcheckbox]:newValue,
          [key]: value,
        });
      }
      else
      {
        setform({
          ...form,
          permissions: {
            ...form.permissions,
            [key]: value,
          },
        });
        setPermission({
          ...permission,
          [key]: value,
        });
      }
     
    }
  };
 
  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  const getPermission = () => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        setPermission({
          id: res?.data?.id,
          carrier_add: res?.data?.carrier_add,
          // carrier_complete_access: res?.data?.carrier_complete_access,
          carrier_delete: res?.data?.carrier_delete,
          carrier_edit: res?.data?.carrier_edit,
          carrier_get: res?.data?.carrier_get,
          faq_add: res?.data?.faq_add,
          faq_delete: res?.data?.faq_delete,
          faq_edit: res?.data?.faq_edit,
          faq_get: res?.data?.faq_get,

          load_add: res?.data?.load_add,
          load_delete: res?.data?.load_delete,
          load_edit: res?.data?.load_edit,
          load_get: res?.data?.load_get,

          bid_add: res?.data?.bid_add,
          bid_delete: res?.data?.bid_delete,
          bid_edit: res?.data?.bid_edit,
          bid_get: res?.data?.bid_get,

          board_add: res?.data?.board_add,
          board_delete: res?.data?.board_delete,
          board_edit: res?.data?.board_edit,
          board_get: res?.data?.board_get,

          settings_get:res?.data?.settings_get,
          settings_edit:res?.data?.settings_edit,

          // content_management_add:res?.data?.content_management_add,
          content_management_edit:res?.data?.content_management_edit,
          content_management_get:res?.data?.content_management_get,
          // content_management_delete:res?.data?.content_management_delete,

          invite_user_get: res?.data?.invite_user_get,
          dashboard_get:res?.data?.dashboard_get,
          statistics_get:res?.data?.statistics_get,
          performance_matrix_get:res?.data?.performance_matrix_get,
          notes_get: res?.data?.notes_get,
          query_get: res?.data?.query_get,
          carrier_request_get: res?.data?.carrier_request_get,
          news_letter_get: res?.data?.news_letter_get,
          notifications_get:res?.data?.notifications_get,
          shipment_notes_get:res?.data?.shipment_notes_get,
        });
        setPermissionId(res?.data?.id);
        // setform({...form,permissions:res?.data})
      }
    });
  };

  useEffect(() => {
    if (id) {
      setform({ ...form, permissions: permission });
    }
  }, [permission]);

  useEffect(() => {
    if (id) {
      setform({});
      loader(true);
      ApiClient.get("user/detail", { id }).then((res) => {
        if (res.success) {
          let value = res?.data;

          setform({
            ...form,
            id: res?.data?.id,
            firstName: res?.data.firstName,
            lastName: res?.data?.lastName,
            mobileNo: res?.data?.mobileNo,
            dialCode: res?.data?.dialCode,
            address: res?.data?.address,
            email: res?.data?.email,
            image: res?.data?.image,
            role: res?.data?.role,
            gender: res?.data?.gender,
            state: res?.data?.state,
            pincode: res?.data?.pincode,
            country: res?.data?.country,
            city: res?.data?.city,
          });
          if (res?.data?.addedBy != null) {
            setEditTrue(true);
          }
          if (res?.data?.role == "staff") {
            getPermission();
          }
          let payload = {
            id: "",
            name: "",
            status: "active",
            permissions: {
              //  Done
              carrier_delete: false,
              carrier_get: false,
              carrier_complete_access: false,
              carrier_edit: false,
              carrier_add: false,
              faq_add: false,
              faq_delete: false,
              faq_edit: false,
              faq_get: false,

              load_add: false,
              load_delete: false,
              load_edit: false,
              load_get: false,

              bid_add: false,
              bid_delete: false,
              bid_edit: false,
              bid_get: false,

              board_add: false,
              board_delete: false,
              board_edit: false,
              board_get: false,

              settings_get:false,
              settings_edit:false,

              // content_management_add:false,
              content_management_edit:false,
              content_management_get:false,
              // content_management_delete:false,

              invite_user_get: false,
              dashboard_get:false,
              statistics_get:false,
              performance_matrix_get:false,
              notes_get: false,
              query_get: false,
              carrier_request_get: false,
              news_letter_get: false,
              notifications_get:false,
              shipment_notes_get:false,
            },
          };

          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });
          payload.loginPortal = value.loginPortal;
          if (value.permissions) {
            payload.permissions = value.permissions;
            // payload.permissions={ ...payload.permissions,...value.permissions}
          }


          // setform({
          //   ...payload,
          // });
        }
        loader(false);
      });
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  const handleAdminUser = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        carrier_edit: value,
        carrier_get: value,
        carrier_delete: value,
        carrier_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  const handleAdminLoads = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        load_add: value,
        load_edit: value,
        load_get: value,
        load_delete: value,
        load_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  const handleAdminBids = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        bid_add: value,
        bid_edit: value,
        bid_get: value,
        bid_delete: value,
        bid_complete_access: value,
      },
    });

    // setpermission('carrier_complete_access',value)
  };

  const handleAdminBoards = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        board_add: value,
        board_edit: value,
        board_get: value,
        board_delete: value,
        board_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };
  const handleContentManagement = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        // content_management_add: value,
        content_management_edit: value,
        content_management_get: value,
        // content_management_delete: value,
        content_management_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };
  
  const handleAdminFAQ = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        faq_add: value,
        faq_edit: value,
        faq_get: value,
        faq_delete: value,
        faq_complete_access: value,
      },
    });
    // setpermission('carrier_complete_access',value)
  };

  useEffect(() => { }, []);
  const HandleAll = (check) => {
    let value = check ? true : false;
    let permissions = roleType.permissions;
    Object.keys(permissions).map((itm) => {
      permissions[itm] = value;
    });
    setform({ ...form, permissions: permissions });
  };

  const isAllChecked = () => {
    let value = true;
    let permissions = {
      carrier_add: form.permissions?.carrier_add,
      carrier_delete: form?.permissions?.carrier_delete,
      carrier_edit: form?.permissions?.carrier_edit,
      carrier_get: form?.permissions?.carrier_get,

      faq_add: form.permissions?.faq_add,
      faq_delete: form?.permissions?.faq_delete,
      faq_edit: form?.permissions?.faq_edit,
      faq_get: form?.permissions?.faq_get,

      load_add: form.permissions?.load_add,
      load_delete: form?.permissions?.load_delete,
      load_edit: form?.permissions?.load_edit,
      load_get: form?.permissions?.load_get,

      bid_add: form.permissions?.bid_add,
      bid_delete: form?.permissions?.bid_delete,
      bid_edit: form?.permissions?.bid_edit,
      bid_get: form?.permissions?.bid_get,

      board_add: form.permissions?.board_add,
      board_delete: form?.permissions?.board_delete,
      board_edit: form?.permissions?.board_edit,
      board_get: form?.permissions?.board_get,

      settings_get:form?.permissions?.settings_get,
      settings_edit:form?.permissions?.settings_edit,

      // content_management_add:form?.permissions?.content_management_add,
      content_management_edit:form?.permissions?.content_management_edit,
      content_management_get:form?.permissions?.content_management_get,
      // content_management_delete:form?.permissions?.content_management_delete,

      invite_user_get: form?.permissions?.invite_user_get,
      dashboard_get:form?.permissions?.dashboard_get,
      statistics_get:form?.permissions?.statistics_get,
      performance_matrix_get:form?.permissions?.performance_matrix_get,
      notes_get: form?.permissions?.notes_get,
      query_get: form?.permissions?.query_get,
      carrier_request_get: form?.permissions?.carrier_request_get,
      news_letter_get: form?.permissions?.news_letter_get,
      notifications_get:form?.permissions?.notifications_get,
      shipment_notes_get:form?.permissions?.shipment_notes_get,
    };
    Object.keys(permissions).map((itm) => {
      if (!permissions[itm]) value = false;
    });
    return value;
  };

  const handlecompleteAccess = () => {
    let result = false;
    if (
      form?.permissions?.carrier_add &&
      form?.permissions?.carrier_delete &&
      form?.permissions?.carrier_edit &&
      form?.permissions?.carrier_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessLoads = () => {
    let result = false;
    if (
      form?.permissions?.load_add &&
      form?.permissions?.load_delete &&
      form?.permissions?.load_edit &&
      form?.permissions?.load_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessBids = () => {
    let result = false;
    if (
      form?.permissions?.bid_add &&
      form?.permissions?.bid_delete &&
      form?.permissions?.bid_edit &&
      form?.permissions?.bid_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const handlecompleteAccessBoards = () => {
    let result = false;
    if (
      form?.permissions?.board_add &&
      form?.permissions?.board_delete &&
      form?.permissions?.board_edit &&
      form?.permissions?.board_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };
  const handlecompleteAccessContentManagement = () => {
    let result = false;
    if (
      // form?.permissions?.content_management_add &&
      // form?.permissions?.content_management_delete &&
      form?.permissions?.content_management_edit &&
      form?.permissions?.content_management_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };
  
  const handlecompleteAccessFAQ = () => {
    let result = false;
    if (
      form?.permissions?.faq_add &&
      form?.permissions?.faq_delete &&
      form?.permissions?.faq_edit &&
      form?.permissions?.faq_get
    ) {
      result = true;
    }
    // setform({...form,permissions:{...form?.permissions,carrier_complete_access:result}})
    return result;
  };

  const arry = [
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
    1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
    2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
  ];
  const HandleAllRead = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_get: value,
        invite_user_get: value,
        dashboard_get:value,
        statistics_get:value,
        performance_matrix_get:value,
        notes_get: value,
        query_get: value,
        board_get: value,
        content_management_get:value,
        bid_get: value,
        load_get: value,
        faq_get: value,
        news_letter_get: value,
        notifications_get:value,
        settings_get:value,
        shipment_notes_get:value,
        carrier_request_get: value,
      },
    });
    setisAllRead(value);
    isAllChecked();
  };
  const HandleAllAdd = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_add: value,
        faq_add: value,
        load_add: value,
        bid_add: value,
        board_add: value,
        carrier_get:value,
        faq_get:value,
        load_get:value,
        bid_get:value,
        board_get:value,
        // content_management_add:value,
      },
    });
    setAllaDD(value);
    isAllChecked();
  };
  const HandleallEdit = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_edit: value,
        faq_edit: value,
        load_edit: value,
        bid_edit: value,
        board_edit: value,
        settings_edit:value,
        content_management_edit:value,
        carrier_get:value,
        faq_get:value,
        load_get:value,
        bid_get:value,
        board_get:value,
        settings_get:value,
        content_management_get:value,
      },
    });
    setAllEdit(value);
    isAllChecked();
  };
  const HandleAllDelete = (check) => {
    let value = check ? true : false;
    setform({
      ...form,
      permissions: {
        ...form.permissions,
        carrier_delete: value,
        board_delete: value,
        bid_delete: value,
        load_delete: value,
        faq_delete: value,
        carrier_get:value,
        board_get:value,
        bid_get:value,
        load_get:value,
        faq_get:value,
      },
    });
    setAllDelete(value);
    isAllChecked();
  };

  useEffect(() => {
    setform({ ...form, permissions: roleType.permissions });
  }, [id]);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role.id == environment.adminRoleId) value = true;
    return value;
  };

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }

    setform({
      ...form,
      address: e.value,
      country: address.country || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });

  };

  useEffect(() => {
    if (id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (!id && param1 == "carrier") {
      setRole([
        {
          id: "carrier",
          name: "Carrier",
        },
      ]);
      setform({ ...form, role: "carrier" });
    } else if (id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    } else if (!id && param1 == "staff") {
      setRole([
        {
          id: "staff",
          name: "Staff",
        },
      ]);
      setform({ ...form, role: "staff" });
    }
  }, [param1]);

  const uploadImage = (e) => {
    setform({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.success) {
          let image = res?.data?.fullpath;
          setform({ ...form, image: image, baseImg: "" });
        } else {
          setform({ ...form, baseImg: "" });
        }
        loader(false);
      }
    );
  };

  const imageResult = (e, key) => {

    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: images?.image });

  };

  useEffect(() => {

  }, [form]);
  return (
    <>
      <Layout2 title={pageName} title2="Staff">
        <div className="mainareas">
          <form onSubmit={handleSubmit}>
            <div className=" edit-page-common new-edit edit-page-commons">
              <div className="row">
                <div className="col-lg-12">
                  <div class=" title-head">
                    <h3
                      className="ViewUseraa mb-3 user-back"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left me-2 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                      {id ? "Edit" : "Add"} {pageName}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class={`${submitted ? "height-set" : ""} col-xl-3 height-set`}>
                  <div class=" white-head mb-3">
                    <h5 class="profilelist">Profile</h5>
                  </div>
                  <div class=" white-bg-main mb-4 profile-sect-n">
                    <div className="imagesRow  mb-3">
                      <div className="imagethumbWrapper">
                        <img
                          src={
                            form?.image
                              ? `${environment.api}images/users/${form?.image}`
                              : "/assets/img/person.jpg"
                          }
                          className="thumbnail"
                        />
                        <div className="upload-img-icon">
                          {form?.image ? (
                            <>
                              <label
                                className={`btn  `}
                                onClick={() => {
                                  setform({ ...form, image: "" });
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </label>{" "}
                            </>
                          ) : (
                            <label className={`btn  `}>
                              <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                              <IoIosCamera />
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className='disable-input'>
                          <label>
                            Carrier Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={methodModel.capitalizeFirstLetter(
                              user?.fullName
                            )}
                            disabled
                        
                          />
                        </div>

                      
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className={`${submitted ? "height-set" : ""} col-xl-9 height-set`}>
                  <div className="row">
                    <div className="col-lg-6 height-set">
                      <div class=" white-head mb-3">
                        <h5 class="profilelist">Basic Information</h5>
                      </div>
                      <div class=" white-bg-main mb-4">
                        <div className="row">
                          <div className="col-lg-6 mb-3 ">
                            <label>
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.firstName}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    firstName: e.target.value,
                                  })
                                }
                              // required
                              />
                            </div>
                            {submitted && !form.firstName ? (
                              <div className="invalid-feedback d-block">
                                First Name is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label>Last Name</label>

                            <div className="input-new-design">
                              <div className="input-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.lastName}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    lastName: e.target.value,
                                  })
                                }
                              // required
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3 ">
                            <label className="">Mobile No</label>
                            <div className="phoneInput_cls d-flex  p-0">
                              {/* <PhoneInput
                                    country={'us'}
                                    value={form?.dialCode}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    placeholder=""
                                    onChange={(phone, country) => {
                                      setform({
                                        ...form,
                                        dialCode: country.dialCode,
                                      });
                                    }}
                                  />

                                  <input
                                    type="text"
                                    className="form-control phph"
                                    placeholder="Mobile No."
                                    value={(form && form.mobileNo) || ''}
                                    maxLength={12}
                                    onChange={(e) =>
                                      setform({
                                        ...form,
                                        mobileNo: methodModel.isNumber(e),
                                      })
                                    }
                                  /> */}

                              <div className="w-100  d-inline-flex staff-react-phone">
                                <PhoneInput
                                  value={form.dialCode + "" + form.mobileNo}
                                  countryCodeEditable={true}
                                  enableSearch={true}
                                  placeholder=""
                                  country="us"
                                  onChange={(phone, country) => {
                                    let phonenumber = phone.replace(
                                      country.dialCode,
                                      ""
                                    );
                                    // if(phonenumber!=form.mobileNo){
                                    // setform({ ...form, mobileNo: phonenumber })
                                    // }
                                    setform({
                                      ...form,
                                      dialCode: country.dialCode,
                                      mobileNo: phonenumber,
                                    });
                                  }}
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 ">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-envelope"></i>
                              </div>
                              <input
                                type="email"
                                className="form-control"
                                value={form.email}
                                disabled={id}
                                onChange={(e) => {
                                  setform({
                                    ...form,
                                    email: e.target.value,
                                  });
                                  // emailCheck(e.target.value);
                                }}
                              // required
                              />
                            </div>

                            {submitted ? (
                              <>{!form.email ? <div className="invalid-feedback d-block">
                                Email is Required
                              </div> : !emailvalidation() ? <div className="invalid-feedback d-block">
                                This email domain not allowed. Please use a different email address.
                              </div> : <></>}</>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 height-set">
                      <div class=" white-head mb-3">
                        <h5 class="profilelist">Address</h5>
                      </div>
                      <div class=" white-bg-main mb-4">
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label>
                              Address<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-address-book"></i>
                              </div>
                              <GooglePlaceAutoComplete
                                value={form.address}
                                result={addressResult}
                                id="address"
                                placeholder=""
                              />
                            </div>

                            {submitted && !form.address ? (
                              <div className="invalid-feedback d-block">
                                Address is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label>
                              City<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-building"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.city}
                                onChange={(e) =>
                                  setform({ ...form, city: e.target.value })
                                }
                              // required
                              />
                            </div>

                            {submitted && !form.city ? (
                              <div className="invalid-feedback d-block">
                                City is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label>
                              State<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-building"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.state}
                                onChange={(e) =>
                                  setform({ ...form, state: e.target.value })
                                }
                              // required
                              />
                            </div>

                            {submitted && !form.state ? (
                              <div className="invalid-feedback d-block">
                                State is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-md-6">
                            <label>
                              Country<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-globe-asia"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={form.country}
                                onChange={(e) =>
                                  setform({ ...form, country: e.target.value })
                                }
                              // required
                              />
                            </div>

                            {submitted && !form.country ? (
                              <div className="invalid-feedback d-block">
                                Country is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-md-6 ">
                            <label>
                              Zipcode<span className="text-danger">*</span>
                            </label>
                            <div className="input-new-design">
                              <div className="input-icon">
                                <i class="fas fa-map-pin"></i>
                              </div>
                              <input
                                type="numeric"
                                pattern="^[a-zA-Z0-9]+$"
                                onKeyPress={(e) => {
                                  var regex = new RegExp("^[a-zA-Z0-9]+$");
                                  var key = String.fromCharCode(
                                    !e.charCode ? e.which : e.charCode
                                  );
                                  if (!regex.test(key)) {
                                    e.preventDefault();
                                    return false;
                                  }
                                }}
                                className="form-control"
                                value={form.pincode}
                                onChange={(e) =>
                                  setform({ ...form, pincode: e.target.value })
                                }
                              // required
                              />
                            </div>

                            {submitted && !form.pincode ? (
                              <div className="invalid-feedback d-block">
                                Zipcode is Required
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {form?.role == "staff" && (
                  <div className="col-md-12 ">
                    <div className="">
                      <div className=" white-head mb-3">
                        <h5 className="profilelist">Permissions</h5>
                      </div>
                      <div class="table-responsive ">
                        <div class="table_section tablepadding table-bordered mt-0">
                          <table class="table mb-0">
                            <thead class="table_head roleTable">
                              <tr class="heading_row">
                                <th scope="col" class="table_data"></th>
                                <th scope="col" class="table_data">
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      HandleAll(e.target.checked)
                                    }
                                    checked={isAllChecked()}
                                  />
                                  All
                                </th>
                                <th scope="col" class="table_data">
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      HandleAllRead(e.target.checked)
                                    }
                                    checked={AllRead || isAllChecked()}
                                  />
                                  Read
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      HandleAllAdd(e.target.checked)
                                    }
                                    checked={AllAdd || isAllChecked()}
                                  />
                                  Add
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      HandleallEdit(e.target.checked)
                                    }
                                    checked={AllEdit || isAllChecked()}
                                  />
                                  Edit
                                </th>
                                <th scope="col" class="table_data">
                                  {" "}
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      HandleAllDelete(e.target.checked)
                                    }
                                    checked={AllDelete || isAllChecked()}
                                  />
                                  Delete
                                </th>

                                {/* <th scope="col" class="table_data"> <input
                                  type="checkbox" onChange={e=>HandleRefreshAll(e.target.checked)} checked={form.permissions.refreshBooking}/>Refresh</th> */}
                              </tr>
                            </thead>
                            <tbody className="roleTable">


                              <tr>
                                <td>
                                  <b>Dashboard</b>
                                </td>
                                <td>
                              
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.dashboard_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "dashboard_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                     
                                </td>
                                <td>
                     
                                </td>
                                <td>
                        
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Statistics</b>
                                </td>
                                <td>
                                
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.statistics_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "statistics_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                     
                                </td>
                                <td>
                     
                                </td>
                                <td>
                        
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Performance Matrix</b>
                                </td>
                                <td>
                                
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.performance_matrix_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "performance_matrix_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                     
                                </td>
                                <td>
                     
                                </td>
                                <td>
                        
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Carrier</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleAdminUser(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccess()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.carrier_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "carrier_get",
                                        e.target.checked
                                      )
                                    }
                                    disabled={form?.permissions?.carrier_add || form?.permissions?.carrier_edit || form?.permissions?.carrier_delete}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.carrier_add}
                                    onChange={(e) =>
                                      setpermission(
                                        "carrier_add",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.carrier_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "carrier_edit",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.carrier_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "carrier_delete",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>
                                {/* <td>
                                <input
                                type="checkbox"
                                checked={
                                form?.permissions?.carrier_request_get
                                }
                                onChange={(e) =>
                                setpermission(
                                "carrier_request_get",
                                e.target.checked
                                )
                                }
                                />{" "}
                                </td> */}
                              </tr>
                              <tr>
                                <td>
                                  <b>Loads</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleAdminLoads(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccessLoads()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.load_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "load_get",
                                        e.target.checked
                                      )
                                    }
                                    disabled={form?.permissions?.load_add || form?.permissions?.load_edit || form?.permissions?.load_delete}
                                  />{" "}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.load_add}
                                    onChange={(e) =>
                                      setpermission(
                                        "load_add",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.load_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "load_edit",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.load_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "load_delete",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Bids</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleAdminBids(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccessBids()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.bid_get}
                                    onChange={(e) =>
                                      setpermission("bid_get", e.target.checked)
                                    }
                                    disabled={form?.permissions?.bid_add || form?.permissions?.bid_edit || form?.permissions?.bid_delete}
                                  />{" "}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.bid_add}
                                    onChange={(e) =>
                                      setpermission("bid_add", e.target.checked)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.bid_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "bid_edit",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.bid_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "bid_delete",
                                        e.target.checked
                                      )
                                    }
                                  />{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Invited Users</b>
                                </td>
                                <td>
                                  {/* <input
                                  type="checkbox"
                                  checked={
                                  form?.permissions?.invite_user_get
                                  }
                                  onChange={(e) =>
                                  setpermission(
                                  "invite_user_get",
                                  e.target.checked
                                  )
                                  }
                                  /> */}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.invite_user_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                        form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                        setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                        )
                                        }
                                        /> */}
                                </td>
                                <td>
                                  {/* <input
                                            type="checkbox"
                                            checked={
                                            form?.permissions?.invite_user_get
                                            }
                                            onChange={(e) =>
                                            setpermission(
                                            "invite_user_get",
                                            e.target.checked
                                            )
                                            }
                                            /> */}
                                </td>
                                <td>
                                  {/* <input
                                  type="checkbox"
                                  checked={
                                  form?.permissions?.invite_user_get
                                  }
                                  onChange={(e) =>
                                  setpermission(
                                  "invite_user_get",
                                  e.target.checked
                                  )
                                  }
                                  /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Carrier Request</b>
                                </td>
                                <td>
                                  {/* <input
                                    type="checkbox"
                                    checked={
                                    form?.permissions?.invite_user_get
                                    }
                                    onChange={(e) =>
                                    setpermission(
                                    "invite_user_get",
                                    e.target.checked
                                    )
                                    }
                                    /> */}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={
                                      form?.permissions?.carrier_request_get
                                    }
                                    onChange={(e) =>
                                      setpermission(
                                        "carrier_request_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                        form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                        setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                        )
                                        }
                                        /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                        form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                        setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                        )
                                        }
                                        /> */}
                                </td>
                                <td>
                                  {/* <input
                                  type="checkbox"
                                  checked={
                                  form?.permissions?.invite_user_get
                                  }
                                  onChange={(e) =>
                                  setpermission(
                                  "invite_user_get",
                                  e.target.checked
                                  )
                                  }
                                  /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Query</b>
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                        form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                        setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                        )
                                        }
                                        /> */}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.query_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "query_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <input
                              type="checkbox"
                              checked={
                              form?.permissions?.invite_user_get
                              }
                              onChange={(e) =>
                              setpermission(
                              "invite_user_get",
                              e.target.checked
                              )
                              }
                              /> */}
                                </td>
                                <td>
                                  {/* <input
                                        type="checkbox"
                                        checked={
                                        form?.permissions?.invite_user_get
                                        }
                                        onChange={(e) =>
                                        setpermission(
                                        "invite_user_get",
                                        e.target.checked
                                        )
                                        }
                                        /> */}
                                </td>
                                <td>
                                  {/* <input
                                    type="checkbox"
                                    checked={
                                    form?.permissions?.invite_user_get
                                    }
                                    onChange={(e) =>
                                    setpermission(
                                    "invite_user_get",
                                    e.target.checked
                                    )
                                    }
                                    /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Notes</b>
                                </td>
                                <td>
                                  {/* <input
                                          type="checkbox"
                                          checked={
                                          form?.permissions?.invite_user_get
                                          }
                                          onChange={(e) =>
                                          setpermission(
                                          "invite_user_get",
                                          e.target.checked
                                          )
                                          }
                                          /> */}
                                </td> 
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.notes_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "notes_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <input
                                  type="checkbox"
                                  checked={
                                  form?.permissions?.invite_user_get
                                  }
                                  onChange={(e) =>
                                  setpermission(
                                  "invite_user_get",
                                  e.target.checked
                                  )
                                  }
                                  /> */}
                                </td>
                                <td>
                                  {/* <input
                                          type="checkbox"
                                          checked={
                                          form?.permissions?.invite_user_get
                                          }
                                          onChange={(e) =>
                                          setpermission(
                                          "invite_user_get",
                                          e.target.checked
                                          )
                                          }
                                          /> */}
                                </td>
                                <td>
                                  {/* <input
                                                  type="checkbox"
                                                  checked={
                                                  form?.permissions?.invite_user_get
                                                  }
                                                  onChange={(e) =>
                                                  setpermission(
                                                  "invite_user_get",
                                                  e.target.checked
                                                  )
                                                  }
                                                  /> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>News Letter</b>
                                </td>
                                <td>
                                  {/* <input
                                              type="checkbox"
                                              checked={
                                              form?.permissions?.invite_user_get
                                              }
                                              onChange={(e) =>
                                              setpermission(
                                              "invite_user_get",
                                              e.target.checked
                                              )
                                              }
                                              /> */}
                                   </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.news_letter_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "news_letter_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <input
                                      type="checkbox"
                                      checked={
                                      form?.permissions?.invite_user_get
                                      }
                                      onChange={(e) =>
                                      setpermission(
                                      "invite_user_get",
                                      e.target.checked
                                      )
                                      }
                                      /> */}
                                </td>
                                <td>
                                  {/* <input
                                type="checkbox"
                                checked={
                                form?.permissions?.invite_user_get
                                }
                                onChange={(e) =>
                                setpermission(
                                "invite_user_get",
                                e.target.checked
                                )
                                }
                                /> */}
                                </td>
                                <td>
                                  {/* <input
                                      type="checkbox"
                                      checked={
                                      form?.permissions?.invite_user_get
                                      }
                                      onChange={(e) =>
                                      setpermission(
                                      "invite_user_get",
                                      e.target.checked
                                      )
                                      }
                                      /> */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Notification</b>
                                </td>
                                <td>

                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.notifications_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "notifications_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Shipment Notes</b>
                                </td>
                                <td>

                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.shipment_notes_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "shipment_notes_get",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Settings</b>
                                </td>
                                <td>

                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.settings_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "settings_get",
                                        e.target.checked
                                      )
                                    }
                                    disabled={form?.permissions?.settings_edit}
                                  />
                                </td>
                                <td>

                                </td>
                                <td>
                                <input
                                    type="checkbox"
                                    checked={form?.permissions?.settings_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "settings_edit",
                                        e.target.checked
                                      )
                                    }
                                  /> 
                                </td>
                                <td>

                                </td>
                              </tr>


                              <tr>
                                <td>
                                  <b>Boards</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleAdminBoards(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccessBoards()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.board_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "board_get",
                                        e.target.checked
                                      )
                                    }
                                    disabled={form?.permissions?.board_add || form?.permissions?.board_edit || form?.permissions?.board_delete}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.board_add}
                                    onChange={(e) =>
                                      setpermission(
                                        "board_add",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.board_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "board_edit",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.board_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "board_delete",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Content Management</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleContentManagement(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccessContentManagement()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.content_management_get}
                                    onChange={(e) =>
                                      setpermission(
                                        "content_management_get",
                                        e.target.checked
                                      )
                                    }
                                    disabled={ form?.permissions?.content_management_edit}
                                  />
                                </td>
                                <td>
                                  {/* <input
                                    type="checkbox"
                                    checked={form?.permissions?.content_management_add}
                                    onChange={(e) =>
                                      setpermission(
                                        "content_management_add",
                                        e.target.checked
                                      )
                                    }
                                  /> */}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.content_management_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "content_management_edit",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>

                                <td>
                                  {/* <input
                                    type="checkbox"
                                    checked={form?.permissions?.content_management_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "content_management_delete",
                                        e.target.checked
                                      )
                                    }
                                  /> */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>FAQ</b>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleAdminFAQ(e.target.checked);
                                      // if(e.target?.checked){
                                      // setform({...form,permissions:{...form?.permissions,carrier_complete_access:true}})
                                      // }else
                                    }}
                                    checked={handlecompleteAccessFAQ()}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.faq_get}
                                    onChange={(e) =>
                                      setpermission("faq_get", e.target.checked)
                                    }
                                    disabled={form?.permissions?.faq_add||form?.permissions?.faq_edit||form?.permissions?.faq_delete}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.faq_add}
                                    onChange={(e) =>
                                      setpermission("faq_add", e.target.checked)
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.faq_edit}
                                    onChange={(e) =>
                                      setpermission(
                                        "faq_edit",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={form?.permissions?.faq_delete}
                                    onChange={(e) =>
                                      setpermission(
                                        "faq_delete",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="text-right mt-3">
                <button
                  type="submit"
                  className="btn btn-primary "
                  onClick={() => {
                    setSubmitted(true);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Layout2>
    </>
  );
};

export default AddEditStaff2;
