import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";

function ViewQuery2() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const GetNote = () => {
    loader(true);
    ApiClient.get("contact-us", { id }).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetNote();
    // if (!Permission?.query_get) {
    //   history.push("/dashboard");
    // }
  }, []);
  const back = () => {
    history.goBack();
  };

  return (
    <>
      <Layout2 title="Query">
        
      <div className="mainareas">
      <div className="pprofile1 edit-page-common p-0 edit-page-commons">
        <div className="row">
          <div className="col-lg-12">
            <div className=" title-head">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="ViewUser mb-3 user-back">
                  {" "}
                  <a onClick={back}>
                    {" "}
                    <i
                      className="fa fa-arrow-left ml-1 "
                      title="Back"
                      aria-hidden="true"
                    ></i>
                  </a>
                  Query Details
                </h3>
              </div>
            </div>
          </div>
        </div>
 
          <div className="row">
            <div className="col-lg-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 className="profilelist">
                  Query information
                  </h5>
                </div>

                <div className="row">
                  
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-3 view-flex">
                        <label className="profileheddingcls">Full Name :</label>
                        <div className="profiledetailscls">
                        {data &&
                              methodModel.capitalizeFirstLetter(
                                data?.firstName
                              )}{" "}
                            {data?.lastName}
                        </div>
                      </div>

                    
                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                          Email:
                          </label>
                          <div className="profiledetailscls">
                          {data && data?.email}
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">
                          Message:
                          </label>
                          <div className="profiledetailscls">
                          {methodModel.capitalizeFirstLetter(data?.message)}
                          </div>
                        </div>
        
                        {data?.user_id?.company_name && (<div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                          Company Name:
                          </label>
                          <div className="profiledetailscls">
                          {data && data?.user_id?.company_name}
                          </div>
                        </div>)}
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
   
          </div>
      </div>
      </div>
      </Layout2>
    </>
  );
}

export default ViewQuery2;
