import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Editor } from "@tinymce/tinymce-react";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import Faq from "./Faqlisting";
import loader from "../../methods/loader";
import Layout2 from "../../components/global/layout2";

const Addeditfaq2 = () => {
  const [addFaq, setAddFaq] = useState({
    question: "",
    answer: "",
  });
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const { id } = useParams();
  const history = useHistory();

  const AddFaqdata = (e) => {
    e.preventDefault();
    let payload = { ...addFaq, id: id };
    if (id) {
      loader(true);
      ApiClient.put("faq", payload).then((res) => {
        history.push("/faq");
        toast.success(res.message);
        loader(false);
      });
    } else {
      delete payload.id;
      ApiClient.post("faq", payload).then((res) => {
        if (res.success) {
          loader(true);
          history.push("/faq");
          toast.success(res.message);
          loader(false);
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`faq?id=${id}`).then((res) => {
        const newdata = res.data;
        setAddFaq({ question: newdata.question, answer: newdata.answer });
        loader(false);
      });
    }
  }, [id]);

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 1000);

    // if (!Permission?.faq_add) {
    //   history.push("/dashboard");
    // }
  }, []);

  return (
    <Layout2 title="FAQ">
      <div className="mainareas">
        <form onSubmit={(e) => AddFaqdata(e)}>
          <div className="edit-page-common new-edit edit-page-commons">
            <div className="row">
              <div className="col-lg-12 ">
                <div className=" title-head">
                  <h3 className="ViewUser mb-0 user-back">
                    <Link to="/faq" className="">
                      <i
                        className="fa fa-arrow-left ml-1 "
                        title="Back"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    {id ? "Edit" : "Add"} FAQ
                  </h3>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">FAQ Details</h5>
                </div>
              </div>
              <div className="col-md-12">
                <div className=" white-bg-main mb-4">
                  <div className="row ">
                    <div className="col-md-12 mb-3">
                      <label>
                        Question<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={addFaq.question}
                        name="question"
                        required
                        onChange={(e) =>
                          setAddFaq({ ...Faq, question: e.target.value })
                        }
                      />
                    </div>
                    {/* <div className="col-md-6 mb-3">
                                        <label>
                                            Status<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                        // value={form.name}
                                        // onChange={(e) =>
                                        //   setform({ ...form, name: e.target.value })
                                        // }
                                        />

                                    </div> */}

                    <div className="col-md-12 mb-3">
                      <label>
                        Answer<span className="text-danger">*</span>
                      </label>
                      <Editor
                        apiKey={
                          "umvdq7vkiacu5ldyurqgqxct553ehzvebsrsccd42pna9x99"
                        }
                        required
                        init={{
                          toolbar:
                            "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
                          height: 400,
                        }}
                        onEditorChange={(evt, editor) =>
                          setAddFaq({ ...addFaq, answer: evt })
                        }
                        value={addFaq?.answer}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right  ">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout2>
  );
};
export default Addeditfaq2;
