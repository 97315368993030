import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastsStore } from "react-toasts";
import { logout } from "../../../actions/user";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import Layout from "../../global/layout";

const ChangePassword = ({ removeQueryParam }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState({
    confirmPassword: "",
    currentPassword: "",
    newPassword: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "currentPassword", minLength: 8 },
    { key: "newPassword", minLength: 8 },
  ];
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let payload = {
      currentPassword: form?.currentPassword,
      newPassword: form?.newPassword,
    };
    loader(true);
    ApiClient.put("change/password", payload).then((res) => {
      if (res.success) {
        localStorage.clear();
        toast.success(res.message);
        // localStorage.removeItem('permission')
        dispatch(logout());
        removeQueryParam("id");
        history.push("/login");
      }
      loader(false);
    });
  };

  return (
    <>
      <form className="" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label class="mb-2">
              Current Password<span class="start">*</span>
            </label>
            <div className="inputWrapper">
              <input
                placeholder=" "
                type={eyes.currentPassword ? "text" : "password"}
                className="form-control"
                value={form.currentPassword}
                maxLength="20"
                onChange={(e) =>
                  setForm({ ...form, currentPassword: e.target.value })
                }
                required
              />
              <i
                className={
                  eyes.currentPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                onClick={() =>
                  setEyes({
                    ...eyes,
                    currentPassword: !eyes.currentPassword,
                  })
                }
              ></i>
            </div>
            {submitted && getError("currentPassword").invalid ? (
              <div className="invalid-feedback d-block">
                Min Length must be 8 characters long
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-12 mb-3">
            <label class="mb-2">
              New Password<span class="start">*</span>
            </label>
            <div className="inputWrapper">
              <input
                placeholder=" "
                type={eyes.password ? "text" : "password"}
                className="form-control"
                value={form.newPassword}
                maxLength="20"
                onChange={(e) =>
                  setForm({ ...form, newPassword: e.target.value })
                }
                required
              />
              <i
                className={eyes.password ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={() => setEyes({ ...eyes, password: !eyes.password })}
              ></i>
            </div>
            {submitted && getError("newPassword").invalid ? (
              <div className="invalid-feedback d-block">
                Min Length must be 8 characters long
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-12 mb-3">
            <label class="mb-2">
              Confirm Password<span class="start">*</span>
            </label>
            <div className="inputWrapper">
              <input
                placeholder=" "
                type={eyes.confirmPassword ? "text" : "password"}
                className="form-control"
                value={form.confirmPassword}
                maxLength="20"
                onChange={(e) =>
                  setForm({ ...form, confirmPassword: e.target.value })
                }
                required
              />
              <i
                className={
                  eyes.confirmPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                onClick={() =>
                  setEyes({
                    ...eyes,
                    confirmPassword: !eyes.confirmPassword,
                  })
                }
              ></i>
            </div>
            {submitted && getError("confirmPassword").invalid ? (
              <>
                {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                {getError("confirmPassword").err.confirmMatch ? (
                  <div className="invalid-feedback d-block">
                    Confirm Password is not matched with New Password
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-12 text-right mt-3">
            {/* <button type="submit" className="btn btn-secondary mr-2 " onClick={()=>{
                    history.push('/dashboard')
                  }}>
                    Back
                  </button> */}
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
