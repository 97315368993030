import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import moment from "moment";
import Layout2 from "../../components/global/layout2";
import datepipeModel from "../../models/datepipemodel";
function ViewStopsNotes2() {
  const { id } = useParams();
  const history = useHistory();
  const [showItem, setShowItem] = useState({});
  const Permission = JSON.parse(localStorage.getItem("permission"));

  const [data, setData] = useState({});
  const toggleShowItem = (index) => {
    setShowItem({ ...showItem, [index]: !showItem[index] });
  };
  const GetNote = () => {
    loader(true);
    ApiClient.get("carrier-note", { id }).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetNote();
    // if (!Permission?.notes_get) {
    //   history.push("/dashboard");
    // }
  }, []);
  const back = () => {
    history.goBack();
  };

  return (
    <>
      <Layout2 title="Shipment Notes">

        <div className="mainareas">
          <div className=" edit-page-common edit-page-commons">
            <div className="row">
              <div className="col-lg-12">
                <div className=" title-head">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h3 className="ViewUser mb-3 user-back">
                      {" "}
                      <a onClick={back}>
                        {" "}
                        <i
                          className="fa fa-arrow-left ml-1 "
                          title="Back"
                          aria-hidden="true"
                        ></i>
                      </a>
                      Shipment Details
                    </h3>

                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className={`${data?.role == "staff" ? "col-lg-6" : "col-lg-12"}`}>
                <div className=" white-bg-main mb-4">
                  <div className=" white-head mb-3">
                    <h5 className="profilelist">
                      Basic information
                    </h5>
                  </div>

                  <div className="row">

                    <div className={`col-md-12 imagesRow  mb-3`}>
                      <div className="row">


                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Dispatcher Name:
                          </label>
                          <div className="profiledetailscls">
                            {(data &&
                              methodModel.capitalizeFirstLetter(
                                data?.carrier_details?.fullName
                              )) ||
                              "--"}
                          </div>
                        </div>



                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls">
                            Title:
                          </label>
                          <div className="profiledetailscls">
                            {data?.title}
                          </div>
                        </div>



                        <div className={`${data?.role == "staff" ? "col-md-12" : "col-md-6"} mb-3 view-flex`}>
                          <label className="profileheddingcls">
                            New ETA:
                          </label>
                          <div className="profiledetailscls">
                          {datepipeModel.isotodate(data?.new_eta)}
                          </div>
                        </div>

                        {data?.load_details?.load_id || data?.load_details?.lane_id
                          ?
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">{data?.load_details?.load_id ? "Load ID:" : "Lane ID:"}</label>
                            <p
                              className=" profiledetailscls mb-0 pointer"
                              style={{ textDecoration: "underline" }}
                              onClick={() => {
                                history.push(`/loads/view/${data?.load_id}`);
                              }}
                            >
                              {data?.load_details?.load_id ? data?.load_details?.load_id : data?.load_details?.lane_id}
                            </p>
                          </div>
                          :
                          <></>
                        }


                        <div className="col-md-6 mb-3 view-flex">
                          <label className="profileheddingcls"> Stop Address:</label>
                          <p className=" profiledetailscls mb-0">
                              {methodModel.capitalizeFirstLetter(
                              data?.stop_details?.address
                              ) || "--"}

                           
                            </p>
                        </div>


                        {data?.load_details?.shipment_status ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">Load Status :</label>
                            <div className="profiledetailscls">
                              {data &&
                                methodModel.capitalizeFirstLetter(
                                  data?.load_details?.shipment_status=="in_transit"?"In Transit":data?.load_details?.shipment_status
                                )}
                            </div>
                          </div>
                        ) : null}


                        {data?.carrier_details?.company_name ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Company Name :
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.company_name}
                            </div>
                          </div>
                        ) : null}

                        {data?.carrier_details?.mc_number ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              Mc Number :
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.mc_number}
                            </div>
                          </div>
                        ) : null}
                        {data?.carrier_details?.dot_number ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">
                              DOT Number :
                            </label>
                            <div className="profiledetailscls">
                              {data && data?.carrier_details?.dot_number}
                            </div>
                          </div>
                        ) : null}
  {data?.load_details?.pickedup_at ? (
                          <div className="col-md-6 mb-3 view-flex">
                            <label className="profileheddingcls">Pickup Date:</label>
                            <div className="profiledetailscls">
                            {/* {datepipeModel.isotodate(data?.load_details?.pickedup_at)} */}
                            {moment(data?.load_details?.pickedup_at).format("DD-MM-YYYY h:mm A")}
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-12 mb-3 view-flex">
                          <label className="profileheddingcls">Description:</label>
                          <div className="profiledetailscls">
                            <p className=" profiledetailscls mb-0">
                              {methodModel.capitalizeFirstLetter(
                                data?.description|| "--")}

                             
                            </p>
                          </div>
                        </div>

                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>



        </div>
      </Layout2>
    </>
  );
}

export default ViewStopsNotes2;
