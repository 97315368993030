import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";

const ViewSettings = () => {
  const [form, setform] = useState({ email: "", contactNo: "" });
  const history = useHistory();

  useEffect(() => {
    loader(true);
    ApiClient.get("settings").then((res) => {
      if (res.success) {
        setform(res.data);
      }
      loader(false);
    });
  }, []);

  return (
    <>
      <Layout>
        <div className="pprofile1 edit-page-common p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-head d-flex justify-content-between align-items-center">
                <h3 className="ViewUser mb-0 user-back">
                  <i
                    class="fa fa-arrow-left mr-2 "
                    onClick={() => {
                      history.goBack();
                    }}
                    title="Back"
                    aria-hidden="true"
                  ></i>
                  View Settings
                </h3>

                <div className="edit-bg">
                  <Link to="/edit-settings" className="">
                    <i
                      style={{ cursor: "pointer" }}
                      class="material-icons edit"
                      title="Edit"
                    >
                      edit
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-12">
              <div className=" white-bg-main mb-4">
                <div className=" white-head mb-3">
                  <h5 class="profilelist">Basic Information</h5>
                </div>

                <div className="row p-3">
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Margin Type :</label>
                    <div className="mb-0 profiledetailscls">
                      {methodModel.capitalizeFirstLetter(form.margin_type)}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 view-flex">
                    <label className="profileheddingcls">Margin Price :</label>
                    <div className="mb-0 profiledetailscls">
                      {form.margin_value}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewSettings;
