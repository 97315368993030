import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Html from "./Html";
import ApiClient from "../../../methods/api/apiClient";

const Sidebar = () => {
  const user = useSelector((state) => state?.user);
  const history = useHistory();
  const menus = {
    user: ["carrier-requests", "staff", "carriers", "carrier", "invited-users"],
    load: ["loads", "delivered-loads,'pending-pickup-loads,'awarded-loads"],
    bids: ["bids", "delivered-bids,accepted-bids,'pending-bids,'awarded-bids"],
    boards: ["boards"],
    category: ["masterskils", "skills", "industry"],
    plan: ["features", "plans"],
    api: ["bookingSystem", "pos", "reviews", "accounting-system"],
    geo: ["continents", "countries", "regions", "cities"],
    dynamicPricing: ["dynamicprice"],
    customer: ["customer"],
    settings: ["website", "fee"],
    performance: ["performace-carriers"],
  };
  // const [Permission, setPermission] = useState({});
  const ListItemLink = ({ to, type = "link", disabled = false, ...rest }) => {
    let url = location.href;
    const host = location.host;
    url = url.split(host)[1];
    return (
      <>
        {type == "link" ? (
          <li
            className={`nav-item ${url.includes(to) ? "active" : ""} ${
              disabled ? "disabled" : ""
            }`}
          >
            {/* {...rest} */}
            <Link to={to} {...rest} className="nav-link hoverclass" />
          </li>
        ) : (
          <li
            className={`nav-item main ${url.includes(to) ? "active" : ""}`}
            {...rest}
          ></li>
        )}
      </>
    );
  };

  const tabclass = (tab) => {
    let url = location.href;
    let value = false;
    menus[tab].map((itm) => {
      if (url.includes(itm)) value = true;
    });
    return value;
  };

  const urlAllow = (url) => {
    return true;
  };

  const route = (p) => {
    history.push(p);
  };
  const Permission = JSON.parse(localStorage.getItem("permission"));

  return (
    <>
      <Html
        route={route}
        tabclass={tabclass}
        urlAllow={urlAllow}
        ListItemLink={ListItemLink}
        user={user}
        Permission={Permission}
      />
    </>
  );
};

export default Sidebar;
