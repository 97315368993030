import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Chart from "react-google-charts";
import Table from "../../components/Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function DriverPerformance() {
  const [DelayedData, setDelayedData] = useState([]);
  const [View, setView] = useState("Table");
  const [filters, setFilter] = useState({isDeleted:false});
  const history=useHistory()

  const GetDelayedData = (p={}) => {
    loader(true);
    let f={
      ...filters,
      ...p
    }
    ApiClient.get("performance/driver",f).then((res) => {
      if (res.success) {
        setDelayedData(res?.data);    
      }
      loader(false);
    });
  };

  const filter=(p={})=>{
    setFilter({
      ...filters,
      ...p
    })
    GetDelayedData(p)
  }


  const DelayedTimeGraphData = [
    [
      "Carrier",
      // "Delayed Time",
      "Total Delivered Loads",
      "Delayed Loads",
      "Percentage",
    ],
    ...((DelayedData?.length > 0 &&
      DelayedData?.map((item) => {
        return [
          `${methodModel.capitalizeFirstLetter(item?.driver_name)}`,
          // item?.total_delay_time,
          item?.total_loads,
          item?.total_delayed_loads,
          item?.on_time_delivery_rate,
        ];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  useEffect(() => {
    GetDelayedData();
  }, []);



  const columns = [
    {
      key: "load_number",
      name: "Lane ID/Load ID",
      // sort: true,
      render: (row) => {
        return <span
        onClick={()=>{
          history.push(`loads/view/${row?.load_id}`)
        }}
        className="text-primary cursor-pointer"
        data-bs-dismiss="modal"
        >{row?.load_number||row?.lane_number}</span>;
      },
    },
    // {
    //   key: "question",
    //   name: "Stops",
    //   sort: false,
    //   render: (row) => {
    //     return <div>
    //       <span className="badge badge-success common-badge" data-bs-dismiss="modal" onClick={()=>{
    //       history.push(`loads/view/${row?.load_id}`)
    //     }}>All Stops</span>
    //       {row.stops?.slice(0,2)?.map(itm=>{
    //         return <div className="mt-2">{itm.address}</div>
    //       })}
    //     </div>;
    //   },
    // },
    {
      key: "origin_location_city",
      name: "Origin City",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.origin_location_city}</span>;
      },
    },
    {
      key: "origin_location_state",
      name: "Origin State",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.origin_location_state}</span>;
      },
    },
    {
      key: "origin_location_postal_code",
      name: "Origin Zip",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.origin_location_postal_code}</span>;
      },
    },
    {
      key: "destination_location_city",
      name: "Destination City",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.destination_location_city}</span>;
      },
    },
    {
      key: "destination_location_state",
      name: "Destination State",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.destination_location_state}</span>;
      },
    },
    {
      key: "destination_location_postal_code",
      name: "Destination Zip",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.destination_location_postal_code}</span>;
      },
    },
    {
      key: "eta_value",
      name: "ETA",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.eta_value} {row?.eta_type}</span>;
      },
    },
    {
      key: "actual_delivery_time_hours",
      name: "Actual Time",
      sort: false,
      render: (row) => {
        return <span className="text-capitalize">{row?.actual_delivery_time_hours.toFixed(2)} {row?.isMinutes?'Minute':'Hour'}</span>;
      },
    },
  ];

  const [loads, setLoads] = useState([]);
  const openLoads=(itm)=>{

    setLoads(itm.loads)
    document.getElementById("openLoadsModal")?.click()
  }

  return (
    <>
      <Layout>
        <div className="row d-flex align-items-center">
          <div className="col-md-9 head_cls">
            <h3 className="hedding">Driver Performance</h3>
            <p className="paraCls"></p>
          </div>
          <div className="d-flex gap-2 ml-auto justify-content-end flex-wrap align-items-center">
            <div className="dropdown addDropdown mt-0">
              <button
                className="btn btn-primary dropdown-toggle "
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                View: {View}
              </button>
              <div
                className="dropdown-menu shadow bg_hover"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={` ${
                    View == "Table" ? "dropdown-item active" : "dropdown-item"
                  }  `}
                  onClick={() => {
                    setView("Table");
                  }}
                >
                  Table
                </a>

                <a
                  className={` ${
                    View == "Graph" ? "dropdown-item active" : "dropdown-item"
                  }  `}
                  onClick={() => {
                    setView("Graph");
                  }}
                >
                  Graph
                </a>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <span className={`nav-link cursor-pointer ${filters.isDeleted ? '' : 'active'}`} onClick={() => filter({ isDeleted: false })}>Active</span>
              </li>
              <li className="nav-item">
                <span className={`nav-link cursor-pointer ${filters.isDeleted ? 'active' : ''}`} onClick={() => filter({ isDeleted: true })}>Deleted</span>
              </li>
            </ul>
          </div>
          {View == "Table" ? (
            <div className="container mt-4">
              <h5>Delayed In Delivered</h5>
              <div className="table-responsive postings_table">
                {" "}
                <table className="table">
                  <thead>
                    <tr>
                      <th>Driver</th>
                      <th>Total Delivered Loads</th>
                      <th>Delayed Loads</th>
                      <th>Delayed Time</th>
                      <th>Percentage</th>
                      <th>Review</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DelayedData?.map((itm, i) => {
                      return (
                        <tr>
                          <td>
                            {methodModel.capitalizeFirstLetter(
                              itm?.driver_name
                            )}
                          </td>
                          <td>{itm?.total_delivered_loads}</td>
                          <td onClick={()=>{openLoads(itm)}}  className="cursor-pointer text-primary"><span 
                          >{itm?.total_delayed_loads}</span></td>
                          <td>{itm?.total_delay_time?.toFixed(1)} {itm.isMinutes?'minutes':''} {itm.isHours?'hours':''}</td>

                          <td>{itm?.on_time_delivery_rate?.toFixed()}%</td>
                          <td>
                            <i className="fa fa-star"></i> {itm.avg_rating?.toFixed(1)} ({itm.total_reviews})</td>
                        </tr>
                      );
                    })}

                    {/* <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                    <td>
                      <strong>{"--"}</strong>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-12 mt-2">
              <div className="graph_section">
                <div className="graph_range mb-3">
                  <div>
                    <h3 className="Amount_sales">Delayed Loads</h3>
                  </div>
                </div>

                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="350px"
                    data={DelayedTimeGraphData}
                    options={{
                      colors: ["#fc466b", "#4bc0bf"],
                      annotations: {
                        alwaysOutside: true,
                      },
                      legend: { position: "top" },
                      vAxis: {
                        format: "0", // Display integers only on the vertical axis
                      },
                    }} //
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>

      <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" id="openLoadsModal" data-bs-target="#loadsModal">
  Launch demo modal
</button>

<div class="modal fade" id="loadsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Loads</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <Table
      columns={columns}
      data={loads}
      total={loads.length}
      />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    </>
  );
}

export default DriverPerformance;
