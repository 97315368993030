import React, { useState } from "react";
import { DateRange } from "react-date-range";
import datepipeModel from "../../../models/datepipemodel";
import { useSelector } from "react-redux";
import "./style.scss";
import { Menu } from "@headlessui/react";
import { Tooltip } from "react-tooltip";

const DateRangePicker = ({
  value,
  onChange,
  disabled = false,
  placeholder = "",
  isCompare = false,
  fullWidth = true,
  showcustom = true,
  title = ''
}) => {
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  const [range, setRange] = useState("");

  const rangeList = [
    { id: "This Month", name: "This Month" },
    { id: "This Year", name: "This Year" },
    { id: "Last Month", name: "Last Month" },
    { id: "Last Year", name: "Last Year" },
  ];

  const compareList = [
    { id: "", name: "Reset Compare" },
    { id: "Previous Period", name: "Previous Period" },
    { id: "Previous Year(Same Date)", name: "Previous Year(Same Date)" },
  ];

  function monthsBetweenDates(d1, d2) {
    if(!d1||!d2) return 0
    let date1=new Date(d1)
    let date2=new Date(d2)
    var months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
}


  const blockDateChange = (e, close) => {
    let months=monthsBetweenDates(e.startDate,e.endDate)
    onChange({ ...value, ...e, compare: "",type: months<1?'daily':'monthly'});
    
    if (
      datepipeModel.datetostring(e.startDate) !=
      datepipeModel.datetostring(e.endDate)
    ) {
      setToggle(false);
    }
    if (e.startDate !== e.endDate) {
      close();
    }
  };

  const toggleChange = () => {
    setToggle(!toggle);
  };

  const getBlockValue = () => {
    let v = {
      startDate: value["startDate"] ? new Date(value["startDate"]) : new Date(),
      endDate: value["endDate"] ? new Date(value["endDate"]) : new Date(),
      key: "selection",
    };
    return [v];
  };

  const dropdownMenuClick = (e) => {
    // let el = document.getElementById(`daterangeDropdown-${id}`)
    // el.click()
  };

  const rangeClick = (e) => {
    let startDate = "";
    let endDate = "";

    if (e == "Last Month") {
      let month1 = new Date();
      month1 = month1.setMonth(month1.getMonth() - 1);
      month1 = datepipeModel.datetostring(month1);

      let current = new Date();
      let monthdate = current.setDate(0);
      monthdate = datepipeModel.datetostring(monthdate);
      startDate = `${month1.split("-")[0]}-${month1.split("-")[1]}-01`;
      endDate = monthdate;
    } else if (e == "This Month") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-${current.split("-")[1]}-01`;

      let month2 = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "This Year") {
      let current = datepipeModel.datetostring(new Date());
      startDate = `${current.split("-")[0]}-01-01`;

      let month2 = new Date(new Date().getFullYear() + 1, 0, 0);
      endDate = datepipeModel.datetostring(month2);
    } else if (e == "Last Year") {
      let current = new Date();
      startDate = `${current.getFullYear() - 1}-01-01`;
      let month2 = new Date(current.getFullYear(), 0, 0);
      endDate = datepipeModel.datetostring(month2);
    }
    setRange(e);
    let months=monthsBetweenDates(startDate,endDate)
    onChange({ ...value, startDate, endDate, compare: "",type: months<1?'daily':'monthly' });
    if (e != "") {
      setToggle(false);
    }
  };

  const getDays = (s, e) => {
    // Define two date objects
    const startDate = new Date(s); // Replace with your start date
    const endDate = new Date(e); // Replace with your end date
    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;
    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const previousYear = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let ssplit = value.startDate.split("-");
      let esplit = value.endDate.split("-");
      let year = Number(ssplit[0]);
      let eyear = Number(esplit[0]);

      let d = new Date(`${year - 1}-${ssplit[1]}-${ssplit[2]}`);
      start = datepipeModel.datetostring(d);

      let ed = new Date(`${eyear - 1}-${esplit[1]}-${esplit[2]}`);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  const previousMonth = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let sMonth = new Date(value.startDate).getMonth();
      start = new Date(value.startDate).setMonth(sMonth - 1);
      start = datepipeModel.datetostring(start);

      let eMonth = new Date(value.endDate).getMonth();
      end = new Date(value.endDate).setMonth(eMonth - 1);
      end = datepipeModel.datetostring(end);
    }
    return { start, end };
  };

  const previousPeriod = () => {
    let start = "";
    let end = "";
    if (value.startDate && value.endDate) {
      let days = getDays(value.startDate, value.endDate) + 1;

      let d = new Date(value.startDate);
      d.setDate(d.getDate() - days);
      start = datepipeModel.datetostring(d);

      let ed = new Date(value.startDate);
      ed.setDate(ed.getDate() - 1);
      end = datepipeModel.datetostring(ed);
    }
    return { start, end };
  };

  const compareChange = (e) => {
    let start = "";
    let end = "";
    if (e == "Previous Period") {
      start = previousPeriod().start;
      end = previousPeriod().end;
    } else if (e == "Previous Year") {
      start = previousYear().start;
      end = previousYear().end;
    } else if (e == "Previous Month") {
      start = previousMonth().start;
      end = previousMonth().end;
    }

    
    let months=monthsBetweenDates(value.startDate,value.endDate)
    let v = {
      ...value,
      compareStart: start,
      compareEnd: end,
      compare: e,
      type: months<1?'daily':'monthly'
    };
    onChange({ ...v });
  };

  return (
    <>
      <Menu as="div" className={`datepickerdropdown text-left ${fullWidth ? "w-full" : "w-fit"}`}>
        <Menu.Button  type="button" disabled={disabled} data-tooltip-id={`tooltip`} className="btn btn-primary">
          {!value?.startDate || !value?.endDate ? (
            <>{placeholder || "Start Date - End Date"}</>
          ) : (
            <>
              {datepipeModel.date(value?.startDate, user?.companyDateFormat)} -{" "}
              {datepipeModel.date(value?.endDate, user?.companyDateFormat)}
            </>
          )}
          <i className="fa fa-chevron-down ml-2"></i>
          {/* <TiArrowSortedDown className={`h-4 w-4 text-[#7E8B99]`} /> */}
        </Menu.Button>
        <Menu.Items className="datepickerdropdown-menu">
          <div className="!px-4 !py-3 ">
            <Menu.Item>
              {({ close }) => (
                <>
                  <div className="d-flex gap-md-2 gap-1 flex-wrap">
                    {rangeList.map((itm) => {
                      return (
                        <Menu.Button
                          className={`btn ${range == itm.id ? "btn-primary" : "btn-secondary"}`}
                          type="button"
                          onClick={(e) => rangeClick(itm.id)}>
                          {itm.name}
                        </Menu.Button>
                      );
                    })}
                    {showcustom == true ? <button type="button"
                      className={`btn ${range == "" ? "btn-primary" : "btn-secondary"}`}
                      onClick={(e) => rangeClick("")}>
                      Custom
                    </button> : null}
                  </div>
                  <div className="">
                    {range || showcustom == false ? (
                      <></>
                    ) : (
                      <>
                        <DateRange
                          editableDateInputs={true}
                          onChange={(e) => blockDateChange(e.selection, close)}
                          moveRangeOnFirstSelection={false}
                          ranges={getBlockValue()}
                          months={2}
                          direction="horizontal"
                          rangeColors={["#6956E5"]}
                        />
                      </>
                    )}
                  </div>
                  {isCompare ? (
                    <>
                    <div className="text-center mt-3">

                    <div className="d-inline-block text-left">
                    <Menu as="div" className="position-relative">
                        <Menu.Button type="button" className="btn btn-primary">
                          Compare
                          {/* <TiArrowSortedDown className={`h-4 w-4 text-[#7E8B99]`} /> */}
                        </Menu.Button>
                        <Menu.Items className="dropdown-menu d-block">
                          <div className="!px-4 !py-3 space-y-2">
                            <Menu.Item>
                              <a className={`dropdown-item ${!value.compare ? "active" : ""}`}
                                onClick={(e) => { compareChange(""); close() }}>
                                Reset Compare
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a className={`dropdown-item ${value.compare == "Previous Month" ? "active" : ""}`}
                                onClick={(e) => { compareChange("Previous Month"); close() }}>
                                Previous Month (
                                {datepipeModel.date(
                                  previousMonth().start,
                                  user.companyDateFormat
                                )}
                                {" "}-{" "}
                                {datepipeModel.date(
                                  previousMonth().end,
                                  user.companyDateFormat
                                )}
                                )
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a className={`dropdown-item ${value.compare == "Previous Year" ? "active" : ""}`}
                                onClick={(e) => { compareChange("Previous Year"); close() }}>
                                Previous Year(Same Date) (
                                {datepipeModel.date(
                                  previousYear().start,
                                  user.companyDateFormat
                                )}
                                {" "}-{" "}
                                {datepipeModel.date(
                                  previousYear().end,
                                  user.companyDateFormat
                                )}
                                )
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a className={`dropdown-item ${value.compare == "Previous Period" ? "active" : ""}`}
                                onClick={(e) => { compareChange("Previous Period"); close() }}>
                                Previous Period(Custom Dates) (
                                {datepipeModel.date(
                                  previousPeriod().start,
                                  user.companyDateFormat
                                )}
                                {" "}-{" "}
                                {datepipeModel.date(  
                                  previousPeriod().end,
                                  user.companyDateFormat
                                )}
                                )
                              </a>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    </div>

                    </div>
                    
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
      {title ? <>
        <Tooltip
          id={`tooltip`}
          classNameArrow="border-b border-r border-primary"
          className="z-[999] bg-white border border-primary"
          place="bottom"><div className="tooltipCal">{title}</div></Tooltip>
      </> : <></>}
      <div
        className={`dropdownOverlap ${toggle ? "d-block" : ""}`}
        onClick={(e) => toggleChange(false)} />
    </>
  )
};
export default DateRangePicker;
