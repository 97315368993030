import React, { useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import states from "us-state-codes";

import allStates from "./AllStates.json";
import { Tooltip } from "antd";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  // VT: [50, -8],
  // NH: [34, 2],
  // MA: [30, -1],
  // RI: [28, 2],
  // CT: [35, 10],
  // NJ: [34, 1],
  // DE: [33, 0],
  // MD: [47, 10],
  // DC: [49, 21],
};

const MapChart = (BidsByChartData) => {
  const maxLoadState = BidsByChartData?.data?.reduce(
    (max, itm) => {
      return itm[2] > max.load ? { state: itm[0], load: itm[2] } : max;
    },
    { state: "", load: 0 }
  );

  const GenerateColor = (id) => {
    let color = "";
    BidsByChartData?.data?.map((itm) => {
      if (itm[0] == states.getStateCodeByStateName(id?.properties?.name)) {
        if (itm[2] < 10) {
          color = "#7DA9C7";
        }
        if (itm[2] > 10) {
          color = "#7DA0C0";
        }
        if (itm[2] > 110 && itm[2] < 310) {
          color = "#6287AA";
        }
        if (itm[2] > 310) {
          color = "#3e4c5c";
        }
      }
    });
    return color || "lightgray";
  };

  return (
    <ComposableMap width={1500} fill="red" projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo, i) => (
              <Geography
                id={i}
                key={geo.rsmKey}
                stroke="#FFF"
                geography={geo}
                fill={GenerateColor(geo)} // Apply different fill color to the state with the highest load count
              />
            ))}
            {geographies.map((geo, i) => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find((s) => s.val === geo.id);
              const fullName = allStates.find((s) => s.val === geo.id).name;
              return (
                <g
                  id={i}
                  color="green"
                  fill="white"
                  enableBackground={true}
                  key={geo.rsmKey + "-name"}
                >
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      BidsByChartData?.data?.map((itm, i) => {
                        if (itm[0] === cur.id) {
                          return (
                            <Tooltip
                              id={i}
                              className="pointer"
                              title={`${states.getStateNameByStateCode(
                                itm[0]
                              )} | Total Loads: ${itm[2]} `}
                            >
                              <Marker coordinates={centroid}>
                                <circle
                                  r={0}
                                  fill={
                                    fullName === maxLoadState.state
                                      ? "#FF5733"
                                      : "white"
                                  }
                                  stroke="black"
                                />
                                <text y="2" fontSize={14} textAnchor="middle">
                                  {cur.id}
                                </text>
                              </Marker>
                            </Tooltip>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text x={4} fontSize={14} alignmentBaseline="middle">
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
