import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import { toast } from "react-toastify";
import img from "../../assets/imgpsh_fullsize_anim (1).jpeg";
import { requestForToken } from "../../firebase/configuration";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const Login = () => {
  const history = useHistory();
  const websitedetail = useSelector((state) => state.WebsiteDetails);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const SearchParams = new URLSearchParams(location?.search);
  const UserID = SearchParams.get("id");
  const [DeviceToken, setDeviceToken] = useState("");
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard");
    }

    if (id || UserID) {
      loader(true);

      ApiClient.post("user/auto-login", { id: id || UserID }).then((res) => {
        loader(false);
        if (res.success) {
          // ToastsStore.success(res.message)
          // toast.success("Login Successfully");
          if (res?.data?.role == "staff") {
            getPermission(res?.data?.id);
          }
          localStorage.setItem("token", res.data.access_token);
          const newdata = res.data;
          const data = { ...newdata, id: newdata._id };
          dispatch(login_success(res.data));
          history.push("/dashboard");
        }
      });
    }
  }, []);

  useEffect(() => {
    requestPermission();
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }
  }, []);

  const getPermission = (id) => {
    ApiClient.get(`permission/user-base?user_id=${id}`).then((res) => {
      if (res.success) {
        localStorage.setItem("permission", JSON.stringify(res?.data));
        if(res?.data?.dashboard_get)
        {
          history.push("/dashboard");
        }
        else
        {
          history.push("/profile");
        }
        // setform({...form,permissions:res?.data})
      }
    });
  };

  const requestPermission = async () => {
    await Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });
        } else if (permission == "denied") {
          requestForToken().then((token) => {
            setDeviceToken(token);
          });

          // alert("You denied Notification permission.");
        }
      })
      .catch((error) => {
        console.error("Error while requesting notification permission:", error);
      });
  };

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
      device_token: DeviceToken,
    };
    loader(true);
    if (remember) {
      localStorage.setItem("remember", JSON.stringify(data));
    } else {
      localStorage.removeItem("remember");
    }
    ApiClient.post("admin/login", data).then((res) => {
      loader(false);
      if (res.success) {
        // ToastsStore.success(res.message)
        // toast.success("Login Successfully");
        localStorage.setItem("token", res.data.access_token);
        const newdata = res.data;
        const data = { ...newdata, id: newdata._id };
        dispatch(login_success(res.data));
        if (res?.data?.role == "staff") {
          getPermission(res?.data?.id);
        }
        else
        {
          history.push("/dashboard");
        }
        
      }
    });
  };

  const HandleEmailEnter = (e) => {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key == 32) {
      //space
      e.preventDefault();
    } else {
    }
  };

  const HandleImageError = (e) => {
    e.preventDefault();
    const image = document.getElementById("logoimg");
    image.src = "/assets/img/Logo_new.png";
  };

  return (
    <>
      <div className=" main_login2">
        <div className=" center-img">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="right_sidee">
                  <div className="text-center mt-2">
                    <img
                      src={img}
                      onError={(e) => HandleImageError(e)}
                      id="logoimg"
                      className="logo_name"
                    />
                    {/* <img src={environment.imageapi+"img/"+websitedetail.logo} onError={e=>HandleImageError(e)} id='logoimg' className='logo_name'/> */}
                  </div>

                  <form className=" mt-3" onSubmit={hendleSubmit}>
                    <div className="text-center mb-4">
                      <h5 className="text-center login_heading">Log In</h5>
                      {/* <p className='accopunt'>Don’t have an account? <a class="sign_up" href="#"> Sign Up</a></p> */}
                    </div>
                    <div className="mb-3">
                      <label>
                        {" "}
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control mb-0 login_input "
                        placeholder="Email address"
                        value={username}
                        onKeyPress={(e) => HandleEmailEnter(e)}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        {" "}
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="inputWrapper">
                        <input
                          type={eyes.password ? "text" : "password"}
                          className="form-control mb-0 login_input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          maxlength="16"
                          required
                        />
                        <i
                          className={
                            eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          onClick={() =>
                            setEyes({ ...eyes, password: !eyes.password })
                          }
                        ></i>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="mr-1"
                          checked={remember}
                          onChange={(e) => setRemember(e.target.checked)}
                        />{" "}
                        <span className="remeber">Remember Me</span>{" "}
                      </label>
                      <div className="forget m-0 p-0">
                        <Link to="/forgotpassword" className="text-primary">
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button type="submit" className="btn dark-btn  mb-4 mt-3">
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
